import { mapsUrlAndSize } from "constants/mapsUrlAndSize";

export const smallMaps = [
    {
        name: "Sanhok",
        url: mapsUrlAndSize["Sanhok"].url,
        size: mapsUrlAndSize["Sanhok"].size,
    },
    {
        name: "Paramo",
        url: mapsUrlAndSize["Paramo"].url,
        size: mapsUrlAndSize["Paramo"].size,
    },
    {
        name: "Karakin",
        url: mapsUrlAndSize["Karakin"].url,
        size: mapsUrlAndSize["Karakin"].size,
        players: 64,
    },
];
