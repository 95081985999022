import React, { useState } from "react";
import {
    TextField,
    Button,
    Box,
    Typography,
    ListItem,
    ListItemText,
    List,
} from "@mui/material";
import axiosInstance from "api/axios";
import { useNavigate, Link } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import { COLORS } from "constants/appWide/colors";

export const Login: React.FC = () => {
    const { login } = useAuth();
    const navigate = useNavigate();

    // Form
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    // Error
    const [error, setError] = useState("");

    const handleLogin = async (e: React.FormEvent) => {
        e.preventDefault();
        setError("");

        try {
            const response = await axiosInstance.post("/api/auth/login", {
                username,
                password,
            });

            if (response.status === 200) {
                const token = response.data.token;
                login(token); // Pass the token to the login function
                navigate("/player-search"); // Redirect to dashboard or any protected route
            }
        } catch (err: any) {
            setError(err.response?.data?.message || "Invalid credentials");
        }
    };

    return (
        <Box
            component="form"
            onSubmit={handleLogin}
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                mt: 4,
                p: 3,
                maxWidth: 600, // Set max width
                margin: "0 auto", // Center the content
            }}
        >
            <Typography variant="h5" component="h1" gutterBottom>
                Sign In
            </Typography>
            <Typography variant="body1" gutterBottom textAlign={"left"}>
                By creating an account and signing in, you can enjoy the
                following benefits:
            </Typography>

            <Typography
                variant="body1"
                component="div"
                gutterBottom
                textAlign="left"
            >
                <List
                    sx={{
                        paddingLeft: "2.5rem",
                    }}
                >
                    <ListItem disableGutters>
                        <ListItemText
                            primary={
                                <strong>Save your favorite players:</strong>
                            }
                            secondary="Keep track of your top players and access their profiles anytime."
                        />
                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemText
                            primary={
                                <strong>Save specific match reports:</strong>
                            }
                            secondary="Choose and save your favorite match reports to revisit them anytime in the future."
                        />
                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemText
                            primary={<strong>Share your tier lists:</strong>}
                            secondary="Create and share your tier lists with friends or make them public on the website."
                        />
                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemText
                            primary={
                                <strong>Customize your experience:</strong>
                            }
                            secondary="Choose your preferred landing page for quicker navigation to what matters most to you."
                        />
                    </ListItem>
                </List>
            </Typography>

            <TextField
                label="Username"
                helperText="If you have never set a username, use your email address instead."
                variant="outlined"
                margin="normal"
                required
                fullWidth
                value={username}
                onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
                label="Password"
                variant="outlined"
                margin="normal"
                required
                fullWidth
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            {error && (
                <Typography variant="body2" color="error" sx={{ mt: 2 }}>
                    {error}
                </Typography>
            )}
            <Button
                type="submit"
                variant="contained"
                sx={{
                    mt: 2,
                    backgroundColor: COLORS.orange,
                    fontWeight: "bold",
                    ":hover": {
                        backgroundColor: COLORS.darkOrange,
                    },
                }}
            >
                Login
            </Button>

            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    mt: 2,
                }}
            >
                <Button
                    component={Link}
                    to="/register"
                    sx={{
                        textTransform: "none",
                        fontWeight: "bold",
                        color: COLORS.orange,
                        border: "1px solid orange",
                        borderRadius: "8px",
                        px: 2,
                        ":hover": {
                            backgroundColor: "rgba(255, 165, 0, 0.1)",
                        },
                    }}
                >
                    No Account? Sign up!
                </Button>
                <Button
                    component={Link}
                    to="/forgot-password"
                    sx={{
                        textTransform: "none",
                        fontWeight: "bold",
                        color: COLORS.silver,
                        border: "1px solid #c7c7c7",
                        borderRadius: "8px",
                        px: 2,
                        ":hover": {
                            backgroundColor: "rgba(199, 199, 199, 0.1)",
                        },
                    }}
                >
                    Forgot password?
                </Button>
            </Box>
        </Box>
    );
};
