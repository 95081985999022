import { API_ENDPOINTS } from "constants/API_ENDPOINTS";
import { useState, useEffect } from "react";

interface AccountData {
    accountId: string | null;
    clanId: string | null;
    banType: "PermanentBan" | "TemporaryBan" | "Innocent" | null;
}

interface UseAccountDataReturn {
    accountId: string | null;
    clanId: string | null;
    banType: "PermanentBan" | "TemporaryBan" | "Innocent" | null;
    accountLoading: boolean;
    accountError: string | null;
}

export const useAccountData = (
    platform: string | null,
    playerName: string | null
): UseAccountDataReturn => {
    const [accountId, setAccountId] = useState<string | null>(null);
    const [clanId, setClanId] = useState<string | null>(null);
    const [banType, setBanType] = useState<
        "PermanentBan" | "TemporaryBan" | "Innocent" | null
    >(null);
    const [accountLoading, setAccountLoading] = useState<boolean>(false);
    const [accountError, setAccountError] = useState<string | null>(null);

    useEffect(() => {
        if (!platform || !playerName) {
            return;
        }
        const loadAccountData = async () => {
            try {
                setAccountLoading(true);
                setAccountError(null);

                const accountResponse = await fetch(
                    API_ENDPOINTS.ACCOUNT_DATA(platform, playerName)
                );

                if (!accountResponse.ok) {
                    throw new Error("Failed to fetch account data");
                }

                const accountData: AccountData = await accountResponse.json();
                console.log("[DEBUG] Account data fetched:", accountData);

                setAccountId(accountData.accountId);
                setClanId(accountData.clanId);
                setBanType(accountData.banType);
            } catch (err) {
                console.error("[DEBUG] Error fetching account data:", err);
                setAccountError((err as Error).message);
            } finally {
                setAccountLoading(false);
                console.log("[DEBUG] Account data loading complete.");
            }
        };

        if (platform && playerName) {
            console.log("[DEBUG] Starting loadAccountData...");
            loadAccountData();
        }
    }, [platform, playerName]);

    return { accountId, clanId, banType, accountLoading, accountError };
};
