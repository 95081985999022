import React from "react";
import { Box, Typography } from "@mui/material";
import { WarningAmber } from "@mui/icons-material";

interface NiceErrorProps {
    errorMessage: string;
    errorTitle?: string;
}

const NiceError: React.FC<NiceErrorProps> = ({
    errorMessage,
    errorTitle = "Something went wrong!",
}) => {
    // Randomly pick an icon from the set
    const errorIcon = <WarningAmber fontSize="large" color="error" />;

    let customMessage = errorMessage;
    if (errorMessage === "Failed to fetch player data") {
        customMessage =
            "There is no player with that name. Please try again, ensure the platform is correct, and check that the name is spelled correctly, including capitalization.";
    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            sx={{
                marginTop: 2,
                padding: 3,
                borderRadius: 2,
                backgroundColor: "#2a2a2a",
                color: "#fff",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
            }}
        >
            <Box sx={{ marginBottom: 2 }}>{errorIcon}</Box>
            <Typography variant="h5" gutterBottom>
                {errorTitle}
            </Typography>
            <Typography variant="body1" sx={{ color: "#f44336" }}>
                {customMessage}
            </Typography>
        </Box>
    );
};

export default NiceError;
