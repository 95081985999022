import { Box, Divider, Typography } from "@mui/material";

interface Patch {
    name: string;
    date: string;
    link: string;
    pdf?: string;
    summary?: string | JSX.Element;
}

export const patches: Patch[] = [
    {
        name: "Patch 33.2",
        date: "2025.01.13",
        link: "https://pubg.com/en/news/8119",
        summary: (
            <Box>
                <Typography variant="h6" gutterBottom>
                    Sanhok Updates
                </Typography>
                <Box mb={2}>
                    <Typography variant="subtitle1" gutterBottom>
                        <strong>Loot Truck</strong>
                    </Typography>
                    <Typography variant="body2">
                        The Loot Truck has been readded to Sanhok.
                    </Typography>
                </Box>
                <Divider sx={{ marginY: 2 }} />
                <Box>
                    <Typography variant="subtitle1" gutterBottom>
                        <strong>Recall System</strong>
                    </Typography>
                    <Typography variant="body2">
                        The Recall System has been introduced to Sanhok.
                    </Typography>
                </Box>
            </Box>
        ),
    },
    {
        name: "Patch 33.1",
        date: "2024.12.02",
        link: "https://pubg.com/en/news/7988",
        summary: (
            <Box>
                <Typography variant="h6" gutterBottom>
                    Update Highlights
                </Typography>
                <Box mb={2}>
                    <Typography variant="subtitle1" gutterBottom>
                        <strong>Region Merge Rollback</strong>
                    </Typography>
                    <Typography variant="body2">
                        AMER region has been split back into NA and SA.
                    </Typography>
                </Box>
                <Divider sx={{ marginY: 2 }} />
                <Box mb={2}>
                    <Typography variant="subtitle1" gutterBottom>
                        <strong>World: Sanhok</strong>
                    </Typography>
                    <Typography variant="body2">
                        Sanhok is now a destructible map with dynamic terrain
                        changes.
                    </Typography>
                </Box>
                <Divider sx={{ marginY: 2 }} />
                <Box mb={2}>
                    <Typography variant="subtitle1" gutterBottom>
                        <strong>Gameplay: Red Zone</strong>
                    </Typography>
                    <Typography variant="body2">
                        <ul style={{ paddingLeft: "20px", margin: 0 }}>
                            <li>
                                The Red Zone (available in Erangel, Deston, and
                                Sanhok) no longer generates outside the Play
                                Area, increasing its generation rate within the
                                Play Area.
                            </li>
                            <li>
                                Damage has been reduced and decreases the
                                farther you are from the center of the
                                explosion.
                            </li>
                            <li>
                                The explosion mechanism now uses missile
                                impacts, similar to the Black Zone.
                            </li>
                            <li>
                                A delay is applied to explosions when missiles
                                hit terrain.
                            </li>
                            <li>
                                A loud sound effect plays when missiles hit the
                                ground, helping players anticipate explosions.
                            </li>
                        </ul>
                    </Typography>
                </Box>

                <Divider sx={{ marginY: 2 }} />
                <Box>
                    <Typography variant="subtitle1" gutterBottom>
                        <strong>Weapon Inspection</strong>
                    </Typography>
                    <Typography variant="body2">
                        Weapon inspect animations are now visible to others and
                        playable while sprinting.
                    </Typography>
                </Box>
            </Box>
        ),
    },
    {
        name: "Patch 32.2",
        date: "2024.11.04",
        link: "https://pubg.com/en/news/7875",
        pdf: "/patches/322.pdf",
        summary: (
            <ul style={{ paddingLeft: "20px" }}>
                <li>
                    <Typography variant="body1">
                        <strong>New Arcade Mode: Hot Drop</strong>: This mode
                        delivers intense, fast-paced gameplay by confining
                        players to small zones on Erangel, Miramar, Vikendi, or
                        Taego. Blue Zones shrink rapidly, pushing for quick
                        looting and relentless combat.
                    </Typography>
                </li>
                <li style={{ marginTop: "8px" }}>
                    <Typography variant="body1">
                        <strong>New Vehicle: Inflatable Boat</strong>: A
                        stealthy watercraft powered by oars, seating up to four
                        players. It allows for quieter water navigation,
                        achieving speeds of up to 50 km/h with coordinated
                        rowing.
                    </Typography>
                </li>
            </ul>
        ),
    },
    {
        name: "Patch 32.1",
        date: "2024.10.09",
        link: "https://pubg.com/en/news/7810",
        summary: (
            <Box mb={2}>
                <Typography variant="h6" gutterBottom>
                    Update 32.1 Highlights
                </Typography>

                <Box mb={2}>
                    <Typography variant="subtitle1" gutterBottom>
                        <strong>Region Merge</strong>
                    </Typography>
                    <Typography
                        variant="body2"
                        component="ul"
                        sx={{ paddingLeft: 2 }}
                    >
                        <li>
                            PC Regions: AS + KR/JP → AS, NA + SA → AMER, OC +
                            SEA → SEA.
                        </li>
                        <li>
                            Console Regions: NA + SA → AMER, AS + OC → APAC.
                        </li>
                    </Typography>
                </Box>

                <Divider sx={{ marginY: 2 }} />

                <Box mb={2}>
                    <Typography variant="subtitle1" gutterBottom>
                        <strong>Blue Chip Locations</strong>
                    </Typography>
                    <Typography
                        variant="body2"
                        component="ul"
                        sx={{ paddingLeft: 2 }}
                    >
                        <li>
                            Teammates' Blue Chip locations are now visible on
                            the World Map and Minimap.
                        </li>
                        <li>
                            Visibility ends when a chip is picked up, destroyed,
                            or removed.
                        </li>
                        <li>All Blue Chips can now be obtained instantly.</li>
                    </Typography>
                </Box>

                <Divider sx={{ marginY: 2 }} />

                <Box mb={2}>
                    <Typography variant="subtitle1" gutterBottom>
                        <strong>Vikendi</strong>
                    </Typography>
                    <Typography
                        variant="body2"
                        component="ul"
                        sx={{ paddingLeft: 2 }}
                    >
                        <li>The Comeback BR map has been removed.</li>
                    </Typography>
                </Box>

                <Divider sx={{ marginY: 2 }} />

                <Box mb={2}>
                    <Typography variant="subtitle1" gutterBottom>
                        <strong>Arcade: Hungers Left Behind</strong>
                    </Typography>
                    <Typography
                        variant="body2"
                        component="ul"
                        sx={{ paddingLeft: 2 }}
                    >
                        <li>
                            Survive against the Hungers, now more powerful and
                            seeking revenge.
                        </li>
                        <li>Difficulty: Nightmare.</li>
                    </Typography>
                </Box>

                <Divider sx={{ marginY: 2 }} />

                <Box mb={2}>
                    <Typography variant="subtitle1" gutterBottom>
                        <strong>Steam Inventory</strong>
                    </Typography>
                    <Typography
                        variant="body2"
                        component="ul"
                        sx={{ paddingLeft: 2 }}
                    >
                        <li>
                            The Steam Inventory database has been transferred to
                            the PUBG Inventory DB.
                        </li>
                    </Typography>
                </Box>
            </Box>
        ),
    },
    {
        name: "Patch 31.2",
        date: "2024.09.03",
        link: "https://pubg.com/en/news/7624",
        summary: (
            <Box mb={2}>
                <Typography variant="h6" gutterBottom>
                    Update Highlights
                </Typography>

                <Box mb={2}>
                    <Typography variant="subtitle1" gutterBottom>
                        <strong>World: Taego</strong>
                    </Typography>
                    <Typography
                        variant="body2"
                        component="ul"
                        sx={{ paddingLeft: 2 }}
                    >
                        <li>Modifications have been made to areas in Taego.</li>
                    </Typography>
                </Box>

                <Divider sx={{ marginY: 2 }} />

                <Box mb={2}>
                    <Typography variant="subtitle1" gutterBottom>
                        <strong>Gameplay</strong>
                    </Typography>
                    <Typography
                        variant="body2"
                        component="ul"
                        sx={{ paddingLeft: 2 }}
                    >
                        <li>
                            <strong>Airplanes:</strong> Planes have been
                            replaced with new models after 7 years of service,
                            except for Paramo.
                        </li>
                        <li>
                            <strong>Bot Refinement:</strong> Enhanced bot travel
                            algorithms for long-distance navigation.
                        </li>
                    </Typography>
                </Box>

                <Divider sx={{ marginY: 2 }} />

                <Box mb={2}>
                    <Typography variant="subtitle1" gutterBottom>
                        <strong>Training Mode</strong>
                    </Typography>
                    <Typography
                        variant="body2"
                        component="ul"
                        sx={{ paddingLeft: 2 }}
                    >
                        <li>
                            <strong>Infinite Ammo:</strong> Ammo can now be
                            added using the reload button in Training and Aim
                            Training. Removed from 1v1 Arena.
                        </li>
                        <li>
                            <strong>Recommended Attachments:</strong> Spawn Tool
                            now equips recommended attachments by default.
                        </li>
                        <li>
                            <strong>Save/Load Gear Set:</strong> Save and load
                            your favorite gear sets in Training Mode. Newly
                            added buttons replace older Spawn Tool features.
                        </li>
                    </Typography>
                </Box>
            </Box>
        ),
    },
    {
        name: "Patch 31.1",
        date: "2024.08.06",
        link: "https://pubg.com/en/news/7582",
        summary: (
            <Box mb={2}>
                <Typography variant="h6" gutterBottom>
                    Update Highlights
                </Typography>

                <Box mb={2}>
                    <Typography variant="subtitle1" gutterBottom>
                        <strong>Gunplay</strong>
                    </Typography>
                    <Typography variant="body2">
                        <strong>New Attachment: Muzzle Brake</strong> -
                        Compatible with most ARs, DMRs, and some Shotguns.
                    </Typography>
                    <ul style={{ marginLeft: "16px", marginTop: "4px" }}>
                        <li>
                            <Typography variant="body2">
                                Vertical recoil control: +10%
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body2">
                                Horizontal recoil control: +10%
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body2">
                                Camera shake control when firing: +50%
                            </Typography>
                        </li>
                    </ul>
                </Box>

                <Divider sx={{ marginY: 2 }} />

                <Box mb={2}>
                    <Typography variant="subtitle1" gutterBottom>
                        <strong>World: Rondo</strong>
                    </Typography>
                    <Typography variant="body2">
                        Improved terrain to limit the advantage of players
                        positioned on stone mountains.
                    </Typography>
                </Box>

                <Divider sx={{ marginY: 2 }} />

                <Box mb={2}>
                    <Typography variant="subtitle1" gutterBottom>
                        <strong>Casual Mode</strong>
                    </Typography>
                    <Typography variant="body2">
                        Casual Mode now resets daily at 2 AM UTC (previously 12
                        AM UTC).
                    </Typography>
                </Box>
            </Box>
        ),
    },
    {
        name: "Patch 30.2",
        date: "2024.07.09",
        link: "https://pubg.com/en/news/7494",
        summary: (
            <Box mb={2}>
                <Typography variant="h6" gutterBottom>
                    Update Highlights
                </Typography>

                <Box mb={2}>
                    <Typography variant="subtitle1" gutterBottom>
                        <strong>Gameplay Updates</strong>
                    </Typography>
                    <Typography
                        variant="body2"
                        component="ul"
                        sx={{ paddingLeft: 2 }}
                    >
                        <li>
                            <strong>Tactical Pack</strong>: Attachment slots
                            increased to 3.
                        </li>
                        <li>
                            <strong>EMT Gear</strong>: Faster healing and
                            revival speed (5 seconds).
                        </li>
                        <li>
                            <strong>All-In-One Repair Kit</strong>: Usable up to
                            3 times.
                        </li>
                        <li>
                            Tactical items now spawn globally in Normal and
                            Custom Matches.
                        </li>
                    </Typography>
                </Box>

                <Divider sx={{ marginY: 2 }} />

                <Box mb={2}>
                    <Typography variant="subtitle1" gutterBottom>
                        <strong>Easier Looting</strong>
                    </Typography>
                    <Typography
                        variant="body2"
                        component="ul"
                        sx={{ paddingLeft: 2 }}
                    >
                        <li>
                            Display improvements for item availability, count,
                            and cooldown.
                        </li>
                        <li>
                            Gear recommendation highlights more useful gear and
                            destroyed items.
                        </li>
                        <li>
                            Improved UI for Inventory, attachments, and looting
                            mechanics.
                        </li>
                    </Typography>
                </Box>

                <Divider sx={{ marginY: 2 }} />

                <Box mb={2}>
                    <Typography variant="subtitle1" gutterBottom>
                        <strong>2D Replay</strong>
                    </Typography>
                    <Typography
                        variant="body2"
                        component="ul"
                        sx={{ paddingLeft: 2 }}
                    >
                        <li>New replay feature available in Match History.</li>
                        <li>
                            Check match details, maps, engagements, Zones, and
                            player info.
                        </li>
                        <li>Playback speeds of up to 20x on PC.</li>
                    </Typography>
                </Box>

                <Divider sx={{ marginY: 2 }} />

                <Box mb={2}>
                    <Typography variant="subtitle1" gutterBottom>
                        <strong>Arcade: Fantasy Battle Royale</strong>
                    </Typography>
                    <Typography
                        variant="body2"
                        component="ul"
                        sx={{ paddingLeft: 2 }}
                    >
                        <li>
                            The fan-favorite mode returns on Dragon’s Isle
                            (Erangel).
                        </li>
                        <li>TPP mode with 20 squads of 4 players.</li>
                        <li>No friendly fire enabled.</li>
                    </Typography>
                </Box>
            </Box>
        ),
    },
    {
        name: "Patch 30.1",
        date: "2024.06.11",
        link: "https://pubg.com/en/news/7424",
        summary: (
            <Box mb={2}>
                <Typography variant="h6" gutterBottom>
                    Update Highlights
                </Typography>

                <Box mb={2}>
                    <Typography variant="subtitle1" gutterBottom>
                        <strong>Arcade</strong>
                    </Typography>
                    <Typography variant="body2">
                        Bluebomb Rush has been temporarily removed and will
                        return in future updates.
                    </Typography>
                </Box>

                <Divider sx={{ marginY: 2 }} />

                <Box mb={2}>
                    <Typography variant="subtitle1" gutterBottom>
                        <strong>Custom Match</strong>
                    </Typography>
                    <Typography
                        variant="body2"
                        component="ul"
                        sx={{ paddingLeft: 2 }}
                    >
                        <li>
                            Erangel Classic has been removed from Normal Mode.
                        </li>
                        <li>
                            War Mode, War Mode: Conquest, and Zombie Mode have
                            been removed.
                        </li>
                        <li>
                            (PC) In Sandbox Mode, the Tactical Gear category and
                            items have been removed, while the LMG category has
                            been added.
                        </li>
                    </Typography>
                </Box>

                <Divider sx={{ marginY: 2 }} />

                <Box mb={2}>
                    <Typography variant="subtitle1" gutterBottom>
                        <strong>Training Mode</strong>
                    </Typography>
                    <Typography
                        variant="body2"
                        component="ul"
                        sx={{ paddingLeft: 2 }}
                    >
                        <li>
                            Tactical Gear category and items have been removed
                            from the Spawn Tool.
                        </li>
                        <li>
                            The LMG category has been added, with the DP-28,
                            M249, and MG3 moved to this category.
                        </li>
                    </Typography>
                </Box>
            </Box>
        ),
    },
    {
        name: "Patch 29.2",
        date: "2024.05.13",
        link: "https://pubg.com/en/news/7172",
    },
    {
        name: "Patch 29.1",
        date: "2024.04.08",
        link: "https://pubg.com/en/news/7172",
    },
    {
        name: "Patch 28.2",
        date: "2024.03.12",
        link: "https://pubg.com/en/news/7038",
    },
    {
        name: "Patch 28.1",
        date: "2024.02.06",
        link: "https://pubg.com/en/news/7038",
    },
    {
        name: "Patch 27.2",
        date: "2024.01.09",
        link: "https://pubg.com/en/news/6996",
    },
    {
        name: "Patch 27.1",
        date: "2023.12.12",
        link: "https://pubg.com/en/news/6939",
    },
    {
        name: "Patch 26.2",
        date: "2023.11.08",
        link: "https://pubg.com/en/news/6833",
    },
    {
        name: "Patch 26.1",
        date: "2023.10.11",
        link: "https://pubg.com/en/news/6717",
    },
    {
        name: "Patch 25.2",
        date: "2023.09.06",
        link: "https://pubg.com/en/news/6616",
    },
    {
        name: "Patch 25.1",
        date: "2023.08.09",
        link: "https://pubg.com/en/news/6548",
    },
    {
        name: "Patch 24.2",
        date: "2023.07.04",
        link: "https://pubg.com/en/news/6453",
    },
    {
        name: "Patch 24.1",
        date: "2023.06.07",
        link: "https://pubg.com/en/news/6359",
    },
    {
        name: "Patch 23.2",
        date: "2023.05.10",
        link: "https://pubg.com/en/news/6292",
    },
    {
        name: "Patch 23.1",
        date: "2023.04.12",
        link: "https://pubg.com/en/news/5040",
    },
    {
        name: "Patch 22.2",
        date: "2023.03.07",
        link: "https://pubg.com/en/news/4555",
    },
    {
        name: "Patch 22.1",
        date: "2023.02.08",
        link: "https://pubg.com/en/news/4553",
    },
    {
        name: "Patch 21.2",
        date: "2023.01.11",
        link: "https://pubg.com/en/news/4552",
    },
    {
        name: "Patch 21.1",
        date: "2022.12.14",
        link: "https://pubg.com/en/news/1721",
    },
    {
        name: "Patch 20.2",
        date: "2022.11.09",
        link: "https://pubg.com/en/news/1724",
    },
    {
        name: "Patch 20.1",
        date: "2022.10.12",
        link: "https://pubg.com/en/news/1730",
    },
    {
        name: "Patch 19.2",
        date: "2022.09.07",
        link: "https://pubg.com/en/news/1734",
    },
    {
        name: "Patch 19.1",
        date: "2022.08.10",
        link: "https://pubg.com/en/news/1738",
    },
    {
        name: "Patch 18.2",
        date: "2022.07.06",
        link: "https://pubg.com/en/news/1741",
    },
    {
        name: "Patch 18.1",
        date: "2022.06.08",
        link: "https://pubg.com/en/news/1742",
    },
    {
        name: "Patch 17.2",
        date: "2022.05.11",
        link: "https://pubg.com/en/news/1713",
    },
    {
        name: "Patch 17.1",
        date: "2022.04.13",
        link: "https://pubg.com/en/news/1715",
    },
    {
        name: "Patch 16.2",
        date: "2022.03.09",
        link: "https://pubg.com/en/news/1717",
    },
    {
        name: "Patch 16.1",
        date: "2022.02.09",
        link: "https://pubg.com/en/news/1719",
    },
    {
        name: "Patch 15.2",
        date: "2022.01.12",
        link: "https://pubg.com/en/news/1725",
    },
    {
        name: "Patch 15.1",
        date: "2021.12.08",
        link: "https://pubg.com/en/news/1727",
    },
    {
        name: "Patch 14.2",
        date: "2021.11.10",
        link: "https://pubg.com/en/news/1735",
    },
    {
        name: "Patch 14.1",
        date: "2021.10.06",
        link: "https://pubg.com/en/news/1714",
    },
    {
        name: "Patch 13.2",
        date: "2021.09.01",
        link: "https://pubg.com/en/news/1720",
    },
    {
        name: "Patch 13.1",
        date: "2021.08.04",
        link: "https://pubg.com/en/news/1729",
    },
    {
        name: "Patch 12.2",
        date: "2021.07.07",
        link: "https://pubg.com/en/news/1740",
    },
    {
        name: "Patch 12.1",
        date: "2021.06.09",
        link: "https://pubg.com/en/news/1744",
    },
    {
        name: "Patch 11.2",
        date: "2021.05.12",
        link: "https://pubg.com/en/news/1710",
    },
];
