import React from "react";
import { IconButton, Avatar } from "@mui/material";

interface AvatarButtonProps {
    onClick: (event: React.MouseEvent<HTMLElement>) => void;
    avatarSrc: string;
    altText?: string;
    iconSize?: number; // Optional, default is 40
}

const AvatarButton: React.FC<AvatarButtonProps> = ({
    onClick,
    avatarSrc,
    altText = "User Avatar",
    iconSize = 40,
}) => {
    return (
        <IconButton
            edge="end"
            color="inherit"
            onClick={onClick}
            sx={{
                padding: 0, // Remove default padding
                margin: 0, // Remove any added margin
            }}
        >
            <Avatar
                alt={altText}
                src={avatarSrc}
                sx={{
                    width: iconSize,
                    height: iconSize,
                }}
            />
        </IconButton>
    );
};

export default AvatarButton;
