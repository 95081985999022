export const formatIncludingExcludingMode = (
    mode: string,
    prefix: string
): string => {
    if (mode.toLowerCase() === "casual") {
        // Capitalize only the first letter for "Casual"
        return `${prefix} ${
            mode.charAt(0).toUpperCase() + mode.slice(1).toLowerCase()
        }`;
    }
    // Keep other modes in uppercase
    return `${prefix} ${mode.toUpperCase()}`;
};
