import React, { useState, useEffect } from "react";
import { Box, Typography, CircularProgress } from "@mui/material";
import {
    SportsEsports,
    EmojiEvents,
    Timeline,
    AirplanemodeActive,
    Group as GroupIcon,
} from "@mui/icons-material";

// Original funnyMessages array
const funnyMessages = [
    { message: "Calculating your K/D...", icon: <Timeline fontSize="large" /> },
    {
        message: "Too many wins to count...",
        icon: <EmojiEvents fontSize="large" />,
    },
    {
        message: "Double-checking your headshots...",
        icon: <SportsEsports fontSize="large" />,
    },
    {
        message: "Loading the loot drop...",
        icon: <AirplanemodeActive fontSize="large" />,
    },
    {
        message: "Reviving your teammate...",
        icon: <GroupIcon fontSize="large" />,
    },
];

const shuffleArray = (array: typeof funnyMessages) => {
    return array
        .map((item) => ({ item, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ item }) => item);
};

const FunnyLoading = () => {
    const [index, setIndex] = useState(0);
    const [shuffledMessages, setShuffledMessages] = useState(funnyMessages);

    useEffect(() => {
        // Shuffle messages on mount
        setShuffledMessages(shuffleArray(funnyMessages));

        const interval = setInterval(() => {
            setIndex((prevIndex) => (prevIndex + 1) % funnyMessages.length);
        }, 1200);

        return () => clearInterval(interval); // Clean up interval on unmount
    }, []);

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height="60vh"
        >
            <Box position="relative" display="inline-flex">
                <CircularProgress size={80} />
                <Box
                    position="absolute"
                    top="50%"
                    left="50%"
                    sx={{
                        transform: "translate(-50%, -50%)",
                    }}
                >
                    {shuffledMessages[index].icon}
                </Box>
            </Box>
            <Typography variant="h6" sx={{ mt: 2, textAlign: "center" }}>
                {shuffledMessages[index].message}
            </Typography>
        </Box>
    );
};

export default FunnyLoading;
