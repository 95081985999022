// components/CardView.tsx
import React from "react";
import { Grid } from "@mui/material";
import MatchCard from "./MatchCard";
import SessionCard from "./SessionCard";
import { SessionStats } from "utils/calculateSessionStats";
import { Match } from "types/match";
import { ITEMS_PER_PAGE } from "pages/playerStats/RecentMatches";

interface CardViewProps {
    matches: Match[];
    sessions: { sessionId: number; matches: Match[] }[];
    sessionStats: SessionStats[];
    accountId: string | null;
    platform: string;
    playerName: string;
    currentPage: number;
    favoriteGameMode: string;
    showImages: boolean;
    viewType: "compactCard" | "detailedCard";
    sessionsView: boolean;
    sessionsOnly: boolean;
}

const CardView: React.FC<CardViewProps> = ({
    matches,
    sessions,
    sessionStats,
    accountId,
    platform,
    playerName,
    currentPage,
    favoriteGameMode,
    showImages,
    viewType,
    sessionsView,
    sessionsOnly,
}) => {
    return (
        <Grid container spacing={2}>
            {matches.map((match, index) => {
                const globalIndex =
                    index + 1 + (currentPage - 1) * ITEMS_PER_PAGE;
                const sessionForMatch = sessions.find((session) =>
                    session.matches.some((m) => m.matchId === match.matchId)
                );

                const isLastMatchInSession =
                    sessionForMatch &&
                    sessionForMatch.matches[sessionForMatch.matches.length - 1]
                        .matchId === match.matchId;

                return (
                    <React.Fragment key={match.matchId}>
                        {sessionsView && isLastMatchInSession && (
                            <Grid item xs={12}>
                                <SessionCard
                                    session={
                                        sessionStats.find(
                                            (s: SessionStats) =>
                                                s.sessionId ===
                                                sessionForMatch?.sessionId
                                        )!
                                    }
                                    viewType={viewType}
                                />
                            </Grid>
                        )}
                        {!sessionsOnly && (
                            <Grid item xs={12} md={6} lg={4}>
                                <MatchCard
                                    match={match}
                                    onMatchHistoryPage
                                    matchIndex={globalIndex}
                                    accountId={accountId}
                                    viewType={viewType}
                                    platform={platform}
                                    playerName={playerName}
                                    favoriteGameMode={favoriteGameMode}
                                    showImages={showImages}
                                />
                            </Grid>
                        )}
                    </React.Fragment>
                );
            })}
        </Grid>
    );
};

export default CardView;
