export const mapNameMapping: { [key: string]: string } = {
    Baltic_Main: "Erangel",
    Desert_Main: "Miramar",
    Tiger_Main: "Taego",
    DihorOtok_Main: "Vikendi",
    Neon_Main: "Rondo",
    Kiki_Main: "Deston",
    Savage_Main: "Sanhok",
    Summerland_Main: "Karakin",
    Chimera_Main: "Paramo",
    // Training
    Range_Main: "Camp Jackal",
    // TDM
    Boardwalk_Main: "Boardwalk",
    Italy_TDM_Main: "Liana",
    PillarCompound_Main: "Pillar Compound",
};
