import React from "react";
import { Box, Typography } from "@mui/material";
import { ammoIcons } from "constants/ammoIcons";

interface WeaponCardProps {
    src?: string; // Make this optional
    title?: string; // Make this optional
    crateWeapon?: boolean; // Make this optional
    ammoType?: string; // Make this optional
    weaponType?: string; // Make this optional
    draggable?: boolean; // Make this optional
}

export const WeaponCard: React.FC<WeaponCardProps> = ({
    src = "default-image-url.png", // Provide a default image
    title = "Unknown Weapon",
    crateWeapon,
    ammoType,
    weaponType,
    draggable = false,
}) => {
    return (
        <Box
            sx={{
                textAlign: "center",
                margin: "0 auto",
            }}
        >
            <Typography variant="subtitle2">{title}</Typography>
            {/* Weapon Image Container */}
            <Box
                sx={{
                    position: "relative", // Set this to make it the positioning context
                    display: "inline-block", // Ensure it wraps only the image and icons
                }}
            >
                {/* Weapon Image */}
                <Box
                    component="img"
                    src={src}
                    alt={title}
                    sx={{
                        width: "100px",
                        cursor: draggable ? "pointer" : "default",
                        borderRadius: "4px",
                        boxShadow: 3,
                    }}
                />
                {/* Crate Weapon Icon */}
                {crateWeapon && (
                    <Box
                        component="img"
                        src="https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Icons/CarePackage/CarePackage_Normal.png"
                        alt="Crate Icon"
                        sx={{
                            position: "absolute",
                            bottom: "10px",
                            left: "5px",
                            width: "24px",
                            height: "24px",
                        }}
                    />
                )}
                {/* Ammo Type Icon */}
                {ammoType && ammoIcons[ammoType] && (
                    <Box
                        component="img"
                        src={ammoIcons[ammoType]}
                        alt={`${ammoType} Ammo Icon`}
                        sx={{
                            position: "absolute",
                            top: "10px",
                            right: "5px",
                            width: "24px",
                            height: "24px",
                        }}
                    />
                )}
            </Box>
        </Box>
    );
};

export default WeaponCard;
