import { API_ENDPOINTS } from "constants/API_ENDPOINTS";
import { useState, useEffect } from "react";

export const useSavedPlayers = (isAuthenticated: boolean) => {
    const [savedPlayers, setSavedPlayers] = useState<{
        myAccounts: { playerName: string; platform: string }[];
        myFriends: { playerName: string; platform: string }[];
        following: { playerName: string; platform: string }[];
    }>({
        myAccounts: [],
        myFriends: [],
        following: [],
    });

    const [loadingSavedPlayers, setLoadingSavedPlayers] = useState(false);

    useEffect(() => {
        if (isAuthenticated) {
            const fetchSavedPlayers = async () => {
                setLoadingSavedPlayers(true);
                try {
                    const token = localStorage.getItem("authToken");
                    const response = await fetch(API_ENDPOINTS.SAVED_PLAYERS, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });

                    if (!response.ok) {
                        throw new Error("Failed to fetch saved players");
                    }

                    const data = await response.json();
                    setSavedPlayers({
                        myAccounts: data.myAccounts || [],
                        myFriends: data.myFriends || [],
                        following: data.following || [],
                    });
                } catch (error) {
                    console.error("Error fetching saved players:", error);
                } finally {
                    setLoadingSavedPlayers(false);
                }
            };

            fetchSavedPlayers();
        }
    }, [isAuthenticated]);

    return { savedPlayers, loadingSavedPlayers };
};
