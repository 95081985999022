import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import { Navbar } from "./components/navbar/Navbar";
import { Home } from "./pages/Home";
import { AccountSettings } from "./pages/usersOnly/AccountSettings";
import { Tech } from "./pages/Tech";
import { Register } from "./pages/usersOnly/Register";
import { Login } from "./pages/usersOnly/Login";
import { AuthProvider } from "./AuthContext";
import { MapsLanding } from "./pages/maps/MapsLanding";
import { TipsStrategies } from "./pages/TipsStrategies";
import TierListMaker from "./pages/tierLists/TierListMaker";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { PatchesPage } from "./pages/PatchesPage";
import { PublicTierLists } from "./pages/tierLists/PublicTierLists";
import ProtectedRoute from "./routers/ProtectedRoute";
import { TierListsLanding } from "./pages/tierLists/TierListsLanding";
import { ErangelSecretRooms } from "./pages/maps/ErangelSecretRooms";
import { TaegoSecretRooms } from "./pages/maps/TaegoSecretRooms";
import { DestonSecretRooms } from "./pages/maps/DestonSecretRooms";
import { FourKMaps } from "./pages/maps/FourKMaps";
import IBRCrates from "routers/IBRRouter";
import { FAQ } from "pages/FAQ";
import { Contact } from "pages/Contact";
import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import PlayerStatsLandingPage from "pages/playerStats/PlayerStatsLanding";
import PlayerSearch from "pages/playerStats/PlayerSearch";
import Footer from "components/Footer";
import Resources from "pages/Resources";
import ReactGA from "react-ga4";
import PrivacyPolicy from "pages/PrivacyPolicy";
import SupportUsPage from "pages/SupportUsPage";
import SavedPlayers from "pages/usersOnly/SavedPlayers";
import SavedMatches from "pages/usersOnly/SavedMatches";
import { ForgotPassword } from "pages/usersOnly/ForgotPassword";
import { ResetPassword } from "pages/usersOnly/ResetPassword";
import { EmailVerification } from "pages/usersOnly/EmailVerification";
import { TouchBackend } from "react-dnd-touch-backend";
import { useMediaQuery, useTheme } from "@mui/material";
import { Preferences } from "pages/usersOnly/Preferences";
import VersionHistory from "pages/VersionHistory";
import { getDynamicTheme } from "themes/getDynamicTheme";
import ScrollToTop from "components/ScrollToTop";
import { TDMMaps } from "pages/maps/TDMMaps";
import { LandingPageProvider } from "components/LandingPageProvider";
import TurnstileWrapper from "components/TurnstileWrapper";
import MapRotationPage from "pages/MapRotationPage";
import axios from "axios";
import Cookies from "js-cookie";
import FunnyLoading from "components/FunnyLoading";

const App: React.FC = () => {
    const GA_MEASUREMENT_ID = process.env.REACT_APP_ANALYTICS_ID;

    // Turnstile verification
    const [isVerified, setIsVerified] = useState(false);
    const [loading, setLoading] = useState(true);

    // Check if the user is already verified
    useEffect(() => {
        const checkVerification = async () => {
            // Check client-side cookie first
            const clientVerified = Cookies.get("turnstileVerifiedClient");

            if (clientVerified === "true") {
                setIsVerified(true);
                setLoading(false); // Skip backend validation if cookie exists
                return;
            }

            // Fallback to backend validation
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/api/check-verification`,
                    { withCredentials: true }
                );

                setIsVerified(response.data.verified);
            } catch (error) {
                console.error("Error checking verification:", error);
                setIsVerified(false);
            } finally {
                setLoading(false);
            }
        };

        checkVerification();
    }, []);

    // Turnstile verification
    const handleVerification = async (token: string) => {
        try {
            // Validate token with the backend
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/validate-turnstile`,
                { token },
                { withCredentials: true } // Include cookies in the request
            );

            if (response.data.success) {
                setIsVerified(true); // Successfully verified
            } else {
                console.error("Turnstile validation failed.");
            }
        } catch (error) {
            console.error("Error validating Turnstile token:", error);
        }
    };
    //////////////////////////////

    useEffect(() => {
        if (process.env.NODE_ENV === "production" && GA_MEASUREMENT_ID) {
            ReactGA.initialize(GA_MEASUREMENT_ID);
            ReactGA.send("pageview");
        }
    }, [GA_MEASUREMENT_ID]);

    const [themePreferences, setThemePreferences] = useState(() => {
        const storedPreferences = localStorage.getItem("themePreferences");
        return storedPreferences
            ? JSON.parse(storedPreferences)
            : {
                  primaryColor: "#FFA500",
                  accentColor1: "#FF8C00",
                  accentColor2: "#2196f3",
                  backgroundColor: "#222222",
                  fontSize: 14,
                  fontFamily: "'Arial', sans-serif",
                  landingPage: "home",
              };
    });

    const theme = getDynamicTheme(themePreferences);
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    if (process.env.REACT_APP_API_URL != "http://localhost:5000") {
        console.log = () => {}; // Disable console.log
        console.warn = () => {}; // Optionally disable console.warn
        console.error = () => {}; // Optionally disable console.error
    }

    if (loading) {
        return null;
    }

    if (!isVerified) {
        return <TurnstileWrapper onVerified={handleVerification} />;
    }

    return (
        <LandingPageProvider>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <AuthProvider>
                    <Router>
                        <ScrollToTop>
                            {/* Main layout container */}
                            <div
                                className="App"
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    minHeight: "100vh",
                                }}
                            >
                                <Navbar />
                                {/* Content wrapper with flex-grow to fill space */}
                                <div style={{ flex: 1 }}>
                                    <Routes>
                                        <Route path="/" element={<Home />} />
                                        {/* Login / Registration / Account Routes */}
                                        <Route
                                            path="/login"
                                            element={<Login />}
                                        />
                                        <Route
                                            path="/account-settings"
                                            element={
                                                <ProtectedRoute>
                                                    <AccountSettings />
                                                </ProtectedRoute>
                                            }
                                        />
                                        <Route
                                            path="/preferences"
                                            element={
                                                <ProtectedRoute>
                                                    <Preferences
                                                        setThemePreferences={
                                                            setThemePreferences
                                                        }
                                                    />
                                                </ProtectedRoute>
                                            }
                                        />
                                        <Route
                                            path="/saved-players"
                                            element={
                                                <ProtectedRoute>
                                                    <SavedPlayers />
                                                </ProtectedRoute>
                                            }
                                        />
                                        <Route
                                            path="/saved-matches"
                                            element={
                                                <ProtectedRoute>
                                                    <SavedMatches />
                                                </ProtectedRoute>
                                            }
                                        />

                                        {/* Tech Stack */}
                                        <Route
                                            path="/tech"
                                            element={<Tech />}
                                        />
                                        <Route
                                            path="/showTechStack"
                                            element={<Tech />}
                                        />
                                        {/* ---------------------------- */}
                                        {/* FAQ / Feedback / Register Routes */}
                                        <Route path="/faq" element={<FAQ />} />
                                        <Route
                                            path="/contact"
                                            element={<Contact />}
                                        />
                                        <Route
                                            path="/privacy-policy"
                                            element={<PrivacyPolicy />}
                                        />
                                        <Route
                                            path="/version-history"
                                            element={<VersionHistory />}
                                        />
                                        <Route
                                            path="/register"
                                            element={<Register />}
                                        />
                                        <Route
                                            path="/verify"
                                            element={<EmailVerification />}
                                        />
                                        <Route
                                            path="/forgot-password"
                                            element={<ForgotPassword />}
                                        />
                                        <Route
                                            path="/reset-password"
                                            element={<ResetPassword />}
                                        />
                                        {/* ---------------------------- */}
                                        {/* Player Stats Routes */}
                                        <Route
                                            path="/player-search"
                                            element={<PlayerSearch />}
                                        />
                                        <Route
                                            path="/player-stats/:platform/:playerName/*"
                                            element={<PlayerStatsLandingPage />}
                                        />

                                        {/* ---------------------------- */}
                                        {/* Tier List Routes */}
                                        <Route
                                            path="/tier-lists"
                                            element={<TierListsLanding />}
                                        />

                                        <Route
                                            path="/tier-lists/maker"
                                            element={
                                                <DndProvider
                                                    backend={
                                                        isMobile
                                                            ? TouchBackend
                                                            : HTML5Backend
                                                    }
                                                >
                                                    <TierListMaker />
                                                </DndProvider>
                                            }
                                        />
                                        <Route
                                            path="/tier-lists/public"
                                            element={<PublicTierLists />}
                                        />
                                        <Route
                                            path="/tier-lists/maker/:id"
                                            element={
                                                <DndProvider
                                                    backend={
                                                        isMobile
                                                            ? TouchBackend
                                                            : HTML5Backend
                                                    }
                                                >
                                                    <TierListMaker />
                                                </DndProvider>
                                            }
                                        />
                                        {/* ---------------------------- */}

                                        {/* Maps Routes */}
                                        <Route
                                            path="/maps"
                                            element={<MapsLanding />}
                                        />
                                        <Route
                                            path="/maps/4k-maps"
                                            element={<FourKMaps />}
                                        />
                                        <Route
                                            path="/maps/4k-maps/:mapName"
                                            element={<FourKMaps />}
                                        />
                                        <Route
                                            path="/maps/tdm"
                                            element={<TDMMaps />}
                                        />
                                        <Route
                                            path="/maps/tdm/:mapName"
                                            element={<TDMMaps />}
                                        />
                                        <Route
                                            path="/maps/erangel-secret-rooms"
                                            element={<ErangelSecretRooms />}
                                        />
                                        <Route
                                            path="/maps/taego-secret-rooms"
                                            element={<TaegoSecretRooms />}
                                        />
                                        <Route
                                            path="/maps/deston-secret-rooms"
                                            element={<DestonSecretRooms />}
                                        />
                                        {/* IBR Routes */}
                                        <Route
                                            path="/maps/ibr-crates/*"
                                            element={<IBRCrates />}
                                        />
                                        <Route
                                            path="/rotations"
                                            element={<MapRotationPage />}
                                        />
                                        {/* ---------------------------- */}
                                        <Route
                                            path="/tips-strategies"
                                            element={<TipsStrategies />}
                                        />
                                        <Route
                                            path="/patches"
                                            element={<PatchesPage />}
                                        />
                                        <Route
                                            path="/resources"
                                            element={<Resources />}
                                        />
                                        <Route
                                            path="/support-us"
                                            element={<SupportUsPage />}
                                        />
                                    </Routes>
                                </div>
                                <Footer />
                            </div>
                        </ScrollToTop>
                    </Router>
                </AuthProvider>
            </ThemeProvider>
        </LandingPageProvider>
    );
};

export default App;
