import React from "react";
import { Box, Typography, Button, useTheme, Divider } from "@mui/material";
import { predefinedPlayers } from "constants/predefinedPlayers";
import { getPlatformLogo } from "utils/getPlatformLogo";

interface PredefinedPlayersProps {
    onSelectPlayer: (playerName: string) => void;
    title?: string; // Optional title for the section
}

export const PredefinedPlayers: React.FC<PredefinedPlayersProps> = ({
    onSelectPlayer,
    title = "Predefined Players",
}) => {
    const theme = useTheme();

    // Define the category colors
    const categoryColors: { [key: string]: string } = {
        "Pro Players": theme.palette.custom.accentColor2,
        "YouTubers / Streamers": theme.palette.secondary.main,
    };

    const regions = ["Americas", "Asia", "APAC", "EMEA"];

    return (
        <Box
            sx={{
                width: "100%",
                margin: "0 auto",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 2,
                textAlign: "center",
                padding: 2, // Add some padding inside the box
                border: `2px solid`, // Define a solid border
                borderColor: "custom.accentColor2", // Use the theme's secondary color
                borderRadius: 2, // Slightly rounded corners
                boxShadow: 2, // Add a subtle shadow for depth
                bgcolor: "background.paper",
            }}
        >
            {/* Title */}
            {title && (
                <Typography
                    variant="h6"
                    sx={{
                        display: "flex", // Use flexbox for the text and icon
                        alignItems: "center", // Vertically align the text and icon
                        gap: 0.5, // Add spacing between text and icon
                    }}
                >
                    <img
                        src={getPlatformLogo("steam")}
                        alt={`Steam logo`}
                        style={{
                            width: 26,
                            height: 26,
                            marginRight: 8,
                        }}
                    />
                    {title}
                </Typography>
            )}

            <Divider
                sx={{
                    width: "100%", // Full-width divider
                    borderColor: "text.secondary", // Match theme text color
                }}
            />

            {Object.entries(predefinedPlayers).map(([category, players]) => (
                <Box key={category}>
                    <Typography variant="h6" gutterBottom>
                        {category}
                    </Typography>

                    {/* Handle regions for Pro Players */}
                    {category === "Pro Players" ? (
                        regions.map((region) => {
                            const regionPlayers = players.filter(
                                (player) => player.region === region
                            );
                            if (regionPlayers.length === 0) return null;

                            return (
                                <Box key={region} sx={{ mt: 2 }}>
                                    <Typography
                                        variant="subtitle1"
                                        sx={{
                                            mb: 2,
                                            color: "text.secondary",
                                        }}
                                    >
                                        {region}
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexWrap: "wrap",
                                            gap: 1.5,
                                            justifyContent: "center",
                                        }}
                                    >
                                        {regionPlayers.map((player) => (
                                            <Button
                                                key={player.name}
                                                variant="contained"
                                                onClick={() =>
                                                    onSelectPlayer(
                                                        player.inGameName ||
                                                            player.name
                                                    )
                                                }
                                                sx={{
                                                    textTransform: "none",
                                                    backgroundColor:
                                                        categoryColors[
                                                            category
                                                        ] ||
                                                        theme.palette.grey[500],
                                                    "&:hover": {
                                                        backgroundColor: `${
                                                            categoryColors[
                                                                category
                                                            ] ||
                                                            theme.palette
                                                                .grey[500]
                                                        }CC`,
                                                    },
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                {player.country && (
                                                    <img
                                                        src={`https://flagcdn.com/w40/${player.country.toLowerCase()}.png`}
                                                        alt={`${player.country} Flag`}
                                                        style={{
                                                            width: "20px",
                                                            height: "14px",
                                                            marginRight: "8px",
                                                            borderRadius: "2px",
                                                        }}
                                                    />
                                                )}
                                                <Typography>
                                                    {player.name}
                                                </Typography>
                                            </Button>
                                        ))}
                                    </Box>
                                </Box>
                            );
                        })
                    ) : (
                        <Box
                            sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 1.5,
                                justifyContent: "center",
                            }}
                        >
                            {players.map((player) => (
                                <Button
                                    key={player.name}
                                    variant="contained"
                                    onClick={() => onSelectPlayer(player.name)}
                                    sx={{
                                        textTransform: "none",
                                        backgroundColor:
                                            categoryColors[category] ||
                                            theme.palette.grey[500],
                                        "&:hover": {
                                            backgroundColor: `${
                                                categoryColors[category] ||
                                                theme.palette.grey[500]
                                            }CC`,
                                        },
                                    }}
                                >
                                    {player.name}
                                </Button>
                            ))}
                        </Box>
                    )}
                </Box>
            ))}
        </Box>
    );
};
