export const predefinedPlayers: {
    [category: string]: {
        name: string;
        country?: string;
        inGameName?: string;
        teamName?: string;
        teamLogo?: string;
        region?: "Americas" | "Asia" | "APAC" | "EMEA";
    }[];
} = {
    "Pro Players": [
        // NA
        {
            name: "Kickstart",
            country: "US",
            region: "Americas",
            inGameName: "FLCN_Kickstart", // "Kickstart-__-",
        },
        {
            name: "M1ME",
            country: "US",
            region: "Americas",
        },
        {
            name: "Shrimzy",
            country: "US",
            region: "Americas",
        },
        {
            name: "TGLTN",
            country: "US",
            region: "Americas",
        },
        // APAC
        {
            name: "Clories",
            country: "VN",
            inGameName: "cloriesxxx",
            region: "APAC",
        },
        {
            name: "Himass",
            country: "VN",
            inGameName: "Himass523",
            region: "APAC",
        },
        {
            name: "Taikonn",
            country: "VN",
            inGameName: "Miluhihi",
            region: "APAC",
        },
        {
            name: "TanVuu",
            country: "VN",
            inGameName: "TanVuu666",
            region: "APAC",
        },
        // Asia
        {
            name: "Aixleft",
            country: "CN",
            inGameName: "AixLeft",
            region: "Asia",
        },
        {
            name: "EEND",
            country: "KR",
            inGameName: "T1_EEND",
            region: "Asia",
        },
        {
            name: "Type",
            country: "KR",
            inGameName: "T1_Type",
            region: "Asia",
        },
        {
            name: "xwudd",
            country: "CN",
            inGameName: "ixwudd",
            region: "Asia",
        },
        // EMEA
        {
            name: "ADOUZ1E",
            country: "RU",
            region: "EMEA",
        },
        {
            name: "Feyerist",
            country: "UA",
            inGameName: "Feyer1st",
            region: "EMEA",
        },
        {
            name: "SoseD",
            country: "UA",
            inGameName: "SoseD-",
            region: "EMEA",
        },
        {
            name: "xmpl",
            country: "RU",
            region: "EMEA",
        },
    ],
    "YouTubers / Streamers": [
        { name: "BadBoyy2k" },
        { name: "chocoTaco" },
        { name: "shroud" },
        { name: "WackyJacky101" },
    ],
};
