// used in the RecentMatches and MatchDetails components

import React from "react";
import {
    Card,
    CardContent,
    Typography,
    LinearProgress,
    Box,
    CardActions,
    Button,
    useTheme,
    IconButton,
    Divider,
    Tooltip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

// Types
import { Match } from "types/match";

// Mapping
import { mapNameMapping } from "constants/mapNameMapping";

// Utils
import { formatMatchType } from "utils/formatMatchType";
import { formatGameMode } from "utils/formatGameMode";

// Colors
import { COLORS } from "constants/appWide/colors";

// Icons
import { mapIcons } from "constants/mapIcons";
import dinner from "assets/matchCardIcons/dinner.png";
import trophy from "assets/matchCardIcons/trophy.png";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import FavoriteIcon from "@mui/icons-material/Favorite";
import GroupIcon from "@mui/icons-material/Group";
import { mapImages } from "constants/mapImages";
import { getElapsedTime } from "utils/getElapsedTime";
import { isSurvivor, isWinner } from "utils/matchIcons";
import { format } from "date-fns";
import { partyImages } from "constants/partyImages";

interface MatchCardProps {
    match: Match;
    matchIndex?: number;
    accountId: string | null;
    viewType: "compactCard" | "detailedCard";
    onMatchHistoryPage?: boolean;
    platform: string;
    playerName: string;
    favoriteGameMode: string;
    showImages: boolean;
    isSaved?: boolean;
}

const MatchCard: React.FC<MatchCardProps> = ({
    match,
    matchIndex,
    accountId,
    viewType,
    platform,
    playerName,
    onMatchHistoryPage,
    favoriteGameMode,
    showImages,
    isSaved = false,
}) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const mapName = match.mapName;
    const icon = mapIcons[mapNameMapping[mapName]];

    // Calculate dynamic minHeight
    const height =
        viewType === "compactCard"
            ? "428px"
            : onMatchHistoryPage // detailed card, on match history page
            ? "820px" // Shorter height when the button is visible
            : "990px"; // Default detailed view height without button

    return (
        <Card
            sx={{
                position: "relative",
                height: onMatchHistoryPage ? height : "auto",
                marginBottom: "16px",
                border:
                    favoriteGameMode === match.gameMode
                        ? `2px solid ${theme.palette.primary.main}`
                        : "none",
            }}
        >
            <CardContent
                sx={{
                    padding: "12px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderBottom: "1px solid #ccc", // Divider-like style
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1, // Space between index and game mode/match type
                    }}
                >
                    {/* Index */}
                    {matchIndex !== undefined && (
                        <Typography
                            variant="subtitle2"
                            sx={{
                                fontWeight: "bold",
                                color: theme.palette.primary.main,
                                fontSize: "14px",
                            }}
                        >
                            {matchIndex}
                        </Typography>
                    )}
                    {/* Game Mode and Match Type */}
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                        {formatGameMode(match.gameMode)} -{" "}
                        {formatMatchType(match.matchType)}
                    </Typography>
                    {isWinner(match) && (
                        <Box
                            sx={{
                                position: "absolute",
                                top: 6,
                                right: 130,
                                zIndex: 1,
                            }}
                        >
                            <Tooltip
                                title={
                                    ["ibr", "tdm"].includes(match.gameMode) ||
                                    match.matchType === "airoyale"
                                        ? "You won! - Intense Battle Royale / Team Deathmatch / Casual"
                                        : "Winner Winner Chicken Dinner!"
                                }
                                arrow
                            >
                                <img
                                    src={
                                        ["ibr", "tdm"].includes(
                                            match.gameMode
                                        ) || match.matchType === "airoyale"
                                            ? trophy
                                            : dinner
                                    }
                                    alt={
                                        ["ibr", "tdm"].includes(
                                            match.gameMode
                                        ) || match.matchType === "airoyale"
                                            ? "Trophy Icon"
                                            : "Chicken Dinner Icon"
                                    }
                                    style={{
                                        width: 40,
                                        height: 40,
                                    }}
                                />
                            </Tooltip>
                        </Box>
                    )}
                    {isSurvivor(match) && (
                        <Box
                            sx={{
                                position: "absolute",
                                top: 6,
                                right: 90,
                                zIndex: 1,
                            }}
                        >
                            <Tooltip
                                title="Survived until the last 15 seconds of the match!"
                                arrow
                            >
                                <VerifiedUserIcon
                                    sx={{
                                        fontSize: 40, // Adjust size
                                        color: COLORS.green, // Green color to signify survival
                                    }}
                                />
                            </Tooltip>
                        </Box>
                    )}
                </Box>

                {/* Elapsed Time */}
                <Tooltip
                    title={`Ended: ${format(
                        new Date(
                            new Date(match.createdAt).getTime() +
                                match.duration * 1000
                        ),
                        "PPpp" // Use date-fns formatting to show local time
                    )}`}
                    arrow
                >
                    <Typography
                        variant="subtitle2"
                        sx={{
                            fontStyle: "italic",
                            color: theme.palette.text.secondary,
                        }}
                    >
                        {getElapsedTime(match.createdAt, match.duration)}
                    </Typography>
                </Tooltip>
            </CardContent>

            <CardContent
                sx={{
                    padding: "12px 12px 0 12px",
                }}
            >
                {/* Trophy or Chicken Dinner Icon */}

                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center", // Center horizontally within the available space
                        alignItems: "center", // Center vertically between text and icon
                        gap: 1, // Space between text and icon
                    }}
                >
                    <Typography
                        variant="h6"
                        sx={{
                            lineHeight: 1, // Prevent extra vertical spacing
                        }}
                    >
                        Map: {mapNameMapping[mapName]}
                    </Typography>
                    {icon &&
                        (typeof icon === "string" ? (
                            <img
                                src={icon}
                                alt={`${mapName} Icon`}
                                style={{
                                    width: "32px",
                                    height: "32px",
                                    objectFit: "contain",
                                }}
                            />
                        ) : (
                            React.createElement(icon, {
                                sx: { width: 32, height: 32 },
                            })
                        ))}
                </Box>

                {match.gameMode !== "tdm" &&
                match.matchType !== "training" &&
                match.matchType !== "trainingroom" ? (
                    <Typography>
                        Place: #{match.playerWinPlace} / {match.rosters.length}
                    </Typography>
                ) : match.gameMode === "tdm" ? (
                    <Typography>
                        {match.playerWinPlace === 1 ? "Won TDM" : "Lost TDM"}
                    </Typography>
                ) : null}

                {showImages && (
                    <Box
                        sx={{
                            marginTop: 2, // Add space above the image
                            textAlign: "center", // Center the image
                        }}
                    >
                        <img
                            src={mapImages[mapNameMapping[mapName]]}
                            alt={`${mapNameMapping[mapName]} Thumbnail`}
                            style={{
                                maxWidth: "100%", // Make the image responsive
                                width: onMatchHistoryPage ? "400px" : "600px",
                            }}
                        />
                    </Box>
                )}

                {/* Divider Below Game Mode and Match Type */}
                <Divider sx={{ marginTop: 2 }} />

                {viewType === "detailedCard" && (
                    <>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            mt={2}
                            textAlign="left"
                        >
                            <Box>
                                <Typography>
                                    My Kills: {match.playerKills}
                                </Typography>

                                {match.playerRoster.length > 1 && (
                                    <Typography>
                                        Our Kills: {match.playerRosterKills}
                                    </Typography>
                                )}
                            </Box>
                            {accountId && (
                                <Box textAlign={"right"}>
                                    <Typography>
                                        My Damage:{" "}
                                        {Number(
                                            match.playerDamage.toFixed(0)
                                        ).toLocaleString()}
                                    </Typography>
                                    {match.playerRoster.length > 1 && (
                                        <Typography>
                                            Our Damage:{" "}
                                            {Number(
                                                match.playerRosterDamage.toFixed(
                                                    0
                                                )
                                            ).toLocaleString()}
                                        </Typography>
                                    )}
                                </Box>
                            )}
                        </Box>
                        {/* Roster Table */}
                        {/* Roster Table */}
                        <Box>
                            <Typography variant="h6">Team Stats</Typography>
                            <Box
                                sx={{
                                    overflowX: "auto",
                                    maxHeight: onMatchHistoryPage
                                        ? "246px"
                                        : "none",
                                    overflowY: "auto",
                                    padding: 2,
                                    borderRadius: 1,
                                    "&::-webkit-scrollbar": {
                                        height: "6px", // Height of horizontal scrollbar
                                    },
                                    "&::-webkit-scrollbar-thumb": {
                                        backgroundColor:
                                            "rgba(255, 255, 255, 0.3)", // Thumb color
                                        borderRadius: "10px", // Rounded corners
                                    },
                                    "&::-webkit-scrollbar-thumb:hover": {
                                        backgroundColor:
                                            "rgba(255, 255, 255, 0.5)", // Hover color
                                    },
                                    "&::-webkit-scrollbar-track": {
                                        backgroundColor:
                                            "rgba(255, 255, 255, 0.1)", // Track color
                                    },
                                    scrollbarWidth: "thin", // For Firefox
                                    scrollbarColor:
                                        "rgba(255, 255, 255, 0.3) rgba(255, 255, 255, 0.1)", // For Firefox
                                }}
                            >
                                <table
                                    style={{
                                        width: "100%",
                                        borderCollapse: "collapse",
                                    }}
                                >
                                    <thead>
                                        <tr
                                            style={{
                                                textAlign: "left",
                                            }}
                                        >
                                            <th style={{ padding: "8px" }}>
                                                Name
                                            </th>
                                            {match.gameMode !== "tdm" && (
                                                <th style={{ padding: "8px" }}>
                                                    Survived
                                                </th>
                                            )}
                                            <th style={{ padding: "8px" }}>
                                                Kills
                                            </th>
                                            <th style={{ padding: "8px" }}>
                                                Damage
                                            </th>
                                            {match.gameMode !== "tdm" && (
                                                <th style={{ padding: "8px" }}>
                                                    DBNOs
                                                </th>
                                            )}
                                            {match.gameMode !== "tdm" && (
                                                <th style={{ padding: "8px" }}>
                                                    Assists
                                                </th>
                                            )}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {match.playerRoster &&
                                            [...match.playerRoster].map(
                                                (player) => (
                                                    <tr
                                                        key={player.name}
                                                        style={{
                                                            borderBottom:
                                                                "1px solid #ddd",
                                                            backgroundColor:
                                                                player.name ===
                                                                playerName
                                                                    ? theme
                                                                          .palette
                                                                          .primary
                                                                          .main
                                                                    : "transparent",
                                                            color:
                                                                player.name ===
                                                                playerName
                                                                    ? theme
                                                                          .palette
                                                                          .primary
                                                                          .contrastText
                                                                    : "inherit",
                                                        }}
                                                    >
                                                        <td
                                                            style={{
                                                                padding: "8px",
                                                                textAlign:
                                                                    "left",
                                                            }}
                                                        >
                                                            {player.name ===
                                                            "Unknown" ? (
                                                                <Tooltip
                                                                    title="For TDM matches, if a player leaves early, they will show as Unknown."
                                                                    arrow
                                                                >
                                                                    <span>
                                                                        {
                                                                            player.name
                                                                        }
                                                                    </span>
                                                                </Tooltip>
                                                            ) : (
                                                                player.name
                                                            )}
                                                        </td>
                                                        {match.gameMode !==
                                                            "tdm" && (
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        "8px",
                                                                    textAlign:
                                                                        "left",
                                                                }}
                                                            >
                                                                {Math.floor(
                                                                    (player.timeSurvived /
                                                                        match.duration) *
                                                                        100
                                                                )}
                                                                %
                                                            </td>
                                                        )}
                                                        <td
                                                            style={{
                                                                padding: "8px",
                                                            }}
                                                        >
                                                            {player.kills}
                                                        </td>
                                                        <td
                                                            style={{
                                                                padding: "8px",
                                                            }}
                                                        >
                                                            {Number(
                                                                player.damageDealt.toFixed(
                                                                    0
                                                                )
                                                            ).toLocaleString()}
                                                        </td>
                                                        {match.gameMode !==
                                                            "tdm" && (
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        "8px",
                                                                }}
                                                            >
                                                                {player.DBNOs}
                                                            </td>
                                                        )}
                                                        {match.gameMode !==
                                                            "tdm" && (
                                                            <td
                                                                style={{
                                                                    padding:
                                                                        "8px",
                                                                }}
                                                            >
                                                                {player.assists}
                                                            </td>
                                                        )}
                                                    </tr>
                                                )
                                            )}
                                    </tbody>
                                </table>
                            </Box>
                        </Box>

                        {(match.gameMode.includes("squad") ||
                            match.gameMode.includes("ibr")) &&
                            match.playerRoster.length === 1 && (
                                <Box
                                    sx={{
                                        marginTop: 2,
                                        display: "inline-flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        padding: 2,
                                        border: (theme) =>
                                            `2px solid ${theme.palette.primary.main}`, // Main theme color for the border
                                        borderRadius: 2, // Rounded corners
                                        gap: 1,
                                    }}
                                >
                                    <img
                                        src={partyImages.oneManSquad}
                                        alt="One Man Squad"
                                        style={{
                                            width: "50px", // Adjust as per your design
                                            height: "50px", // Adjust as per your design
                                            objectFit: "contain", // Ensure the image retains its aspect ratio
                                        }}
                                    />
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            fontWeight: "bold",
                                            textAlign: "center",
                                        }}
                                    >
                                        {`1-man ${
                                            match.gameMode.includes("ibr")
                                                ? "IBR"
                                                : "Squad"
                                        }! You are insane!`}
                                    </Typography>
                                </Box>
                            )}

                        {!onMatchHistoryPage &&
                            match.playerRoster.length === 4 && (
                                <Box
                                    sx={{
                                        marginTop: 2,
                                        display: "inline-flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        padding: 2,
                                        border: (theme) =>
                                            `2px solid ${theme.palette.primary.main}`, // Main theme color for the border
                                        borderRadius: 2, // Rounded corners
                                        gap: 1,
                                    }}
                                >
                                    <img
                                        src={partyImages.squad}
                                        alt="Four Man Squad"
                                        style={{
                                            width: "50px", // Adjust as per your design
                                            height: "50px", // Adjust as per your design
                                            objectFit: "contain", // Ensure the image retains its aspect ratio
                                        }}
                                    />
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            fontWeight: "bold",
                                            textAlign: "center",
                                        }}
                                    >
                                        4 Strong! Your squad was as prepared as
                                        it could be and gave it your all!
                                    </Typography>
                                </Box>
                            )}

                        <Box sx={{ marginTop: 2 }}>
                            <Typography variant="body2">
                                Survival Time:{" "}
                                {Math.floor(match.playerSurvivalTime / 60)}m{" "}
                                {match.playerSurvivalTime % 60}s /{" "}
                                {Math.floor(match.duration / 60)}m{" "}
                                {match.duration % 60}s
                            </Typography>
                            <LinearProgress
                                variant="determinate"
                                value={
                                    (match.playerSurvivalTime /
                                        match.duration) *
                                    100
                                }
                                sx={{
                                    height: 10,
                                    borderRadius: 5,
                                    backgroundColor: "#ddd",
                                    "& .MuiLinearProgress-bar": {
                                        backgroundColor: COLORS.green,
                                    },
                                }}
                            />
                        </Box>
                    </>
                )}
            </CardContent>
            {onMatchHistoryPage && (
                <CardActions
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Button
                        component="a"
                        href={`/player-stats/${platform}/${encodeURIComponent(
                            playerName
                        )}/matches/${match.matchId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={(e) => {
                            // Prevent default if Match ID is undefined
                            if (!match.matchId) {
                                e.preventDefault();
                                console.error("Match ID is undefined:", match);
                            }
                        }}
                    >
                        View Details
                    </Button>
                    {isSaved && (
                        <IconButton
                            disableRipple
                            sx={{
                                color: "red",
                                marginLeft: "auto",
                            }}
                        >
                            <FavoriteIcon />
                        </IconButton>
                    )}
                </CardActions>
            )}
        </Card>
    );
};

export default MatchCard;
