export const COLORS = {
    // Basic
    black: "#000000",
    orange: "#FFA500",
    darkOrange: "#FF8C00",
    // Custom
    blue: "#2196f3",
    green: "#4caf50",
    white: "#ffffff",
    silver: "#c7c7c7",
    red: "#FF0000",
    // Payment Method Colors
    paypal: {
        background: "#009CDE", // PayPal Blue
        hover: "#0079BA", // Slightly darker blue for hover
    },
    venmo: {
        background: "#008CFF", // Venmo Blue
        hover: "#0077E5", // Slightly darker blue for hover
    },
    buyMeACoffee: {
        background: "#FFDD00", // Buy Me a Coffee Yellow
        hover: "#E6C200", // Slightly darker yellow for hover
    },
    patreon: {
        background: "#FF424D", // Patreon Red
        hover: "#E03641", // Slightly darker red for hover
    },
    kofi: {
        background: "#FF5E5B", // Ko-fi Coral Red
        hover: "#E0524E", // Slightly darker coral red for hover
    },
};
