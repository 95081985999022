import { API_ENDPOINTS } from "constants/API_ENDPOINTS";
import { useState, useEffect } from "react";
import { ClanInfo } from "types/clanInfo";

export const useClanInfo = (clanId: string | null, platform: string) => {
    const [clanInfo, setClanInfo] = useState<ClanInfo | null>(null);
    const [clanLoading, setClanLoading] = useState<boolean>(false);
    const [clanError, setClanError] = useState<string | null>(null);

    useEffect(() => {
        if (!clanId) {
            console.log("[DEBUG] clanId is null, skipping loadClanData.");
            return;
        }

        const loadClanData = async () => {
            try {
                setClanLoading(true);
                setClanError(null);

                const clanResponse = await fetch(
                    API_ENDPOINTS.CLAN(platform, clanId)
                );

                if (!clanResponse.ok) {
                    throw new Error("Failed to fetch clan data");
                }

                const clanData: ClanInfo = await clanResponse.json();
                console.log("[DEBUG] Clan data fetched:", clanData);

                setClanInfo(clanData);
            } catch (err) {
                console.error("[DEBUG] Error fetching clan data:", err);
                setClanError((err as Error).message);
            } finally {
                setClanLoading(false);
            }
        };

        console.log("[DEBUG] Starting loadClanData...");
        loadClanData();
    }, [clanId, platform]);

    return { clanInfo, clanLoading, clanError };
};
