import { useState } from "react";
import { Turnstile } from "@marsidev/react-turnstile";
import { Box, Paper, Typography } from "@mui/material";

interface TurnstileWrapperProps {
    onVerified: (token: string) => void; // Adjusted to expect a token
}

const TurnstileWrapper: React.FC<TurnstileWrapperProps> = ({ onVerified }) => {
    const [verified, setVerified] = useState(false);

    const handleVerification = (token: string) => {
        onVerified(token); // Pass token to parent function
        setVerified(true);
    };

    return (
        <Box
            sx={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100vw",
                height: "100vh",
                backgroundColor: "rgba(0, 0, 0, 0.9)", // Dark background
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 1000,
                backdropFilter: "blur(6px)", // Frosted glass effect
            }}
        >
            <Paper
                elevation={6}
                sx={{
                    backgroundColor: "#ffffff",
                    padding: "40px",
                    margin: "30px",
                    borderRadius: "16px", // Smooth rounded corners
                    textAlign: "center",
                    boxShadow: "0 8px 30px rgba(0, 0, 0, 0.5)", // Depth shadow
                    width: "400px", // Max width
                    maxWidth: "1000%", // Responsive width
                }}
            >
                <img
                    src="/logo512.png"
                    alt="Logo"
                    style={{ width: "200px", marginBottom: "20px" }}
                />
                <Typography
                    variant="h5"
                    component="h2"
                    sx={{ fontWeight: "bold", color: "#333333", mb: 2 }}
                >
                    Prove You're Not a Bot 🤖
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        color: "#666666",
                        mb: 3,
                        lineHeight: 1.5,
                    }}
                >
                    Please hold on for 1-2 seconds.
                </Typography>
                <Turnstile
                    siteKey={process.env.REACT_APP_TURNSTILE_SITE_KEY}
                    onSuccess={handleVerification}
                />
                <Typography
                    variant="caption"
                    sx={{
                        display: "block",
                        mt: 2,
                        color: "#888888",
                        fontStyle: "italic",
                    }}
                >
                    This process helps keep this web app secure. Thanks for your
                    patience!
                </Typography>
            </Paper>
        </Box>
    );
};

export default TurnstileWrapper;
