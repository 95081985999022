import React from "react";
import { Pie } from "react-chartjs-2";
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
    ChartData,
} from "chart.js";
import { Box, Typography, useTheme } from "@mui/material";
import { WeaponCategories, WeaponInfo } from "components/WeaponMasterySection";
import ChartDataLabels from "chartjs-plugin-datalabels";

// Register Chart.js plugins
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const PIE_COLORS = [
    "#ff6384",
    "#36a2eb",
    "#cc65fe",
    "#ffce56",
    "#ff9f40",
    "#4bc0c0",
    "#9966ff",
    "#c9cbcf",
    "#ffa1b5",
];

const HOVER_COLORS = PIE_COLORS.map((color) => {
    const lightness = 20; // Adjust hover brightness
    const rgb = color.match(/\w\w/g)?.map((hex) => parseInt(hex, 16));
    return rgb
        ? `rgb(${Math.min(rgb[0] + lightness, 255)}, ${Math.min(
              rgb[1] + lightness,
              255
          )}, ${Math.min(rgb[2] + lightness, 255)})`
        : color;
});

interface WeaponXPDistributionChartProps {
    weaponMastery: WeaponCategories | null;
}

const WeaponXPDistributionChart: React.FC<WeaponXPDistributionChartProps> = ({
    weaponMastery,
}) => {
    const theme = useTheme();

    const weaponUsageData = weaponMastery
        ? Object.entries(weaponMastery)
              .map(([category, weapons]) => ({
                  category,
                  totalXP: weapons.reduce(
                      (sum: number, weapon: WeaponInfo) => sum + weapon.XPTotal,
                      0
                  ),
              }))
              .sort((a, b) => b.totalXP - a.totalXP)
        : [];

    const totalXP = weaponUsageData.reduce(
        (sum, item) => sum + item.totalXP,
        0
    );

    const chartData: ChartData<"pie", number[], string> = {
        labels: weaponUsageData.map((item) => item.category),
        datasets: [
            {
                data: weaponUsageData.map((item) => item.totalXP),
                backgroundColor: PIE_COLORS,
                hoverBackgroundColor: HOVER_COLORS,
                borderWidth: 1,
            },
        ],
    };

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false, // Allow flexibility for the chart to fill its container
        cutout: "20%", // Converts pie chart into a donut chart
        plugins: {
            tooltip: {
                callbacks: {
                    label: (context: any) => {
                        const value = context.raw as number;
                        const dataset = context.dataset.data as number[];
                        const total = dataset.reduce(
                            (sum, val) => sum + val,
                            0
                        );
                        const percentage = ((value / total) * 100).toFixed(0); // Calculate percentage with no decimals

                        let formattedValue;
                        if (value >= 1_000_000) {
                            formattedValue = `${(value / 1_000_000).toFixed(
                                1
                            )}M`;
                        } else if (value >= 1_000) {
                            formattedValue = `${(value / 1_000).toFixed(1)}k`;
                        } else {
                            formattedValue = value.toLocaleString();
                        }

                        return `XP: ${formattedValue} (${percentage}%)`; // Combine formatted value and percentage
                    },
                },
            },
            legend: {
                display: true,
                position: "top" as const, // Legend above the chart
                labels: {
                    font: {
                        size: 12,
                    },
                    color: theme.palette.text.primary,
                },
                onHover: (e: any, legendItem: any, legend: any) => {
                    const chart = legend.chart;
                    const dataset = chart.data.datasets[0];

                    // Reset all colors to black except the hovered slice
                    dataset.backgroundColor = dataset.backgroundColor.map(
                        (color: string, index: number) =>
                            index === legendItem.index
                                ? theme.palette.primary.main
                                : "#000000"
                    );

                    chart.update();
                },
                onLeave: (e: any, legendItem: any, legend: any) => {
                    const chart = legend.chart;
                    const dataset = chart.data.datasets[0];

                    // Reset colors to the original palette
                    dataset.backgroundColor = PIE_COLORS;

                    chart.update();
                },
            },
            datalabels: {
                display: true,
                color: theme.palette.text.primary,
                formatter: (value: number, context: any) => {
                    const dataset = context.chart.data.datasets[0]; // Get the dataset
                    const total = dataset.data.reduce(
                        (sum: number, val: number) => sum + val,
                        0
                    ); // Calculate the total
                    const percentage = (value / total) * 100; // Calculate percentage

                    if (percentage < 5) {
                        return ""; // Do not display if less than 10%
                    }

                    if (value >= 1_000_000) {
                        return `${(value / 1_000_000).toFixed(1)}M`;
                    }
                    if (value >= 1_000) {
                        return `${(value / 1_000).toFixed(1)}K`;
                    }
                    return value.toLocaleString();
                },
                font: {
                    size: 10,
                    weight: "bold" as "bold",
                },
                anchor: "end" as const,
                align: "start" as const,
            },
        },
    };

    return (
        <Box
            sx={{
                position: "relative",
                width: "100%",
                height: "500px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Typography
                variant="h6"
                sx={{
                    marginBottom: 2,
                    color: theme.palette.text.primary,
                    textAlign: "center",
                }}
            >
                XP Distribution by Weapon Type
            </Typography>
            {/* Chart Container */}
            <Box
                sx={{
                    width: "100%",
                    height: "100%",
                    position: "relative",
                }}
            >
                <Pie data={chartData} options={chartOptions} />
                {/* Donut Text */}
                <Box
                    sx={{
                        position: "absolute",
                        top: {
                            xs: "63.5%",
                            md: "58%",
                        },
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        pointerEvents: "none", // Prevent interaction
                    }}
                >
                    <Typography
                        variant="h6"
                        sx={{ fontWeight: "bold", color: "text.primary" }}
                    >
                        {totalXP >= 1_000_000
                            ? `${(totalXP / 1_000_000).toFixed(1)}M`
                            : totalXP >= 1_000
                            ? `${(totalXP / 1_000).toFixed(1)}k`
                            : totalXP.toLocaleString()}
                    </Typography>
                    <Typography
                        variant="body2"
                        sx={{ color: "text.secondary", fontSize: "0.8rem" }}
                    >
                        Total XP
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default WeaponXPDistributionChart;
