import React, { useEffect, useState } from "react";
import { Box, LinearProgress, Typography, useTheme } from "@mui/material";
import { formatDateTimeRange } from "utils/formatDateTimeRange";

interface RotationHeaderProps {
    rotationType: "pc" | "console" | "ranked";
}

const pcRotationStartDate = new Date("2025-01-08T02:00:00Z");
const consoleRotationStartDate = new Date("2025-01-09T07:00:00Z"); // Console starts a day later
const oneWeekInMs = 7 * 24 * 60 * 60 * 1000;

export const RotationHeader: React.FC<RotationHeaderProps> = ({
    rotationType,
}) => {
    const theme = useTheme();
    const [timeLeft, setTimeLeft] = useState("");
    const [progressValue, setProgressValue] = useState(0);

    const rotationTitle = {
        pc: "PC Map Rotation",
        console: "Console Map Rotation",
        ranked: "Ranked Map Rotation",
    }[rotationType];

    const calculateDates = () => {
        const now = new Date();
        const rotationStartDate =
            rotationType === "pc"
                ? pcRotationStartDate
                : rotationType === "console"
                ? consoleRotationStartDate
                : pcRotationStartDate; // Default for ranked

        const weeksSinceStart = Math.floor(
            (now.getTime() - rotationStartDate.getTime()) / oneWeekInMs
        );

        const startDate = new Date(
            rotationStartDate.getTime() + weeksSinceStart * oneWeekInMs
        );

        const endDate = new Date(startDate.getTime() + oneWeekInMs);

        return { startDate, endDate };
    };

    useEffect(() => {
        const { startDate, endDate } = calculateDates();

        const updateCountdown = () => {
            const now = new Date();
            const timeDifference = endDate.getTime() - now.getTime();
            const totalDuration = endDate.getTime() - startDate.getTime();
            const elapsedTime = now.getTime() - startDate.getTime();

            if (timeDifference <= 0) {
                setTimeLeft("Rotation has ended.");
                setProgressValue(100);
                return;
            }

            const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
            const hours = Math.floor(
                (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
            );
            const minutes = Math.floor(
                (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
            );
            const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

            setTimeLeft(`${days}d ${hours}h ${minutes}m ${seconds}s left`);
            setProgressValue(
                Math.min((elapsedTime / totalDuration) * 100, 100)
            );
        };

        updateCountdown();
        const interval = setInterval(updateCountdown, 1000);

        return () => clearInterval(interval);
    }, [rotationType]);

    const { startDate, endDate } = calculateDates();

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            marginBottom={2}
        >
            <Typography variant="h5">{rotationTitle}</Typography>
            {rotationType === "ranked" && (
                <Typography variant="body2">Ongoing</Typography>
            )}
            {rotationType !== "ranked" && (
                <>
                    <Typography variant="body2" color="textSecondary">
                        {formatDateTimeRange(startDate, endDate)}
                    </Typography>
                    <Typography
                        variant="body2"
                        sx={{ textAlign: "center", marginTop: 1 }}
                    >
                        {`Rotation Period Elapsed: ${progressValue.toFixed(
                            1
                        )}%`}
                    </Typography>
                    <Box sx={{ width: 350, maxWidth: "100%", marginTop: 1 }}>
                        <LinearProgress
                            variant="determinate"
                            value={progressValue}
                            sx={{
                                marginBottom: 1,
                                height: 10,
                                borderRadius: 5,
                                backgroundColor: "#ddd",
                                "& .MuiLinearProgress-bar": {
                                    backgroundColor:
                                        theme.palette.secondary.main,
                                },
                            }}
                        />

                        <Typography variant="body2">{timeLeft}</Typography>
                    </Box>
                </>
            )}
        </Box>
    );
};
