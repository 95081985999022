import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    // Remove temporarily for debugging
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

// Register the service worker and handle updates
serviceWorkerRegistration.register({
    onUpdate: (registration) => {
        if (registration && registration.waiting) {
            registration.waiting.postMessage({ type: "SKIP_WAITING" });

            // Optionally prompt the user or refresh immediately
            if (window.confirm("A new version is available. Refresh now?")) {
                window.location.reload();
            }
        }
    },
    onSuccess: () => {
        console.log("Service Worker registered successfully.");
    },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
