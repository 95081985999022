import { Box, Button } from "@mui/material";
import { COLORS } from "constants/appWide/colors";

// Payment method logos
import PayPalLogo from "assets/paymentMethods/paypal.png";
import VenmoLogo from "assets/paymentMethods/venmo.png";
import BuyMeACoffeeLogo from "assets/paymentMethods/buyMeACoffee.png";
import PatreonLogo from "assets/paymentMethods/patreon.png";
import KoFiLogo from "assets/paymentMethods/kofi.png";

export const PaymentOptions = [
    {
        method: "PayPal",
        button: (
            <Button
                href="https://www.paypal.com/ncp/payment/M7VU9CTDW29VN"
                target="_blank"
                sx={{
                    width: "210px",
                    display: "flex",
                    textTransform: "none",
                    "&:hover": {
                        backgroundColor: "transparent",
                        "& .text-section": {
                            backgroundColor: COLORS.paypal.hover,
                        },
                    },
                }}
            >
                <img
                    src={PayPalLogo}
                    alt="PayPal Logo"
                    style={{
                        width: "24px",
                        marginRight: "8px",
                        height: "24px",
                        alignItems: "center",
                    }}
                />

                {/* Text Section with Background */}
                <Box
                    className="text-section"
                    sx={{
                        backgroundColor: COLORS.paypal.background,
                        borderRadius: "4px",
                        padding: "4px 8px",
                        color: "black",
                        transition: "background-color 0.3s ease",
                    }}
                >
                    PayPal / Venmo
                </Box>

                <img
                    src={VenmoLogo}
                    alt="Venmo Logo"
                    style={{
                        width: "24px",
                        height: "24px",
                        alignItems: "center",
                        marginLeft: "8px",
                    }}
                />
            </Button>
        ),
        supportedPayments: "Credit/Debit Cards, PayPal, Venmo",
        rows: [
            {
                type: "One-Time",
                loginRequired: "No (if using Credit/Debit Cards)",
            },
            {
                type: "One-Time",
                loginRequired: "Yes (if using PayPal / Venmo)",
            },
        ],
    },
    {
        method: "Buy Me a Coffee",
        button: (
            <Button
                href="https://www.buymeacoffee.com/pubgmeta"
                target="_blank"
                sx={{
                    width: "180px",
                    display: "flex",
                    textTransform: "none",
                    "&:hover": {
                        backgroundColor: "transparent",
                        "& .text-section": {
                            backgroundColor: COLORS.buyMeACoffee.hover,
                        },
                    },
                }}
            >
                <img
                    src={BuyMeACoffeeLogo}
                    alt="Buy Me a Coffee Logo"
                    style={{
                        width: "24px",
                        height: "24px",
                        alignItems: "center",
                        marginRight: "8px",
                    }}
                />

                {/* Text Section */}
                <Box
                    className="text-section"
                    sx={{
                        backgroundColor: COLORS.buyMeACoffee.background,
                        borderRadius: "4px",
                        padding: "4px 8px",
                        color: "black",
                        transition: "background-color 0.3s ease",
                    }}
                >
                    Buy Me a Coffee
                </Box>
            </Button>
        ),
        supportedPayments: "Credit/Debit Cards, Link by Stripe",
        rows: [
            { type: "One-Time", loginRequired: "No" },
            { type: "Membership (Monthly / Annual ⭐)", loginRequired: "No" },
        ],
    },

    {
        method: "Ko-fi",
        button: (
            <Button
                href="https://ko-fi.com/pubgmeta"
                target="_blank"
                sx={{
                    width: "110px",
                    display: "flex",
                    textTransform: "none",
                    "&:hover": {
                        backgroundColor: "transparent",
                        "& .text-section": {
                            backgroundColor: COLORS.kofi.hover,
                        },
                    },
                }}
            >
                {/* Logo */}

                <img
                    src={KoFiLogo}
                    alt="Ko-fi Logo"
                    style={{
                        width: "24px",
                        height: "24px",
                        alignItems: "center",
                        marginRight: "8px",
                    }}
                />

                {/* Text Section */}
                <Box
                    className="text-section"
                    sx={{
                        backgroundColor: COLORS.kofi.background,
                        borderRadius: "4px",
                        padding: "4px 8px",
                        color: "black",
                    }}
                >
                    Ko-fi
                </Box>
            </Button>
        ),
        supportedPayments: "Credit/Debit Cards, PayPal, Venmo",
        rows: [
            {
                type: "One-Time",
                loginRequired: "No (if using Credit/Debit Cards)",
            },
            { type: "Membership (Monthly)", loginRequired: "No" },
        ],
    },
    {
        method: "Patreon",
        button: (
            <Button
                href="https://www.patreon.com/PUBGMeta"
                target="_blank"
                sx={{
                    width: "130px",
                    display: "flex",
                    textTransform: "none",
                    "&:hover": {
                        backgroundColor: "transparent",
                        "& .text-section": {
                            backgroundColor: COLORS.patreon.hover,
                        },
                    },
                }}
            >
                {/* Logo */}

                <img
                    src={PatreonLogo}
                    alt="Patreon Logo"
                    style={{
                        width: "24px",
                        height: "24px",
                        alignItems: "center",
                        marginRight: "8px",
                    }}
                />

                {/* Text Section */}
                <Box
                    className="text-section"
                    sx={{
                        backgroundColor: COLORS.patreon.background,
                        borderRadius: "4px",
                        padding: "4px 8px",
                        color: "black",
                        transition: "background-color 0.3s ease",
                    }}
                >
                    Patreon
                </Box>
            </Button>
        ),
        supportedPayments: "Credit/Debit Cards, PayPal",
        rows: [{ type: "Membership (Monthly)", loginRequired: "Yes" }],
    },
];
