import React from "react";
import { Bar } from "react-chartjs-2";
import { WeaponCategories } from "components/WeaponMasterySection";
import { useTheme } from "@mui/material";

import "components/dataViz/chartSetup";

interface TopWeaponsByXPChartProps {
    weaponMastery: WeaponCategories | null;
}

const TopWeaponsByXPChart: React.FC<TopWeaponsByXPChartProps> = ({
    weaponMastery,
}) => {
    const theme = useTheme();

    const topWeapons = weaponMastery
        ? Object.values(weaponMastery)
              .flat()
              .sort((a, b) => b.XPTotal - a.XPTotal)
              .slice(0, 10)
        : [];

    const chartData = {
        labels: topWeapons.map((weapon) => weapon.weaponId),
        datasets: [
            {
                label: "XP",
                data: topWeapons.map((weapon) => weapon.XPTotal),
                backgroundColor: theme.palette.custom.accentColor2,
            },
        ],
    };

    const chartOptions = {
        responsive: true,
        layout: {
            padding: {
                top: 30, // Add padding at the top
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: (context: any) =>
                        `XP: ${context.raw.toLocaleString()}`,
                },
            },
            datalabels: {
                display: true, // Enable data labels
                color: theme.palette.text.primary, // Text color
                anchor: "end" as const, // Explicitly cast to const
                align: "end" as const, // Explicitly cast to const
                formatter: (value: number) => {
                    return `${(value / 1000).toFixed(0)}k`; // Format as 'k'
                },
                font: {
                    size: 12, // Adjust font size
                    weight: "bold" as "bold", // Use the allowed type
                },
            },
        },
    };

    return <Bar data={chartData} options={chartOptions} />;
};

export default TopWeaponsByXPChart;
