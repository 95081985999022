import React from "react";
import { Box, Typography, Link as MuiLink } from "@mui/material";
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
import { Link } from "react-router-dom";
import { COLORS } from "constants/appWide/colors";
import { Build } from "@mui/icons-material";

const Footer: React.FC = () => {
    return (
        <Box
            sx={{
                backgroundColor: "#2A2A2A",
                color: COLORS.silver,
                padding: "16px 8px",
                textAlign: "center",
                borderTop: "1px solid #444",
                marginTop: "auto", // Pushes the footer to the bottom
            }}
        >
            <Typography variant="body2">
                PUBG, the PUBG logo, and all related assets, including weapons
                and maps, are trademarks or registered trademarks of PUBG
                Corporation. Any references to third-party websites, trademarks,
                or intellectual property are for informational purposes only and
                do not imply endorsement or affiliation unless explicitly
                stated.
            </Typography>
            <Typography variant="body2" sx={{ marginTop: "8px" }}>
                <MuiLink
                    component={Link}
                    to="/privacy-policy"
                    sx={{
                        display: "inline-flex",
                        alignItems: "center", // Aligns icon and text vertically
                        padding: "4px 8px", // Adds padding for button-like feel
                        backgroundColor: COLORS.orange, // Updated to orange
                        color: "#000", // Black text
                        borderRadius: "4px", // Rounded edges
                        textDecoration: "none", // Removes underline
                        fontWeight: "bold", // Bold text
                        transition: "background-color 0.3s",
                        marginRight: "8px", // Adds space between links
                        "&:hover": {
                            backgroundColor: COLORS.darkOrange, // Slightly darker orange on hover
                        },
                    }}
                >
                    <PrivacyTipIcon
                        sx={{ marginRight: "4px", fontSize: "18px" }}
                    />
                    Privacy Policy
                </MuiLink>
                <MuiLink
                    component={Link}
                    to="/version-history"
                    sx={{
                        display: "inline-flex",
                        alignItems: "center",
                        padding: "4px 8px",
                        backgroundColor: COLORS.orange, // Updated to orange
                        color: "#000", // Black text
                        borderRadius: "4px",
                        textDecoration: "none",
                        fontWeight: "bold",
                        transition: "background-color 0.3s",
                        "&:hover": {
                            backgroundColor: COLORS.darkOrange, // Slightly darker orange on hover
                        },
                    }}
                >
                    <Build sx={{ marginRight: "4px", fontSize: "18px" }} />
                    Version 0.6.3
                </MuiLink>
            </Typography>
        </Box>
    );
};

export default Footer;
