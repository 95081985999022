import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { Box, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { categories } from "../../constants/mapCategories";
import ReactGA from "react-ga4";
import { COLORS } from "constants/appWide/colors";
import { maxWidths } from "constants/appWide/maxWidths";

export const MapsLanding: React.FC = () => {
    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: "/maps",
            title: "Maps Page",
        });
    }, []);

    return (
        <Box
            sx={{
                maxWidth: maxWidths.l, // Limit the width of the content
                margin: "0 auto", // Center the content horizontally
                padding: { xs: "16px", md: "24px" }, // Responsive padding
                textAlign: "center", // Align text in the center
            }}
        >
            <Typography variant="h5" gutterBottom>
                Maps Section
            </Typography>
            <Typography variant="body1" gutterBottom>
                Explore various maps for PUBG, categorized for easy navigation.
            </Typography>
            <Box
                sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    gap: "30px", // Adjust the spacing between cards
                    marginTop: "30px",
                }}
            >
                {categories.map((category) => (
                    <Box
                        key={category.name}
                        component={motion.div}
                        whileHover={{ scale: 1.05 }}
                        sx={{
                            width: "280px", // Set a consistent card width
                            textAlign: "center",
                            cursor: "pointer",
                        }}
                    >
                        <Link
                            to={category.route}
                            style={{
                                textDecoration: "none",
                                color: "inherit",
                            }}
                        >
                            {/* Category Thumbnail */}
                            <Box
                                component="img"
                                src={category.image}
                                alt={category.name}
                                sx={{
                                    width: "100%",
                                    height: "250px", // Adjust the height to keep cards uniform
                                    objectFit: "cover",
                                    borderRadius: "4px 4px 0 0", // Add rounded corners
                                }}
                            />
                            {/* Category Name */}
                            <Button
                                variant="contained"
                                sx={{
                                    marginTop: "-8px",
                                    width: "100%",
                                    fontSize: "16px",
                                    padding: "10px",
                                }}
                            >
                                {category.name}
                            </Button>
                        </Link>
                    </Box>
                ))}
            </Box>
        </Box>
    );
};
