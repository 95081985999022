import React, { useState } from "react";
import {
    TextField,
    Button,
    Box,
    Typography,
    InputAdornment,
    IconButton,
    ListItem,
    ListItemText,
    List,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axiosInstance from "api/axios";

// Visibility and VisibilityOff icons
import { ErrorOutline, Visibility, VisibilityOff } from "@mui/icons-material";
import { COLORS } from "constants/appWide/colors";

export const Register: React.FC = () => {
    // Form
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");

    // Password
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    // Show password
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    // Errors
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");

    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const validateEmail = (value: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(value)) {
            setEmailError("Invalid email address.");
        } else {
            setEmailError("");
        }
    };

    const validatePassword = (value: string) => {
        if (value.length < 8 || value.length > 128) {
            setPasswordError("Password must be between 8 and 128 characters.");
        } else {
            setPasswordError("");
        }
    };

    const validateConfirmPassword = (value: string) => {
        if (value !== password) {
            setConfirmPasswordError("Passwords do not match.");
        } else {
            setConfirmPasswordError("");
        }
    };

    const handleRegister = async (e: React.FormEvent) => {
        e.preventDefault();
        setError("");
        setIsLoading(true);

        if (password !== confirmPassword) {
            setError("Passwords do not match.");
            return;
        }

        try {
            const response = await axiosInstance.post("/api/auth/register", {
                username,
                email,
                password,
            });

            if (response.status === 200 || response.status === 201) {
                localStorage.setItem("username", username);
                localStorage.setItem("email", email);
                localStorage.setItem("password", password);
                navigate("/verify"); // Move to verification step
            }
        } catch (err: any) {
            setError(err.response?.data?.message || "Something went wrong");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                mt: 4,
                p: 3,
                maxWidth: 600,
                margin: "0 auto", // Center the content
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%", // Ensures it uses the full width
                }}
                component="form"
                onSubmit={handleRegister}
            >
                <Typography variant="h5" component="h1" gutterBottom>
                    Sign Up
                </Typography>
                <Typography variant="body1" gutterBottom textAlign={"left"}>
                    By creating an account and signing in, you can enjoy the
                    following benefits:
                </Typography>

                <Typography
                    variant="body1"
                    component="div"
                    gutterBottom
                    textAlign="left"
                >
                    <List
                        sx={{
                            paddingLeft: "2.5rem",
                        }}
                    >
                        <ListItem disableGutters>
                            <ListItemText
                                primary={
                                    <strong>Save your favorite players:</strong>
                                }
                                secondary="Keep track of your top players and access their profiles anytime."
                            />
                        </ListItem>
                        <ListItem disableGutters>
                            <ListItemText
                                primary={
                                    <strong>
                                        Save specific match reports:
                                    </strong>
                                }
                                secondary="Choose and save your favorite match reports to revisit them anytime in the future."
                            />
                        </ListItem>
                        <ListItem disableGutters>
                            <ListItemText
                                primary={
                                    <strong>Share your tier lists:</strong>
                                }
                                secondary="Create and share your tier lists with friends or make them public on the website."
                            />
                        </ListItem>
                        <ListItem disableGutters>
                            <ListItemText
                                primary={
                                    <strong>Customize your experience:</strong>
                                }
                                secondary="Choose your preferred landing page for quicker navigation to what matters most to you."
                            />
                        </ListItem>
                    </List>
                </Typography>
                <TextField
                    label="Username"
                    helperText="We recommend setting a username for better privacy. If left blank, your email address will be used as the username."
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <TextField
                    label="Email Address"
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    value={email}
                    onChange={(e) => {
                        setEmail(e.target.value);
                        validateEmail(e.target.value);
                    }}
                    error={!!emailError}
                />
                {emailError && (
                    <Box
                        sx={{
                            display: "flex",
                            gap: 1,
                            mt: 1,
                            width: "100%",
                            color: "error.main",
                            marginBottom: 0.5,
                        }}
                    >
                        <ErrorOutline fontSize="small" />
                        <Typography variant="body2">{emailError}</Typography>
                    </Box>
                )}

                <TextField
                    label="Password"
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    type={showPassword ? "text" : "password"}
                    value={password}
                    error={!!passwordError}
                    onChange={(e) => {
                        setPassword(e.target.value);
                        validatePassword(e.target.value);
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() =>
                                        setShowPassword((prev) => !prev)
                                    }
                                    edge="end"
                                >
                                    {showPassword ? (
                                        <Visibility />
                                    ) : (
                                        <VisibilityOff />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />

                {/* Moved to a separate Typography because if I were to use helperText on the above, it would also turn red when erroring */}
                <Typography
                    variant="body2"
                    sx={{
                        ml: 2,

                        mb: 1,
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "0.8rem",
                    }}
                >
                    Password must be 8–128 characters long. For better security,
                    use longer and more random combinations.
                </Typography>

                {passwordError && (
                    <Box
                        sx={{
                            display: "flex",
                            gap: 1,
                            mt: 1,
                            width: "100%",
                            color: "error.main",
                            marginBottom: 0.5,
                        }}
                    >
                        <ErrorOutline fontSize="small" />
                        <Typography variant="body2">{passwordError}</Typography>
                    </Box>
                )}
                <TextField
                    label="Confirm Password"
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    type={showConfirmPassword ? "text" : "password"}
                    value={confirmPassword}
                    onChange={(e) => {
                        setConfirmPassword(e.target.value);
                        validateConfirmPassword(e.target.value);
                    }}
                    error={!!confirmPasswordError}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() =>
                                        setShowConfirmPassword((prev) => !prev)
                                    }
                                    edge="end"
                                >
                                    {showConfirmPassword ? (
                                        <Visibility />
                                    ) : (
                                        <VisibilityOff />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                {confirmPasswordError && (
                    <Box
                        sx={{
                            display: "flex",
                            gap: 1,
                            mt: 1,
                            width: "100%",
                            color: "error.main",
                            marginBottom: 0.5,
                        }}
                    >
                        <ErrorOutline fontSize="small" />
                        <Typography variant="body2">
                            {confirmPasswordError}
                        </Typography>
                    </Box>
                )}
                {error && (
                    <Typography variant="body2" color="error" sx={{ mt: 2 }}>
                        {error}
                    </Typography>
                )}
                <Button
                    type="submit"
                    variant="contained"
                    sx={{
                        mt: 3,
                        backgroundColor: COLORS.orange,
                        fontWeight: "bold",
                        ":hover": {
                            backgroundColor: COLORS.darkOrange,
                        },
                    }}
                    disabled={isLoading}
                >
                    {isLoading ? "signing up..." : "Sign Up"}
                </Button>
            </Box>
        </Box>
    );
};
