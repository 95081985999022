import { match } from "assert";
import { API_ENDPOINTS } from "constants/API_ENDPOINTS";
import { useState, useEffect } from "react";

export const useTelemetry = (matchId: string, platform: string) => {
    const [telemetryData, setTelemetryData] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchTelemetry = async () => {
            setLoading(true);
            setError(null);

            try {
                // Step 1: Fetch match data to get telemetry URL
                const matchResponse = await fetch(
                    API_ENDPOINTS.MATCH_DETAILS(platform, matchId)
                );

                if (!matchResponse.ok) {
                    throw new Error(
                        `Failed to fetch match data for match ID: ${matchId}`
                    );
                }

                const matchData = await matchResponse.json();

                // Step 3: Fetch telemetry data
                const telemetryResponse = await fetch(matchData.telemetryUrl);

                if (!telemetryResponse.ok) {
                    throw new Error(
                        `Failed to fetch telemetry data: ${telemetryResponse.status}`
                    );
                }

                const telemetryJson = await telemetryResponse.json();
                setTelemetryData(telemetryJson);

                console.log(telemetryJson);
            } catch (err) {
                setError(
                    (err as Error).message ||
                        "An error occurred while fetching telemetry data."
                );
            } finally {
                setLoading(false);
            }
        };

        fetchTelemetry();
    }, [matchId, platform]);

    return { telemetryData, loading, error };
};
