import React from "react";
import { Pie } from "react-chartjs-2";
import { mapNameMapping } from "constants/mapNameMapping";
import { Match } from "types/match";

interface MapPieChartProps {
    matches: Match[];
}

const MapPieChart: React.FC<MapPieChartProps> = ({ matches }) => {
    const mapCounts = matches.reduce((acc, match) => {
        const formattedMapName = mapNameMapping[match.mapName] || match.mapName;
        acc[formattedMapName] = (acc[formattedMapName] || 0) + 1;
        return acc;
    }, {} as Record<string, number>);

    const data = {
        labels: Object.keys(mapCounts),
        datasets: [
            {
                label: "Maps",
                data: Object.values(mapCounts),
                backgroundColor: [
                    "#FF6384",
                    "#36A2EB",
                    "#FFCE56",
                    "#4CAF50",
                    "#FF5722",
                ],
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            datalabels: {
                display: false, // Disable data labels for this chart
            },
        },
    };

    return <Pie data={data} options={options} />;
};

export default MapPieChart;
