import { createTheme } from "@mui/material";

export const getDynamicTheme = (themePreferences: {
    primaryColor: string;
    accentColor1: string;
    accentColor2: string;
    backgroundColor: string;
    fontSize: number;
    fontFamily: string;
}) =>
    createTheme({
        palette: {
            mode: "dark",
            primary: {
                main: themePreferences.primaryColor,
            },
            secondary: {
                main: themePreferences.accentColor1,
            },
            custom: {
                accentColor2: themePreferences.accentColor2,
            },
            background: {
                default: themePreferences.backgroundColor,
                paper: "#2A2A2A",
            },
            text: {
                primary: "#ffffff",
                secondary: "#c7c7c7",
            },
        },
        typography: {
            fontSize: themePreferences.fontSize,
            fontFamily: themePreferences.fontFamily,
        },
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        "&.Mui-contained": {
                            backgroundColor: themePreferences.accentColor1,
                            "&:hover": {
                                backgroundColor: themePreferences.accentColor2,
                            },
                        },
                    },
                },
            },
        },
    });
