export const MatchTypeMap: Record<string, string> = {
    airoyale: "Casual", // AI Royale
    arcade: "Arcade",
    official: "Public",
    custom: "Custom",
    training: "Training",
    trainingroom: "Training",
    competitive: "Ranked",
    event: "Arcade", // Event matches are treated as Arcade
    seasonal: "Public", /// Refers to the selectable map option
};
