import React from "react";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import logo from "assets/pubg_meta_logo.png";

interface MetaLogoProps {
    to: string;
    height?: number;
    marginRight?: number;
    cursorStyle?: string;
}

export const MetaLogo: React.FC<MetaLogoProps> = ({
    to,
    height = 40,
    marginRight = 2,
    cursorStyle = "pointer",
}) => {
    return (
        <Link to={to} style={{ textDecoration: "none" }}>
            <Box
                component="img"
                sx={{
                    height: height,
                    mr: marginRight,
                    cursor: cursorStyle,
                }}
                alt="PUBG Meta Logo"
                src={logo}
            />
        </Link>
    );
};
