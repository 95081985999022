import React, { useState } from "react";
import {
    Box,
    Typography,
    Divider,
    Button,
    FormControlLabel,
    Checkbox,
} from "@mui/material";

import { RotationMapCardSection } from "components/mapRotations/RotationMapCardSection";

import { PieChart, Pie, Cell, Legend, Tooltip } from "recharts";
import { RotationHeader } from "components/mapRotations/RotationHeader";
import { Link } from "react-router-dom";
import { maxWidths } from "constants/appWide/maxWidths";

interface WeeklyRotation {
    pc: {
        maps: Record<string, number>;
    };
    console: {
        maps: Record<string, number>;
    };
}

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#A28AFF"];

const rotationStartDate = "2025-01-08T02:00:00Z"; // Start date for the first week of the patch batch
const weeklyRotations: WeeklyRotation[] = [
    {
        pc: {
            maps: {
                Erangel: 15,
                Rondo: 15,
                Vikendi: 15,
                Deston: 15,
                Sanhok: 40,
            },
        },
        console: {
            maps: {
                Erangel: 15,
                Sanhok: 40,
                Rondo: 15,
                Karakin: 15,
                Taego: 15,
            },
        },
    },
    {
        pc: {
            maps: {
                Erangel: 15,
                Rondo: 15,
                Vikendi: 15,
                Deston: 15,
                Sanhok: 40,
            },
        },
        console: {
            maps: {
                Erangel: 25,
                Vikendi: 25,
                Miramar: 25,
                Paramo: 25,
            },
        },
    },
    {
        pc: {
            maps: {
                Erangel: 20,
                Miramar: 20,
                Taego: 20,
                Deston: 20,
                Sanhok: 20,
            },
        },
        console: {
            maps: {
                Erangel: 25,
                Vikendi: 25,
                Miramar: 25,
                Paramo: 25,
            },
        },
    },
];

const rankedRotation = {
    maps: {
        Erangel: 25,
        Miramar: 25,
        Taego: 20,
        Rondo: 20,
        Deston: 10,
    },
};

const getCurrentWeek = (startDate: string): number => {
    const start = new Date(startDate);
    const now = new Date();
    const daysDifference = Math.floor(
        (now.getTime() - start.getTime()) / (1000 * 60 * 60 * 24)
    );
    return Math.floor(daysDifference / 7) + 1;
};

interface RotationSectionProps {
    rotationType: "pc" | "console" | "ranked";
    maps: Record<string, number>;
    useTopographicImages: boolean;
}

const CardRotationSection: React.FC<RotationSectionProps> = ({
    rotationType,
    maps,
    useTopographicImages,
}) => {
    const sortedMaps = Object.entries(maps).sort((a, b) => b[1] - a[1]);

    return (
        <Box marginBottom={4}>
            <RotationHeader rotationType={rotationType} />
            <RotationMapCardSection
                maps={sortedMaps.map(([name, probability]) => ({
                    name,
                    probability,
                }))}
                useTopographicImages={useTopographicImages}
            />
        </Box>
    );
};

const getChartData = (maps: Record<string, number>) =>
    Object.entries(maps).map(([name, value]) => ({ name, value }));

const MapRotationPage: React.FC = () => {
    const weekNumber = getCurrentWeek(rotationStartDate);
    const pcRotation = weeklyRotations[weekNumber - 1];
    const consoleRotation = weeklyRotations[weekNumber - 2];

    const [viewMode, setViewMode] = useState<"Card View" | "Pie Chart">(
        "Card View"
    );

    const [showPC, setShowPC] = useState(true);
    const [showConsole, setShowConsole] = useState(true);
    const [showRanked, setShowRanked] = useState(true);

    const [useTopographicImages, setUseTopographicImages] = useState(true);

    if (!pcRotation || !consoleRotation) {
        return (
            <Box textAlign="center" padding="20px">
                <Typography variant="h5">
                    No map rotation data available.
                </Typography>
            </Box>
        );
    }

    const pcChartData = getChartData(pcRotation.pc.maps);
    const consoleChartData = getChartData(consoleRotation.console.maps);
    const rankedChartData = getChartData(rankedRotation.maps);

    const renderChart = (data: { name: string; value: number }[]) => {
        // Sort the data by value in descending order
        const sortedData = [...data].sort((a, b) => b.value - a.value);

        return (
            <PieChart width={400} height={400}>
                <Pie
                    data={sortedData}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={120}
                    startAngle={90} // Start at 12 o'clock
                    endAngle={-270} // Complete the circle counterclockwise
                    fill="#8884d8"
                    label
                >
                    {sortedData.map((entry, index) => (
                        <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                        />
                    ))}
                </Pie>
                <Legend />
                <Tooltip />
            </PieChart>
        );
    };

    return (
        <Box maxWidth={maxWidths.l} margin="0 auto" padding="20px">
            <Typography variant="h5" textAlign="center" gutterBottom>
                Map Rotations
            </Typography>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: { xs: "column-reverse", md: "row" }, // Reverse order on mobile
                    // alignItems: { xs: "flex-start", md: "stretch" },
                    gap: 4, // Spacing between sections
                    maxWidth: maxWidths.l,
                    margin: "0 auto",
                    marginBottom: 2,
                }}
            >
                {/* Control Box */}
                <Box
                    sx={{
                        flex: "0 0 300px", // Fixed width for desktop
                        maxWidth: "100%", // Full width for mobile
                        backgroundColor: "#1f1f1f",
                        padding: 2,
                        borderRadius: "8px",
                        marginBottom: { xs: 4, md: 0 }, // Adds spacing for mobile
                        textAlign: { xs: "center", md: "left" },
                    }}
                >
                    <Box display="flex" justifyContent="center" gap={2}>
                        <Button
                            variant={
                                viewMode === "Card View"
                                    ? "contained"
                                    : "outlined"
                            }
                            onClick={() => setViewMode("Card View")}
                        >
                            Card View
                        </Button>
                        <Button
                            variant={
                                viewMode === "Pie Chart"
                                    ? "contained"
                                    : "outlined"
                            }
                            onClick={() => setViewMode("Pie Chart")}
                        >
                            Pie Chart
                        </Button>
                    </Box>
                    <Divider sx={{ my: 2 }} />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={showPC}
                                onChange={() => setShowPC(!showPC)}
                            />
                        }
                        label="Show PC Map Rotation"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={showConsole}
                                onChange={() => setShowConsole(!showConsole)}
                            />
                        }
                        label="Show Console Map Rotation"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={showRanked}
                                onChange={() => setShowRanked(!showRanked)}
                            />
                        }
                        label="Show Ranked Map Rotation"
                    />
                    {viewMode === "Card View" && (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={useTopographicImages}
                                    onChange={() =>
                                        setUseTopographicImages(
                                            !useTopographicImages
                                        )
                                    }
                                />
                            }
                            label="Use Top-Down Map Images"
                        />
                    )}
                </Box>

                {/* Text Description  */}
                <Box
                    flex="1"
                    sx={{
                        backgroundColor: "#1f1f1f",
                        color: "#c7d5e0",
                        padding: 3,
                        borderRadius: "8px",
                        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)", // Subtle shadow
                        textAlign: "left",
                    }}
                >
                    <Typography variant="body2" color="inherit" mb={2}>
                        • The PC Map Rotation only applies to Random Map Regions
                        - NA, SA, EU, RU & Console, not to Map Select Regions
                        (AS, SEA).
                    </Typography>
                    <Typography variant="body2" color="inherit" mb={2}>
                        • PC players can anticipate the next rotation every{" "}
                        {(() => {
                            const now = new Date();
                            const nextWednesday = new Date(
                                now.setDate(
                                    now.getDate() +
                                        ((3 - now.getDay() + 7) % 7 || 7)
                                )
                            );
                            nextWednesday.setUTCHours(2, 0, 0, 0);
                            const localTime = nextWednesday.toLocaleTimeString(
                                undefined,
                                {
                                    hour: "numeric",
                                    hour12: true,
                                }
                            );
                            const dayOfWeek = nextWednesday.toLocaleDateString(
                                undefined,
                                {
                                    weekday: "long",
                                }
                            );
                            return `${dayOfWeek} at ${localTime} (2 AM UTC)`;
                        })()}
                        .
                    </Typography>
                    <Typography variant="body2" color="inherit" mb={2}>
                        • Console players can expect the same every{" "}
                        {(() => {
                            const now = new Date();
                            const nextThursday = new Date(
                                now.setDate(
                                    now.getDate() +
                                        ((4 - now.getDay() + 7) % 7 || 7)
                                )
                            );
                            nextThursday.setUTCHours(7, 0, 0, 0);
                            const localTime = nextThursday.toLocaleTimeString(
                                undefined,
                                {
                                    hour: "numeric",
                                    hour12: true,
                                }
                            );
                            const dayOfWeek = nextThursday.toLocaleDateString(
                                undefined,
                                {
                                    weekday: "long",
                                }
                            );
                            return `${dayOfWeek} at ${localTime} (7 AM UTC)`;
                        })()}
                        .
                    </Typography>
                    <Typography variant="body2" color="inherit" mb={2}>
                        • The map service for Ranked is updated on a
                        season-by-season basis.
                    </Typography>
                    <Typography variant="body2" color="inherit">
                        • Probabilities for map rotations are derived from the
                        official PUBG patch announcements. For more details, you
                        can explore the full patch history on the{" "}
                        <Link
                            to="/patches"
                            style={{
                                color: "#66c0f4",
                                textDecoration: "underline",
                            }}
                        >
                            Patches Page
                        </Link>
                        .
                    </Typography>
                </Box>
            </Box>

            {/* Card View */}
            {viewMode === "Card View" ? (
                <>
                    {showPC && (
                        <>
                            <CardRotationSection
                                rotationType="pc"
                                maps={pcRotation.pc.maps}
                                useTopographicImages={useTopographicImages}
                            />
                            <Divider sx={{ marginY: 4 }} />
                        </>
                    )}

                    {showConsole && (
                        <>
                            <CardRotationSection
                                rotationType="console"
                                maps={consoleRotation.console.maps}
                                useTopographicImages={useTopographicImages}
                            />
                            <Divider sx={{ marginY: 4 }} />
                        </>
                    )}

                    {showRanked && (
                        <CardRotationSection
                            rotationType="ranked"
                            maps={rankedRotation.maps}
                            useTopographicImages={useTopographicImages}
                        />
                    )}
                </>
            ) : (
                <Box
                    display="flex"
                    justifyContent="center"
                    flexWrap="wrap"
                    gap={4}
                >
                    {showPC && (
                        <Box>
                            <RotationHeader rotationType="pc" />
                            {renderChart(pcChartData)}
                        </Box>
                    )}
                    {showConsole && (
                        <Box>
                            <RotationHeader rotationType="console" />
                            {renderChart(consoleChartData)}
                        </Box>
                    )}
                    {showRanked && (
                        <Box>
                            <RotationHeader rotationType="ranked" />
                            {renderChart(rankedChartData)}
                        </Box>
                    )}
                </Box>
            )}
        </Box>
    );
};

export default MapRotationPage;
