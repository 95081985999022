import { useState } from "react";

export const useShowMapImages = () => {
    const [showMapImages, setShowMapImages] = useState<boolean>(() => {
        const savedValue = localStorage.getItem("showMapImages");
        return savedValue !== null ? JSON.parse(savedValue) : true; // Default to true if no saved value
    });

    const toggleShowMapImages = (value: boolean) => {
        setShowMapImages(value);
        localStorage.setItem("showMapImages", JSON.stringify(value));
    };

    return { showMapImages, toggleShowMapImages };
};
