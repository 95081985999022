import slug from "assets/customWeaponImages/12gaSlug.png";

export const ammoIcons: { [key: string]: string } = {
    // Main
    556: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Ammunition/None/Item_Ammo_556mm_C.png",
    762: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Ammunition/None/Item_Ammo_762mm_C.png",
    "12ga": "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Ammunition/None/Item_Ammo_12Guage_C.png",

    // Secondary
    // SMGs and Handguns
    "9mm": "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Ammunition/None/Item_Ammo_9mm_C.png",
    45: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Ammunition/None/Item_Ammo_45ACP_C.png",

    // O12 Shotgun
    "12gaSlug": slug,

    // Crate Package
    // P90 uses 5.7 ammo
    57: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Ammunition/None/item_Ammo_57mm_c.png",
    // AWM .300 Magnum
    ".300": "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Ammunition/None/Item_Ammo_300Magnum_C.png",
    // Lynx AMR 50 cal has no weapon icon, it is built into the gun and we never see it

    // Mortar uses 60mm ammo
    60: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Ammunition/None/item_Ammo_60mm_C.png",

    // Crossbow Bolt
    Bolt: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Ammunition/None/Item_Ammo_Bolt_C.png",

    // Non-Lethal
    // Smoke Grenade Launcher uses 40mm ammo
    40: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Ammunition/None/item_Ammo_40mm_C.png",
    // Flare Gun
    Flare: "https://github.com/pubg/api-assets/blob/master/Assets/Item/Ammunition/None/Item_Ammo_Flare_C.png",
};
