import { weaponNameMap } from "constants/weaponNameMap";

/**
 * Formats a weapon name by removing prefixes/suffixes and mapping to a friendly name.
 * @param weaponName - The raw weapon name.
 * @returns The formatted weapon name.
 */
export const formatWeapon = (weaponName: string | undefined): string => {
    if (!weaponName) return "N/A";

    const cleanWeaponName = weaponName.replace(/^Weap/, "").replace(/_C$/, "");
    return weaponNameMap[cleanWeaponName] || cleanWeaponName;
};
