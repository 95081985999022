import { API_ENDPOINTS } from "constants/API_ENDPOINTS";

export const fetchAccountId = async (
    playerName: string,
    platform: string
): Promise<any> => {
    console.log(`Fetching new account ID for ${playerName} on ${platform}`);
    try {
        const response = await fetch(
            API_ENDPOINTS.ACCOUNT_DATA(platform, playerName)
        );
        if (!response.ok) {
            throw new Error(
                `Failed to fetch account ID: ${response.statusText}`
            );
        }

        const accountData = await response.json();

        return accountData;
    } catch (error) {
        console.error(
            `Error fetching account ID for ${playerName} on ${platform}:`,
            error
        );
        throw error;
    }
};
