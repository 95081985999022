import rondoImage from "assets/mapCardImages/rondo.jpg";
import taegoImage from "assets/mapCardImages/taego.jpg";
import destonImage from "assets/mapCardImages/deston.jpg";
import erangelImage from "assets/mapCardImages/erangel.jpg";
import miramarImage from "assets/mapCardImages/miramar.jpg";
import sanhokImage from "assets/mapCardImages/sanhok.jpg";
import vikendiImage from "assets/mapCardImages/vikendi.jpg";
import karakinImage from "assets/mapCardImages/karakin.jpg";
import trainingImage from "assets/mapCardImages/training.jpg";
import paramoImage from "assets/mapCardImages/paramo.jpg";
import havenImage from "assets/mapCardImages/haven.jpg";
import boardwalkImage from "assets/mapCardImages/boardwalk.jpg";
import lianaImage from "assets/mapCardImages/liana.jpg";
import pillarCompoundImage from "assets/mapCardImages/pillarCompound.jpg";

//TODO Add Boardwalk and Pillar

export const mapImages: { [key: string]: string } = {
    Erangel: erangelImage,
    Miramar: miramarImage,
    Sanhok: sanhokImage,
    Vikendi: vikendiImage,
    Karakin: karakinImage,
    "Camp Jackal": trainingImage,
    Taego: taegoImage,
    Rondo: rondoImage,
    Deston: destonImage,
    Paramo: paramoImage,
    Haven: havenImage,
    // TDM Maps
    Boardwalk: boardwalkImage,
    Liana: lianaImage,
    "Pillar Compound": pillarCompoundImage,
};
