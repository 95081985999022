import React from "react";
import { Pie } from "react-chartjs-2";
import { formatGameMode } from "utils/formatGameMode";
import { formatMatchType } from "utils/formatMatchType";
import { Match } from "types/match";

interface GameModeMatchTypePieChartProps {
    matches: Match[];
}

const GameModeMatchTypePieChart: React.FC<GameModeMatchTypePieChartProps> = ({
    matches,
}) => {
    const gameModeMatchTypeCountsArray = Object.entries(
        matches.reduce((acc, match) => {
            const formattedGameMode = formatGameMode(match.gameMode);
            const formattedMatchType = formatMatchType(match.matchType);
            const key = `${formattedGameMode} - ${formattedMatchType}`;
            acc[key] = (acc[key] || 0) + 1;
            return acc;
        }, {} as Record<string, number>)
    ).sort((a, b) => b[1] - a[1]);

    const data = {
        labels: gameModeMatchTypeCountsArray.map(([key]) => key),
        datasets: [
            {
                data: gameModeMatchTypeCountsArray.map(([, count]) => count),
                backgroundColor: [
                    "#FF6384",
                    "#36A2EB",
                    "#FFCE56",
                    "#4CAF50",
                    "#FF9800",
                    "#9C27B0",
                    "#8BC34A",
                    "#FFC107",
                ],
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: "right" as const,
            },
            tooltip: {
                callbacks: {
                    label: (tooltipItem: any) => {
                        const value = tooltipItem.raw as number;
                        const total = gameModeMatchTypeCountsArray.reduce(
                            (sum, [, count]) => sum + count,
                            0
                        );
                        const percentage = ((value / total) * 100).toFixed(2);
                        return `${tooltipItem.label}: ${value} matches (${percentage}%)`;
                    },
                },
            },
            datalabels: {
                display: true,
                formatter: (value: number) => `${value}`,
                color: "#fff",
                font: {
                    weight: "bold" as const,
                    size: 12,
                },
                align: "center" as const,
                anchor: "center" as const,
            },
        },
    };

    return <Pie data={data} options={options} />;
};

export default GameModeMatchTypePieChart;
