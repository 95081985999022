import React from "react";
import { Box, Modal } from "@mui/material";
import { PredefinedPlayers } from "./PredefinedPlayers";

interface CompareModalProps {
    open: boolean;
    onClose: () => void;
    onSelectPlayer: (playerName: string) => void;
}

export const PopularPlayersModal: React.FC<CompareModalProps> = ({
    open,
    onClose,
    onSelectPlayer,
}) => {
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="compare-modal-title"
            aria-describedby="compare-modal-description"
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 2,
            }}
        >
            <Box
                sx={{
                    maxWidth: "700px",
                    width: { xs: "90%", sm: "60%", md: "40%" },
                    maxHeight: "90vh", // Ensure the modal doesn't exceed 90% of the viewport height
                    overflowY: "auto", // Enable vertical scrolling for overflow content
                }}
            >
                <PredefinedPlayers
                    onSelectPlayer={onSelectPlayer}
                    title="Compare your stats with popular players!"
                />
            </Box>
        </Modal>
    );
};
