import React, { useState } from "react";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Divider,
    Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Match } from "types/match";
import KillsBarChart from "../BarCharts/KillsBarChart";
import DamageBarChart from "../BarCharts/DamageBarChart";
import { useNavigate } from "react-router-dom";
import BarChartIcon from "@mui/icons-material/BarChart";

const KillsDamageDataAccordion: React.FC<{
    matches: Match[];
    platform: string;
    playerName: string;
}> = ({ matches, platform, playerName }) => {
    const [expanded, setExpanded] = useState(false);
    const navigate = useNavigate();

    const handleAccordionChange = () => {
        setExpanded((prevExpanded) => !prevExpanded);
    };

    const handleChartClick = (index: number) => {
        const matchId = matches[index].matchId;
        navigate(
            `/player-stats/${platform}/${encodeURIComponent(
                playerName
            )}/matches/${matchId}`
        );
    };

    return (
        <Accordion expanded={expanded} onChange={handleAccordionChange}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="map-game-mode-content"
                id="map-game-mode-header"
                sx={{
                    display: "flex",
                    alignItems: "center",
                    "& .MuiAccordionSummary-content": {
                        display: "flex",
                        alignItems: "center",
                        gap: 1, // Space between icons and text
                    },
                }}
            >
                {/* Pie and Bar Chart Icons */}
                <BarChartIcon fontSize="small" color="primary" />

                {/* Summary Text */}
                <Typography variant="h6">Kills and Damage Data</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Divider sx={{ marginBottom: 2 }} />
                <Box
                    sx={{
                        overflowX: "auto",
                        marginBottom: 2,
                    }}
                >
                    <Typography variant="h6">Kills</Typography>
                    <Box
                        sx={{
                            overflowX: "auto", // Enable horizontal scrolling for the chart only
                            "&::-webkit-scrollbar": {
                                height: "6px", // Height of horizontal scrollbar
                            },
                            "&::-webkit-scrollbar-thumb": {
                                backgroundColor: "rgba(255, 255, 255, 0.3)", // Thumb color
                                borderRadius: "10px", // Rounded corners
                            },
                            "&::-webkit-scrollbar-thumb:hover": {
                                backgroundColor: "rgba(255, 255, 255, 0.5)", // Hover color
                            },
                            "&::-webkit-scrollbar-track": {
                                backgroundColor: "rgba(255, 255, 255, 0.1)", // Track color
                            },
                            scrollbarWidth: "thin", // For Firefox
                            scrollbarColor:
                                "rgba(255, 255, 255, 0.3) rgba(255, 255, 255, 0.1)", // For Firefox
                        }}
                    >
                        <Box
                            sx={{
                                minWidth: `${matches.length * 20}px`,
                                minHeight: 300,
                            }}
                        >
                            <KillsBarChart
                                matches={matches}
                                onClick={handleChartClick}
                            />
                        </Box>
                    </Box>
                </Box>

                {/* Damage Chart */}
                <Box
                    sx={{
                        overflowX: "auto",
                        marginBottom: 2,
                    }}
                >
                    <Typography variant="h6" sx={{ marginBottom: 1 }}>
                        Damage
                    </Typography>
                    <Box
                        sx={{
                            overflowX: "auto", // Enable horizontal scrolling for the chart only
                            "&::-webkit-scrollbar": {
                                height: "6px", // Height of horizontal scrollbar
                            },
                            "&::-webkit-scrollbar-thumb": {
                                backgroundColor: "rgba(255, 255, 255, 0.3)", // Thumb color
                                borderRadius: "10px", // Rounded corners
                            },
                            "&::-webkit-scrollbar-thumb:hover": {
                                backgroundColor: "rgba(255, 255, 255, 0.5)", // Hover color
                            },
                            "&::-webkit-scrollbar-track": {
                                backgroundColor: "rgba(255, 255, 255, 0.1)", // Track color
                            },
                            scrollbarWidth: "thin", // For Firefox
                            scrollbarColor:
                                "rgba(255, 255, 255, 0.3) rgba(255, 255, 255, 0.1)", // For Firefox
                        }}
                    >
                        <Box
                            sx={{
                                minWidth: `${matches.length * 20}px`,
                                minHeight: 300,
                            }}
                        >
                            <DamageBarChart
                                matches={matches}
                                onClick={handleChartClick}
                            />
                        </Box>
                    </Box>
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

export { KillsDamageDataAccordion };
