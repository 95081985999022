import React from "react";
import {
    Box,
    Typography,
    LinearProgress,
    useTheme,
    Divider,
} from "@mui/material";
import { Match } from "types/match";

// Helper function to calculate the number of casual matches played today
const getCasualMatchesPlayedToday = (matches: Match[]) => {
    const now = new Date();
    const resetTimeUTC = new Date(now);
    resetTimeUTC.setUTCHours(2, 0, 0, 0); // 2 AM UTC reset time

    if (now < resetTimeUTC) {
        resetTimeUTC.setUTCDate(resetTimeUTC.getUTCDate() - 1); // Move to the previous day if before 2 AM UTC
    }

    // Filter casual matches since the last reset time
    const casualMatches = matches.filter(
        (match) =>
            match.matchType === "airoyale" &&
            new Date(match.createdAt) >= resetTimeUTC
    );

    return casualMatches.length;
};

// Helper function to check if any casual matches were played in the last 2 weeks
const hasPlayedCasualMatchesInLast2Weeks = (matches: Match[]) => {
    const twoWeeksAgo = new Date();
    twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14); // Go back 14 days

    return matches.some(
        (match) =>
            match.matchType === "airoyale" &&
            new Date(match.createdAt) >= twoWeeksAgo
    );
};

const CasualMatchesBox: React.FC<{ matches: Match[] }> = ({ matches }) => {
    const theme = useTheme();
    const casualMatchesPlayed = getCasualMatchesPlayedToday(matches);
    const progressValue = (casualMatchesPlayed / 3) * 100; // Max 3 matches

    // Check if player played any casual matches in the last 2 weeks
    const playedInLast2Weeks = hasPlayedCasualMatchesInLast2Weeks(matches);

    if (!playedInLast2Weeks) {
        return null; // Do not render the box if no casual matches in last 2 weeks
    }

    const resetTimeUTC = new Date();
    resetTimeUTC.setUTCHours(2, 0, 0, 0); // 2 AM UTC

    // Convert UTC reset time to local time without leading zeros
    const resetTimeLocal = new Intl.DateTimeFormat([], {
        hour: "numeric", // Numeric hour without leading zero
        hour12: true, // Use 12-hour format with AM/PM
    }).format(resetTimeUTC);

    return (
        <Box
            sx={{
                border: "1px solid #ccc",
                borderRadius: "8px",
                padding: 2,
                marginBottom: 2,
                backgroundColor: theme.palette.background.paper,
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                textAlign: "center",
            }}
        >
            <Typography variant="h6" sx={{ marginBottom: 1 }}>
                Casual Matches Played Today
            </Typography>
            <Box
                sx={{
                    position: "relative",
                    width: "100%", // Ensure it matches the width of the LinearProgress
                }}
            >
                {/* Linear Progress */}
                <LinearProgress
                    variant="determinate"
                    value={progressValue}
                    sx={{
                        height: 10,
                        borderRadius: 5,
                        backgroundColor: "#ddd",
                        "& .MuiLinearProgress-bar": {
                            backgroundColor: theme.palette.secondary.main,
                        },
                    }}
                />

                {/* Separating Lines */}
                <Box
                    sx={{
                        position: "absolute",
                        top: 0,
                        left: "33%",
                        height: "100%",
                        width: "5px",
                        backgroundColor: "#000", // Line color
                        zIndex: 1,
                    }}
                />
                <Box
                    sx={{
                        position: "absolute",
                        top: 0,
                        left: "66%",
                        height: "100%",
                        width: "5px",
                        backgroundColor: "#000", // Line color
                        zIndex: 1,
                    }}
                />
            </Box>

            <Typography variant="body2" sx={{ marginTop: 1 }}>
                {`${casualMatchesPlayed} of 3 matches played`}
            </Typography>
            <Typography
                variant="body2"
                sx={{
                    marginTop: 1,
                    marginBottom: 1,
                    color: theme.palette.text.secondary,
                }}
            >
                {`Resets at ${resetTimeLocal} every day (2 AM UTC)`}
            </Typography>
            <Divider />
            <Typography
                variant="subtitle2"
                sx={{
                    marginTop: 1,
                    color: theme.palette.text.secondary,
                }}
            >
                *Matches left in the lobby still count towards the limit but are
                not displayed here because the API does not return them.
            </Typography>
        </Box>
    );
};

export default CasualMatchesBox;
