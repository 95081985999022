export const mapsUrlAndSize: Record<
    string,
    { url: string; size: number; players?: number }
> = {
    Erangel: {
        url: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Maps/Erangel_Main_Low_Res.png",
        size: 8,
    },
    Miramar: {
        url: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Maps/Miramar_Main_Low_Res.png",
        size: 8,
    },
    Sanhok: {
        url: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Maps/Sanhok_Main_Low_Res.png",
        size: 4,
    },
    Vikendi: {
        url: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Maps/Vikendi_Main_Low_Res.png",
        size: 6,
    },
    Karakin: {
        url: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Maps/Karakin_Main_Low_Res.png",
        size: 2,
        players: 64,
    },
    Taego: {
        url: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Maps/Taego_Main_Low_Res.png",
        size: 8,
    },
    Rondo: {
        url: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Maps/Rondo_Main_Low_Res.png",
        size: 8,
    },
    Deston: {
        url: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Maps/Deston_Main_Low_Res.png",
        size: 8,
    },
    Paramo: {
        url: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Maps/Paramo_Main_Low_Res.png",
        size: 3,
    },
    Haven: {
        url: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Maps/Haven_Main_Low_Res.png",
        size: 1,
        players: 32,
    },
    "Camp Jackal": {
        url: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Maps/Camp_Jackal_Main_Low_Res.png",
        size: 2,
    },
};
