import React, { useState, useEffect } from "react";
import {
    Button,
    TextField,
    Typography,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
    useTheme,
} from "@mui/material";
import axios from "axios";
import { useAuth } from "../../AuthContext";
import { ErrorOutline, Visibility, VisibilityOff } from "@mui/icons-material";
import { format } from "date-fns";
import { COLORS } from "constants/appWide/colors";
import FunnyLoading from "components/FunnyLoading";
import { API_ENDPOINTS } from "constants/API_ENDPOINTS";

interface UserProfile {
    username: string;
    email: string;
    joinedDate: string;
}

export const AccountSettings: React.FC = () => {
    const { token, logout } = useAuth();
    const [user, setUser] = useState<UserProfile | null>(null);

    // Password Reset
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");

    // Password Reset
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [passwordError, setPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");

    // Delete Account
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [deletePassword, setDeletePassword] = useState("");
    const [deleteError, setDeleteError] = useState("");
    const [isDeleting, setIsDeleting] = useState(false);

    // Theme
    const theme = useTheme();

    useEffect(() => {
        const fetchUserData = async () => {
            if (!token) {
                console.error("No token available");
                return;
            }

            try {
                const response = await axios.get<UserProfile>(
                    API_ENDPOINTS.USER_PROFILE,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                setUser(response.data);
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        };

        fetchUserData();
    }, [token]);

    const validatePassword = (value: string) => {
        if (value.length < 8 || value.length > 128) {
            setPasswordError("Password must be between 8 and 128 characters.");
        } else {
            setPasswordError("");
        }
    };

    const validateConfirmPassword = (value: string) => {
        if (value !== newPassword) {
            setConfirmPasswordError("Passwords do not match.");
        } else {
            setConfirmPasswordError("");
        }
    };

    const handlePasswordChange = async () => {
        if (newPassword !== confirmPassword) {
            setError("New passwords do not match.");
            return;
        }

        setLoading(true);
        setError("");
        setMessage("");

        try {
            await axios.post(
                API_ENDPOINTS.CHANGE_PASSWORD,
                {
                    currentPassword,
                    newPassword,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setMessage("Password changed successfully!");
            setCurrentPassword("");
            setNewPassword("");
            setConfirmPassword("");
        } catch (err: any) {
            setError(
                err.response?.data?.error || "Failed to change the password."
            );
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteAccountClick = () => {
        setDeleteDialogOpen(true);
    };

    const handleConfirmDelete = async () => {
        setIsDeleting(true);
        setDeleteError("");
        try {
            await axios.delete(API_ENDPOINTS.DELETE_ACCOUNT, {
                data: { password: deletePassword },
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            // Log the user out and redirect to home
            logout();
            alert("Your account has been deleted successfully.");
            window.location.href = "/";
        } catch (err: any) {
            setDeleteError(
                err.response?.data?.message || "Failed to delete account."
            );
        } finally {
            setIsDeleting(false);
        }
    };

    if (!user) {
        return <Typography>Loading...</Typography>;
    }

    return (
        <Box
            sx={{
                p: 3,
                maxWidth: 600,
                margin: "0 auto",
            }}
        >
            <Typography variant="h5">Account Settings</Typography>
            <Box sx={{ mt: 2 }}>
                <TextField
                    label="Username"
                    value={user.username}
                    fullWidth
                    margin="normal"
                    InputProps={{
                        readOnly: true,
                    }}
                />
                <TextField
                    label="Email Address"
                    value={user.email}
                    fullWidth
                    margin="normal"
                    InputProps={{
                        readOnly: true,
                    }}
                />
                <TextField
                    label="Member Since"
                    value={format(new Date(user.joinedDate), "MMMM d, yyyy")}
                    fullWidth
                    margin="normal"
                    InputProps={{
                        readOnly: true,
                    }}
                />
            </Box>

            <Box
                sx={{
                    mt: 4,
                    p: 2,
                    border: "2px solid",
                    borderColor: COLORS.blue,
                    borderRadius: 2,
                    textAlign: "center",
                }}
            >
                <Typography variant="h6" gutterBottom>
                    Change Password
                </Typography>
                {message && (
                    <Typography color="success.main">{message}</Typography>
                )}
                {error && <Typography color="error">{error}</Typography>}
                <TextField
                    label="Current Password"
                    type="password"
                    fullWidth
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    margin="normal"
                />
                <TextField
                    label="New Password"
                    type={showPassword ? "text" : "password"}
                    fullWidth
                    value={newPassword}
                    error={!!passwordError}
                    onChange={(e) => {
                        setNewPassword(e.target.value);
                        validatePassword(e.target.value);
                    }}
                    margin="normal"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() =>
                                        setShowPassword((prev) => !prev)
                                    }
                                    edge="end"
                                >
                                    {showPassword ? (
                                        <Visibility />
                                    ) : (
                                        <VisibilityOff />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                {/* Moved to a separate Typography because if I were to use helperText on the above, it would also turn red when erroring */}
                <Typography
                    variant="body2"
                    sx={{
                        ml: 2,

                        mb: 1,
                        color: "text.secondary",
                        textAlign: "left",
                        fontSize: "0.8rem",
                    }}
                >
                    Password must be 8–128 characters long. For better security,
                    use longer and more random combinations.
                </Typography>

                {passwordError && (
                    <Box
                        sx={{
                            display: "flex",
                            gap: 1,
                            mt: 1,
                            mb: 1,
                            color: "error.main",
                        }}
                    >
                        <ErrorOutline fontSize="small" />
                        <Typography variant="body2" sx={{ textAlign: "left" }}>
                            {passwordError}
                        </Typography>
                    </Box>
                )}
                <TextField
                    label="Confirm New Password"
                    type={showConfirmPassword ? "text" : "password"}
                    fullWidth
                    value={confirmPassword}
                    onChange={(e) => {
                        setConfirmPassword(e.target.value);
                        validateConfirmPassword(e.target.value);
                    }}
                    error={!!confirmPasswordError}
                    margin="normal"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() =>
                                        setShowConfirmPassword((prev) => !prev)
                                    }
                                    edge="end"
                                >
                                    {showConfirmPassword ? (
                                        <Visibility />
                                    ) : (
                                        <VisibilityOff />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                {confirmPasswordError && (
                    <Box
                        sx={{
                            display: "flex",
                            gap: 1,
                            mt: 1,
                            color: "error.main",
                        }}
                    >
                        <ErrorOutline fontSize="small" />
                        <Typography variant="body2">
                            {confirmPasswordError}
                        </Typography>
                    </Box>
                )}
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handlePasswordChange}
                    sx={{ mt: 2 }}
                    disabled={loading}
                >
                    {loading ? <FunnyLoading /> : "Change Password"}
                </Button>
            </Box>

            <Box
                sx={{
                    mt: 4,
                    p: 2,
                    border: "2px solid",
                    borderColor: "error.main",
                    borderRadius: 2,

                    textAlign: "center",
                }}
            >
                <Typography variant="h6" gutterBottom>
                    Delete Account
                </Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                    You will be asked for password confirmation before deleting.
                </Typography>
                <Button
                    variant="contained"
                    color="error"
                    onClick={handleDeleteAccountClick}
                    sx={{ width: "100%" }}
                >
                    Delete Account
                </Button>
            </Box>

            {/* Delete Account Dialog */}
            <Dialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
            >
                <DialogTitle>Confirm Account Deletion</DialogTitle>
                <DialogContent>
                    <Typography>
                        Are you sure you want to delete your account? This
                        action cannot be undone. All of your saved players will
                        be lost, and all of your saved matches will be removed
                        from our database. You are always free to make a new
                        account using the same email address.
                    </Typography>
                    <TextField
                        label="Password"
                        type="password"
                        fullWidth
                        value={deletePassword}
                        onChange={(e) => setDeletePassword(e.target.value)}
                        margin="normal"
                        error={!!deleteError}
                        helperText={deleteError}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setDeleteDialogOpen(false)}
                        disabled={isDeleting}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleConfirmDelete}
                        color="error"
                        disabled={!deletePassword || isDeleting}
                    >
                        {isDeleting ? "Deleting..." : "Delete"}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};
