import React from "react";
import { Box, Grid, Typography } from "@mui/material";

import yellowBoxImage from "assets/maps/ibr/boxes/yellow_box.png";
import blueBoxImage from "assets/maps/ibr/boxes/blue_box.png";
import redBoxImage from "assets/maps/ibr/boxes/red_box.png";
import { COLORS } from "constants/appWide/colors";

const IBRBoxDetails: React.FC = () => (
    <Box sx={{ marginTop: "40px" }}>
        <Typography variant="h5" gutterBottom>
            Box Contents
        </Typography>
        <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
                <Box
                    sx={{
                        textAlign: "center",
                        padding: "20px",
                        border: 1,
                        borderColor: "#ddd",
                        borderRadius: "8px",
                        boxShadow: 2,
                    }}
                >
                    <img
                        src={yellowBoxImage}
                        alt="Yellow Box"
                        style={{ marginBottom: "10px" }}
                    />
                    <Typography variant="h6">Yellow Box Contents</Typography>
                    <Typography variant="body2">
                        Level 3 Helmet, Level 3 Vest, C4, Jammer Pack, Folded
                        Shield
                    </Typography>
                </Box>
            </Grid>

            <Grid item xs={12} sm={4}>
                <Box
                    sx={{
                        textAlign: "center",
                        padding: "20px",
                        border: 1,
                        borderColor: "#ddd",
                        borderRadius: "8px",
                        boxShadow: 2,
                    }}
                >
                    <img
                        src={blueBoxImage}
                        alt="Blue Box"
                        style={{ marginBottom: "10px" }}
                    />
                    <Typography variant="h6">Blue Box Contents</Typography>
                    <Typography variant="body2">
                        Self-AED, BZ Grenade, Med Kit, First Aid Kit, Emergency
                        Pickup, etc.
                    </Typography>
                </Box>
            </Grid>

            <Grid item xs={12} sm={4}>
                <Box
                    sx={{
                        textAlign: "center",
                        padding: "20px",
                        border: 1,
                        borderColor: "#ddd",
                        borderRadius: "8px",
                        boxShadow: 2,
                    }}
                >
                    <img
                        src={redBoxImage}
                        alt="Red Box"
                        style={{ marginBottom: "10px" }}
                    />
                    <Typography variant="h6">Red Box Contents</Typography>
                    <Typography variant="body2">
                        Combination of 5.56 ARs/DMRs and 7.62 ARs/DMRs
                    </Typography>
                </Box>
            </Grid>
        </Grid>
    </Box>
);

export default IBRBoxDetails;
