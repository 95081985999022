import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { COLORS } from "constants/appWide/colors";
import { tdmMaps } from "constants/tdmMaps";

export const TDMMaps: React.FC = () => {
    const { mapName } = useParams<{ mapName?: string }>();
    const [selectedMap, setSelectedMap] = useState<{
        name: string;
        parentMap: string;
        image: string;
    } | null>(null);

    const navigate = useNavigate();

    useEffect(() => {
        if (mapName) {
            const foundMap = tdmMaps.find(
                (map) => map.name.toLowerCase() === mapName.toLowerCase()
            );
            setSelectedMap(foundMap || null);
        } else {
            setSelectedMap(null);
        }
    }, [mapName]);

    const handleBack = () => {
        navigate("/maps/tdm");
    };

    return (
        <Box
            sx={{
                maxWidth: 1200,
                margin: "0 auto",
                p: 2,
            }}
        >
            {!selectedMap ? (
                <>
                    <Typography variant="h5" gutterBottom>
                        TDM Maps
                    </Typography>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            flexWrap: "wrap",
                            gap: "20px",
                            marginTop: "20px",
                        }}
                    >
                        {tdmMaps.map((map) => (
                            <motion.div
                                whileHover={{ scale: 1.1 }}
                                key={map.name}
                            >
                                <Box
                                    onClick={() =>
                                        navigate(`/maps/tdm/${map.name}`)
                                    }
                                    sx={{
                                        width: "300px",
                                        height: "300px",
                                        border: "3px solid orange",
                                        borderRadius: "10px",
                                        overflow: "hidden",
                                        cursor: "pointer",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "flex-end",
                                        position: "relative",
                                        // backgroundColor: "#fff",
                                        "&:hover": {
                                            boxShadow:
                                                "0 4px 15px rgba(0,0,0,0.2)",
                                        },
                                    }}
                                >
                                    <Box
                                        component="img"
                                        src={map.image}
                                        alt={map.name}
                                        sx={{
                                            width: "100%",
                                            height: "calc(100% - 30px)",
                                            objectFit: "cover",
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            backgroundColor: COLORS.orange,
                                            color: COLORS.black,
                                            padding: "5px",
                                            textAlign: "center",
                                            fontWeight: "bold",
                                            position: "relative",
                                            zIndex: 1,
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            paddingInline: "10px",
                                        }}
                                    >
                                        <Typography
                                            variant="body1"
                                            sx={{
                                                overflow: "hidden",
                                                whiteSpace: "nowrap",
                                                textOverflow: "ellipsis",
                                            }}
                                        >
                                            {map.name}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                fontWeight: "bold",
                                            }}
                                        >
                                            {map.parentMap}
                                        </Typography>
                                    </Box>
                                </Box>
                            </motion.div>
                        ))}
                    </Box>
                </>
            ) : (
                <motion.div
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.8 }}
                    transition={{ duration: 0.5 }}
                >
                    <Typography variant="h4" align="center" gutterBottom>
                        {selectedMap.name}
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        align="center"
                        sx={{ color: "#666", marginBottom: "16px" }}
                    >
                        Located in {selectedMap.parentMap}
                    </Typography>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            marginBottom: 2,
                        }}
                    >
                        <Button
                            onClick={handleBack}
                            variant="contained"
                            sx={{
                                backgroundColor: "#FF9800",
                                color: "#000",
                                ":hover": {
                                    backgroundColor: "#E68900",
                                },
                            }}
                        >
                            Back to Maps
                        </Button>
                    </Box>
                    <Box
                        component="img"
                        src={selectedMap.image}
                        alt={selectedMap.name}
                        sx={{
                            maxWidth: "100%",
                            objectFit: "contain",
                            borderRadius: "8px",
                        }}
                    />
                </motion.div>
            )}
        </Box>
    );
};
