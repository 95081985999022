// utils/sessionStats.ts
import { Match } from "types/match";

/**
 * Formats a given duration in seconds into a human-readable string.
 * @param totalSeconds - The total duration in seconds.
 * @returns A formatted string representing the duration in hours and minutes.
 */
export const formatDuration = (totalSeconds: number): string => {
    const totalMinutes = Math.floor(totalSeconds / 60);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours > 0 ? `${hours}h ` : ""}${
        minutes > 0 ? `${minutes}m` : ""
    }`;
};

export interface SessionStats {
    sessionId: number;

    // Matches and Wins
    matchesCount: number;
    totalWins: number;
    winsPercent: number;

    // Kills
    totalKills: string;
    totalRosterKills: string;
    killsPercent: number;

    // Damage
    totalDamage: string;
    totalRosterDamage: string;
    damagePercent: number;

    // Survival Time
    totalSurvivalTime: string;
    totalMatchesDuration: string;
    survivalPercent: number;

    // Session Duration
    duration: string;
    startTime: string;
    endTime: string;
}

export const calculateSessionStats = (
    sessions: { sessionId: number; matches: Match[] }[]
): SessionStats[] => {
    return sessions.map((session) => {
        const totalWins = session.matches.filter(
            (m) => m.playerWinPlace === 1
        ).length;

        const totalKills = session.matches.reduce(
            (sum, match) => sum + (match.playerKills || 0),
            0
        );
        const totalDamage = session.matches.reduce(
            (sum, match) => sum + (match.playerDamage || 0),
            0
        );

        const totalRosterKills = session.matches.reduce(
            (sum, match) => sum + (match.playerRosterKills || 0),
            0
        );

        const totalRosterDamage = session.matches.reduce(
            (sum, match) => sum + (match.playerRosterDamage || 0),
            0
        );
        const totalSurvivalTime = session.matches.reduce(
            (sum, match) => sum + (match.playerSurvivalTime || 0),
            0
        );

        const totalMatchesDuration = session.matches.reduce(
            (sum, match) => sum + match.duration,
            0
        );

        const sessionStartTime = new Date(
            session.matches[0].createdAt
        ).getTime();
        const lastMatch = session.matches[session.matches.length - 1];
        const sessionEndTime =
            new Date(lastMatch.createdAt).getTime() + lastMatch.duration * 1000;

        const durationMinutes = Math.floor(
            (sessionEndTime - sessionStartTime) / 60000
        );
        const hours = Math.floor(durationMinutes / 60);
        const minutes = durationMinutes % 60;

        return {
            sessionId: session.sessionId,

            // Matches and Wins
            matchesCount: session.matches.length,
            totalWins,
            winsPercent: Math.round((totalWins / session.matches.length) * 100),

            // Kills
            totalKills: Math.round(totalKills).toLocaleString(),
            totalRosterKills: Math.round(totalRosterKills).toLocaleString(),
            killsPercent: Math.round((totalKills / totalRosterKills) * 100),

            // Damage
            totalDamage: Math.round(totalDamage).toLocaleString(),
            totalRosterDamage: Math.round(totalRosterDamage).toLocaleString(),
            damagePercent: Math.round((totalDamage / totalRosterDamage) * 100),

            // Survival Time
            totalSurvivalTime: formatDuration(totalSurvivalTime),
            totalMatchesDuration: formatDuration(totalMatchesDuration),
            survivalPercent: Math.round(
                (totalSurvivalTime / totalMatchesDuration) * 100
            ),

            // Session Duration
            startTime: new Date(sessionStartTime).toLocaleString("en-US", {
                month: "numeric",
                day: "numeric",
                hour: "numeric",
                minute: "2-digit",
                hour12: true,
            }),
            endTime: new Date(sessionEndTime).toLocaleString("en-US", {
                hour: "numeric",
                minute: "2-digit",
                hour12: true,
            }),
            duration: `${hours > 0 ? `${hours}h ` : ""}${minutes}m`,
        };
    });
};
