// components/SessionCard.tsx
import React from "react";
import {
    Card,
    CardContent,
    Typography,
    Box,
    Divider,
    TableCell,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { SessionStats } from "utils/calculateSessionStats";

// MUI Icons
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import SportsMmaIcon from "@mui/icons-material/SportsMma";

import { maxWidths } from "constants/appWide/maxWidths";
import { metricIcons } from "constants/metricIcons";

interface SessionCardProps {
    session: SessionStats;
    viewType: "compactCard" | "detailedCard";
}

const SessionCard: React.FC<SessionCardProps> = ({ session, viewType }) => {
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Card
                sx={{
                    marginBottom: 2,
                    border: "2px solid #444",
                    borderRadius: "8px",
                    backgroundColor: "#2e2e2e",
                    width: maxWidths.s,
                    maxWidth: "100%",
                }}
            >
                <CardContent>
                    <Typography variant="h6" color="primary" gutterBottom>
                        Session {session.sessionId}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        Occurred: {session.startTime} to {session.endTime}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        Duration: {session.duration}
                    </Typography>
                    <Divider sx={{ marginY: 1 }} />

                    {/* Detailed and Compact Views */}
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        marginBottom={1}
                    >
                        {viewType === "detailedCard" ? (
                            <>
                                {/* Left Column: Matches and Wins */}
                                <Box sx={{ textAlign: "left" }}>
                                    <Typography variant="body2">{`Matches: ${session.matchesCount}`}</Typography>
                                    <Typography variant="body2">{`Wins: ${session.totalWins} (${session.winsPercent}%)`}</Typography>
                                </Box>

                                {/* Center Column: Survival Time */}
                                <Typography
                                    variant="body2"
                                    sx={{ textAlign: "center", flex: 1 }}
                                >
                                    {`Survival Time: ${session.totalSurvivalTime}`}
                                </Typography>

                                {/* Right Column: Kills and Damage */}
                                <Box sx={{ textAlign: "right" }}>
                                    <Typography variant="body2">{`Kills: ${session.totalKills}`}</Typography>
                                    <Typography variant="body2">{`Damage: ${session.totalDamage}`}</Typography>
                                </Box>
                            </>
                        ) : (
                            <>
                                {/* CompactCard View */}
                                <Box sx={{ textAlign: "left" }}>
                                    <Typography variant="body2">{`Matches: ${session.matchesCount}`}</Typography>
                                </Box>

                                <Box sx={{ textAlign: "right" }}>
                                    <Typography variant="body2">{`Wins: ${session.totalWins}`}</Typography>
                                </Box>
                            </>
                        )}
                    </Box>

                    {/* Divider before Table */}
                    {viewType === "detailedCard" && <Divider />}

                    {/* Table Section */}
                    {viewType === "detailedCard" && (
                        <Box>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell
                                                sx={{ fontWeight: "bold" }}
                                            >
                                                Metric
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    fontWeight: "bold",
                                                    textAlign: "right",
                                                }}
                                            >
                                                Amount
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    fontWeight: "bold",
                                                    textAlign: "right",
                                                }}
                                            >
                                                My %
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {[
                                            {
                                                metric: "Match Durations",
                                                icon: metricIcons["duration"],
                                                amount: session.totalMatchesDuration,
                                                percentage:
                                                    session.survivalPercent,
                                            },
                                            {
                                                metric: "Our Damage",
                                                icon: metricIcons["damage"],
                                                amount: session.totalRosterDamage,
                                                percentage: `${session.damagePercent}%`,
                                            },
                                            {
                                                metric: "Our Kills",
                                                icon: metricIcons["kills"],
                                                amount: session.totalRosterKills,
                                                percentage: `${session.killsPercent}%`,
                                            },
                                        ].map((row) => (
                                            <TableRow key={row.metric}>
                                                <TableCell
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        gap: 1,
                                                    }}
                                                >
                                                    {row.icon} {row.metric}
                                                </TableCell>
                                                <TableCell
                                                    sx={{ textAlign: "right" }}
                                                >
                                                    {row.amount}
                                                </TableCell>
                                                <TableCell
                                                    sx={{ textAlign: "right" }}
                                                >
                                                    {row.percentage}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    )}
                </CardContent>
            </Card>
        </Box>
    );
};

export default SessionCard;
