export const formatDateTimeRange = (start: Date, end: Date): string => {
    const optionsTime: Intl.DateTimeFormatOptions = {
        hour: "numeric",
        hour12: true,
    };

    const startDay = start.getDate();
    const endDay = end.getDate();
    const startMonth = start.toLocaleDateString(undefined, { month: "long" });
    const endMonth = end.toLocaleDateString(undefined, { month: "long" });
    const time = start.toLocaleTimeString(undefined, optionsTime); // Time is the same for both start and end

    // Case 1: Same month and year
    if (
        start.getMonth() === end.getMonth() &&
        start.getFullYear() === end.getFullYear()
    ) {
        return `${startMonth} ${startDay} to ${endDay}  at ${time}`;
    }

    // Case 2: Different months but same year
    if (start.getFullYear() === end.getFullYear()) {
        return `${startMonth} ${startDay}  to ${endMonth} ${endDay}  at ${time}`;
    }

    // Case 3: Different years
    return `${startMonth} ${startDay}  ${start.getFullYear()} to ${endMonth} ${endDay}  ${end.getFullYear()} at ${time}`;
};
