import { Box, Typography } from "@mui/material";
import koreanRatingOne from "assets/tipsAndStrategies/koreanRatingOne.png";
import koreanRatingTwo from "assets/tipsAndStrategies/koreanRatingTwo.png";
import brightness from "assets/tipsAndStrategies/brightness.jpg";
import jumpVaultKeybinds from "assets/tipsAndStrategies/jumpVaultKeybinds.jpg";
import bikeLean from "assets/tipsAndStrategies/bikeLean.jpg";

interface Tip {
    title: string;
    content: string | JSX.Element;
}

export const SECTIONS: Tip[] = [
    {
        title: "General Tips",
        content: (
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <Typography variant="body2">
                    1. Turn on the Korean Rating Steam launch option:
                </Typography>
                <Typography variant="body2" sx={{ pl: 2 }}>
                    - Turns blood green for better visibility.
                    <br />
                    - Makes dead bodies disappear instantly to avoid confusion.
                    <br />- Molotov flames appear as gas (slightly harder to
                    spot).
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: 2,
                    }}
                >
                    <img
                        src={koreanRatingOne}
                        alt="Korean Rating Option 1"
                        style={{
                            width: "100%",
                            borderRadius: "8px",
                        }}
                    />
                    <img
                        src={koreanRatingTwo}
                        alt="Korean Rating Option 2"
                        style={{
                            width: "100%",

                            borderRadius: "8px",
                        }}
                    />
                </Box>
                <Typography variant="body2">
                    2. Increase brightness on darker maps for better visibility.
                </Typography>
                <img
                    src={brightness}
                    alt="Manually Adjust Brightness"
                    style={{
                        width: "100%",
                        borderRadius: "8px",
                    }}
                />
                <Typography variant="body2">
                    3. Place your assault weapon in slot #2 for faster access
                    and improved stealth when prone.
                </Typography>
                <Typography variant="body2">
                    4. Use C4 on a vehicle to create a mobile bomb. Honk to draw
                    attention and time detonation carefully.
                </Typography>
                <Typography variant="body2">
                    5. Delete game videos from the following directory for
                    faster loading into the game:
                </Typography>
                <Typography
                    variant="body2"
                    sx={{
                        backgroundColor: "#1e1e1e",
                        padding: "10px",
                        borderRadius: "8px",
                        fontFamily: "monospace",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    steamapps\common\PUBG\TslGame\Content\Movies
                </Typography>
            </Box>
        ),
    },
    {
        title: "Vehicle Tips",
        content: (
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <Typography variant="body2">
                    1. Hold <strong>W</strong> while switching seats to keep
                    moving forward.
                </Typography>
                <Typography variant="body2">
                    2. Swap seats right before exiting the vehicle for an
                    unexpected approach.
                </Typography>
                <Typography variant="body2">
                    3. When getting in vehicles, always keep a slight distance
                    from vehicles to avoid hitbox damage.
                </Typography>
                <Typography variant="body2">
                    4. Lead shots slightly ahead when shooting at moving
                    vehicles.
                </Typography>
            </Box>
        ),
    },

    {
        title: "Smoke Tips",
        content: (
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <Typography variant="body2">
                    1. If playing in TPP mode, switch to <strong>FPP</strong>{" "}
                    while you are inside smoke to see nearby enemies.
                </Typography>
                <Typography variant="body2">
                    2. Smoke when reviving, but be cautious as it may attract
                    grenades.
                </Typography>
                <Typography variant="body2">
                    3. Crouch in smoke if enemies are at a higher elevation,
                    otherwise they can see you from above.
                </Typography>
                <Typography variant="body2">
                    4. In <strong>TPP</strong>, peek around the edges of smoke
                    while staying hidden.
                </Typography>
            </Box>
        ),
    },

    {
        title: "Grenade Tips",
        content: (
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <Typography variant="body2">
                    1. Jump while throwing grenades to increase distance.
                </Typography>
                <Typography variant="body2">
                    2. Grenades will only bounce up to <strong>3 times</strong>{" "}
                    regardless of slope.
                </Typography>
            </Box>
        ),
    },

    {
        title: "Revive Tips",
        content: (
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <Typography variant="body2">
                    1. While being revived, you can move around the player
                    reviving you for a better position post-revive if you hug
                    their hitbox while rotating around.
                </Typography>
                <Typography variant="body2">
                    2. Use smoke to conceal revives when possible.
                </Typography>
            </Box>
        ),
    },

    {
        title: "TPP Tips",
        content: `
1. TPP: Peek around the edges of smoke while staying hidden.
        `,
    },
    {
        title: "Strategy",
        content: (
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <Typography variant="body2">
                    1. Close the gap when engaging enemies to leave them without
                    cover.
                </Typography>
                <Typography variant="body2">
                    2. Pre-fire around corners where you expect enemies.
                </Typography>
                <Typography variant="body2">
                    3. Aim for headshots for faster kills with AR/SMGs.
                </Typography>
                <Typography variant="body2">
                    4. In 1v4 situations, consider repositioning over finishing
                    a knocked enemy.
                </Typography>
                <Typography variant="body2">
                    5. Stay close to teammates when pushing a single enemy for a
                    better trade.
                </Typography>
            </Box>
        ),
    },

    {
        title: "Essential Settings",
        content: (
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <Typography variant="body2">
                    1. <strong>Graphics</strong> - Set Display Mode to{" "}
                    <strong>Borderless</strong> for easy multitasking.
                </Typography>
                <Typography variant="body2">
                    2. Set Lobby FPS Cap to <strong>60</strong> to prevent GPU
                    overheating.
                </Typography>
                <Typography variant="body2">
                    3. Set In-Game FPS Cap to <strong>Display Based</strong>.
                </Typography>
                <Typography variant="body2">
                    4. Disable <strong>Universal Brightness</strong> for
                    map-specific brightness adjustments.
                </Typography>
                <Typography variant="body2">
                    5. Set Render Scale to <strong>100%</strong> to match
                    monitor resolution.
                </Typography>
                <Typography variant="body2">
                    6. Enable <strong>HRTF</strong> for better sound quality.
                </Typography>
            </Box>
        ),
    },

    {
        title: "Essential Key Bindings",
        content: (
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <Typography variant="body2">
                    1. <strong>Jump Only</strong> - Set to{" "}
                    <strong>Space</strong>.
                </Typography>
                <Typography variant="body2">
                    2. <strong>Vault Only</strong> - Set to <strong>V</strong>.
                </Typography>
                <img
                    src={jumpVaultKeybinds}
                    alt="Jump and Vault Keybindings"
                    style={{
                        width: "100%",
                        borderRadius: "8px",
                    }}
                />
                <Typography variant="body2">
                    3. <strong>Motorcycle Lean</strong> - Forward{" "}
                    <strong>W</strong>, - Back <strong>S</strong>.
                </Typography>
                <img
                    src={bikeLean}
                    alt="Bike Lean Keybindings"
                    style={{
                        width: "100%",
                        borderRadius: "8px",
                    }}
                />
                <Typography variant="body2">
                    4. <strong>Toggle Perspective</strong> - Map to a mouse
                    button.
                </Typography>
                <Typography variant="body2">
                    5. <strong>Instant Volume Reducer</strong> - Map to a
                    convenient button.
                </Typography>
            </Box>
        ),
    },
    {
        title: "Recommended Gaming Peripherals",
        content: (
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <Typography variant="body2">
                    1. <strong>Wireless mouse</strong> with multiple buttons,
                    e.g., Razer Naga.
                </Typography>
                <Typography variant="body2">
                    2. <strong>Cloth mouse pad</strong> for better control.
                </Typography>
                <Typography variant="body2">
                    3. <strong>Mouse cable holder</strong> or wireless mouse to
                    avoid cable interference.
                </Typography>
                <Typography variant="body2">
                    4. <strong>Good headset</strong> for precise sound
                    localization.
                </Typography>
            </Box>
        ),
    },
];
