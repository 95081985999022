import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useMediaQuery, useTheme } from "@mui/material";

const ScrollToTop: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const location = useLocation();

    useEffect(() => {
        if (isMobile) {
            window.scrollTo({ top: 0 });
        }
    }, [isMobile, location.pathname]); // Run on path change

    return <>{children}</>;
};

export default ScrollToTop;
