import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, useTheme } from "@mui/material";

// Analytics and Auth
import ReactGA from "react-ga4";
import { useAuth } from "AuthContext";

import { PredefinedPlayers } from "components/PredefinedPlayers";
import FunnyLoading from "components/FunnyLoading";
import PlayerCountComponent from "components/PlayerCountComponent";

import { PlayerSearchBox } from "components/PlayerSearchBox";
import { API_ENDPOINTS } from "constants/API_ENDPOINTS";

const PlayerSearch: React.FC = () => {
    // Player Count
    const [playerCount, setPlayerCount] = useState<string | null>(null);
    const [loadingPlayerCount, setLoadingPlayerCount] = useState<boolean>(true);

    const theme = useTheme();

    // Auth and Navigation
    const { isAuthenticated } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: "/player-search",
            title: "Player Search Page",
        });
    }, []);

    useEffect(() => {
        const fetchPlayerCount = async () => {
            try {
                const response = await fetch(API_ENDPOINTS.PLAYER_COUNT);
                if (!response.ok) {
                    throw new Error("Failed to fetch player count");
                }

                const data = await response.json();
                setPlayerCount(data.playerCount);
            } catch (error) {
                console.error("Error fetching player count:", error);
            } finally {
                setLoadingPlayerCount(false);
            }
        };

        fetchPlayerCount();
    }, []);

    const handleRecommendedSearch = (name: string) => {
        navigate(`/player-stats/steam/${encodeURIComponent(name)}/profile`);
    };

    return (
        <Box
            sx={{
                maxWidth: "650px",
                margin: "0 auto",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                minHeight: "800px",
                gap: 3,
                padding: 2,
            }}
        >
            <Box
                sx={{
                    backgroundColor: "#1b2838", // Steam-like dark background
                    color: "#c7d5e0", // Steam-like light text
                    padding: "20px",
                    borderRadius: "8px",
                    textAlign: "center",
                    marginTop: "20px",
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)", // Subtle shadow
                }}
            >
                {loadingPlayerCount || !playerCount ? (
                    <FunnyLoading />
                ) : (
                    <PlayerCountComponent playerCount={Number(playerCount)} />
                )}
            </Box>

            <PlayerSearchBox isAuthenticated={isAuthenticated} />
            <PredefinedPlayers
                onSelectPlayer={handleRecommendedSearch}
                title="Need Steam search suggestions?"
            />
        </Box>
    );
};

export default PlayerSearch;
