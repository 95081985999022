import { useState } from "react";

export const useShowSessionsOnly = () => {
    const [showSessionsOnly, setShowSessionsOnly] = useState<boolean>(() => {
        const savedValue = localStorage.getItem("showSessionsOnly");
        return savedValue !== null ? JSON.parse(savedValue) : true; // Default to true if no saved value
    });

    const toggleShowSessionsOnly = (value: boolean) => {
        setShowSessionsOnly(value);
        localStorage.setItem("showSessionsOnly", JSON.stringify(value));
    };

    return { showSessionsOnly, toggleShowSessionsOnly };
};
