import { API_ENDPOINTS } from "constants/API_ENDPOINTS";
import { useState, useEffect } from "react";
import { SavedMatch } from "types/SavedMatch";

interface FetchSavedMatchesResponse {
    matches: SavedMatch[];
}

export const useSavedMatches = () => {
    const [savedMatches, setSavedMatches] = useState<SavedMatch[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const fetchSavedMatches = async () => {
        setLoading(true);
        setError(null);
        try {
            const token = localStorage.getItem("authToken");
            if (!token) {
                throw new Error("No authentication token found");
            }

            const response = await fetch(API_ENDPOINTS.SAVED_MATCHES, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error("Failed to fetch saved matches");
            }

            const data: FetchSavedMatchesResponse = await response.json();
            setSavedMatches(data.matches);
        } catch (error) {
            const errorMessage =
                error instanceof Error
                    ? error.message
                    : "An unknown error occurred";
            setError(errorMessage);
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchSavedMatches();
    }, []);

    // Also return the fetch function to allow manual refetching
    return { savedMatches, loading, error, refetch: fetchSavedMatches };
};
