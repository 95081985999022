import React, { useState } from "react";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Divider,
    Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import GameModeMatchTypePieChart from "components/dataViz/RecentMatches/PieCharts/GameModeMatchTypePieChart";
import MapPieChart from "components/dataViz/RecentMatches/PieCharts/MapPieChart";
import { Match } from "types/match";
import PieChartIcon from "@mui/icons-material/PieChart";

const MapGameModeDataAccordion: React.FC<{ matches: Match[] }> = ({
    matches,
}) => {
    const [expanded, setExpanded] = useState(false);

    const handleAccordionChange = () => {
        setExpanded((prevExpanded) => !prevExpanded);
    };

    return (
        <Accordion expanded={expanded} onChange={handleAccordionChange}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="map-game-mode-content"
                id="map-game-mode-header"
                sx={{
                    display: "flex",
                    alignItems: "center",
                    "& .MuiAccordionSummary-content": {
                        display: "flex",
                        alignItems: "center",
                        gap: 1, // Space between icons and text
                    },
                }}
            >
                {/* Pie and Bar Chart Icons */}
                <PieChartIcon fontSize="small" color="primary" />

                {/* Summary Text */}
                <Typography variant="h6">Map and Game Mode Data</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Divider sx={{ marginBottom: 2 }} />
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: { xs: "column", md: "row" },
                        gap: 4,
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Box
                        sx={{
                            flex: 1,
                            textAlign: "center",
                            maxWidth: "600px",
                            maxHeight: "600px",
                            margin: "0 auto",
                            "& > h6": { marginBottom: "16px" }, // Add spacing between header and chart
                        }}
                    >
                        <Typography variant="h6" gutterBottom>
                            Game Modes & Match Types
                        </Typography>
                        <GameModeMatchTypePieChart matches={matches} />
                    </Box>
                    <Box
                        sx={{
                            flex: 1,
                            textAlign: "center",
                            maxWidth: "400px",
                            maxHeight: "400px",
                            margin: "0 auto",
                            "& > h6": { marginBottom: "16px" },
                        }}
                    >
                        <Typography variant="h6" gutterBottom>
                            Maps
                        </Typography>
                        <MapPieChart matches={matches} />
                    </Box>
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};
export { MapGameModeDataAccordion };
