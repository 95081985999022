import React, { useEffect, useState } from "react";
import {
    Pagination,
    Box,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";

import changePages from "assets/webButtons/changePage.png";

interface PaginatedViewProps {
    currentPage: number;
    totalPages: number;
    onPageChange: (page: number) => void;
    itemsPerPage: number;
    totalItems: number;
}

const PaginatedView: React.FC<PaginatedViewProps> = ({
    currentPage,
    totalPages,
    onPageChange,
    itemsPerPage,
    totalItems,
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Detect mobile screens

    const handleChange = (_: React.ChangeEvent<unknown>, page: number) => {
        onPageChange(page);
    };

    const startItem = (currentPage - 1) * itemsPerPage + 1;
    const endItem = Math.min(currentPage * itemsPerPage, totalItems);
    const [isKeyHandled, setIsKeyHandled] = useState(false);

    // Keyboard navigation for Q/E keys
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (isKeyHandled) return; // Prevent repeated actions for held keys

            if (event.key.toLowerCase() === "q" && currentPage > 1) {
                setIsKeyHandled(true); // Block further actions until key is released

                setTimeout(() => {
                    onPageChange(currentPage - 1);
                }, 0); // Timeout ensures `isKeyHandled` is set before the change
            } else if (
                event.key.toLowerCase() === "e" &&
                currentPage < totalPages
            ) {
                setIsKeyHandled(true);

                setTimeout(() => {
                    onPageChange(currentPage + 1);
                }, 0); // Same logic for the "e" key
            }
        };

        const handleKeyUp = (event: KeyboardEvent) => {
            if (
                event.key.toLowerCase() === "q" ||
                event.key.toLowerCase() === "e"
            ) {
                setIsKeyHandled(false); // Reset state when key is released
            }
        };

        window.addEventListener("keydown", handleKeyDown);
        window.addEventListener("keyup", handleKeyUp);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
            window.removeEventListener("keyup", handleKeyUp);
        };
    }, [currentPage, totalPages, onPageChange, isKeyHandled]);

    return (
        <Box
            display="flex"
            flexDirection={isMobile ? "column" : "row"}
            alignItems="center"
            justifyContent="space-between"
            gap={isMobile ? 2 : 0}
            mb={3}
            mt={3}
        >
            {/* Showing matches message */}
            <Typography
                variant="body2"
                color="textSecondary"
                textAlign={isMobile ? "center" : "left"}
            >
                Showing matches {startItem}-{endItem} of {totalItems}
            </Typography>

            {/* Pagination */}
            <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handleChange}
                color="primary"
            />

            {/* Navigation hint */}
            {!isMobile && (
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <img
                        src={changePages}
                        alt="Press Q/E to change pages"
                        style={{
                            width: "200px", // Adjust as needed
                            height: "auto", // Maintain aspect ratio
                        }}
                    />
                </Box>
            )}
        </Box>
    );
};

export default PaginatedView;
