import erangelIcon from "assets/mapIcons/erangelIcon.png";
import miramarIcon from "assets/mapIcons/miramarIcon.png";
import sanhokIcon from "assets/mapIcons/sanhokIcon.png";
import vikendiIcon from "assets/mapIcons/vikendiIcon.png";
import karakinIcon from "assets/mapIcons/karakinIcon.png";
import trainingIcon from "assets/mapIcons/trainingIcon.png";

// MUI Icons
import PhishingIcon from "@mui/icons-material/Phishing";
import FactoryIcon from "@mui/icons-material/Factory";
import FloodIcon from "@mui/icons-material/Flood";
import VolcanoIcon from "@mui/icons-material/Volcano";
import SpaIcon from "@mui/icons-material/Spa";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import StadiumIcon from "@mui/icons-material/Stadium";

// Required
import { SvgIconComponent } from "@mui/icons-material";

export const mapIcons: { [key: string]: string | SvgIconComponent } = {
    Erangel: erangelIcon,
    Miramar: miramarIcon,
    Sanhok: sanhokIcon,
    Vikendi: vikendiIcon,
    Karakin: karakinIcon,
    Trainig: trainingIcon,
    // MUI Icon 8x8 Maps
    Taego: PhishingIcon,
    Rondo: SpaIcon,
    Deston: FloodIcon,
    // MUI Icon Small Maps
    Paramo: VolcanoIcon,
    Haven: FactoryIcon,
    // MUI Icon TDM Maps
    Boardwalk: DirectionsBoatIcon,
    Liana: HolidayVillageIcon,
    "Pillar Compound": StadiumIcon,
};
