import { Box, Divider, Tooltip, Typography, useTheme } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export const SurvivalMasteryXP: React.FC<{ xp: number }> = ({ xp }) => {
    const theme = useTheme();

    const MAX_XP = 4318570; // Maximum XP (100%)
    const percentage = (xp / MAX_XP) * 100; // Total percentage
    const cappedPercentage = Math.min(percentage, 100); // Main bar capped at 100%
    const overflowPercentage =
        percentage > 100 ? Math.min(percentage - 100, 100) : 0; // Overflow percentage, capped at 100%

    return (
        <Box
            sx={{
                marginTop: 2,
            }}
        >
            <Divider sx={{ marginTop: -1, marginBottom: 1 }} />
            <Typography variant="body2" gutterBottom>
                XP: {xp.toLocaleString()} / {MAX_XP.toLocaleString()}{" "}
            </Typography>

            <Box
                sx={{
                    display: "inline-flex", // Align items horizontally
                    alignItems: "center", // Align items vertically
                    gap: "4px", // Add a small gap between the text and the icon
                }}
            >
                <Typography variant="body2">
                    {percentage.toFixed(2)}% of Tier 5 Level 500
                </Typography>
                <Tooltip title={`You still earn XP after 100%`} arrow>
                    <InfoOutlinedIcon
                        sx={{
                            fontSize: "16px",
                            color: "#888",
                            cursor: "pointer",
                        }}
                    />
                </Tooltip>
            </Box>

            {/* First Bar: Green for 0-100% */}
            <Box
                sx={{
                    position: "relative",
                    height: "16px",
                    width: "100%",
                    backgroundColor: "#ddd",
                    borderRadius: "8px",
                    overflow: "hidden",
                }}
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: `${cappedPercentage}%`,
                        height: "100%",
                        backgroundColor: theme.palette.secondary.main, // Green for main progress
                    }}
                />
            </Box>
            {/* Second Bar: Yellow for Overflow */}
            {overflowPercentage > 0 && (
                <Box
                    sx={{
                        position: "relative",
                        marginTop: "8px", // Space between the two bars
                        height: "16px",
                        width: "100%",
                        backgroundColor: "#ddd",
                        borderRadius: "8px",
                        overflow: "hidden",
                    }}
                >
                    <Box
                        sx={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: `${overflowPercentage}%`,
                            height: "100%",
                            backgroundColor: theme.palette.custom.accentColor2,
                        }}
                    />
                </Box>
            )}
        </Box>
    );
};
