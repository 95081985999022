import React from "react";
import {
    Box,
    Typography,
    useTheme,
    Divider,
    TableCell,
    TableRow,
    TableHead,
    TableBody,
    Table,
    TableContainer,
    Paper,
} from "@mui/material";

import { maxWidths } from "constants/appWide/maxWidths";
import { PaymentOptions } from "components/PaymentOptions";
import { COLORS } from "constants/appWide/colors";

const SupportUsPage: React.FC = () => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                padding: { xs: "16px", md: "40px" },
                backgroundColor: theme.palette.background.default,
                color: theme.palette.text.primary,
                minHeight: "80vh",
                maxWidth: maxWidths.l,
                margin: "0 auto",
                borderRadius: "8px",
                textAlign: "left",
            }}
        >
            <Typography variant="h4" gutterBottom>
                Support PUBG Meta
            </Typography>
            <Typography variant="body1" paragraph>
                If you enjoy using PUBG Meta and would like to support my
                efforts to keep the platform running and improve its features,
                consider making a donation.
            </Typography>
            <Divider></Divider>
            <Typography mt={2} variant="body1" paragraph>
                At PUBG Meta, I prioritize your experience, which is why I
                choose not to display ads on the website. Ads can greatly
                diminish the quality of your experience, and I believe in
                providing a clean, distraction-free platform.
            </Typography>
            <Typography variant="body1" paragraph>
                In fact, I encourage you to install ad blockers on your desktop
                and mobile devices to enhance your browsing experience across
                the web. Some popular ad blocker options include:
                <ul>
                    <li>
                        <a
                            href="https://adblockplus.org/"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: "#007BFF" }}
                        >
                            Adblock Plus
                        </a>{" "}
                        (Desktop & Mobile)
                    </li>
                    <li>
                        <a
                            href="https://getadblock.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: "#007BFF" }}
                        >
                            AdBlock
                        </a>{" "}
                        (Desktop & Mobile)
                    </li>
                    <li>
                        <a
                            href="https://ublockorigin.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: "#007BFF" }}
                        >
                            uBlock Origin
                        </a>{" "}
                        (Desktop)
                    </li>
                </ul>
                <Typography
                    variant="body2"
                    sx={{ marginTop: 2, color: "text.secondary" }}
                >
                    Please note that I am not affiliated with or sponsored by
                    any of these ad blockers. My goal is simply to share
                    information and improve public knowledge about tools that
                    can enhance your browsing experience.
                </Typography>
            </Typography>

            <Divider></Divider>
            <Typography mt={2} variant="body1" paragraph>
                Instead, I take an organic approach to monetization through
                voluntary support from the community. Hosting the backend and
                database currently costs me around $30 per month, and every
                contribution helps cover these costs, develop new features, and
                continuously improve the platform for all users.
            </Typography>

            <TableContainer
                component={Paper}
                sx={{
                    marginTop: 4,
                }}
            >
                <Table
                    sx={{
                        minWidth: "840px",
                    }}
                >
                    <TableHead>
                        <TableRow>
                            <TableCell>Payment Method</TableCell>
                            <TableCell>Supported Payments</TableCell>
                            <TableCell>Support Type</TableCell>
                            <TableCell>Login Required</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {PaymentOptions.map((option) => (
                            <>
                                {option.rows.map((row, index) => (
                                    <TableRow
                                        key={`${option.method}-${row.type}`}
                                    >
                                        {/* Render Button and Supported Payments only in the first row */}
                                        {index === 0 && (
                                            <>
                                                <TableCell
                                                    rowSpan={option.rows.length}
                                                >
                                                    {option.button}
                                                </TableCell>
                                                <TableCell
                                                    rowSpan={option.rows.length}
                                                >
                                                    {option.supportedPayments}
                                                </TableCell>
                                            </>
                                        )}
                                        <TableCell>
                                            {row.type === "One-Time" &&
                                            row.loginRequired.includes("No") ? (
                                                <Box
                                                    sx={{
                                                        display: "inline-block", // Keeps the box size tight to the text
                                                        backgroundColor:
                                                            COLORS.orange, // Highlight color
                                                        color: "#000", // Black text
                                                        fontWeight: "bold",
                                                        borderRadius: "4px", // Optional rounded edges
                                                        padding: "2px 6px", // Adds minimal padding around text
                                                    }}
                                                >
                                                    {row.type}
                                                </Box>
                                            ) : (
                                                row.type
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {row.type === "One-Time" &&
                                            row.loginRequired.includes("No") ? (
                                                <Box
                                                    sx={{
                                                        display: "inline-block", // Tight highlight box around the text
                                                        backgroundColor:
                                                            COLORS.orange,
                                                        color: "#000",
                                                        fontWeight: "bold",
                                                        borderRadius: "4px",
                                                        padding: "2px 6px",
                                                    }}
                                                >
                                                    {row.loginRequired}
                                                </Box>
                                            ) : (
                                                row.loginRequired
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Typography
                variant="body2"
                sx={{
                    marginTop: 2,
                    color: "gray",
                    textAlign: "center",
                    fontStyle: "italic",
                }}
            >
                <Box
                    sx={{
                        display: "inline-block", // Tight highlight box around the text
                        backgroundColor: COLORS.orange,
                        color: "#000",
                        fontWeight: "bold",
                        borderRadius: "4px",
                        padding: "2px 6px",
                    }}
                >
                    Highlighted options
                </Box>{" "}
                indicate the easiest payment method: One-Time support with no
                login required.
            </Typography>
            <Typography
                variant="body2"
                sx={{
                    marginTop: 2,
                    color: "gray",
                    textAlign: "center",
                    fontStyle: "italic",
                }}
            >
                ⭐ Annual memberships are available at a special rate: 12 months
                for the price of 9.
            </Typography>
        </Box>
    );
};

export default SupportUsPage;
