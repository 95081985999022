import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
    Box,
    Button,
    Typography,
    Card,
    CardContent,
    Grid,
    useMediaQuery,
} from "@mui/material";
import ReactGA from "react-ga4";

import { QRCodeCanvas } from "qrcode.react";
import { COLORS } from "constants/appWide/colors";
import { maxWidths } from "constants/appWide/maxWidths";
import cover from "assets/cover.jpg";
import { useAuth } from "AuthContext";

import { PlayerSearchBox } from "components/PlayerSearchBox";

interface SectionCardProps {
    title: string;
    description: string;
    buttonText: string;
    linkTo: string;
}

const SectionCard: React.FC<SectionCardProps> = ({
    title,
    description,
    buttonText,
    linkTo,
}) => {
    return (
        <Card
            sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <CardContent
                sx={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Typography variant="h5" sx={{ mb: 2 }}>
                    {title}
                </Typography>
                <Typography variant="body1" textAlign={"left"} sx={{ mb: 3 }}>
                    {description}
                </Typography>
                <Box sx={{ marginTop: "auto" }}>
                    <Button variant="contained" component={Link} to={linkTo}>
                        {buttonText.toUpperCase()}
                    </Button>
                </Box>
            </CardContent>
        </Card>
    );
};

export const Home: React.FC = () => {
    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: "/",
            title: "Home Page",
        });
    }, []);

    const { isAuthenticated } = useAuth();

    // Check if the screen width is large (desktop)
    const isDesktop = useMediaQuery("(min-width:960px)");

    return (
        <Box
            sx={{
                p: { xs: 2, md: 4 },
                maxWidth: maxWidths.l, // Limit the width of the content
                margin: "0 auto", // Center the box horizontally
                textAlign: "center", // Align text in the center
            }}
        >
            <Typography variant="h4" sx={{ mb: 3, textAlign: "center" }}>
                Welcome to Your Ultimate PUBG Companion!
            </Typography>
            <Typography variant="h6" sx={{ mb: 3, textAlign: "left" }}>
                Unlock the ultimate player experience with tools designed for
                success! Analyze player stats, explore detailed interactive
                maps, create and share tier lists, and master expert tips and
                strategies. Stay on top of the evolving meta with comprehensive
                patch notes and access invaluable resources to elevate your
                gameplay.
            </Typography>
            <PlayerSearchBox isAuthenticated={isAuthenticated} />

            <Box
                component="img"
                src={cover}
                alt="Cover"
                sx={{
                    width: "100%",
                    maxWidth: "800px",
                    height: "auto",
                    borderRadius: "4px",
                    marginBottom: 3,
                }}
            />
            <Grid container justifyContent="center" spacing={3}>
                <Grid item xs={12} md={3}>
                    <SectionCard
                        title="Player Stats"
                        description="Search for detailed stats and lifetime performance of your favorite players. Compare stats to level up your game!"
                        buttonText="View Player Stats"
                        linkTo="/player-search"
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <SectionCard
                        title="Maps"
                        description="Explore interactive maps with detailed locations, routes, and hidden spots. Use 4K maps to measure mortar distances by selecting two points!"
                        buttonText="View Maps"
                        linkTo="/maps"
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <SectionCard
                        title="Map Rotations"
                        description="Stay updated with detailed map rotations for PC, Console, and Ranked modes. Explore key insights about maps, rotation schedules, and interactive features to know what you can expect from the map queue."
                        buttonText="View Map Rotations"
                        linkTo="/rotation"
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <SectionCard
                        title="Tier Lists"
                        description="Build, share, and browse tier lists to rank your favorite characters, items, or strategies. Collaborate with the community to find the best meta choices!"
                        buttonText="View Tier Lists"
                        linkTo="/tier-lists"
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <SectionCard
                        title="Tips & Strategies"
                        description="Learn from the best with expert tips and strategies. Level up your skills and outsmart your opponents with ease."
                        buttonText="View Tips & Strategies"
                        linkTo="/tips-strategies"
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <SectionCard
                        title="Patch Notes History"
                        description="Stay updated with the latest game patches and explore the history of changes to improve your understanding of the evolving meta."
                        buttonText="View Patch Notes"
                        linkTo="/patches"
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <SectionCard
                        title="More Resources"
                        description="Discover helpful YouTubers, web apps, and other tools to improve your gameplay and stay ahead of the competition."
                        buttonText="View More Resources"
                        linkTo="/resources"
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <SectionCard
                        title="Bot Name List"
                        description="Check out the comprehensive list of bot names to easily identify non-player characters during gameplay."
                        buttonText="View Bot Name List"
                        linkTo="/bot-names"
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <SectionCard
                        title="Weapons Wiki"
                        description="Explore basic information about all weapons."
                        buttonText="View Weapons Wiki"
                        linkTo="/weapons-wiki"
                    />
                </Grid>
            </Grid>

            <Typography variant="h5" sx={{ mt: 3, mb: 3, textAlign: "center" }}>
                What should I do next?
            </Typography>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" }, // Responsive layout: column for mobile, row for desktop
                    alignItems: "stretch",
                    gap: { xs: 2, md: 4 }, // Smaller gap on mobile, larger on desktop
                    margin: "16px auto",
                    backgroundColor: "#2e2e2e", // Unified background for the container
                    borderRadius: "8px", // Unified border radius
                    padding: 1, // Padding inside the container
                }}
            >
                {/* Create Account Section */}
                <Box
                    sx={{
                        flex: 1,
                        padding: 2,
                        borderRight: { md: "1px solid #444" }, // Border for desktop layout
                        borderBottom: { xs: "1px solid #444", md: "none" }, // Bottom border for mobile only
                        textAlign: "left",
                    }}
                >
                    <Typography variant="h5" textAlign="center" gutterBottom>
                        Create an Account
                    </Typography>
                    <Typography>
                        Unlock exclusive benefits by creating an account:
                    </Typography>
                    <ul>
                        <li>
                            Save your favorite players and access their profiles
                            anytime.
                        </li>
                        <li>
                            Choose and save specific match reports indfeinitely.
                        </li>
                        <li>
                            Create and share tier lists with friends or the
                            community.
                        </li>
                        <li>
                            Customize your experience with personalized settings
                            such as default landing page.
                        </li>
                    </ul>
                </Box>

                {/* Pin Website Section */}
                <Box
                    sx={{
                        flex: 1,
                        padding: 2,
                        borderRight: { md: "1px solid #444" },
                        textAlign: "left",
                    }}
                >
                    <Typography variant="h5" textAlign="center" gutterBottom>
                        Install on Your Mobile Devices
                    </Typography>
                    <Typography mb={3} gutterBottom>
                        I’ve made this a PWA (Progressive Web App) so you can
                        add it to your home screen for easy access and a native
                        app-like feel.
                    </Typography>
                    <Typography variant="body1">
                        <strong>On Android:</strong>
                    </Typography>
                    <Typography variant="body2" mb={2}>
                        Open the browser menu and select "Add to Home screen."
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        <strong>On iPhone:</strong>
                    </Typography>
                    <Typography variant="body2" gutterBottom mb={2}>
                        Tap the share icon in Safari and select "Add to Home
                        Screen."
                    </Typography>
                    <Typography variant="body1">
                        * Please note that after I release an update, the app
                        will refresh automatically when you close and reopen it.
                    </Typography>
                </Box>
                {/* QR Code Section (Desktop Only) */}
                {isDesktop && (
                    <Box
                        sx={{
                            flex: 1,
                            padding: 2,
                        }}
                    >
                        <Typography variant="h6" mb={2}>
                            Scan to easily open this website on your mobile
                            device
                        </Typography>
                        <QRCodeCanvas
                            value="https://www.pubg-meta.com/"
                            size={200}
                            bgColor={COLORS.white}
                            fgColor="#000000"
                            level="H"
                        />
                        <Typography variant="h6" mb={2}>
                            Opens{" "}
                            <a
                                href="https://www.pubg-meta.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: "#007BFF" }}
                            >
                                https://www.pubg-meta.com/
                            </a>
                        </Typography>
                    </Box>
                )}
            </Box>
        </Box>
    );
};
