import React, { useEffect, useState } from "react";
import {
    Typography,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    DialogActions,
    Dialog,
    Button,
    MenuItem,
    Select,
    DialogTitle,
    useTheme,
    useMediaQuery,
} from "@mui/material";
import { useParams } from "react-router-dom";

import { getPlatformLogo } from "utils/getPlatformLogo";

import WeaponMasterySection, {
    WeaponCategories,
} from "components/WeaponMasterySection";
import PlayerTag from "components/PlayerTag";
import { mapNameMapping } from "constants/mapNameMapping";
import ReactGA from "react-ga4";
import { useAuth } from "AuthContext";

// Heart Icons for Save Players
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

import showClips from "assets/webButtons/showClips.jpg";
import hideClips from "assets/webButtons/hideClips.jpg";

// PUBG Report Section
import { Visibility, VisibilityOff } from "@mui/icons-material";
import pubgReportLogo from "assets/pubgreport.png";
import LaunchIcon from "@mui/icons-material/Launch";
import { formatGameMode } from "utils/formatGameMode";

import liquipediaLogo from "assets/liquipedia.png";
import { predefinedPlayers } from "constants/predefinedPlayers";
import { useClanInfo } from "hooks/pubgApi/useClanInfo";
import { useSurvivalMastery } from "hooks/pubgApi/useSurvivalMastery";
import { SurvivalMasteryXP } from "components/SurvivalMasteryXP";
import { useAccountData } from "hooks/pubgApi/useAccountData";
import FunnyLoading from "components/FunnyLoading";
import BanStatus from "components/BanStatus";
import { maxWidths } from "constants/appWide/maxWidths";
import { KeyboardController } from "hooks/KeyboardController";
import { API_ENDPOINTS } from "constants/API_ENDPOINTS";

interface Clip {
    matchId: string;
    attackId: number;
    videoId: string;
    killer: string;
    victim: string;
    map: string;
    mode: string;
    date: string;
    url: string;
}

const PlayerProfile: React.FC = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Detect mobile screens

    // Search parameters
    const { platform = "steam", playerName = "Unknown Player" } = useParams<{
        platform?: string;
        playerName?: string;
    }>();

    console.log("Player Profile:", playerName, platform);

    const [playerCategory, setPlayerCategory] = useState<string | null>(null);

    // Fetched data states
    const { accountId, clanId, banType, accountLoading, accountError } =
        useAccountData(platform, playerName);

    // Using Banner Hooks
    const { clanInfo, clanLoading, clanError } = useClanInfo(clanId, platform);

    const { survivalMastery, survivalLoading, survivalError } =
        useSurvivalMastery(accountId, platform);

    // PUBG Report Clip States
    const [clips, setClips] = useState<Clip[]>([]);
    const [killerClips, setKillerClips] = useState<Clip[]>([]);
    const [victimClips, setVictimClips] = useState<Clip[]>([]);

    // Clan Info State

    // Mastery states

    const [weaponMastery, setWeaponMastery] = useState<WeaponCategories | null>(
        null
    );

    const [clipsLoading, setClipsLoading] = useState(false);
    const [clipsError, setClipsError] = useState<string | null>(null);

    const [weaponLoading, setWeaponLoading] = useState(false);
    const [weaponError, setWeaponError] = useState<string | null>(null);

    // Show or hide PUBG Report Tables
    const [showTables, setShowTables] = useState(false);

    // Login only
    const { isAuthenticated } = useAuth();
    const [isSaved, setIsSaved] = useState(false);

    const [selectedCategory, setSelectedCategory] = useState("Following");
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: "/player-stats/profile",
            title: "Player Profile Page",
        });
    }, []);

    // Check if player is saved
    useEffect(() => {
        const checkIfSaved = async () => {
            try {
                const token = localStorage.getItem("authToken");
                const response = await fetch(API_ENDPOINTS.SAVED_PLAYERS, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error("Failed to fetch saved players");
                }

                const data = await response.json();
                console.log("Saved players data:", data);

                let categoryFound: string | null = null;
                let playerFound = false;

                // Iterate through each category
                for (const [category, players] of Object.entries(data)) {
                    if (!Array.isArray(players)) continue;

                    const found = players.some(
                        (player: { playerName: string; platform: string }) =>
                            player.playerName === playerName &&
                            player.platform === platform
                    );

                    if (found) {
                        categoryFound = category;
                        playerFound = true;
                        break; // Stop searching once the player is found
                    }
                }

                setPlayerCategory(categoryFound); // Set the found category or null
                setIsSaved(playerFound); // Update saved state
            } catch (error) {
                console.error("Error checking saved player:", error);
            }
        };

        if (isAuthenticated) {
            checkIfSaved();
        }
    }, [isAuthenticated, playerName, platform]);

    // Handle save player
    const handleToggleSave = async (category?: string) => {
        try {
            const token = localStorage.getItem("authToken");
            if (!token) {
                alert("You are not logged in!");
                return;
            }

            console.log(category);

            if (isSaved) {
                // Remove player from saved list
                const response = await fetch(API_ENDPOINTS.REMOVE_PLAYER, {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        playerName,
                        platform,
                        category: playerCategory,
                    }),
                });

                if (!response.ok) {
                    throw new Error("Failed to remove player");
                }

                setIsSaved(false);
            } else if (!category) {
                // Open the dialog to select a category
                setOpenModal(true);
            } else {
                // Save player with the selected category
                const response = await fetch(API_ENDPOINTS.SAVE_PLAYER, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        playerName,
                        platform,
                        category,
                    }),
                });

                if (!response.ok) {
                    throw new Error("Failed to save player");
                }

                setIsSaved(true);
                setPlayerCategory(category);
            }
        } catch (error) {
            console.error("Error toggling save:", error);
            alert("An error occurred while toggling the saved state.");
        }
    };

    // Fetch PUBG Report Clips and Weapon Mastery
    useEffect(() => {
        if (!accountId) {
            console.log(
                "[DEBUG] accountId is null, skipping loadAdditionalData."
            );
            return;
        }

        const loadAdditionalData = async () => {
            try {
                setClipsLoading(true);
                setClipsError(null);

                const clipsResponse = await fetch(
                    API_ENDPOINTS.PUBG_REPORT(accountId)
                );

                if (!clipsResponse.ok) {
                    throw new Error("Failed to fetch clips");
                }

                const clipsData: Clip[] = await clipsResponse.json();
                console.log("[DEBUG] Clips data fetched:", clipsData);

                setClips(clipsData);
                setKillerClips(
                    clipsData.filter(
                        (clip) =>
                            clip.killer.toLowerCase() ===
                            playerName.toLowerCase()
                    )
                );
                setVictimClips(
                    clipsData.filter(
                        (clip) =>
                            clip.victim.toLowerCase() ===
                            playerName.toLowerCase()
                    )
                );
            } catch (err) {
                console.error("[DEBUG] Error fetching clips:", err);
                setClipsError((err as Error).message);
            } finally {
                setClipsLoading(false);
            }

            try {
                // Fetch Weapon Mastery
                const weaponResponse = await fetch(
                    API_ENDPOINTS.WEAPON_MASTERY(platform, accountId)
                );

                if (!weaponResponse.ok) {
                    throw new Error("Failed to fetch weapon mastery data");
                }

                const weaponData = await weaponResponse.json();
                console.log("[DEBUG] Weapon mastery data fetched:", weaponData);

                setWeaponMastery(weaponData);
            } catch (err) {
                console.error("[DEBUG] Error fetching weapon mastery:", err);
                setWeaponError((err as Error).message);
            } finally {
                setWeaponLoading(false);
            }
        };

        console.log("[DEBUG] Starting loadAdditionalData...");
        loadAdditionalData();
    }, [accountId, platform, playerName]);

    const renderTable = (
        clips: Clip[],
        title: string,
        totalClips: number,
        customFirstColumnTitle: string = "Killed"
    ) => {
        const maxClips = 3; // Limit to the latest 3 clips
        const displayedClips = clips.slice(0, maxClips); // Slice the array to show only the first 3

        return (
            <Box sx={{ flex: 1 }}>
                <Typography variant="h6" textAlign="center" gutterBottom>
                    {title}
                </Typography>
                <Typography
                    variant="body2"
                    textAlign="center"
                    color="gray"
                    sx={{ marginBottom: 2 }}
                >
                    Showing {displayedClips.length} of {totalClips} clips
                </Typography>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{customFirstColumnTitle}</TableCell>
                                <TableCell>Game Mode</TableCell>
                                <TableCell>Map</TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell>Clip Link</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {displayedClips.map((clip, index) => (
                                <TableRow key={index}>
                                    <TableCell>
                                        {customFirstColumnTitle === "Killed By"
                                            ? clip.killer
                                            : clip.victim}
                                    </TableCell>
                                    <TableCell>
                                        {formatGameMode(clip.mode)}
                                    </TableCell>
                                    <TableCell>
                                        {mapNameMapping[clip.map] || clip.map}
                                    </TableCell>
                                    <TableCell>{clip.date}</TableCell>
                                    <TableCell>
                                        <a
                                            href={clip.url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{
                                                color: "#ff5722",
                                                textDecoration: "none",
                                            }}
                                        >
                                            Watch Clip
                                        </a>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        );
    };

    return (
        <Box>
            {/* Player Profile Header */}
            {!accountLoading && survivalMastery ? (
                <Box
                    sx={{
                        maxWidth: maxWidths.l,
                        margin: "0 auto",
                        border: "2px solid #444",
                        borderRadius: "8px",
                        padding: "16px",
                        backgroundColor: "#2e2e2e",
                        marginTop: "16px",
                    }}
                >
                    {/* Player Header Section */}
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: { xs: "column", sm: "row" },
                            alignItems: "center",
                            justifyContent: "space-between",
                            gap: "16px",
                            marginBottom: "16px",
                        }}
                    >
                        {/* Left Section: Banner and Favorite Icon */}
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: { xs: "column", sm: "row" },
                                alignItems: "center",
                                gap: 2, // Gap between banner and favorite icon
                            }}
                        >
                            {/* Banner Section */}
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                }}
                            >
                                <img
                                    src={getPlatformLogo(platform)}
                                    alt={`${platform} logo`}
                                    style={{ width: 26, height: 26 }}
                                />
                                <Typography variant="h6" color="#fff">
                                    Player Profile for {playerName}
                                </Typography>
                            </Box>

                            {/* Player Tag */}
                            <PlayerTag
                                playerName={playerName}
                                survivalTier={survivalMastery?.tier}
                                survivalLevel={survivalMastery?.level}
                                clanInfo={clanInfo}
                            />

                            {/* Favorite Icon */}
                            {isAuthenticated && (
                                <IconButton
                                    onClick={() => handleToggleSave()}
                                    sx={{
                                        color: isSaved ? "red" : "gray",
                                    }}
                                >
                                    {isSaved ? (
                                        <FavoriteIcon />
                                    ) : (
                                        <FavoriteBorderIcon />
                                    )}
                                </IconButton>
                            )}
                        </Box>

                        <BanStatus banType={banType} />
                    </Box>
                    {/* Pro Player Status */}
                    {predefinedPlayers["Pro Players"].find(
                        (player) =>
                            player.name.toLowerCase() ===
                                playerName.toLowerCase() ||
                            (player.inGameName &&
                                player.inGameName.toLowerCase() ===
                                    playerName.toLowerCase())
                    ) ? (
                        <Box
                            sx={{
                                border: "1px solid #444",
                                borderRadius: "8px",
                                padding: "16px",
                                backgroundColor: "#3a3a3a",
                                marginBottom: "16px",
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <Typography
                                    variant="h6"
                                    sx={{
                                        textAlign: "center",
                                        color: "#fff",
                                        flexGrow: 1,
                                    }}
                                >
                                    Pro Player Status
                                </Typography>
                                <a
                                    href="https://liquipedia.net/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <img
                                        src={liquipediaLogo}
                                        alt="Liquipedia"
                                        style={{
                                            width: "40px",
                                            height: "40px",
                                            marginLeft: "8px",
                                        }}
                                    />
                                </a>
                            </Box>
                            {(() => {
                                const player = predefinedPlayers[
                                    "Pro Players"
                                ].find(
                                    (player) =>
                                        player.name.toLowerCase() ===
                                            playerName.toLowerCase() ||
                                        (player.inGameName &&
                                            player.inGameName.toLowerCase() ===
                                                playerName.toLowerCase())
                                );

                                return (
                                    <Typography
                                        variant="body2"
                                        textAlign="center"
                                    >
                                        {player?.name} is a Pro Player. For more
                                        details, check out{" "}
                                        <a
                                            href={`https://liquipedia.net/pubg/${encodeURIComponent(
                                                player?.name || ""
                                            )}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{
                                                textDecoration: "none",
                                                color: "#36a2eb",
                                            }}
                                        >
                                            Liquipedia
                                        </a>
                                        .
                                    </Typography>
                                );
                            })()}
                        </Box>
                    ) : null}

                    {/* Clan Info and Survival Mastery */}
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: { xs: "column", md: "row" },
                            gap: "16px",
                        }}
                    >
                        {/* Clan Info Section */}
                        {!clanLoading && clanInfo && (
                            <Box
                                sx={{
                                    flex: 1,
                                    padding: 2,
                                    borderRadius: "8px",
                                    border: "1px solid #444",
                                }}
                            >
                                <Typography
                                    variant="h6"
                                    textAlign="center"
                                    gutterBottom
                                >
                                    Clan Info
                                </Typography>
                                <Typography>
                                    Clan Name: {clanInfo.clanName}
                                </Typography>
                                <Typography>
                                    Clan Tag: {clanInfo.clanTag}
                                </Typography>
                                <Typography>
                                    Clan Level: {clanInfo.clanLevel}
                                </Typography>
                                <Typography>
                                    Members: {clanInfo.clanMemberCount}
                                </Typography>
                            </Box>
                        )}

                        {/* Survival Mastery Section */}
                        {!survivalLoading && survivalMastery && (
                            <Box
                                sx={{
                                    flex: 1,
                                    padding: 2,
                                    backgroundColor: "#2e2e2e",
                                    borderRadius: "8px",
                                    border: "1px solid #444",
                                }}
                            >
                                <Typography
                                    variant="h6"
                                    textAlign="center"
                                    gutterBottom
                                >
                                    Survival Mastery
                                </Typography>
                                <Typography>
                                    Tier: {survivalMastery.tier}
                                </Typography>
                                <Typography>
                                    Level: {survivalMastery.level}
                                </Typography>
                                <SurvivalMasteryXP xp={survivalMastery.xp} />
                            </Box>
                        )}
                    </Box>
                </Box>
            ) : (
                <Typography
                    variant="h5"
                    sx={{
                        textAlign: "center",
                        marginTop: 4,
                    }}
                >
                    Loading Player Profile...
                </Typography>
            )}

            {/* Weapon Mastery */}
            <Box
                sx={{
                    maxWidth: 1200, // Limit the width to 60% of the viewport
                    margin: "0 auto", // Center the box horizontally
                    border: "2px solid #444", // Add a border
                    borderRadius: "4px", // Optional: Add rounded corners
                    padding: "16px", // Add spacing inside the box
                    marginTop: "16px", // Add top margin
                }}
            >
                <Typography
                    variant="h5"
                    sx={{ textAlign: "center", marginBottom: 1 }}
                >
                    Weapon Mastery
                </Typography>

                {weaponLoading && <FunnyLoading />}
                {weaponError && (
                    <Typography color="error">{weaponError}</Typography>
                )}

                {!weaponLoading && weaponMastery && (
                    <WeaponMasterySection weaponMastery={weaponMastery} />
                )}
            </Box>

            {/* PUBG Report Clips */}
            {clipsLoading && <FunnyLoading />}
            {clipsError && <Typography color="error">{clipsError}</Typography>}
            {!clipsLoading && !clipsError && (
                <Box sx={{ marginBottom: 4, maxWidth: 1200, margin: "0 auto" }}>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: 2,
                            backgroundColor: "#282c34",
                            borderRadius: "8px",
                            margin: "0 auto",
                            marginTop: 4,
                        }}
                    >
                        <KeyboardController
                            controlType="action"
                            actionKey="f"
                            onAction={() => setShowTables((prev) => !prev)}
                        />

                        <IconButton
                            onClick={() => setShowTables(!showTables)}
                            sx={{ color: "white" }}
                        >
                            {showTables ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                        {!isMobile && (
                            <img
                                src={showTables ? hideClips : showClips}
                                alt="Press F to show/hide clips"
                                style={{
                                    width: "150px", // Adjust as needed
                                    height: "auto", // Maintain aspect ratio
                                }}
                            />
                        )}
                        {/* PUBG Report Link */}
                        <Box
                            sx={{
                                flex: 1,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: 1,
                            }}
                        >
                            <a
                                href={`https://pubg.report/players/${accountId}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    textDecoration: "none",
                                    color: "white",
                                }}
                            >
                                <img
                                    src={pubgReportLogo}
                                    alt="PUBG Report Logo"
                                    style={{
                                        width: 233,
                                        height: 52,
                                        marginRight: 8,
                                    }}
                                />
                                <LaunchIcon
                                    fontSize="medium"
                                    sx={{
                                        color: "#ff5722",
                                    }}
                                />
                            </a>

                            {/* Header Message */}

                            <Typography
                                variant="body2"
                                sx={{
                                    textAlign: "center",
                                    color: "gray",
                                    marginTop: 1,
                                }}
                            >
                                Only the latest 3 clips are shown in each
                                category. For more clips, check out
                                <a
                                    href={`https://pubg.report/players/${accountId}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                        textDecoration: "none",
                                        color: "#ff5722",
                                        marginLeft: 4,
                                    }}
                                >
                                    pubg.report
                                </a>
                                <LaunchIcon
                                    fontSize="small"
                                    sx={{
                                        color: "#ff5722",
                                        marginLeft: 1,
                                        verticalAlign: "middle",
                                    }}
                                />
                            </Typography>
                        </Box>
                    </Box>

                    {showTables && (
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: { xs: "column", md: "row" }, // Column on small screens, row on larger screens
                                gap: 4,
                                marginTop: 2,
                            }}
                        >
                            {renderTable(
                                killerClips,
                                "Clips Where You Are the Killer",
                                clips.filter(
                                    (clip) =>
                                        clip.killer.toLowerCase() ===
                                        playerName.toLowerCase()
                                ).length,
                                "Killed"
                            )}
                            {renderTable(
                                victimClips,
                                "Clips Where You Are the Victim",
                                clips.filter(
                                    (clip) =>
                                        clip.victim.toLowerCase() ===
                                        playerName.toLowerCase()
                                ).length,
                                "Killed By"
                            )}
                        </Box>
                    )}
                    {openModal && (
                        <Dialog
                            open={openModal}
                            onClose={() => setOpenModal(false)}
                            sx={{ "& .MuiDialog-paper": { padding: 2 } }}
                        >
                            <DialogTitle>Save Player To</DialogTitle>
                            <Select
                                value={selectedCategory}
                                onChange={(e) =>
                                    setSelectedCategory(e.target.value)
                                }
                                fullWidth
                            >
                                <MenuItem value="My Accounts">
                                    My Accounts
                                </MenuItem>
                                <MenuItem value="My Friends">
                                    My Friends
                                </MenuItem>
                                <MenuItem value="Following">Following</MenuItem>
                            </Select>
                            <DialogActions>
                                <Button onClick={() => setOpenModal(false)}>
                                    Cancel
                                </Button>
                                <Button
                                    onClick={() => {
                                        setOpenModal(false);
                                        handleToggleSave(selectedCategory); // Pass the selected category
                                    }}
                                    variant="contained"
                                >
                                    Save
                                </Button>
                            </DialogActions>
                        </Dialog>
                    )}
                </Box>
            )}
        </Box>
    );
};

export default PlayerProfile;
