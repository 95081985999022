import { useState } from "react";

export const useShowSessions = () => {
    const [showSessions, setShowSessions] = useState<boolean>(() => {
        const savedValue = localStorage.getItem("showSessions");
        return savedValue !== null ? JSON.parse(savedValue) : true; // Default to true if no saved value
    });

    const toggleShowSessions = (value: boolean) => {
        setShowSessions(value);
        localStorage.setItem("showSessions", JSON.stringify(value));
    };

    return { showSessions, toggleShowSessions };
};
