import React, { useEffect, useMemo, useState } from "react";
import {
    Typography,
    Box,
    Button,
    TableCell,
    TableRow,
    Table,
    TableContainer,
    TableBody,
    Paper,
    TableHead,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    FormControlLabel,
    Checkbox,
    useTheme,
    Tooltip,
    Divider,
    useMediaQuery,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { computeRosterStats, Participant } from "utils/rosterStats";

import "components/dataViz/chartSetup";

import fChangeViewType from "assets/webButtons/fChangeViewType.png";

// Icons
import { Match } from "types/match";

// GA
import ReactGA from "react-ga4";
import { COLORS } from "constants/appWide/colors";
import SearchIcon from "@mui/icons-material/Search";
import { maxWidths } from "constants/appWide/maxWidths";
import { useShowMapImages } from "hooks/useShowMapImages";

import { MatchesTable } from "components/MatchesView/MatchesTable";

// MUI Icons
import CardsIcon from "@mui/icons-material/ViewModule";
import TableIcon from "@mui/icons-material/ViewList";
import PersonIcon from "@mui/icons-material/Person";
import DailyStatsGrid from "components/dataViz/RecentMatches/DailyStatsGrid";
import RefreshIcon from "@mui/icons-material/Refresh";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VideoCameraBackIcon from "@mui/icons-material/VideoCameraBack";

import { getPlatformLogo } from "utils/getPlatformLogo";
import PlayerTag from "components/PlayerTag";
import { useSurvivalMastery } from "hooks/pubgApi/useSurvivalMastery";
import { useClanInfo } from "hooks/pubgApi/useClanInfo";
import { useAccountData } from "hooks/pubgApi/useAccountData";
import FunnyLoading from "components/FunnyLoading";
import BanStatus from "components/BanStatus";
import CasualMatchesBox from "components/CasualMatchesBox";
import PaginatedView from "components/MatchesView/PaginatedView";
import { KillsDamageDataAccordion } from "components/dataViz/RecentMatches/Accordions/KillsDamageDataAccordion";
import { MapGameModeDataAccordion } from "components/dataViz/RecentMatches/Accordions/MapGameModeDataAccordion";
import { useShowSessions } from "hooks/useShowSessions";
import { KeyboardController } from "hooks/KeyboardController";
import { calculateSessionStats } from "utils/calculateSessionStats";
import CardView from "components/MatchesView/CardView";
import { formatIncludingExcludingMode } from "utils/formatIncludingExcludingMode";
import { metricIcons } from "constants/metricIcons";
import { API_ENDPOINTS } from "constants/API_ENDPOINTS";
import { useShowSessionsOnly } from "hooks/useShowSessionsOnly";

// Top Data Table Stats Calculation
const calculateSummaryStats = (matches: Match[], sessions: number) => {
    const totalWins = matches.reduce(
        (total, match) => (match.playerWinPlace === 1 ? total + 1 : total),
        0
    );

    const totalKills = matches.reduce(
        (total, match) => total + (match.playerKills || 0),
        0
    );
    const totalDamage = matches.reduce(
        (total, match) => total + (match.playerDamage || 0),
        0
    );
    const totalSurvivalTimeInMinutes = matches.reduce(
        (total, match) => total + (match.playerSurvivalTime / 60 || 0),
        0
    );
    const totalMatches = matches.length;

    const formatTime = (minutes: number) => {
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = Math.round(minutes % 60);
        return `${hours > 0 ? `${hours}h ` : ""}${remainingMinutes}m`;
    };

    const survivalTimePerDay = Math.floor(totalSurvivalTimeInMinutes / 14);
    const survivalTimePerMatch = Math.floor(
        totalSurvivalTimeInMinutes / totalMatches
    );
    const survivalTimePerSession = Math.floor(
        totalSurvivalTimeInMinutes / sessions
    );
    return {
        // Wins
        totalWins,
        winsPerDay: (totalWins / 14).toFixed(2),
        winsPerSession: (totalWins / sessions).toFixed(2),
        // Kills
        totalKills,
        killsPerMatch: (totalKills / totalMatches).toFixed(2),
        killsPerDay: (totalKills / 14).toFixed(2),
        killsPerSession: (totalKills / sessions).toFixed(2),
        // Damage
        totalDamage: Math.round(totalDamage).toLocaleString(),
        damagePerMatch: Math.round(totalDamage / totalMatches).toLocaleString(),
        damagePerDay: Math.round(totalDamage / 14).toLocaleString(),
        damagePerSession: Math.round(totalDamage / sessions).toLocaleString(),
        // Survival Time
        totalSurvivalTime: formatTime(totalSurvivalTimeInMinutes),
        survivalTimePerDay: formatTime(survivalTimePerDay),
        survivalTimePerMatch: formatTime(survivalTimePerMatch),
        survivalTimePerSession: formatTime(survivalTimePerSession),
        // Matches
        totalMatches,
        matchesPerDay: (totalMatches / 14).toFixed(2),
        matchesPerSession: (totalMatches / sessions).toFixed(2),
        // Sessions
        totalSessions: sessions,
        sessionsPerDay: (sessions / 14).toFixed(2),
    };
};

export const ITEMS_PER_PAGE = 50;

const RecentMatches: React.FC = () => {
    // Search parameters
    const { platform = "steam", playerName = "Unknown" } = useParams<{
        platform?: string;
        playerName?: string;
    }>();

    const { accountId, clanId, banType, accountLoading, accountError } =
        useAccountData(platform, playerName);

    // Using Banner Hooks
    const { clanInfo, clanLoading, clanError } = useClanInfo(clanId, platform);

    const { survivalMastery, survivalLoading, survivalError } =
        useSurvivalMastery(accountId, platform);

    const [matches, setMatches] = useState<Match[]>([]);
    const [matchesLoading, setMatchesLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const { showMapImages, toggleShowMapImages } = useShowMapImages();
    const { showSessions, toggleShowSessions } = useShowSessions();
    const { showSessionsOnly, toggleShowSessionsOnly } = useShowSessionsOnly();

    // Table Filters
    const [includeTDM, setIncludeTDM] = useState<boolean>(true);
    const [includeIBR, setIncludeIBR] = useState<boolean>(true);
    const [includeCasual, setIncludeCasual] = useState<boolean>(true);

    const includedModes = useMemo(() => {
        const modes: string[] = [];
        if (includeTDM) modes.push("tdm");
        if (includeIBR) modes.push("ibr");
        if (includeCasual) modes.push("casual");
        return modes;
    }, [includeTDM, includeIBR, includeCasual]);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const favoriteGameMode = localStorage.getItem("favoriteGameMode") || "";

    // State for the selected game mode filter
    const [gameModeFilter, setGameModeFilter] = useState<string>("all");
    const [perspectiveFilter, setPerspectiveFilter] = useState<string>("both");

    const navigate = useNavigate();

    // Function to handle game mode change
    // Updated handleGameModeChange function
    const handleGameModeChange = (event: SelectChangeEvent<string>) => {
        setGameModeFilter(event.target.value);
    };

    const handlePerspectiveChange = (event: SelectChangeEvent<string>) => {
        setPerspectiveFilter(event.target.value);
    };

    // Keep in mind the face value (disabled) of the checkboxes is not the same as the actual value
    const filteredMatches = useMemo(() => {
        return matches.filter((match) => {
            const isPerspectiveMatched = () => {
                if (perspectiveFilter === "both") return true;
                if (
                    match.gameMode
                        .toLowerCase()
                        .includes(perspectiveFilter.toLowerCase())
                ) {
                    return true;
                }
                return false;
            };

            // Handle All Casual Matches
            if (match.matchType === "airoyale") {
                return (
                    (gameModeFilter === "all" ||
                        gameModeFilter === "solo" ||
                        gameModeFilter === "squad") &&
                    includedModes.includes("casual") &&
                    perspectiveFilter !== "fpp"
                );
            }

            // Handle All TDM
            if (match.gameMode === "tdm") {
                return (
                    (gameModeFilter === "all" &&
                        includedModes.includes("tdm") &&
                        perspectiveFilter !== "tpp") ||
                    gameModeFilter === "tdm"
                );
            }

            // Handle All IBR
            if (match.gameMode === "ibr") {
                return (
                    (gameModeFilter === "all" &&
                        includedModes.includes("ibr") &&
                        perspectiveFilter !== "fpp") || // Exclude FPP for TPP-only modes
                    gameModeFilter === "ibr"
                );
            }

            if (
                gameModeFilter === "all" ||
                match.gameMode
                    .toLowerCase()
                    .includes(gameModeFilter.toLowerCase())
            ) {
                return isPerspectiveMatched();
            }

            return false;
        });
    }, [matches, gameModeFilter, perspectiveFilter, includedModes]);

    const calculateSessions = (matches: Match[]) => {
        if (!matches || matches.length === 0) return [];

        // Sort matches by creation time
        const sortedMatches = [...matches].sort(
            (a, b) =>
                new Date(a.createdAt).getTime() -
                new Date(b.createdAt).getTime()
        );

        const sessions: { sessionId: number; matches: Match[] }[] = [];
        let currentSession: Match[] = [sortedMatches[0]];

        for (let i = 1; i < sortedMatches.length; i++) {
            const prevMatchEndTime = new Date(
                sortedMatches[i - 1].createdAt
            ).getTime();
            const currentMatchStartTime = new Date(
                sortedMatches[i].createdAt
            ).getTime();
            const timeDifference =
                (currentMatchStartTime - prevMatchEndTime) / (1000 * 60); // In minutes

            if (timeDifference <= 60) {
                // Add to the current session
                currentSession.push(sortedMatches[i]);
            } else {
                // End current session and start a new one
                sessions.push({
                    sessionId: sessions.length + 1,
                    matches: currentSession,
                });
                currentSession = [sortedMatches[i]];
            }
        }

        // Push the last session
        if (currentSession.length > 0) {
            sessions.push({
                sessionId: sessions.length + 1,
                matches: currentSession,
            });
        }

        return sessions;
    };

    const [currentPage, setCurrentPage] = useState(1);
    // Reset Page to 1 whenever any of the filters change
    useEffect(() => {
        setCurrentPage(1);
    }, [gameModeFilter, perspectiveFilter, includedModes]);
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(ITEMS_PER_PAGE);
    const [paginatedMatches, setPaginatedMatches] = useState<Match[]>([]);

    useEffect(() => {
        // Recalculate startIndex and endIndex
        const newStartIndex = showSessionsOnly
            ? 0
            : (currentPage - 1) * ITEMS_PER_PAGE;
        const newEndIndex = showSessionsOnly
            ? filteredMatches.length
            : newStartIndex + ITEMS_PER_PAGE;

        setStartIndex(newStartIndex);
        setEndIndex(newEndIndex);

        // Update paginated matches
        setPaginatedMatches(filteredMatches.slice(newStartIndex, newEndIndex));
    }, [currentPage, filteredMatches, showSessionsOnly]);

    const sessions = useMemo(() => {
        return calculateSessions(filteredMatches);
    }, [filteredMatches]);

    const sessionStats = useMemo(() => {
        return calculateSessionStats(sessions);
    }, [filteredMatches]);

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    const viewModes = [
        "compactCard",
        "detailedCard",
        "compactTable",
        "detailedTable",
    ] as const;
    const [viewMode, setViewMode] =
        useState<(typeof viewModes)[number]>("compactCard");

    const handleViewModeSwitch = () => {
        const currentIndex = viewModes.indexOf(viewMode);
        const nextIndex = (currentIndex + 1) % viewModes.length;
        setViewMode(viewModes[nextIndex]);
    };
    ///

    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: "/player-stats/matches",
            title: "Recent Matches Page",
        });
    }, []);

    const stats = calculateSummaryStats(filteredMatches, sessions.length);

    // Fetch matches after the accountId is set
    useEffect(() => {
        if (!accountId) return;

        const fetchMatches = async () => {
            setMatchesLoading(true);
            setError(null);

            try {
                const accountResponse = await fetch(
                    API_ENDPOINTS.ACCOUNT_DATA(platform, playerName)
                );

                if (!accountResponse.ok) {
                    throw new Error(
                        `Failed to fetch player info for ${playerName}.`
                    );
                }

                const accountData = await accountResponse.json();

                const matchPromises = accountData.matchIds.map(
                    async (id: string): Promise<Match> => {
                        const matchResponse = await fetch(
                            API_ENDPOINTS.MATCH_DETAILS(platform, id)
                        );

                        if (!matchResponse.ok) {
                            throw new Error(
                                `Failed to fetch details for match ID: ${id}`
                            );
                        }

                        const matchData = await matchResponse.json();

                        const rostersWithStats = computeRosterStats(
                            matchData.rosters,
                            matchData.participants,
                            matchData.gameMode === "tdm"
                        );

                        // Find the participant that matches the current player
                        const playerParticipant = matchData.participants.find(
                            (p: Participant) =>
                                p.name === playerName ||
                                p.playerId === accountId
                        );

                        if (!playerParticipant) {
                            throw new Error(
                                `Player ${playerName} (or Account ID: ${accountId}) not found in match ${id}`
                            );
                        }

                        // Find the roster the player belongs to
                        const playerRoster = rostersWithStats.find((roster) =>
                            roster.participants.includes(playerParticipant.id)
                        );

                        if (!playerRoster) {
                            throw new Error(
                                `Roster not found for player ${playerName} in match ${id}`
                            );
                        }

                        return {
                            ...matchData,
                            playerRoster: playerRoster.playerStats,
                            playerWinPlace: playerParticipant.winPlace,
                            playerRosterKills: playerRoster.totalKills,
                            playerRosterDamage: playerRoster.totalDamage,
                            playerKills: playerParticipant.kills,
                            playerDamage: playerParticipant.damageDealt,
                            playerSurvivalTime: playerParticipant.timeSurvived,
                        };
                    }
                );

                const matchDetails: Match[] = await Promise.all(matchPromises);

                setMatches(matchDetails);
            } catch (err) {
                setError(
                    (err as Error).message ||
                        "An error occurred while fetching matches."
                );
            } finally {
                setMatchesLoading(false);
            }
        };

        fetchMatches();
    }, [accountId, platform, playerName]);

    if (
        matchesLoading ||
        accountLoading ||
        clanLoading ||
        survivalLoading ||
        !survivalMastery
    ) {
        return <FunnyLoading />;
    }

    if (error) {
        return (
            <Typography color="error" variant="body1">
                {error}
            </Typography>
        );
    }

    if (!matchesLoading && matches.length === 0) {
        return (
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    textAlign: "center",
                    gap: 2,
                    padding: 3,
                }}
            >
                <SearchIcon
                    sx={{
                        fontSize: 60,
                        color: "text.secondary",
                    }}
                />
                <Typography
                    variant="h5"
                    sx={{
                        fontWeight: "bold",
                        color: "text.primary",
                    }}
                >
                    No Matches
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        color: "text.secondary",
                    }}
                >
                    <strong>{playerName}</strong> hasn't played any matches in
                    the last 14 days.
                </Typography>
            </Box>
        );
    }

    return (
        <Box
            sx={{
                maxWidth: maxWidths.l, // Adjust the max width as needed
                width: "100%", // Ensures it adapts to smaller screens
                position: "relative", // Allows the toggle to stay relative to the container
                margin: "0 auto",
            }}
        >
            <KeyboardController
                controlType="action"
                actionKey="f"
                onAction={handleViewModeSwitch}
            />
            <Box
                sx={{
                    maxWidth: maxWidths.l,
                    margin: "0 auto",
                    border: "2px solid #444",
                    borderRadius: "8px",
                    padding: "16px",
                    backgroundColor: "#2e2e2e",
                    marginTop: "16px",
                    marginBottom: "24px",
                    alignContent: "center",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: { xs: "column", sm: "row" },
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: "16px",
                        marginBottom: "16px",
                    }}
                >
                    {/* Left Section: Page Title and Banner */}
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: { xs: "column", sm: "row" },
                            alignItems: "center",
                            gap: 2, // Gap between banner and favorite icon
                        }}
                    >
                        {/* Banner Section */}
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                            }}
                        >
                            <img
                                src={getPlatformLogo(platform)}
                                alt={`${platform} logo`}
                                style={{ width: 26, height: 26 }}
                            />
                            <Typography variant="h6" color="#fff">
                                Recent Matches for {playerName}
                            </Typography>
                        </Box>

                        {/* Player Tag */}
                        <PlayerTag
                            playerName={playerName}
                            survivalTier={survivalMastery?.tier}
                            survivalLevel={survivalMastery?.level}
                            clanInfo={clanInfo}
                        />
                    </Box>

                    <BanStatus banType={banType} />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        mt: 4,
                        flexDirection: { xs: "column", md: "row" }, // Column for mobile, row for desktop
                        gap: 2, // Space between filter box and stats grid
                        alignItems: { xs: "stretch", md: "flex-start" }, // Align items appropriately
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 1, // Add spacing between each filter option
                            backgroundColor: "#1f1f1f",
                            padding: 2, // Add padding inside the box
                            borderRadius: "8px", // Rounded corners for a polished look
                            maxWidth: "330px", // Limit the width to prevent it from stretching too far
                            marginBottom: 4, // Add margin at the bottom for separation
                        }}
                    >
                        <Typography
                            variant="body1"
                            color="text.secondary"
                            sx={{ mb: 2 }}
                        >
                            Changing the filters below will update the
                            statistics across the entire page.
                        </Typography>
                        <Divider sx={{ mt: -2, mb: 2 }} />

                        {/* Game Mode Filter */}
                        <FormControl
                            variant="outlined"
                            size="small"
                            sx={{
                                maxWidth: "220px",
                                "& .MuiOutlinedInput-root": {
                                    backgroundColor: COLORS.blue,
                                },
                            }}
                        >
                            <InputLabel
                                sx={{
                                    backgroundColor:
                                        theme.palette.custom.accentColor2,
                                    padding: "0 4px", // Add padding for better readability
                                    marginLeft: "-4px", // Slight adjustment to align with dropdown
                                    borderRadius: "4px", // Rounded corners
                                }}
                            >
                                Game Mode
                            </InputLabel>
                            <Select
                                value={gameModeFilter}
                                onChange={handleGameModeChange}
                                label="Game Mode"
                            >
                                <MenuItem value="all">
                                    <Box
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        width="100%"
                                    >
                                        All
                                    </Box>
                                </MenuItem>
                                <MenuItem value="solo">
                                    <Box
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        width="100%"
                                    >
                                        Solo
                                        <PersonIcon />
                                    </Box>
                                </MenuItem>
                                <MenuItem value="duo">
                                    <Box
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        width="100%"
                                    >
                                        Duo
                                        <Box display="flex" gap={0.5}>
                                            <PersonIcon />
                                            <PersonIcon />
                                        </Box>
                                    </Box>
                                </MenuItem>
                                <MenuItem value="squad">
                                    <Box
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        width="100%"
                                    >
                                        Squad
                                        <Box display="flex" gap={0.5}>
                                            <PersonIcon />
                                            <PersonIcon />
                                            <PersonIcon />
                                            <PersonIcon />
                                        </Box>
                                    </Box>
                                </MenuItem>
                                <MenuItem value="tdm">
                                    <Box
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        width="100%"
                                    >
                                        Team Deathmatch
                                        <RefreshIcon />
                                    </Box>
                                </MenuItem>
                                <MenuItem value="ibr">
                                    <Box
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        width="100%"
                                    >
                                        Intense Battle Royale
                                        <WhatshotIcon />
                                    </Box>
                                </MenuItem>
                            </Select>
                        </FormControl>

                        {/* Perspective Filter */}
                        {gameModeFilter !== "tdm" &&
                            gameModeFilter !== "ibr" && (
                                <FormControl
                                    variant="outlined"
                                    size="small"
                                    sx={{
                                        marginTop: 2,
                                        maxWidth: "120px",
                                        "& .MuiOutlinedInput-root": {
                                            backgroundColor: COLORS.blue,
                                        },
                                    }}
                                >
                                    <InputLabel
                                        sx={{
                                            backgroundColor:
                                                theme.palette.custom
                                                    .accentColor2,
                                            padding: "0 4px", // Add padding for better readability
                                            marginLeft: "-4px", // Slight adjustment to align with dropdown
                                            borderRadius: "4px", // Rounded corners
                                        }}
                                    >
                                        Perspective
                                    </InputLabel>
                                    <Select
                                        value={perspectiveFilter}
                                        onChange={handlePerspectiveChange}
                                        label="Perspective"
                                    >
                                        <MenuItem value="both">
                                            <Box
                                                display="flex"
                                                justifyContent="space-between"
                                                alignItems="center"
                                                width="100%"
                                            >
                                                Both
                                            </Box>
                                        </MenuItem>
                                        <MenuItem value="fpp">
                                            <Box
                                                display="flex"
                                                justifyContent="space-between"
                                                alignItems="center"
                                                width="100%"
                                            >
                                                FPP
                                                <VisibilityIcon />
                                            </Box>
                                        </MenuItem>
                                        <MenuItem value="tpp">
                                            <Box
                                                display="flex"
                                                justifyContent="space-between"
                                                alignItems="center"
                                                width="100%"
                                            >
                                                TPP
                                                <Box display="flex" gap={0.5}>
                                                    <VideoCameraBackIcon />
                                                </Box>
                                            </Box>
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            )}

                        <Tooltip
                            title={
                                gameModeFilter === "all" ||
                                gameModeFilter === "squad" ||
                                gameModeFilter === "solo" ? (
                                    ""
                                ) : (
                                    <Typography>
                                        This option is only available when{" "}
                                        <span
                                            style={{
                                                color: theme.palette.primary
                                                    .main,
                                                fontWeight: "bold",
                                            }}
                                        >
                                            All
                                        </span>
                                        ,{" "}
                                        <span
                                            style={{
                                                color: theme.palette.primary
                                                    .main,
                                                fontWeight: "bold",
                                            }}
                                        >
                                            Solo
                                        </span>
                                        , or{" "}
                                        <span
                                            style={{
                                                color: theme.palette.primary
                                                    .main,
                                                fontWeight: "bold",
                                            }}
                                        >
                                            Squad
                                        </span>{" "}
                                        game modes are selected above.
                                    </Typography>
                                )
                            }
                            arrow
                        >
                            <span>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={
                                                includeCasual &&
                                                (gameModeFilter === "all" ||
                                                    gameModeFilter === "solo" ||
                                                    gameModeFilter === "squad")
                                            }
                                            onChange={(e) =>
                                                setIncludeCasual(
                                                    e.target.checked
                                                )
                                            }
                                            disabled={
                                                gameModeFilter === "duo" ||
                                                gameModeFilter === "ibr" ||
                                                gameModeFilter === "tdm"
                                            } // Disable if the game mode filter is not applicable
                                        />
                                    }
                                    label="Include Casual Matches Stats"
                                    sx={{
                                        textAlign: "left", // Align label text to the left
                                        width: "100%", // Ensure the label spans the full width
                                        marginLeft: 0, // Remove extra spacing
                                    }}
                                />
                            </span>
                        </Tooltip>
                        {/* Include Team Deathmatch Stats */}
                        <Tooltip
                            title={
                                gameModeFilter === "all" ? (
                                    ""
                                ) : (
                                    <Typography>
                                        This option is only available when the{" "}
                                        <span
                                            style={{
                                                color: theme.palette.primary
                                                    .main,
                                                fontWeight: "bold",
                                            }}
                                        >
                                            All
                                        </span>{" "}
                                        game mode is selected above. It is also
                                        enabled by default if{" "}
                                        <span
                                            style={{
                                                color: theme.palette.primary
                                                    .main,
                                                fontWeight: "bold",
                                            }}
                                        >
                                            TDM
                                        </span>{" "}
                                        is selected.
                                    </Typography>
                                )
                            }
                            arrow
                        >
                            <span>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={
                                                gameModeFilter === "tdm" ||
                                                (includeTDM &&
                                                    gameModeFilter === "all")
                                            }
                                            onChange={(e) =>
                                                setIncludeTDM(e.target.checked)
                                            }
                                            disabled={gameModeFilter !== "all"} // Disable if not in All game mode
                                        />
                                    }
                                    label="Include Team Deathmatch Stats"
                                    sx={{
                                        textAlign: "left",
                                        width: "100%",
                                        marginLeft: 0,
                                    }}
                                />
                            </span>
                        </Tooltip>
                        {/* Include Intense Battle Royale Stats */}
                        <Tooltip
                            title={
                                gameModeFilter === "all" ? (
                                    ""
                                ) : (
                                    <Typography>
                                        This option is only available when the{" "}
                                        <span
                                            style={{
                                                color: theme.palette.primary
                                                    .main,
                                                fontWeight: "bold",
                                            }}
                                        >
                                            All
                                        </span>{" "}
                                        game mode is selected above. It is also
                                        enabled by default if{" "}
                                        <span
                                            style={{
                                                color: theme.palette.primary
                                                    .main,
                                                fontWeight: "bold",
                                            }}
                                        >
                                            IBR
                                        </span>{" "}
                                        is selected.
                                    </Typography>
                                )
                            }
                            arrow
                        >
                            <span>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={
                                                gameModeFilter === "ibr" ||
                                                (includeIBR &&
                                                    gameModeFilter === "all")
                                            }
                                            onChange={(e) =>
                                                setIncludeIBR(e.target.checked)
                                            }
                                            disabled={gameModeFilter !== "all"} // Disable if not in All game mode
                                        />
                                    }
                                    label="Include Intense Battle Royale Stats"
                                    sx={{
                                        textAlign: "left",
                                        width: "100%",
                                        marginLeft: 0,
                                    }}
                                />
                            </span>
                        </Tooltip>

                        <Divider />

                        <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{
                                fontStyle: "italic",
                            }}
                        >
                            Note: Training sessions are displayed for reference
                            only and do not contribute to statistics.
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            flex: 1, // Take up the remaining space on desktop
                            margin: { xs: "0 auto", md: 0 }, // Center on mobile, align normally on desktop
                            maxWidth: { xs: "100%", md: "calc(100% - 350px)" }, // Adjust width on mobile and desktop
                        }}
                    >
                        <DailyStatsGrid filteredMatches={filteredMatches} />
                    </Box>
                </Box>

                <Box
                    sx={{
                        display: "flex",
                        flexDirection: { xs: "column", md: "row" }, // Stack on smaller screens, side-by-side on larger screens
                        alignItems: { xs: "center", md: "flex-start" },
                        justifyContent: "space-between",
                        gap: 3, // Spacing between CasualMatchesBox and the table
                        marginBottom: 4,
                    }}
                >
                    {/* Casual Matches Box */}
                    <Box
                        sx={{
                            flex: { xs: "unset", md: 1 }, // On larger screens, allocate 1/3 space
                            maxWidth: { md: "28%" }, // Limit width for larger screens
                            width: "100%", // Full width for smaller screens
                        }}
                    >
                        <Box
                            sx={{
                                border: "2px solid #444",
                                borderRadius: "8px",
                                padding: 2,
                                marginBottom: 4,
                                backgroundColor: "#2e2e2e",
                            }}
                        >
                            <Typography variant="body2" mb={2}>
                                You played{" "}
                                <Box
                                    component="span"
                                    sx={{
                                        color: "primary.main",
                                        fontWeight: "bold",
                                    }}
                                >
                                    {sessions.length} PUBG sessions over the
                                    past 14 days
                                </Box>
                                . To see more info about each session, check the
                                card or table list below.
                            </Typography>

                            <Divider />
                            <Typography
                                variant="subtitle2"
                                sx={{
                                    marginTop: 1,
                                    color: theme.palette.text.secondary,
                                }}
                            >
                                * PUBG Meta defines a session as a series of
                                matches where each match starts within 60
                                minutes of the previous match in the same
                                session.
                            </Typography>
                        </Box>
                        <CasualMatchesBox matches={matches} />
                    </Box>

                    {/* Info Table */}
                    <Box
                        sx={{
                            flex: { xs: "unset", md: 2 }, // On larger screens, allocate 2/3 space
                            maxWidth: { md: "72%" }, // Limit width for larger screens
                            width: "100%", // Full width for smaller screens
                        }}
                    >
                        <TableContainer
                            component={Paper}
                            sx={{
                                maxWidth: "100%",
                                margin: "0 auto",
                            }}
                        >
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {/* Top-Left Cell */}
                                        <TableCell
                                            sx={{
                                                borderTop: `2px solid ${theme.palette.secondary.main}`,
                                                borderRight: `2px solid ${theme.palette.secondary.main}`, // Match top row border
                                                borderLeft: `2px solid ${theme.palette.custom.accentColor2}`,
                                                borderBottom: `2px solid ${theme.palette.custom.accentColor2}`,
                                            }}
                                        >
                                            Metric
                                        </TableCell>
                                        {/* Remaining Header Cells */}
                                        <TableCell
                                            sx={{
                                                border: `2px solid ${theme.palette.secondary.main}`, // Blue border for header cells
                                            }}
                                        >
                                            Total
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                border: `2px solid ${theme.palette.secondary.main}`,
                                            }}
                                        >
                                            Per Day
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                border: `2px solid ${theme.palette.secondary.main}`,
                                            }}
                                        >
                                            Per Match
                                        </TableCell>{" "}
                                        <TableCell
                                            sx={{
                                                border: `2px solid ${theme.palette.secondary.main}`,
                                            }}
                                        >
                                            Per Session
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {[
                                        {
                                            metric: "Matches",
                                            icon: metricIcons["matches"],
                                            total: stats.totalMatches,
                                            perMatch: (
                                                <Tooltip
                                                    title="Not applicable because it is impossible to calculate the average number of matches per match."
                                                    arrow
                                                >
                                                    <Box
                                                        sx={{
                                                            backgroundImage: `repeating-linear-gradient(
                                    45deg,
                                    rgba(0, 0, 0, 0.1) 0px,
                                    rgba(0, 0, 0, 0.1) 10px,
                                    transparent 10px,
                                    transparent 20px
                                )`,
                                                            backgroundColor:
                                                                "rgba(0, 0, 0, 0.05)",
                                                            borderRadius: "4px",
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        <Typography color="text.secondary">
                                                            N/A
                                                        </Typography>
                                                    </Box>
                                                </Tooltip>
                                            ),
                                            perDay: stats.matchesPerDay,
                                            perSession: stats.matchesPerSession,
                                        },
                                        {
                                            metric: "Wins",
                                            icon: metricIcons["wins"],
                                            total: stats.totalWins,
                                            perMatch: (
                                                <Tooltip
                                                    title="Not applicable because it is impossible to calculate the average number of wins per match."
                                                    arrow
                                                >
                                                    <Box
                                                        sx={{
                                                            backgroundImage: `repeating-linear-gradient(
                                    45deg,
                                    rgba(0, 0, 0, 0.1) 0px,
                                    rgba(0, 0, 0, 0.1) 10px,
                                    transparent 10px,
                                    transparent 20px
                                )`,
                                                            backgroundColor:
                                                                "rgba(0, 0, 0, 0.05)",
                                                            borderRadius: "4px",
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        <Typography color="text.secondary">
                                                            N/A
                                                        </Typography>
                                                    </Box>
                                                </Tooltip>
                                            ),
                                            perDay: stats.winsPerDay,
                                            perSession: stats.winsPerSession,
                                        },
                                        {
                                            metric: "Survival Time",
                                            icon: metricIcons["duration"],
                                            total: stats.totalSurvivalTime,
                                            perMatch:
                                                stats.survivalTimePerMatch,
                                            perDay: stats.survivalTimePerDay,
                                            perSession:
                                                stats.survivalTimePerSession,
                                        },
                                        {
                                            metric: "Kills",
                                            icon: metricIcons["kills"],
                                            total: stats.totalKills,
                                            perMatch: stats.killsPerMatch,
                                            perDay: stats.killsPerDay,
                                            perSession: stats.killsPerSession,
                                        },
                                        {
                                            metric: "Damage",
                                            icon: metricIcons["damage"],
                                            total: stats.totalDamage,
                                            perMatch: stats.damagePerMatch,
                                            perDay: stats.damagePerDay,
                                            perSession: stats.damagePerSession,
                                        },
                                        {
                                            metric: "Sessions",
                                            icon: metricIcons["sessions"],
                                            total: stats.totalSessions,
                                            perDay: stats.sessionsPerDay,
                                            perMatch: (
                                                <Tooltip
                                                    title="Not applicable because it is impossible to calculate the average number of sessions per match."
                                                    arrow
                                                >
                                                    <Box
                                                        sx={{
                                                            backgroundImage: `repeating-linear-gradient(
                                    45deg,
                                    rgba(0, 0, 0, 0.1) 0px,
                                    rgba(0, 0, 0, 0.1) 10px,
                                    transparent 10px,
                                    transparent 20px
                                )`,
                                                            backgroundColor:
                                                                "rgba(0, 0, 0, 0.05)",
                                                            borderRadius: "4px",
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        <Typography color="text.secondary">
                                                            N/A
                                                        </Typography>
                                                    </Box>
                                                </Tooltip>
                                            ),
                                            perSession: (
                                                <Tooltip
                                                    title="Not applicable because it is impossible to calculate the average number of sessions per session."
                                                    arrow
                                                >
                                                    <Box
                                                        sx={{
                                                            backgroundImage: `repeating-linear-gradient(
                                    45deg,
                                    rgba(0, 0, 0, 0.1) 0px,
                                    rgba(0, 0, 0, 0.1) 10px,
                                    transparent 10px,
                                    transparent 20px
                                )`,
                                                            backgroundColor:
                                                                "rgba(0, 0, 0, 0.05)",
                                                            borderRadius: "4px",
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        <Typography color="text.secondary">
                                                            N/A
                                                        </Typography>
                                                    </Box>
                                                </Tooltip>
                                            ),
                                        },
                                    ].map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell
                                                sx={{
                                                    borderBottom: `2px solid ${theme.palette.custom.accentColor2}`,
                                                    borderRight: `2px solid ${theme.palette.custom.accentColor2}`,
                                                    borderLeft: `2px solid ${theme.palette.custom.accentColor2}`,
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: 1,
                                                }}
                                            >
                                                {row.icon} {row.metric}
                                            </TableCell>

                                            <TableCell>{row.total}</TableCell>

                                            <TableCell>{row.perDay}</TableCell>
                                            <TableCell>
                                                {row.perMatch}
                                            </TableCell>
                                            <TableCell>
                                                {row.perSession}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>
            </Box>

            {/* <Box
                sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    marginTop: "4px",
                    marginRight: "8px",
                }}
            >
                <Typography
                    variant="body2"
                    component="a"
                    href="/faq#match-card-icons"
                    sx={{
                        textDecoration: "none",
                        color: "primary.main",
                        fontWeight: "bold",
                        "&:hover": {
                            textDecoration: "underline",
                        },
                    }}
                >
                    What do the match card icons mean?
                </Typography>
            </Box> */}

            <Box
                sx={{
                    border: "2px solid #444",
                    borderRadius: "8px",
                    padding: 2,
                    marginBottom: 4,
                    backgroundColor: "#2e2e2e",
                }}
            >
                <MapGameModeDataAccordion matches={filteredMatches} />
            </Box>

            {/* Kills and Damage Data */}
            <Box
                sx={{
                    border: "2px solid #444",
                    borderRadius: "8px",
                    padding: 2,
                    marginBottom: 4,
                    backgroundColor: "#2e2e2e",
                }}
            >
                <KillsDamageDataAccordion
                    matches={filteredMatches}
                    platform={platform}
                    playerName={playerName}
                />
            </Box>

            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: 2,
                    width: "500px",
                    maxWidth: "100%",
                    margin: "0 auto",
                    marginBottom: 4,
                    padding: 2,
                    border: "1px solid rgba(255, 255, 255, 0.3)",
                    borderRadius: "8px",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                    backgroundColor: "#2e2e2e",
                }}
            >
                <Typography variant="h6">
                    Matches played in the past 14 days
                </Typography>

                <Box
                    sx={{
                        border: "2px solid",
                        borderColor: "primary.main",
                        padding: 2,
                        borderRadius: "16px", // Rounded corners
                        marginBottom: 2,
                        boxShadow: 2,
                    }}
                >
                    {gameModeFilter !== "all" && (
                        <Typography variant="body2" color="inherit">
                            Game Mode:{" "}
                            <strong>
                                {gameModeFilter === "tdm"
                                    ? "Team Deathmatch"
                                    : gameModeFilter === "ibr"
                                    ? "Intense Battle Royale"
                                    : gameModeFilter
                                          .toLowerCase()
                                          .split(" ")
                                          .map(
                                              (word) =>
                                                  word.charAt(0).toUpperCase() +
                                                  word.slice(1)
                                          )
                                          .join(" ")}
                            </strong>
                        </Typography>
                    )}
                    {gameModeFilter !== "tdm" &&
                        gameModeFilter !== "ibr" &&
                        perspectiveFilter !== "both" && (
                            <Typography variant="body2" color="inherit">
                                Perspective:{" "}
                                <strong>
                                    {perspectiveFilter.toUpperCase()}
                                </strong>
                            </Typography>
                        )}

                    {gameModeFilter !== "duo" &&
                        gameModeFilter !== "tdm" &&
                        gameModeFilter !== "ibr" &&
                        includedModes.length > 0 && (
                            <Typography variant="body2" color="inherit">
                                {(() => {
                                    let modesToCheck = includedModes;

                                    // If gameModeFilter is solo or squad, only consider casual and ignore others
                                    if (
                                        gameModeFilter === "solo" ||
                                        gameModeFilter === "squad"
                                    ) {
                                        modesToCheck = includedModes.filter(
                                            (mode) => mode === "casual"
                                        );
                                    }

                                    // If gameModeFilter is all, consider all selections
                                    const included = modesToCheck.map((mode) =>
                                        formatIncludingExcludingMode(
                                            mode,
                                            "Including"
                                        )
                                    );

                                    // Only exclude modes that are not casual if gameModeFilter is all
                                    const excluded =
                                        gameModeFilter === "all"
                                            ? ["tdm", "ibr", "casual"]
                                                  .filter(
                                                      (mode) =>
                                                          !modesToCheck.includes(
                                                              mode
                                                          )
                                                  )
                                                  .map((mode) =>
                                                      formatIncludingExcludingMode(
                                                          mode,
                                                          "Excluding"
                                                      )
                                                  )
                                            : [];

                                    return [...included, ...excluded].join(
                                        ", "
                                    );
                                })()}
                            </Typography>
                        )}
                </Box>
                <Typography
                    variant="body2"
                    sx={{
                        color: theme.palette.text.secondary,
                        textAlign: "center",
                    }}
                >
                    These matches are filtered based on the selected game mode
                    and other options above.
                </Typography>
                <Divider sx={{ width: "100%", borderBottomWidth: 2 }} />
                <Typography variant="h6">View Options</Typography>
                {!isMobile && (
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <img
                            src={fChangeViewType}
                            alt="Press F to cycle through view types"
                            style={{
                                width: "200px", // Adjust as needed
                                height: "auto", // Maintain aspect ratio
                            }}
                        />
                    </Box>
                )}
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                    }}
                >
                    {/* Card View Buttons */}
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                        }}
                    >
                        <Button
                            variant={
                                viewMode === "compactCard"
                                    ? "contained"
                                    : "outlined"
                            }
                            startIcon={<CardsIcon />}
                            onClick={() => setViewMode("compactCard")}
                        >
                            Compact Card
                        </Button>
                        <Button
                            variant={
                                viewMode === "detailedCard"
                                    ? "contained"
                                    : "outlined"
                            }
                            startIcon={<CardsIcon />}
                            onClick={() => setViewMode("detailedCard")}
                        >
                            Detailed Card
                        </Button>
                    </Box>

                    {/* Table View Buttons */}
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                        }}
                    >
                        <Button
                            variant={
                                viewMode === "compactTable"
                                    ? "contained"
                                    : "outlined"
                            }
                            startIcon={<TableIcon />}
                            onClick={() => setViewMode("compactTable")}
                        >
                            Compact Table
                        </Button>
                        <Button
                            variant={
                                viewMode === "detailedTable"
                                    ? "contained"
                                    : "outlined"
                            }
                            startIcon={<TableIcon />}
                            onClick={() => setViewMode("detailedTable")}
                        >
                            Detailed Table
                        </Button>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row", // Arrange items horizontally
                        alignItems: "center", // Align items vertically in the center
                        justifyContent: "center", // Center items horizontally
                        flexWrap: "wrap", // Ensure they wrap on smaller screens
                    }}
                >
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={showSessions}
                                onChange={(e) =>
                                    toggleShowSessions(e.target.checked)
                                }
                            />
                        }
                        label="Show Individual PUBG Sessions Stats"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={showSessionsOnly}
                                onChange={(e) =>
                                    toggleShowSessionsOnly(e.target.checked)
                                }
                            />
                        }
                        label="Show Sessions Only"
                    />
                    {(viewMode === "compactCard" ||
                        viewMode === "detailedCard") && (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={showMapImages}
                                    onChange={(e) =>
                                        toggleShowMapImages(e.target.checked)
                                    }
                                />
                            }
                            label="Show Map Images in Match Cards"
                        />
                    )}
                </Box>
            </Box>

            {viewMode === "compactTable" || viewMode === "detailedTable" ? (
                <>
                    {!showSessionsOnly && (
                        <PaginatedView
                            currentPage={currentPage}
                            totalPages={Math.ceil(
                                filteredMatches.length / ITEMS_PER_PAGE
                            )}
                            itemsPerPage={ITEMS_PER_PAGE}
                            totalItems={filteredMatches.length}
                            onPageChange={handlePageChange}
                        />
                    )}
                    <MatchesTable
                        matches={paginatedMatches}
                        playerName={playerName}
                        platform={platform}
                        currentPage={currentPage}
                        viewType={viewMode}
                        sessionsView={showSessions}
                        sessions={sessions}
                        sessionStats={sessionStats}
                        sessionsOnly={showSessionsOnly}
                    />
                    {!showSessionsOnly && (
                        <PaginatedView
                            currentPage={currentPage}
                            totalPages={Math.ceil(
                                filteredMatches.length / ITEMS_PER_PAGE
                            )}
                            itemsPerPage={ITEMS_PER_PAGE}
                            totalItems={filteredMatches.length}
                            onPageChange={handlePageChange}
                        />
                    )}
                </>
            ) : null}

            {(viewMode === "compactCard" || viewMode === "detailedCard") && (
                <>
                    {!showSessionsOnly && (
                        <PaginatedView
                            currentPage={currentPage}
                            totalPages={Math.ceil(
                                filteredMatches.length / ITEMS_PER_PAGE
                            )}
                            itemsPerPage={ITEMS_PER_PAGE}
                            totalItems={filteredMatches.length}
                            onPageChange={handlePageChange}
                        />
                    )}
                    <CardView
                        matches={paginatedMatches}
                        sessions={sessions}
                        sessionStats={sessionStats}
                        accountId={accountId}
                        platform={platform}
                        playerName={playerName}
                        currentPage={currentPage}
                        favoriteGameMode={favoriteGameMode}
                        showImages={showMapImages}
                        viewType={viewMode}
                        sessionsView={showSessions}
                        sessionsOnly={showSessionsOnly}
                    />
                    {!showSessionsOnly && (
                        <PaginatedView
                            currentPage={currentPage}
                            totalPages={Math.ceil(
                                filteredMatches.length / ITEMS_PER_PAGE
                            )}
                            itemsPerPage={ITEMS_PER_PAGE}
                            totalItems={filteredMatches.length}
                            onPageChange={handlePageChange}
                        />
                    )}
                </>
            )}
        </Box>
    );
};

export default RecentMatches;
