import React from "react";
import { Bar } from "react-chartjs-2";
import { WeaponCategories } from "components/WeaponMasterySection";
import { useTheme } from "@mui/material";

interface TopWeaponsByKillsChartProps {
    weaponMastery: WeaponCategories | null;
}

const TopWeaponsByKillsChart: React.FC<TopWeaponsByKillsChartProps> = ({
    weaponMastery,
}) => {
    const theme = useTheme();

    const topWeaponsByKills = weaponMastery
        ? Object.values(weaponMastery)
              .flat()
              .sort((a, b) => {
                  const killsA =
                      (a.OfficialStatsTotal?.Kills || 0) +
                      (a.StatsTotal?.Kills || 0) +
                      (a.CompetitiveStatsTotal?.Kills || 0);
                  const killsB =
                      (b.OfficialStatsTotal?.Kills || 0) +
                      (b.StatsTotal?.Kills || 0) +
                      (b.CompetitiveStatsTotal?.Kills || 0);
                  return killsB - killsA;
              })
              .slice(0, 10)
        : [];

    const chartData = {
        labels: topWeaponsByKills.map((weapon) => weapon.weaponId),
        datasets: [
            {
                label: "Normal",
                data: topWeaponsByKills.map(
                    (weapon) => weapon.OfficialStatsTotal?.Kills || 0
                ),
                backgroundColor: theme.palette.primary.main,
            },
            {
                label: "Ranked",
                data: topWeaponsByKills.map(
                    (weapon) => weapon.CompetitiveStatsTotal?.Kills || 0
                ),
                backgroundColor: theme.palette.secondary.main,
            },
            {
                label: "Legacy",
                data: topWeaponsByKills.map(
                    (weapon) => weapon.StatsTotal?.Kills || 0
                ),
                backgroundColor: theme.palette.custom.accentColor2,
            },
        ],
    };

    const chartOptions = {
        responsive: true,
        layout: {
            padding: {
                top: 30, // Add padding at the top
            },
        },
        plugins: {
            legend: {
                display: true,
                position: "top" as const,
                labels: {
                    color: theme.palette.text.primary,
                },
            },
            tooltip: {
                mode: "index" as const, // Show data for all datasets on hover
                intersect: false, // Show tooltip when hovering anywhere on the bar
                callbacks: {
                    label: (context: any) => {
                        const datasetLabel = context.dataset.label || "";
                        const value = context.raw as number;
                        return `${datasetLabel}: ${value.toLocaleString()}`;
                    },
                },
            },
            datalabels: {
                display: (context: any) => {
                    // Only display the label for the last dataset in the stack
                    const datasetIndex = context.datasetIndex;
                    const chart = context.chart;
                    return datasetIndex === chart.data.datasets.length - 1;
                },
                color: theme.palette.text.primary,
                anchor: "end" as const,
                align: "end" as const,
                formatter: (value: number, context: any) => {
                    const chart = context.chart;
                    const barIndex = context.dataIndex;

                    // Sum the visible datasets
                    const total = chart.data.datasets.reduce(
                        (sum: number, dataset: any, datasetIndex: number) => {
                            if (chart.isDatasetVisible(datasetIndex)) {
                                return sum + (dataset.data[barIndex] || 0);
                            }
                            return sum;
                        },
                        0
                    );

                    return total.toLocaleString(); // Display the total for visible datasets
                },
                font: {
                    size: 12,
                    weight: "bold" as "bold",
                },
            },
        },
        scales: {
            x: {
                stacked: true, // Enable stacking on the x-axis
                ticks: {
                    color: theme.palette.text.primary,
                },
            },
            y: {
                stacked: true, // Enable stacking on the y-axis
                ticks: {
                    color: theme.palette.text.primary,
                },
            },
        },
    };

    return <Bar data={chartData} options={chartOptions} />;
};

export default TopWeaponsByKillsChart;
