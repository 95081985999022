import React, { createContext, useContext, useState, useEffect } from "react";

const LandingPageContext = createContext({
    landingPage: "",
    setLandingPage: (page: string) => {},
});

export const LandingPageProvider: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    const [landingPage, setLandingPage] = useState("");

    useEffect(() => {
        // Retrieve the landing page preference from localStorage
        const savedPreferences = localStorage.getItem("landingPagePreference");
        if (savedPreferences) {
            setLandingPage(savedPreferences);
        }
    }, []);

    // Save to localStorage whenever the landingPage changes
    useEffect(() => {
        localStorage.setItem("landingPagePreference", landingPage);
    }, [landingPage]);

    return (
        <LandingPageContext.Provider value={{ landingPage, setLandingPage }}>
            {children}
        </LandingPageContext.Provider>
    );
};

export const useLandingPage = () => useContext(LandingPageContext);
