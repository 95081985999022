import { useState } from "react";

export const useShowWeaponCategories = () => {
    const [showWeaponCategories, setShowWeaponCategories] = useState<boolean>(
        () => {
            const savedValue = localStorage.getItem("showWeaponCategories");
            return savedValue !== null ? JSON.parse(savedValue) : true; // Default to true
        }
    );

    const toggleShowWeaponCategories = (value: boolean) => {
        setShowWeaponCategories(value);
        localStorage.setItem("showWeaponCategories", JSON.stringify(value));
    };

    return { showWeaponCategories, toggleShowWeaponCategories };
};
