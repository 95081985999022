import React from "react";
import { Box, Typography, List, ListItem, ListItemText } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { useSavedMatches } from "hooks/useSavedMatches";
import FunnyLoading from "components/FunnyLoading";

const SavedMatches: React.FC = () => {
    const { savedMatches, loading, error } = useSavedMatches();

    const navigate = useNavigate();

    const handleMatchClick = (
        matchId: string,
        platform: string,
        playerName: string
    ) => {
        navigate(
            `/player-stats/matches/${matchId}?platform=${platform}&name=${encodeURIComponent(
                playerName
            )}`
        );
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <Box sx={{ padding: 2, maxWidth: 1400, margin: "0 auto" }}>
            <Typography variant="h5" gutterBottom>
                Saved Matches
            </Typography>
            {loading ? (
                <FunnyLoading />
            ) : savedMatches.length > 0 ? (
                <List>
                    {savedMatches.map((match, index) => (
                        <ListItem
                            key={index}
                            onClick={() =>
                                handleMatchClick(
                                    match.matchId,
                                    match.platform,
                                    match.playerName
                                )
                            }
                            sx={{
                                cursor: "pointer",
                            }}
                        >
                            <ListItemText
                                primary={`Match ID: ${match.matchId}`}
                                secondary={`Platform: ${
                                    match.platform
                                } | Saved since: ${format(
                                    new Date(match.savedAt),
                                    "PPpp"
                                )}`}
                            />
                        </ListItem>
                    ))}
                </List>
            ) : (
                <Typography>No saved matches found.</Typography>
            )}
        </Box>
    );
};

export default SavedMatches;
