import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ReactGA from "react-ga4";
import { faqData } from "../constants/faqConstants";
import { maxWidths } from "constants/appWide/maxWidths";

export const FAQ: React.FC = () => {
    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: "/faq",
            title: "FAQ Page",
        });
    }, []);

    return (
        <Box
            sx={{
                maxWidth: maxWidths.l,
                margin: "0 auto",
                padding: "40px",
                textAlign: "left",
            }}
        >
            <Typography variant="h5" gutterBottom>
                Frequently Asked Questions
            </Typography>

            {faqData.map((category) => (
                <Box key={category.category} sx={{ marginBottom: 4 }}>
                    <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                            marginBottom: 2,
                            fontWeight: "bold",
                        }}
                    >
                        {category.category}
                    </Typography>

                    {category.questions.map((faq) => (
                        <Accordion key={faq.id}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`${faq.id}-content`}
                                id={`${faq.id}-header`}
                            >
                                <Typography variant="h6">
                                    {faq.question}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography variant="body1">
                                    {faq.answer}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Box>
            ))}
        </Box>
    );
};
