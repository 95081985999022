import React, { useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    Typography,
    LinearProgress,
    Tooltip,
    IconButton,
    Collapse,
    Divider,
} from "@mui/material";
import { COLORS } from "constants/appWide/colors";
import { useNavigate } from "react-router-dom";

// Utility Functions
import { formatGameMode } from "utils/formatGameMode";
import { formatMatchType } from "utils/formatMatchType";
import { mapNameMapping } from "constants/mapNameMapping";
import { getElapsedTime } from "utils/getElapsedTime";

// Icons
import { mapIcons } from "constants/mapIcons";
import dinner from "assets/matchCardIcons/dinner.png";
import trophy from "assets/matchCardIcons/trophy.png";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { isSurvivor, isWinner } from "utils/matchIcons";
import { format } from "date-fns";
import { Match } from "types/match";
import { SessionStats } from "utils/calculateSessionStats";
import { ITEMS_PER_PAGE } from "pages/playerStats/RecentMatches";
import { metricIcons } from "constants/metricIcons";

interface MatchesTableProps {
    matches: Match[]; // Replace `any[]` with the correct type for matches if available
    playerName: string;
    platform: string;
    currentPage: number;
    viewType: "compactTable" | "detailedTable"; // Compact or detailed view
    sessionsView?: boolean; // New prop to toggle session view
    sessions?: { sessionId: number; matches: Match[] }[]; // Pass session data
    sessionStats: SessionStats[];
    sessionsOnly: boolean;
}

export const MatchesTable: React.FC<MatchesTableProps> = ({
    matches,
    playerName,
    platform,
    currentPage,
    viewType,
    sessionsView = false,
    sessions = [],
    sessionStats = [],
    sessionsOnly,
}) => {
    const navigate = useNavigate();

    const [expandedSession, setExpandedSession] = useState<number | null>(null);

    const toggleSessionRow = (sessionId: number) => {
        setExpandedSession((prev) => (prev === sessionId ? null : sessionId));
    };

    return (
        <TableContainer
            component={Paper}
            sx={{
                border: "1px solid #444",
                borderRadius: "8px",
                backgroundColor: "#2e2e2e",
                overflowX: "auto",
                maxWidth: "100%",
                margin: "0 auto",
            }}
        >
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Game Mode & Match Type</TableCell>
                        <TableCell>Map</TableCell>
                        <TableCell>Place</TableCell>
                        {viewType === "detailedTable" && (
                            <>
                                <TableCell>Kills</TableCell>
                                <TableCell>Damage</TableCell>
                                <TableCell>Team Kills</TableCell>
                                <TableCell>Team Damage</TableCell>
                                <TableCell>Survival Time</TableCell>
                            </>
                        )}
                        <TableCell>Awards</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sessionsOnly
                        ? // Render sessions only
                          sessions.map((session) => {
                              const sessionStat = sessionStats.find(
                                  (stat) => stat.sessionId === session.sessionId
                              );

                              return (
                                  <React.Fragment key={session.sessionId}>
                                      <TableRow>
                                          <TableCell
                                              colSpan={
                                                  viewType === "detailedTable"
                                                      ? 9
                                                      : 5
                                              } // Ensure it spans all columns
                                              sx={{
                                                  backgroundColor: "#444",
                                                  color: "#FFF",
                                                  fontWeight: "bold",
                                                  textAlign: "center",
                                                  padding: "16px",
                                              }}
                                          >
                                              <Box
                                                  sx={{
                                                      display: "flex",
                                                      justifyContent:
                                                          "space-between",
                                                      alignItems: "center",
                                                  }}
                                              >
                                                  <IconButton
                                                      onClick={() =>
                                                          toggleSessionRow(
                                                              session.sessionId
                                                          )
                                                      }
                                                  >
                                                      {expandedSession ===
                                                      session.sessionId ? (
                                                          <ExpandLessIcon />
                                                      ) : (
                                                          <ExpandMoreIcon />
                                                      )}
                                                  </IconButton>
                                                  <Typography
                                                      sx={{
                                                          fontWeight: "bold",
                                                          flex: 1,
                                                          textAlign: "left",
                                                      }}
                                                  >
                                                      {`Session ${session.sessionId}`}
                                                  </Typography>

                                                  {sessionStat && (
                                                      <Box
                                                          sx={{
                                                              display: "flex",
                                                              gap: 2,
                                                              flexWrap:
                                                                  "nowrap",
                                                              flex: 8,
                                                              justifyContent:
                                                                  "space-evenly",
                                                              "& > div": {
                                                                  textAlign:
                                                                      "center",
                                                              },
                                                          }}
                                                      >
                                                          <div>
                                                              <Typography
                                                                  variant="body2"
                                                                  color="#FFF"
                                                              >
                                                                  {`Occurred: ${sessionStat.startTime} to ${sessionStat.endTime} (${sessionStat.duration})`}
                                                              </Typography>
                                                          </div>
                                                          <div>
                                                              <Typography
                                                                  variant="body2"
                                                                  color="#FFF"
                                                              >
                                                                  {`Matches: ${sessionStat.matchesCount}`}
                                                              </Typography>
                                                          </div>
                                                          <div>
                                                              <Typography
                                                                  variant="body2"
                                                                  color="#FFF"
                                                              >
                                                                  {`Wins: ${sessionStat.totalWins} (${sessionStat.winsPercent}%)`}
                                                              </Typography>
                                                          </div>
                                                          {viewType ===
                                                              "detailedTable" && (
                                                              <>
                                                                  <div>
                                                                      <Typography
                                                                          variant="body2"
                                                                          color="#FFF"
                                                                      >
                                                                          {`Kills: ${sessionStat.totalKills}`}
                                                                      </Typography>
                                                                  </div>
                                                                  <div>
                                                                      <Typography
                                                                          variant="body2"
                                                                          color="#FFF"
                                                                      >
                                                                          {`Damage: ${sessionStat.totalDamage}`}
                                                                      </Typography>
                                                                  </div>
                                                                  <div>
                                                                      <Typography
                                                                          variant="body2"
                                                                          color="#FFF"
                                                                      >
                                                                          {`Survival Time: ${sessionStat.totalSurvivalTime}`}
                                                                      </Typography>
                                                                  </div>
                                                              </>
                                                          )}
                                                      </Box>
                                                  )}
                                              </Box>
                                          </TableCell>
                                      </TableRow>
                                  </React.Fragment>
                              );
                          })
                        : // Render matches
                          matches.map((match, index) => {
                              const globalIndex =
                                  index +
                                  1 +
                                  (currentPage - 1) * ITEMS_PER_PAGE;
                              const icon =
                                  mapIcons[mapNameMapping[match.mapName]];
                              // Find the session this match belongs to
                              const sessionForMatch = sessions.find((session) =>
                                  session.matches.some(
                                      (m) => m.matchId === match.matchId
                                  )
                              );

                              // Find the session stats for the identified session
                              const sessionStat = sessionForMatch
                                  ? sessionStats.find(
                                        (stat) =>
                                            stat.sessionId ===
                                            sessionForMatch.sessionId
                                    )
                                  : null;

                              // Check if the current match is the last match in its session
                              const isLastMatchInSession =
                                  sessionForMatch &&
                                  sessionForMatch.matches[
                                      sessionForMatch.matches.length - 1
                                  ].matchId === match.matchId;

                              return (
                                  <React.Fragment key={match.matchId}>
                                      {sessionsView &&
                                          isLastMatchInSession &&
                                          sessionStat && (
                                              <>
                                                  <TableRow>
                                                      <TableCell
                                                          colSpan={
                                                              viewType ===
                                                              "detailedTable"
                                                                  ? 9
                                                                  : 5
                                                          } // Ensure it spans all columns
                                                          sx={{
                                                              backgroundColor:
                                                                  "#444",
                                                              color: "#FFF",
                                                              fontWeight:
                                                                  "bold",
                                                              textAlign:
                                                                  "center",
                                                              padding: "16px", // Adjust padding for better alignment
                                                          }}
                                                      >
                                                          <Box
                                                              sx={{
                                                                  display:
                                                                      "flex",
                                                                  justifyContent:
                                                                      "space-between",
                                                                  alignItems:
                                                                      "center",
                                                              }}
                                                          >
                                                              <IconButton
                                                                  onClick={() =>
                                                                      toggleSessionRow(
                                                                          sessionStat.sessionId
                                                                      )
                                                                  }
                                                              >
                                                                  {expandedSession ===
                                                                  sessionStat.sessionId ? (
                                                                      <ExpandLessIcon />
                                                                  ) : (
                                                                      <ExpandMoreIcon />
                                                                  )}
                                                              </IconButton>
                                                              <Typography
                                                                  sx={{
                                                                      fontWeight:
                                                                          "bold",
                                                                      flex: 1,
                                                                      textAlign:
                                                                          "left",
                                                                  }}
                                                              >
                                                                  {`Session ${sessionStat.sessionId}`}
                                                              </Typography>

                                                              <Box
                                                                  sx={{
                                                                      display:
                                                                          "flex",
                                                                      gap: 2,
                                                                      flexWrap:
                                                                          "nowrap",
                                                                      flex: 8, // Take up the remaining space evenly
                                                                      justifyContent:
                                                                          "space-evenly", // Distribute evenly
                                                                      "& > div":
                                                                          {
                                                                              textAlign:
                                                                                  "center",
                                                                          },
                                                                  }}
                                                              >
                                                                  <div>
                                                                      <Typography
                                                                          variant="body2"
                                                                          color="#FFF"
                                                                      >
                                                                          {`Occurred: ${sessionStat.startTime} to ${sessionStat.endTime} (${sessionStat.duration})`}
                                                                      </Typography>
                                                                  </div>

                                                                  <div>
                                                                      <Typography
                                                                          variant="body2"
                                                                          color="#FFF"
                                                                      >
                                                                          {`Matches: ${sessionStat.matchesCount}`}
                                                                      </Typography>
                                                                  </div>
                                                                  <div>
                                                                      <Typography
                                                                          variant="body2"
                                                                          color="#FFF"
                                                                      >
                                                                          {`Wins: ${sessionStat.totalWins}  (${sessionStat.winsPercent}%)`}
                                                                      </Typography>
                                                                  </div>
                                                                  {viewType ===
                                                                      "detailedTable" && (
                                                                      <div>
                                                                          <Typography
                                                                              variant="body2"
                                                                              color="#FFF"
                                                                          >
                                                                              {`Kills: ${sessionStat.totalKills}`}
                                                                          </Typography>
                                                                      </div>
                                                                  )}
                                                                  {/* <div>
                                                        <Typography
                                                            variant="body2"
                                                            color="#FFF"
                                                        >
                                                            {`K/D: ${(
                                                                sessionForMatch.matches.reduce(
                                                                    (sum, m) =>
                                                                        sum +
                                                                        (m.playerKills ||
                                                                            0),
                                                                    0
                                                                ) /
                                                                sessionForMatch
                                                                    .matches
                                                                    .length
                                                            ).toFixed(2)}`}
                                                        </Typography>
                                                    </div> */}
                                                                  {viewType ===
                                                                      "detailedTable" && (
                                                                      <div>
                                                                          <Typography
                                                                              variant="body2"
                                                                              color="#FFF"
                                                                          >
                                                                              {`Damage: ${sessionStat.totalDamage}`}
                                                                          </Typography>
                                                                      </div>
                                                                  )}
                                                                  {viewType ===
                                                                      "detailedTable" && (
                                                                      <div>
                                                                          <Typography
                                                                              variant="body2"
                                                                              color="#FFF"
                                                                          >
                                                                              {`Survival Time: ${sessionStat.totalSurvivalTime}`}
                                                                          </Typography>
                                                                      </div>
                                                                  )}
                                                              </Box>
                                                          </Box>
                                                      </TableCell>
                                                  </TableRow>
                                                  {/* Collapsible Section */}
                                                  <TableRow>
                                                      <TableCell
                                                          colSpan={
                                                              viewType ===
                                                              "detailedTable"
                                                                  ? 9
                                                                  : 5
                                                          }
                                                          sx={{
                                                              padding: 0,
                                                          }}
                                                      >
                                                          <Collapse
                                                              in={
                                                                  expandedSession ===
                                                                  sessionStat.sessionId
                                                              }
                                                              timeout="auto"
                                                              unmountOnExit
                                                          >
                                                              <Box
                                                                  sx={{
                                                                      padding: 2,
                                                                      backgroundColor:
                                                                          "#333",
                                                                      borderRadius: 2,
                                                                      marginTop: 1,
                                                                  }}
                                                              >
                                                                  <TableContainer>
                                                                      <Table>
                                                                          <TableHead>
                                                                              <TableRow>
                                                                                  <TableCell>
                                                                                      Metric
                                                                                  </TableCell>
                                                                                  <TableCell align="right">
                                                                                      Amount
                                                                                  </TableCell>
                                                                                  <TableCell align="right">
                                                                                      My
                                                                                      %
                                                                                  </TableCell>
                                                                              </TableRow>
                                                                          </TableHead>
                                                                          <TableBody>
                                                                              {[
                                                                                  {
                                                                                      metric: "Match Durations",
                                                                                      icon: metricIcons[
                                                                                          "duration"
                                                                                      ],
                                                                                      amount: sessionStat.totalMatchesDuration,
                                                                                      percentage:
                                                                                          sessionStat.survivalPercent,
                                                                                  },
                                                                                  {
                                                                                      metric: "Our Damage",
                                                                                      icon: metricIcons[
                                                                                          "damage"
                                                                                      ],
                                                                                      amount: sessionStat.totalRosterDamage,
                                                                                      percentage: `${sessionStat.damagePercent}%`,
                                                                                  },
                                                                                  {
                                                                                      metric: "Our Kills",
                                                                                      icon: metricIcons[
                                                                                          "kills"
                                                                                      ],
                                                                                      amount: sessionStat.totalRosterKills,
                                                                                      percentage: `${sessionStat.killsPercent}%`,
                                                                                  },
                                                                              ].map(
                                                                                  (
                                                                                      row
                                                                                  ) => (
                                                                                      <TableRow
                                                                                          key={
                                                                                              row.metric
                                                                                          }
                                                                                      >
                                                                                          <TableCell
                                                                                              sx={{
                                                                                                  display:
                                                                                                      "flex",
                                                                                                  alignItems:
                                                                                                      "center",
                                                                                                  gap: 1,
                                                                                              }}
                                                                                          >
                                                                                              {
                                                                                                  row.icon
                                                                                              }
                                                                                              {
                                                                                                  row.metric
                                                                                              }
                                                                                          </TableCell>
                                                                                          <TableCell align="right">
                                                                                              {
                                                                                                  row.amount
                                                                                              }
                                                                                          </TableCell>
                                                                                          <TableCell align="right">
                                                                                              {
                                                                                                  row.percentage
                                                                                              }
                                                                                          </TableCell>
                                                                                      </TableRow>
                                                                                  )
                                                                              )}
                                                                          </TableBody>
                                                                      </Table>
                                                                  </TableContainer>
                                                              </Box>
                                                          </Collapse>
                                                      </TableCell>
                                                  </TableRow>
                                              </>
                                          )}

                                      <TableRow
                                          key={match.matchId}
                                          hover
                                          sx={{
                                              cursor: "pointer",
                                              "&:hover": {
                                                  backgroundColor: "#3e3e3e",
                                              },
                                          }}
                                          onClick={() =>
                                              navigate(
                                                  `/player-stats/${platform}/${encodeURIComponent(
                                                      playerName
                                                  )}/matches/${match.matchId}`
                                              )
                                          }
                                      >
                                          <TableCell
                                              sx={{
                                                  color: "white",
                                                  position: "relative",
                                              }}
                                          >
                                              <Typography
                                                  sx={{
                                                      position: "absolute",
                                                      top: "4px",
                                                      left: "4px",
                                                      fontSize: "12px",
                                                      color: COLORS.orange,
                                                  }}
                                              >
                                                  {globalIndex}
                                              </Typography>
                                              <Typography
                                                  sx={{ fontWeight: "bold" }}
                                              >{`${formatGameMode(
                                                  match.gameMode
                                              )} - ${formatMatchType(
                                                  match.matchType
                                              )}`}</Typography>
                                              <Tooltip
                                                  title={`Ended: ${format(
                                                      new Date(
                                                          new Date(
                                                              match.createdAt
                                                          ).getTime() +
                                                              match.duration *
                                                                  1000
                                                      ),
                                                      "PPpp" // Use date-fns formatting to show local time
                                                  )}`}
                                                  arrow
                                              >
                                                  <Typography
                                                      variant="body2"
                                                      sx={{
                                                          fontStyle: "italic",
                                                          color: "#bbb",
                                                      }}
                                                  >
                                                      {getElapsedTime(
                                                          match.createdAt,
                                                          match.duration
                                                      )}
                                                  </Typography>
                                              </Tooltip>
                                          </TableCell>
                                          <TableCell
                                              sx={{
                                                  color: "white",
                                                  height: "100%", // Ensure the cell takes full vertical space
                                                  padding: "16px", // Adjust padding to center content vertically
                                                  verticalAlign: "middle", // Ensure text is vertically centered without flex
                                              }}
                                          >
                                              <Box
                                                  sx={{
                                                      display: "inline-flex", // Use inline-flex to align content horizontally and respect vertical centering
                                                      alignItems: "center", // Vertically align icon and text
                                                      gap: 1, // Space between the text and icon
                                                  }}
                                              >
                                                  {
                                                      mapNameMapping[
                                                          match.mapName
                                                      ]
                                                  }
                                                  {icon &&
                                                      (typeof icon ===
                                                      "string" ? (
                                                          <img
                                                              src={icon}
                                                              alt={`${match.mapName} Icon`}
                                                              style={{
                                                                  width: "24px",
                                                                  height: "24px",
                                                                  objectFit:
                                                                      "contain",
                                                              }}
                                                          />
                                                      ) : (
                                                          React.createElement(
                                                              icon,
                                                              {
                                                                  sx: {
                                                                      width: 24,
                                                                      height: 24,
                                                                  },
                                                              }
                                                          )
                                                      ))}
                                              </Box>
                                          </TableCell>
                                          <TableCell>
                                              {match.playerWinPlace}
                                          </TableCell>
                                          {viewType === "detailedTable" && (
                                              <>
                                                  <TableCell>
                                                      {match.playerKills}
                                                  </TableCell>
                                                  <TableCell>
                                                      {match.playerDamage.toFixed(
                                                          0
                                                      )}
                                                  </TableCell>
                                                  <TableCell>
                                                      {match.playerRosterKills}
                                                  </TableCell>
                                                  <TableCell>
                                                      {match.playerRosterDamage.toFixed(
                                                          0
                                                      )}
                                                  </TableCell>
                                                  <TableCell>
                                                      <Box
                                                          sx={{
                                                              width: "100%",
                                                              display: "flex",
                                                              alignItems:
                                                                  "center",
                                                              gap: 1,
                                                          }}
                                                      >
                                                          <Typography
                                                              sx={{
                                                                  color: "white",
                                                                  whiteSpace:
                                                                      "nowrap", // Prevent wrapping
                                                              }}
                                                          >
                                                              {`${Math.floor(
                                                                  match.playerSurvivalTime /
                                                                      60
                                                              )}m ${
                                                                  match.playerSurvivalTime %
                                                                  60
                                                              }s`}
                                                          </Typography>
                                                          <LinearProgress
                                                              variant="determinate"
                                                              value={
                                                                  (match.playerSurvivalTime /
                                                                      match.duration) *
                                                                  100
                                                              }
                                                              sx={{
                                                                  width: "100%",
                                                                  height: 10,
                                                                  borderRadius: 5,
                                                                  backgroundColor:
                                                                      "#ddd",
                                                                  "& .MuiLinearProgress-bar":
                                                                      {
                                                                          backgroundColor:
                                                                              COLORS.green,
                                                                      },
                                                              }}
                                                          />
                                                          <Typography
                                                              sx={{
                                                                  color: "white",
                                                                  whiteSpace:
                                                                      "nowrap", // Prevent wrapping
                                                              }}
                                                          >
                                                              {`${Math.floor(
                                                                  match.duration /
                                                                      60
                                                              )}m ${
                                                                  match.duration %
                                                                  60
                                                              }s`}
                                                          </Typography>
                                                      </Box>
                                                  </TableCell>
                                              </>
                                          )}
                                          <TableCell>
                                              {isWinner(match) && (
                                                  <Tooltip
                                                      title={
                                                          [
                                                              "ibr",
                                                              "tdm",
                                                          ].includes(
                                                              match.gameMode
                                                          ) ||
                                                          match.matchType ===
                                                              "airoyale"
                                                              ? "You won! - Intense Battle Royale / Team Deathmatch / Casual"
                                                              : "Winner Winner Chicken Dinner!"
                                                      }
                                                      arrow
                                                  >
                                                      <img
                                                          src={
                                                              [
                                                                  "ibr",
                                                                  "tdm",
                                                              ].includes(
                                                                  match.gameMode
                                                              ) ||
                                                              match.matchType ===
                                                                  "airoyale"
                                                                  ? trophy
                                                                  : dinner
                                                          }
                                                          alt={
                                                              [
                                                                  "ibr",
                                                                  "tdm",
                                                              ].includes(
                                                                  match.gameMode
                                                              ) ||
                                                              match.matchType ===
                                                                  "airoyale"
                                                                  ? "Trophy Icon"
                                                                  : "Chicken Dinner Icon"
                                                          }
                                                          style={{
                                                              width: "32px",
                                                              height: "32px",
                                                          }}
                                                      />
                                                  </Tooltip>
                                              )}
                                              {isSurvivor(match) && (
                                                  <Tooltip
                                                      title="Survived until the last 15 seconds!"
                                                      arrow
                                                  >
                                                      <VerifiedUserIcon
                                                          sx={{
                                                              fontSize: 32,
                                                              color: COLORS.green,
                                                          }}
                                                      />
                                                  </Tooltip>
                                              )}
                                          </TableCell>
                                      </TableRow>
                                  </React.Fragment>
                              );
                          })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default MatchesTable;
