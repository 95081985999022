import { mapsUrlAndSize } from "constants/mapsUrlAndSize";

export const nonEsportsMaps = [
    {
        name: "Deston",
        url: mapsUrlAndSize["Deston"].url,
        size: mapsUrlAndSize["Deston"].size,
    },
    {
        name: "Vikendi",
        url: mapsUrlAndSize["Vikendi"].url,
        size: mapsUrlAndSize["Vikendi"].size,
    },
];
