import { API_ENDPOINTS } from "constants/API_ENDPOINTS";
import { useNavigate } from "react-router-dom";

/**
 * Hook to handle player search and navigation.
 * Fetches data then navigates to the player profile page.
 *
 * @returns A function to execute the player search.
 */
export const usePlayerSearch = () => {
    const navigate = useNavigate();

    const handleSearch = async (
        playerName: string,
        platform: string
    ): Promise<void> => {
        if (!playerName.trim()) {
            alert("Player name cannot be empty.");
            return;
        }

        try {
            const response = await fetch(
                API_ENDPOINTS.ACCOUNT_DATA(platform, playerName)
            );

            if (!response.ok) {
                throw new Error("Failed to fetch player data");
            }

            const accountData = await response.json();

            // Navigate to profile with player and platform details
            navigate(
                `/player-stats/${platform}/${encodeURIComponent(playerName)}`
            );
        } catch (error) {
            throw error;
        }
    };

    return handleSearch;
};
