import React, { useEffect } from "react";
import {
    Box,
    Typography,
    Paper,
    ListItemText,
    ListItem,
    List,
} from "@mui/material";
import { versionHistory } from "constants/versionHistory";
import { COLORS } from "constants/appWide/colors";
import { maxWidths } from "constants/appWide/maxWidths";

const VersionHistory: React.FC = () => {
    return (
        <Box
            sx={{
                padding: "20px",
                maxWidth: maxWidths.m,
                margin: "0 auto",
                textAlign: "center",
            }}
        >
            <Typography
                variant="h6"
                sx={{
                    color: COLORS.orange,
                    marginBottom: "20px",
                    fontWeight: "bold",
                }}
            >
                Upcoming Features
            </Typography>
            <Paper
                elevation={3}
                sx={{
                    marginBottom: "20px",
                    textAlign: "left",
                }}
            >
                <List>
                    <ListItem>
                        <ListItemText primary="- Release login and account system to production. Currently fully implemented but still testing." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="- Detailed match analysis using telemetry data." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="- Comprehensive esports match insights." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="- Making completely custom made maps to adhere to a single design standard." />
                    </ListItem>

                    <ListItem>
                        <ListItemText primary="- Integration of official PUBG Leaderboards." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="- Custom leaderboards featuring user-generated data." />
                    </ListItem>
                </List>
            </Paper>
            {/* Page Header */}
            <Typography variant="h5" gutterBottom>
                Version History
            </Typography>

            {/* Render Versions */}
            {versionHistory.map((version, index) => (
                <Paper
                    key={index}
                    elevation={3}
                    sx={{
                        padding: "16px",
                        marginBottom: "16px",
                        textAlign: "left",
                    }}
                >
                    {/* Version Title */}
                    <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                        {version.version}
                    </Typography>
                    {/* Version Description */}
                    <Typography
                        variant="body1"
                        sx={{
                            whiteSpace: "pre-line",
                        }}
                    >
                        {version.description}
                    </Typography>
                </Paper>
            ))}
        </Box>
    );
};

export default VersionHistory;
