import { mapsUrlAndSize } from "constants/mapsUrlAndSize";

export const esportsMaps = [
    {
        name: "Erangel",
        url: mapsUrlAndSize["Erangel"].url,
        size: mapsUrlAndSize["Erangel"].size,
    },
    {
        name: "Miramar",
        url: mapsUrlAndSize["Miramar"].url,
        size: mapsUrlAndSize["Miramar"].size,
    },
    {
        name: "Taego",
        url: mapsUrlAndSize["Taego"].url,
        size: mapsUrlAndSize["Taego"].size,
    },
    {
        name: "Rondo",
        url: mapsUrlAndSize["Rondo"].url,
        size: mapsUrlAndSize["Rondo"].size,
    },
];
