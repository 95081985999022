import React from "react";
import { Box, Typography } from "@mui/material";

interface BanStatusProps {
    banType: "PermanentBan" | "TemporaryBan" | "Innocent" | null;
}

const COLORS = {
    red: "#FF0000",
    orange: "#FFA500",
    green: "#008000",
};

const BanStatus: React.FC<BanStatusProps> = ({ banType }) => {
    if (!banType) return null;

    const styles = {
        maxWidth: "100%",
        textAlign: "center" as const,
        padding: banType === "Innocent" ? 1 : 2,
        borderRadius: 2,
        color: "#fff",
        backgroundColor:
            banType === "PermanentBan"
                ? COLORS.red
                : banType === "TemporaryBan"
                ? COLORS.orange
                : COLORS.green,
        boxShadow:
            banType === "PermanentBan" || banType === "TemporaryBan"
                ? "0px 4px 10px rgba(0, 0, 0, 0.3)"
                : "0px 2px 5px rgba(0, 0, 0, 0.2)",
        border:
            banType === "PermanentBan" || banType === "TemporaryBan"
                ? "2px solid black"
                : "none",
        fontSize: banType === "Innocent" ? "0.85rem" : "1rem",
    };

    const message =
        banType === "PermanentBan"
            ? "⚠️ Permanent Ban"
            : banType === "TemporaryBan"
            ? "⚠️ Temporary Ban"
            : "✅ No Active Ban";

    return (
        <Box sx={styles}>
            <Typography
                variant={banType === "Innocent" ? "body2" : "h5"}
                sx={{
                    fontWeight: banType === "Innocent" ? "normal" : "bold",
                }}
            >
                {message}
            </Typography>
        </Box>
    );
};

export default BanStatus;
