import { Box, Typography } from "@mui/material";
import { COLORS } from "constants/appWide/colors";
import { motion } from "framer-motion";

export const MapSection: React.FC<{
    title: string;
    maps: { name: string; url: string; size: number; players?: number }[];
    onMapSelect: (map: { name: string; url: string; size: number }) => void;
}> = ({ title, maps, onMapSelect }) => (
    <>
        <Typography variant="h6" sx={{ marginTop: "20px" }}>
            {title}
        </Typography>
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                gap: "20px",
                marginTop: "20px",
            }}
        >
            {maps.map((map) => {
                // Generate the low-resolution URL from the high-resolution one

                return (
                    <motion.div whileHover={{ scale: 1.1 }} key={map.name}>
                        <Box
                            onClick={() => onMapSelect(map)}
                            sx={{
                                width: "300px",
                                height: "300px",
                                border: "3px solid orange",
                                borderRadius: "10px",
                                overflow: "hidden",
                                cursor: "pointer",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "flex-end",
                                position: "relative",
                                backgroundColor: "#fff",
                                "&:hover": {
                                    boxShadow: "0 4px 15px rgba(0,0,0,0.2)",
                                },
                            }}
                        >
                            {/* Use the low-resolution URL for the thumbnail */}
                            <Box
                                component="img"
                                src={map.url}
                                alt={map.name}
                                sx={{
                                    width: "100%",
                                    height: "calc(100% - 30px)",
                                    objectFit: "cover",
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                }}
                            />
                            {map.players && (
                                <Box
                                    sx={{
                                        backgroundColor: COLORS.orange,
                                        color: COLORS.black,
                                        padding: "5px",
                                        textAlign: "center",
                                        fontWeight: "bold",
                                        position: "absolute", // Absolutely positioned
                                        top: "5px", // Slight padding from the top
                                        right: "5px", // Slight padding from the left
                                        zIndex: 2, // Above the image
                                        borderRadius: "5px", // Rounded corner for aesthetics
                                    }}
                                >
                                    {/* Probability */}
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {`${map.players} players`}
                                    </Typography>
                                </Box>
                            )}
                            <Box
                                sx={{
                                    backgroundColor: COLORS.orange,
                                    color: COLORS.black,
                                    padding: "5px",
                                    textAlign: "center",
                                    fontWeight: "bold",
                                    position: "relative",
                                    zIndex: 1,
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    paddingInline: "10px",
                                }}
                            >
                                <Typography
                                    variant="body1"
                                    sx={{
                                        overflow: "hidden",
                                        whiteSpace: "nowrap",
                                        textOverflow: "ellipsis",
                                    }}
                                >
                                    {map.name}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    sx={{
                                        fontWeight: "bold",
                                    }}
                                >
                                    {`${map.size}x${map.size} km`}
                                </Typography>
                            </Box>
                        </Box>
                    </motion.div>
                );
            })}
        </Box>
    </>
);
