import { useEffect, useState } from "react";

interface QuickActionPlayer {
    playerName: string;
    platform: string;
}

export const useQuickActionPlayer = (
    localStorageKey: string = "quickActionPlayer"
) => {
    const [quickActionPlayer, setQuickActionPlayer] =
        useState<QuickActionPlayer | null>(null);

    useEffect(() => {
        const savedQuickActionPlayer = localStorage.getItem(localStorageKey);
        if (savedQuickActionPlayer) {
            try {
                const parsedPlayer: QuickActionPlayer = JSON.parse(
                    savedQuickActionPlayer
                );
                setQuickActionPlayer(parsedPlayer);
            } catch (error) {
                console.error("Error parsing quick action player:", error);
            }
        }
    }, [localStorageKey]);

    return [quickActionPlayer, setQuickActionPlayer] as const;
};
