import boardwalkDetail from "assets/tdmMaps/boardwalkDetail.png";
import pillarCompoundDetail from "assets/tdmMaps/pillarCompoundDetail.jpg";
import palaceDetail from "assets/tdmMaps/palaceDetail.jpg";
import marketDetail from "assets/tdmMaps/marketDetail.jpg";
import mountainDetail from "assets/tdmMaps/mountainDetail.jpg";
import docksDetail from "assets/tdmMaps/docksDetail.jpg";
import paradiseResortDetail from "assets/tdmMaps/paradiseResortDetail.jpg";
import lianaDetail from "assets/tdmMaps/lianaDetail.jpg";
import warehouseDetail from "assets/tdmMaps/warehouseDetail.jpg";
import rinJiangDetail from "assets/tdmMaps/rinJiangDetail.jpg";
import ruinsDetail from "assets/tdmMaps/ruinsDetail.jpg";

export const tdmMaps = [
    { name: "Palace", parentMap: "Taego", image: palaceDetail },
    { name: "Market", parentMap: "Taego", image: marketDetail },
    { name: "Mountain", parentMap: "Taego", image: mountainDetail },
    { name: "Docks", parentMap: "Sanhok", image: docksDetail },
    {
        name: "Paradise Resort",
        parentMap: "Sanhok",
        image: paradiseResortDetail,
    },
    { name: "Boardwalk", parentMap: "Near Deston", image: boardwalkDetail },
    { name: "Pillar Compound", parentMap: "", image: pillarCompoundDetail },
    { name: "Liana", parentMap: "Near Riviera", image: lianaDetail },
    { name: "Warehouse", parentMap: "Rondo", image: warehouseDetail },
    { name: "Rin Jiang", parentMap: "Rondo", image: rinJiangDetail },
    { name: "Ruins", parentMap: "Erangel", image: ruinsDetail },
];
