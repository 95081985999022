import { match } from "assert";

const API_BASE_URL = process.env.REACT_APP_API_URL;

export const API_ENDPOINTS = {
    // My own API

    // Submit feedback
    SUBMIT_FEEDBACK: `${API_BASE_URL}/api/submit-feedback`,

    // Account Settings
    USER_PROFILE: `${API_BASE_URL}/api/user/profile`,
    CHANGE_PASSWORD: `${API_BASE_URL}/change-password`,
    DELETE_ACCOUNT: `${API_BASE_URL}/api/user/delete-account`,

    // Tier List
    SAVE_TIER_LIST: `${API_BASE_URL}/api/tier-list`,
    TIER_LISTS: `${API_BASE_URL}/api/tier-lists/public`,
    GET_TIER_LIST: (id: string) => `${API_BASE_URL}/api/tier-list/${id}`,

    // Saved Players
    SAVED_PLAYERS: `${API_BASE_URL}/saved-players`,
    REMOVE_PLAYER: `${API_BASE_URL}/remove-player`,
    SAVE_PLAYER: `${API_BASE_URL}/save-player`,

    // Saved Matches
    SAVE_MATCH: `${API_BASE_URL}/save-match`,
    SAVED_MATCHES: `${API_BASE_URL}/saved-matches`,
    REMOVE_MATCH: `${API_BASE_URL}/remove-match`,

    // PUBG API
    ACCOUNT_DATA: (platform: string, playerName: string) =>
        `${API_BASE_URL}/api/account/${platform}/${playerName}`,

    SURVIVAL_MASTERY: (platform: string, accountId: string) =>
        `${API_BASE_URL}/api/survival-mastery/${platform}/${accountId}`,

    CLAN: (platform: string, clanId: string) =>
        `${API_BASE_URL}/api/clans/${platform}/${clanId}`,

    MATCHES: (platform: string, accountId: string) =>
        `${API_BASE_URL}/api/players/${platform}/${accountId}/matches`,

    MATCH: (matchId: string) => `${API_BASE_URL}/api/matches/${matchId}`,

    MATCH_DETAILS: (platform: string, matchId: string) =>
        `${API_BASE_URL}/api/match/${platform}/${matchId}`,

    WEAPON_MASTERY: (platform: string, accountId: string) =>
        `${API_BASE_URL}/api/weapon-mastery/${platform}/${accountId}`,

    LIFETIME: (platform: string, accountId: string) =>
        `${API_BASE_URL}/api/players/${platform}/${accountId}/lifetime`,

    SEASON_LIST: (platform: string) =>
        `${API_BASE_URL}/api/seasons/${platform}`,

    SEASON_STATS: (
        platform: string,
        accountId: string,
        selectedSeasonId: string,
        selectedStatType: string
    ) =>
        `${API_BASE_URL}/api/players/${platform}/${accountId}/seasons/${selectedSeasonId}?ranked=${
            selectedStatType === "ranked"
        }`,

    // Third Party API
    PUBG_REPORT: (accountId: string) =>
        `${API_BASE_URL}/api/pubg-clips/${accountId}`,

    PLAYER_COUNT: `${API_BASE_URL}/steam-players`,
};
