import { formatWeapon } from "utils/weapons/formatWeapon";

export const processLogPlayerKillV2 = (logPlayerKillEvents: any[]) => {
    const killTree: {
        [killer: string]: {
            victim: string;
            distance: number;
            weapon: string;
        }[];
    } = {};

    const allPlayers = new Set<string>();
    const victims = new Set<string>();

    logPlayerKillEvents.forEach((event) => {
        if (event.finishDamageInfo?.damageTypeCategory === "Damage_BlueZone") {
            // Skip blue zone deaths
            return;
        }

        const killer = event.killer?.name || "Unknown";
        const victim = event.victim?.name || "Unknown";
        const distance = event.killerDamageInfo?.distance || 0;

        const weapon = formatWeapon(event.killerDamageInfo?.damageCauserName);

        if (!killTree[killer]) killTree[killer] = [];
        killTree[killer].push({ victim, distance, weapon });

        // Track players
        allPlayers.add(killer);
        allPlayers.add(victim);
        victims.add(victim);
    });

    // Remaining players are those in allPlayers but not in victims
    const remainingPlayers = Array.from(allPlayers).filter(
        (player) => !victims.has(player)
    );

    return { killTree, remainingPlayers };
};
