import React, { useEffect, useState } from "react";
import { Box, Typography, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { patches } from "constants/patches";
import { API_ENDPOINTS } from "constants/API_ENDPOINTS";

export const PublicTierLists: React.FC = () => {
    const [tierLists, setTierLists] = useState<
        { id: string; name: string; createdAt: string; createdBy?: string }[]
    >([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchTierLists = async () => {
            try {
                const response = await fetch(API_ENDPOINTS.TIER_LISTS);
                if (response.ok) {
                    const data = await response.json();
                    // Map `_id` to `id` for consistency
                    const mappedData = data.map((tierList: any) => ({
                        id: tierList._id,
                        name: tierList.name,
                        createdAt: tierList.createdAt,
                        createdBy: tierList.createdBy || "Anonymous",
                    }));
                    setTierLists(mappedData);
                } else {
                    console.error("Failed to fetch public tier lists");
                }
            } catch (error) {
                console.error("Error fetching public tier lists:", error);
            }
        };

        fetchTierLists();
    }, []);

    const handleTierListClick = (id: string) => {
        if (!id) {
            console.error("Invalid tier list ID");
            return;
        }
        navigate(`/tier-lists/maker/${id}`);
    };

    const findPatch = (createdAt: string) => {
        const createdDate = new Date(createdAt);

        // Parse the patch dates into Date objects and sort in descending order
        const sortedPatches = [...patches].sort(
            (a, b) =>
                new Date(b.date.replace(/\./g, "-")).getTime() -
                new Date(a.date.replace(/\./g, "-")).getTime()
        );

        // Find the most recent patch that is older than or equal to the created date
        const patch = sortedPatches.find(
            (p) => createdDate >= new Date(p.date.replace(/\./g, "-"))
        );

        return patch ? patch.name : "Unknown Patch";
    };

    return (
        <Box
            sx={{
                padding: "20px",
                textAlign: "center",
                maxWidth: 1400,
                margin: "0 auto",
            }}
        >
            <Typography variant="h4" gutterBottom>
                Public Tier Lists
            </Typography>
            {tierLists.length === 0 ? (
                <Typography variant="body1">
                    No public tier lists available.
                </Typography>
            ) : (
                tierLists.map((tierList) => (
                    <Paper
                        key={tierList.id}
                        elevation={3}
                        sx={{
                            padding: "10px",
                            margin: "10px 0",
                            textAlign: "left",
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            // "&:hover": {
                            //     backgroundColor: "#f5f5f5",
                            // },
                        }}
                        onClick={() => handleTierListClick(tierList.id)}
                    >
                        {/* Left side */}
                        <Box>
                            <Typography variant="h6">
                                {tierList.name}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                {findPatch(tierList.createdAt)}
                            </Typography>
                        </Box>

                        {/* Right side */}
                        <Box sx={{ textAlign: "right" }}>
                            <Typography variant="body2">
                                Created By: {tierList.createdBy}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                {new Date(
                                    tierList.createdAt
                                ).toLocaleDateString()}
                            </Typography>
                        </Box>
                    </Paper>
                ))
            )}
        </Box>
    );
};
