export const isWinner = (match: any): boolean => {
    return (
        match.matchType !== "training" &&
        match.matchType !== "trainingroom" &&
        match.playerWinPlace === 1
    );
};

export const isSurvivor = (match: any): boolean => {
    return (
        match.matchType !== "tdm" &&
        match.matchType !== "training" &&
        match.matchType !== "trainingroom" &&
        match.playerSurvivalTime >= match.duration - 15
    );
};
