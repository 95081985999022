import { API_ENDPOINTS } from "constants/API_ENDPOINTS";
import { useState, useEffect } from "react";
import { SurvivalMastery } from "types/survivalMastery";

export const useSurvivalMastery = (
    accountId: string | null,
    platform: string
) => {
    const [survivalMastery, setSurvivalMastery] =
        useState<SurvivalMastery | null>(null);
    const [survivalLoading, setSurvivalLoading] = useState<boolean>(false);
    const [survivalError, setSurvivalError] = useState<string | null>(null);

    useEffect(() => {
        if (!accountId) {
            console.log(
                "[DEBUG] accountId is null, skipping loadSurvivalMastery."
            );
            return;
        }

        const loadSurvivalMastery = async () => {
            try {
                const survivalResponse = await fetch(
                    API_ENDPOINTS.SURVIVAL_MASTERY(platform, accountId)
                );

                if (!survivalResponse.ok) {
                    throw new Error("Failed to fetch survival mastery data");
                }

                const survivalData = await survivalResponse.json();
                console.log(
                    "[DEBUG] Survival mastery data fetched:",
                    survivalData
                );

                setSurvivalMastery(survivalData);
            } catch (err) {
                console.error("[DEBUG] Error fetching survival mastery:", err);
                setSurvivalError((err as Error).message);
            } finally {
                setSurvivalLoading(false);
            }
        };

        console.log("[DEBUG] Starting loadSurvivalMastery...");
        loadSurvivalMastery();
    }, [accountId, platform]);

    return { survivalMastery, survivalLoading, survivalError };
};
