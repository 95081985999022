import React, { useEffect, useState } from "react";
import {
    Typography,
    Box,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    TextField,
    Button,
} from "@mui/material";
import { fetchAccountId } from "../../../api/fetchAccountId";
import { lifetimeFieldLabels } from "constants/lifetimeFieldLabels";
import { useNavigate, useParams } from "react-router-dom";
import { PopularPlayersModal } from "components/PopularPlayersModal";

import Tooltip from "@mui/material/Tooltip";

// MUI Icons
import PersonIcon from "@mui/icons-material/Person";
import VisibilityIcon from "@mui/icons-material/Visibility";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import VideoCameraBackIcon from "@mui/icons-material/VideoCameraBack";

import ReactGA from "react-ga4";
import { COLORS } from "constants/appWide/colors";
import FunnyLoading from "components/FunnyLoading";
import { maxWidths } from "constants/appWide/maxWidths";
import { useSavedPlayers } from "hooks/useSavedPlayers";
import { useAuth } from "AuthContext";
import { SavedPlayersDropdown } from "components/SavedPlayersDropdown";
import { getPlatformLogo } from "utils/getPlatformLogo";
import PlayerTag from "components/PlayerTag";
import BanStatus from "components/BanStatus";
import { useClanInfo } from "hooks/pubgApi/useClanInfo";
import { useSurvivalMastery } from "hooks/pubgApi/useSurvivalMastery";
import { useAccountData } from "hooks/pubgApi/useAccountData";
import { API_ENDPOINTS } from "constants/API_ENDPOINTS";

const ORDERED_GAME_MODES = [
    "solo-fpp",
    "solo",
    "duo-fpp",
    "duo",
    "squad-fpp",
    "squad",
];

const CATEGORIES = [
    "All",
    "Placement / Kills",
    "Distance",
    "Damage / Survival",
    "Support / Actions",
    "Resource Usage",
    "Other",
];

interface LifetimeStat {
    [key: string]: number | string;
}

const CATEGORY_MAP: { [key: string]: string[] } = {
    All: [
        "roundsPlayed",
        "wins",
        "top10s",
        "kills",
        "headshotKills",
        "roundMostKills",
        "maxKillStreaks",
        "teamKills",
        "walkDistance",
        "rideDistance",
        "swimDistance",
        "longestKill",
        "damageDealt",
        "timeSurvived",
        "longestTimeSurvived",
        "assists",
        "revives",
        "dBNOs",
        "vehicleDestroys",
        "roadKills",
        "boosts",
        "heals",
        "weaponsAcquired",
        "days",
        "dailyKills",
        "dailyWins",
        "weeklyKills",
        "weeklyWins",
        "suicides",
    ],
    "Placement / Kills": [
        "roundsPlayed",
        "wins",
        "top10s",
        "kills",
        "headshotKills",
        "roundMostKills",
        "maxKillStreaks",
        "teamKills",
    ],
    Distance: ["walkDistance", "rideDistance", "swimDistance", "longestKill"],
    "Damage / Survival": ["damageDealt", "timeSurvived", "longestTimeSurvived"],
    "Support / Actions": [
        "assists",
        "revives",
        "dBNOs",
        "vehicleDestroys",
        "roadKills",
    ],
    "Resource Usage": ["boosts", "heals", "weaponsAcquired"],
    Other: [
        "days",
        "dailyKills",
        "dailyWins",
        "weeklyKills",
        "weeklyWins",
        "suicides",
    ],
};

const infoTooltips: { [key: string]: string } = {
    "K/D Ratio":
        "Kills-to-Deaths Ratio. Won matches do not count as deaths. Calculated as: Kills / (Matches Played - Wins).",
    "K/M Ratio":
        "Kills-to-Matches Ratio. Calculated as: Kills / Matches Played.",
    "Days Played": "Total days spent in matches.",
    teamKills: "The number of teammates you killed.",
    longestKill:
        "Bullets despawn, and enemies are not rendered beyond 1000m. Kills over 1000m are likely achieved with the mortar.",
    maxKillStreaks:
        "Exact definition of a kill streak is unclear. Will confirm with the PUBG Developer Discord.",
};

const formatStat = (key: string, value: number | null | undefined): string => {
    if (value == null) {
        // Handle null or undefined values gracefully
        return "N/A"; // Replace "N/A" with any placeholder you prefer
    }

    if (["walkDistance", "rideDistance", "swimDistance"].includes(key)) {
        return `${Math.round(value / 1000)} km`; // Whole kilometers
    }
    if (key === "longestKill") {
        return `${Math.round(value)} m`; // Whole meters
    }
    if (key === "damageDealt") {
        return `${(value / 1000).toFixed(1)}k`; // Thousands with one decimal
    }
    if (key === "timeSurvived") {
        return `${(value / 3600).toFixed(1)} hours`; // Hours with one decimal
    }
    if (["longestTimeSurvived", "mostSurvivalTime"].includes(key)) {
        return `${(value / 60).toFixed(1)} mins`; // Minutes with one decimal
    }
    return value.toString();
};

const renderGameModeIcons = (mode: string) => {
    if (mode.includes("solo")) {
        return <PersonIcon />;
    }
    if (mode.includes("duo")) {
        return (
            <>
                <PersonIcon />
                <PersonIcon />
            </>
        );
    }
    if (mode.includes("squad")) {
        return (
            <>
                <PersonIcon />
                <PersonIcon />
                <PersonIcon />
                <PersonIcon />
            </>
        );
    }
};

const renderPerspectiveIcon = (mode: string) => {
    if (mode.includes("fpp")) {
        return <VisibilityIcon />;
    }
    if (mode.includes("tpp")) {
        return (
            <>
                <VideoCameraBackIcon />
            </>
        );
    }
};

const calculatePercentage = (part: number, total: number): string => {
    if (total === 0) return "(0%)";
    return `(${((part / total) * 100).toFixed(1)}%)`;
};

const LifetimeStats: React.FC = () => {
    const navigate = useNavigate();
    // Search parameters
    const { platform = "steam", playerName = "Unknown Player" } = useParams<{
        platform?: string;
        playerName?: string;
    }>();
    const { player2Name: player2NameFromUrl } = useParams<{
        player2Name?: string;
    }>();

    const [lifetimeStats, setLifetimeStats] = useState<any>(null);
    const [player2Name, setPlayer2Name] = useState<string>(
        player2NameFromUrl || ""
    );

    // Update state when URL changes (handle when users navigate back or forward)
    useEffect(() => {
        setPlayer2Name(player2NameFromUrl || "");
    }, [player2NameFromUrl]);
    // Prevent the existing player2Name from being overwritten by the new search box text
    const [searchInput, setSearchInput] = useState<string>("");
    const [player2Stats, setPlayer2Stats] = useState<any>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    const [modalOpen, setModalOpen] = useState(false);
    const [triggerFetch, setTriggerFetch] = useState(false);

    const { isAuthenticated } = useAuth();
    const { savedPlayers, loadingSavedPlayers } =
        useSavedPlayers(isAuthenticated);

    const { accountId, clanId, banType, accountLoading, accountError } =
        useAccountData(platform, playerName);
    // Using Banner Hooks
    const { clanInfo, clanLoading, clanError } = useClanInfo(clanId, platform);

    const { survivalMastery, survivalLoading, survivalError } =
        useSurvivalMastery(accountId, platform);

    const handleSelectPredefinedPlayer = (player: string) => {
        setPlayer2Name(player);
        setTriggerFetch(true);
        setModalOpen(false);
    };

    const handleSelectPlayer = () => {
        setPlayer2Name(searchInput);
        setTriggerFetch(true);
        setSearchInput("");
    };

    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: "/player-stats/lifetime",
            title: "Lifetime Stats Page",
        });
    }, []);

    // Effect to fetch stats after player2Name is updated
    useEffect(() => {
        if (triggerFetch && player2Name) {
            handleAddPlayer();
            setTriggerFetch(false); // Reset the trigger
        }
    }, [triggerFetch, player2Name]); // Dependencies include trigger and player2Name

    // Filters
    const [selectedCategory, setSelectedCategory] =
        useState<string>("Placement / Kills");
    const [perspectiveFilter, setPerspectiveFilter] = useState<string>("All");
    const [teamFilter, setTeamFilter] = useState<string>("All");

    // Normalize modes for TPP
    const EXTENDED_GAME_MODES = ORDERED_GAME_MODES.flatMap((mode) => {
        if (mode.includes("fpp")) return [mode]; // Keep FPP modes
        return [`${mode}-tpp`]; // Add only TPP variant
    });

    const filteredGameModes = EXTENDED_GAME_MODES.filter((mode) => {
        const isFppMode = mode.includes("fpp");
        const isTppMode = mode.includes("tpp");
        const matchesPerspective =
            perspectiveFilter === "All" ||
            (perspectiveFilter === "FPP" && isFppMode) ||
            (perspectiveFilter === "TPP" && isTppMode);
        const matchesTeam =
            teamFilter === "All" || mode.startsWith(teamFilter.toLowerCase());
        return matchesPerspective && matchesTeam;
    });

    const handleClearPlayer2 = () => {
        setPlayer2Name("");
        setPlayer2Stats(null);
        navigate(`/player-stats/${platform}/${playerName}/lifetime`, {
            replace: true, // Remove compare from the URL
        });
    };

    const fetchLifetimeStats = async (
        playerName: string,
        platform: string
    ): Promise<any> => {
        // Fetch account details
        console.log(`Fetching new data for ${playerName}`);
        const accountData = await fetchAccountId(playerName, platform);
        if (!accountData || !accountData.accountId) {
            throw new Error(`Player ${playerName} not found`);
        }

        // Fetch lifetime stats
        const response = await fetch(
            API_ENDPOINTS.LIFETIME(platform, accountData.accountId)
        );
        if (!response.ok) {
            throw new Error(`Failed to fetch stats for ${playerName}`);
        }

        const statsData = await response.json();
        const gameModeStats = statsData.data.attributes.gameModeStats;

        return gameModeStats;
    };

    useEffect(() => {
        const loadPlayerStats = async () => {
            if (!playerName) {
                setError("Player name is missing in the URL.");
                setLoading(false);
                return;
            }

            setLoading(true);
            try {
                const stats = await fetchLifetimeStats(playerName, platform);
                setLifetimeStats(stats);

                if (player2NameFromUrl) {
                    const stats2 = await fetchLifetimeStats(
                        player2NameFromUrl,
                        platform
                    );
                    setPlayer2Stats(stats2);
                }
            } catch (err) {
                setError((err as Error).message);
            } finally {
                setLoading(false);
            }
        };

        loadPlayerStats();
    }, [playerName, player2NameFromUrl]);

    const handleAddPlayer = async () => {
        try {
            const stats2 = await fetchLifetimeStats(player2Name, platform);
            setPlayer2Stats(stats2);

            // Update the URL with the second player's name
            const currentPath = location.pathname; // Get the current path
            const newPath = `${currentPath}/compare/${encodeURIComponent(
                player2Name || ""
            )}`;
            navigate(newPath, { replace: true }); // Update the URL without navigating
        } catch (err) {
            setError((err as Error).message);
        }
    };

    const renderCumulativeStats = () => {
        if (!lifetimeStats) return null;

        // Filter and accumulate stats for Player 1
        const cumulativePlayer1Stats: { [key: string]: number } = (
            Object.values(lifetimeStats) as any[]
        )
            .filter((modeStats: any) => {
                const isFpp =
                    perspectiveFilter === "FPP" &&
                    modeStats?.mode?.includes("fpp");
                const isTpp =
                    perspectiveFilter === "TPP" &&
                    modeStats?.mode?.includes("tpp");
                const isSolo =
                    teamFilter === "Solo" && modeStats?.mode?.includes("solo");
                const isDuo =
                    teamFilter === "Duo" && modeStats?.mode?.includes("duo");
                const isSquad =
                    teamFilter === "Squad" &&
                    modeStats?.mode?.includes("squad");

                if (perspectiveFilter !== "All" && !isFpp && !isTpp)
                    return false;
                if (teamFilter !== "All" && !isSolo && !isDuo && !isSquad)
                    return false;

                return true;
            })
            .reduce((acc: any, modeStats: any) => {
                for (const statKey in modeStats) {
                    if (typeof modeStats[statKey] === "number") {
                        if (
                            [
                                "roundMostKills",
                                "maxKillStreaks",
                                "longestKill",
                                "longestTimeSurvived",
                            ].includes(statKey)
                        ) {
                            acc[statKey] = Math.max(
                                acc[statKey] || 0,
                                modeStats[statKey]
                            );
                        } else {
                            acc[statKey] =
                                (acc[statKey] || 0) + modeStats[statKey];
                        }
                    }
                }
                return acc;
            }, {});

        // Filter and accumulate stats for Player 2
        const cumulativePlayer2Stats: { [key: string]: number } =
            player2Stats &&
            Object.values(player2Stats)
                .filter((modeStats: any) => {
                    const isFpp =
                        perspectiveFilter === "FPP" &&
                        modeStats?.mode?.includes("fpp");
                    const isTpp =
                        perspectiveFilter === "TPP" &&
                        modeStats?.mode?.includes("tpp");
                    const isSolo =
                        teamFilter === "Solo" &&
                        modeStats?.mode?.includes("solo");
                    const isDuo =
                        teamFilter === "Duo" &&
                        modeStats?.mode?.includes("duo");
                    const isSquad =
                        teamFilter === "Squad" &&
                        modeStats?.mode?.includes("squad");

                    if (perspectiveFilter !== "All" && !isFpp && !isTpp)
                        return false;
                    if (teamFilter !== "All" && !isSolo && !isDuo && !isSquad)
                        return false;

                    return true;
                })
                .reduce((acc: any, modeStats: any) => {
                    for (const statKey in modeStats) {
                        if (typeof modeStats[statKey] === "number") {
                            if (
                                [
                                    "roundMostKills",
                                    "maxKillStreaks",
                                    "longestKill",
                                    "longestTimeSurvived",
                                ].includes(statKey)
                            ) {
                                acc[statKey] = Math.max(
                                    acc[statKey] || 0,
                                    modeStats[statKey]
                                );
                            } else {
                                acc[statKey] =
                                    (acc[statKey] || 0) + modeStats[statKey];
                            }
                        }
                    }
                    return acc;
                }, {});

        // Helper to calculate ratios with two decimals
        const calculateRatio = (
            kills: number,
            roundsPlayed: number,
            wins: number = 0
        ) =>
            (roundsPlayed - wins > 0
                ? kills / (roundsPlayed - wins)
                : 0
            ).toFixed(2);

        const calculateKillsPerMatch = (kills: number, roundsPlayed: number) =>
            (roundsPlayed > 0 ? kills / roundsPlayed : 0).toFixed(2);

        const player1KDRatio = parseFloat(
            calculateRatio(
                cumulativePlayer1Stats.kills || 0,
                cumulativePlayer1Stats.roundsPlayed || 0,
                cumulativePlayer1Stats.wins || 0
            )
        );

        const player1KMRatio = parseFloat(
            calculateKillsPerMatch(
                cumulativePlayer1Stats.kills || 0,
                cumulativePlayer1Stats.roundsPlayed || 0
            )
        );

        const player2KDRatio =
            player2Stats &&
            parseFloat(
                calculateRatio(
                    cumulativePlayer2Stats?.kills || 0,
                    cumulativePlayer2Stats?.roundsPlayed || 0,
                    cumulativePlayer2Stats?.wins || 0
                )
            );

        const player2KMRatio =
            player2Stats &&
            parseFloat(
                calculateKillsPerMatch(
                    cumulativePlayer2Stats?.kills || 0,
                    cumulativePlayer2Stats?.roundsPlayed || 0
                )
            );

        return (
            <Box
                sx={{
                    border: `8px solid orange`,
                    borderRadius: "8px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                    padding: 2,
                    transition: "transform 0.2s",
                    "&:hover": {
                        transform: "scale(1.02)",
                    },
                }}
            >
                <Typography
                    variant="h6"
                    component="div"
                    textAlign="center"
                    sx={{
                        backgroundColor: COLORS.orange,
                        color: "black",
                        padding: "8px 0",
                        borderRadius: "6px 6px 0 0",
                        alignItems: "center",
                    }}
                >
                    CUMULATIVE STATS
                </Typography>

                <Box
                    sx={{
                        overflowX: "auto", // Enable horizontal scrolling
                        width: "100%", // Ensure the container takes full width
                    }}
                >
                    <Box
                        component="table"
                        sx={{
                            minWidth: player2Name ? "520px" : "100%",
                            width: "100%", // Allow the table to expand
                        }}
                    >
                        <thead>
                            <tr
                                style={{
                                    backgroundColor: "#e0e0e0",
                                    color: "#333",
                                    fontWeight: "bold",
                                }}
                            >
                                <th
                                    style={{
                                        textAlign: "left",
                                        padding: "8px",
                                        width: "400px",
                                    }}
                                >
                                    Data Point
                                </th>
                                <th
                                    style={{
                                        textAlign: "left",
                                        padding: "8px",
                                    }}
                                >
                                    {playerName}
                                </th>
                                {player2Name && player2Stats && (
                                    <>
                                        <th
                                            style={{
                                                textAlign: "left",
                                                padding: "8px",
                                            }}
                                        >
                                            {player2Name}
                                        </th>
                                        <th
                                            style={{
                                                textAlign: "left",
                                                padding: "8px",
                                            }}
                                        >
                                            Difference
                                        </th>
                                    </>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {CATEGORY_MAP[selectedCategory].map((statKey) => {
                                const player1Value =
                                    cumulativePlayer1Stats?.[statKey] || 0;
                                const player2Value =
                                    cumulativePlayer2Stats?.[statKey];

                                return renderTableRow(
                                    statKey,
                                    player1Value,
                                    player2Value
                                );
                            })}

                            {/* Add K/D and K/M Ratios */}
                            {selectedCategory === "Placement / Kills" && (
                                <>
                                    {renderTableRow(
                                        "K/D Ratio",
                                        player1KDRatio,
                                        player2Stats
                                            ? player2KDRatio
                                            : undefined // Only pass if player2Stats exists
                                    )}
                                    {renderTableRow(
                                        "K/M Ratio",
                                        player1KMRatio,
                                        player2Stats
                                            ? player2KMRatio
                                            : undefined // Only pass if player2Stats exists
                                    )}
                                </>
                            )}
                        </tbody>
                    </Box>
                </Box>
            </Box>
        );
    };

    const renderTableRow = (
        statKey: string,
        player1Value: number,
        player2Value?: number,
        basePlayer1?: number,
        basePlayer2?: number
    ) => {
        const difference = player2Value ? player2Value - player1Value : 0;

        const formatStat = (key: string, value: number): string => {
            if (
                ["walkDistance", "rideDistance", "swimDistance"].includes(key)
            ) {
                return `${Math.round(value / 1000).toLocaleString()} km`; // Whole kilometers
            }
            if (key === "longestKill") {
                return `${Math.round(value).toLocaleString()} m`; // Whole meters
            }
            if (key === "damageDealt") {
                return Math.round(value).toLocaleString(); // No decimals
            }
            if (key === "timeSurvived") {
                return `${Math.round(value / 3600).toLocaleString()} hours`; // Whole hours
            }
            if (["longestTimeSurvived", "mostSurvivalTime"].includes(key)) {
                return `${Math.round(value / 60).toLocaleString()} mins`; // Whole minutes
            }
            return value.toLocaleString(); // Default formatting for other numeric values
        };

        const formattedDifference = formatStat(statKey, difference);

        const percentageDifference =
            basePlayer1 && basePlayer2
                ? ((player2Value || 0) / basePlayer2) * 100 -
                  (player1Value / basePlayer1) * 100
                : 0;

        const valueDifferenceColor = difference > 0 ? "green" : "red";
        const percentageDifferenceColor =
            percentageDifference > 0 ? "green" : "red";

        return (
            <tr key={statKey}>
                <td style={{ textAlign: "left" }}>
                    <span>
                        {lifetimeFieldLabels[statKey] || statKey}{" "}
                        {infoTooltips[statKey] && (
                            <Tooltip title={infoTooltips[statKey]} arrow>
                                <InfoOutlinedIcon
                                    sx={{
                                        fontSize: "16px",
                                        verticalAlign: "middle",
                                        marginLeft: "4px",
                                        color: "#888",
                                        cursor: "pointer",
                                    }}
                                />
                            </Tooltip>
                        )}
                    </span>
                </td>
                <td style={{ textAlign: "left" }}>
                    {formatStat(statKey, player1Value)}{" "}
                    {basePlayer1 !== undefined &&
                        calculatePercentage(player1Value, basePlayer1)}
                </td>
                {player2Value !== undefined ? (
                    <>
                        <td style={{ textAlign: "left" }}>
                            {formatStat(statKey, player2Value)}{" "}
                            {basePlayer2 !== undefined &&
                                calculatePercentage(player2Value, basePlayer2)}
                        </td>
                        <td style={{ textAlign: "left" }}>
                            <span style={{ color: valueDifferenceColor }}>
                                {difference > 0 ? "+" : ""}
                                {formattedDifference}
                            </span>{" "}
                            <span style={{ color: percentageDifferenceColor }}>
                                {basePlayer1 && basePlayer2
                                    ? `(${percentageDifference.toFixed(1)}%)`
                                    : ""}
                            </span>
                        </td>
                    </>
                ) : null}
            </tr>
        );
    };

    if (
        loading ||
        accountLoading ||
        clanLoading ||
        survivalLoading ||
        !survivalMastery
    ) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center">
                <FunnyLoading />
            </Box>
        );
    }

    if (error) {
        return (
            <Typography color="error" textAlign="center">
                {error}
            </Typography>
        );
    }

    return (
        <Box
            sx={{
                maxWidth: maxWidths.l,
                margin: "0 auto",
            }}
        >
            <Box
                sx={{
                    display: "grid",
                    gridTemplateColumns: { xs: "1fr", md: "2fr 1fr" },
                    gap: 2,
                    alignItems: "start",
                    marginBottom: 2,
                    maxWidth: "100%",
                }}
            >
                {/* Left Column: Title and Filters */}
                <Box>
                    {/* Title and Clear Player 2 Button */}
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        {/* Left Section: Page Title and Banner */}
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: { xs: "column", sm: "row" },
                                alignItems: "center",
                                justifyContent: "space-between",
                                alignContent: "center",
                                gap: 2, // Gap between banner and favorite icon
                                margin: "0 auto",
                                border: "2px solid #444",
                                borderRadius: "8px",
                                padding: "16px",
                                backgroundColor: "#2e2e2e",
                                marginTop: "16px",
                                marginBottom: "24px",
                                width: "100%",
                            }}
                        >
                            {/* Banner Section */}
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: { xs: "column", sm: "row" },
                                    alignItems: "center",
                                    gap: 2, // Add spacing between items
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",

                                        gap: 1,
                                    }}
                                >
                                    <img
                                        src={getPlatformLogo(platform)}
                                        alt={`${platform} logo`}
                                        style={{ width: 26, height: 26 }}
                                    />
                                    <Typography variant="h6">
                                        Lifetime Stats for {playerName}
                                        {player2Name && ` vs ${player2Name}`}
                                    </Typography>
                                </Box>
                                {/* Player Tag */}
                                <PlayerTag
                                    playerName={playerName!}
                                    survivalTier={survivalMastery?.tier}
                                    survivalLevel={survivalMastery?.level}
                                    clanInfo={clanInfo}
                                />{" "}
                            </Box>
                            <BanStatus banType={banType} />
                            {player2Name && (
                                <Button
                                    variant="outlined"
                                    color="error"
                                    onClick={handleClearPlayer2}
                                >
                                    Clear Player 2
                                </Button>
                            )}
                        </Box>
                    </Box>

                    {/* Filters Section */}
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column", // Stack filters vertically
                            gap: 2, // Add spacing between items
                            alignItems: "flex-start", // Left-align items
                        }}
                    >
                        {/* Perspective and Team Type Filters */}
                        <Box
                            sx={{
                                display: "flex",
                                gap: 2, // Spacing between dropdowns
                            }}
                        >
                            <FormControl
                                variant="outlined"
                                size="small"
                                sx={{ minWidth: 120 }}
                            >
                                <InputLabel id="perspective-select-label">
                                    Perspective
                                </InputLabel>
                                <Select
                                    labelId="perspective-select-label"
                                    value={perspectiveFilter}
                                    onChange={(e) =>
                                        setPerspectiveFilter(e.target.value)
                                    }
                                    label="Perspective"
                                >
                                    <MenuItem value="All">
                                        <Box
                                            display="flex"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            width="100%"
                                        >
                                            Both
                                        </Box>
                                    </MenuItem>
                                    <MenuItem value="FPP">
                                        <Box
                                            display="flex"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            width="100%"
                                        >
                                            FPP
                                            <VisibilityIcon />
                                        </Box>
                                    </MenuItem>
                                    <MenuItem value="TPP">
                                        <Box
                                            display="flex"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            width="100%"
                                        >
                                            TPP
                                            <Box display="flex" gap={0.5}>
                                                <VideoCameraBackIcon />
                                            </Box>
                                        </Box>
                                    </MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl
                                variant="outlined"
                                size="small"
                                sx={{ minWidth: 210 }}
                            >
                                <InputLabel id="team-select-label">
                                    Team Type
                                </InputLabel>
                                <Select
                                    labelId="team-select-label"
                                    value={teamFilter}
                                    onChange={(e) =>
                                        setTeamFilter(e.target.value)
                                    }
                                    label="Team Type"
                                >
                                    <MenuItem value="All">
                                        <Box
                                            display="flex"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            width="100%"
                                        >
                                            All
                                        </Box>
                                    </MenuItem>
                                    <MenuItem value="Solo">
                                        <Box
                                            display="flex"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            width="100%"
                                        >
                                            Solo
                                            <PersonIcon />
                                        </Box>
                                    </MenuItem>
                                    <MenuItem value="Duo">
                                        <Box
                                            display="flex"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            width="100%"
                                        >
                                            Duo
                                            <Box display="flex" gap={0.5}>
                                                <PersonIcon />
                                                <PersonIcon />
                                            </Box>
                                        </Box>
                                    </MenuItem>
                                    <MenuItem value="Squad">
                                        <Box
                                            display="flex"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            width="100%"
                                        >
                                            Squad
                                            <Box display="flex" gap={0.5}>
                                                <PersonIcon />
                                                <PersonIcon />
                                                <PersonIcon />
                                                <PersonIcon />
                                            </Box>
                                        </Box>
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Box>

                        {/* Stats Category Filter */}
                        <FormControl
                            variant="outlined"
                            size="small"
                            sx={{ minWidth: 200, marginTop: 1 }}
                        >
                            <InputLabel id="category-select-label">
                                Stats Category
                            </InputLabel>
                            <Select
                                labelId="category-select-label"
                                value={selectedCategory}
                                onChange={(e) =>
                                    setSelectedCategory(e.target.value)
                                }
                                label="Stats Category"
                            >
                                {CATEGORIES.map((category) => (
                                    <MenuItem key={category} value={category}>
                                        {category}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </Box>

                {/* Right Column: Search and Compare Buttons */}
                <Box
                    sx={{
                        border: "2px solid #ccc",
                        borderRadius: "8px",
                        padding: "16px",
                        maxWidth: 380,
                        marginTop: "16px",
                    }}
                >
                    <Box
                        sx={{
                            marginTop: -1,
                            marginBottom: 1,
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <Typography
                            variant="caption"
                            color="textSecondary"
                            sx={{
                                display: "flex", // Use flexbox for the text and icon
                                alignItems: "center", // Vertically align the text and icon
                                gap: 0.5, // Add spacing between text and icon
                            }}
                        >
                            * The player you compare with must be on
                            <img
                                src={getPlatformLogo(platform)}
                                alt={`${platform} logo`}
                                style={{
                                    width: 16,
                                    height: 16,
                                }}
                            />
                        </Typography>
                    </Box>

                    <Box
                        display="flex"
                        gap={2}
                        alignItems="center"
                        marginBottom={1}
                        sx={{
                            flexWrap: "nowrap", // Prevent wrapping
                        }}
                    >
                        {/* Text */}
                        <Typography variant="body1">Compare With</Typography>

                        {/* Saved Players Dropdown */}
                        <Box sx={{ flex: 1, minWidth: 200 }}>
                            <SavedPlayersDropdown
                                savedPlayers={savedPlayers}
                                loading={loadingSavedPlayers}
                                onSelect={(playerName) => {
                                    setPlayer2Name(playerName);
                                    setTriggerFetch(true);
                                }}
                                currentSelection={null} // No default selection
                                platform={platform}
                            />
                        </Box>
                    </Box>
                    {/* Horizontal Line with OR */}
                    <Box
                        sx={{
                            position: "relative",
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <Box
                            sx={{
                                height: "1px",
                                backgroundColor: "#ccc",
                                flex: 1,
                            }}
                        />
                        <Typography
                            variant="body2"
                            sx={{
                                zIndex: 1,
                                marginX: 1,
                            }}
                        >
                            OR
                        </Typography>
                        <Box
                            sx={{
                                height: "1px",
                                backgroundColor: "#ccc",
                                flex: 1,
                            }}
                        />
                    </Box>
                    {/* Search Player Section */}
                    <Box
                        display="flex"
                        gap={2}
                        marginTop={1}
                        marginBottom={1}
                        sx={{
                            flexWrap: "nowrap", // Prevent wrapping
                        }}
                    >
                        <Button
                            variant="contained"
                            sx={{ backgroundColor: COLORS.blue }}
                            onClick={handleSelectPlayer}
                        >
                            Compare With
                        </Button>
                        <TextField
                            label="Player Name"
                            variant="outlined"
                            size="small"
                            value={searchInput}
                            onChange={(e) => setSearchInput(e.target.value)}
                            sx={{
                                flex: 1, // Allow TextField to grow while leaving space for the button
                                minWidth: "0", // Ensure it doesn't shrink below its content
                            }}
                        />
                    </Box>
                    {platform === "steam" && (
                        <>
                            <Box
                                sx={{
                                    position: "relative",
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <Box
                                    sx={{
                                        height: "1px",
                                        backgroundColor: "#ccc",
                                        flex: 1,
                                    }}
                                />
                                <Typography
                                    variant="body2"
                                    sx={{
                                        zIndex: 1,
                                        marginX: 1,
                                    }}
                                >
                                    OR
                                </Typography>
                                <Box
                                    sx={{
                                        height: "1px",
                                        backgroundColor: "#ccc",
                                        flex: 1,
                                    }}
                                />
                            </Box>

                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: COLORS.green,
                                    marginTop: 1,
                                }}
                                onClick={() => setModalOpen(true)}
                                fullWidth
                            >
                                Compare with Popular Players
                            </Button>
                        </>
                    )}
                </Box>
                {/* Modal */}
                <PopularPlayersModal
                    open={modalOpen}
                    onClose={() => setModalOpen(false)}
                    onSelectPlayer={handleSelectPredefinedPlayer}
                />
            </Box>

            {/* Cumulative Stats */}
            {perspectiveFilter === "All" && teamFilter === "All" && (
                <Box marginBottom={4}>{renderCumulativeStats()}</Box>
            )}

            {/* Game Mode Stats */}
            <Box
                display="grid"
                gridTemplateColumns={{ xs: "1fr", md: "repeat(2, 1fr)" }} // 1 column on mobile, 2 columns on desktop
                gap={4} // Add spacing between rows/columns
            >
                {filteredGameModes.map((mode) => {
                    const normalizedMode = mode.replace("-tpp", "");

                    const stats = lifetimeStats[normalizedMode];
                    const stats2 = player2Stats?.[normalizedMode];

                    return (
                        <Box
                            key={mode}
                            sx={{
                                border: `8px solid ${
                                    mode.includes("fpp")
                                        ? COLORS.green
                                        : COLORS.blue
                                }`,
                                borderRadius: "8px",
                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                                padding: 2,
                                transition: "transform 0.2s",
                                "&:hover": {
                                    transform: "scale(1.02)",
                                },
                                minWidth: "100%",
                            }}
                        >
                            <Typography
                                variant="h6"
                                component="div"
                                textAlign="center"
                                sx={{
                                    backgroundColor: mode.includes("fpp")
                                        ? COLORS.green
                                        : COLORS.blue,
                                    color: "white",
                                    padding: "8px 0",
                                    borderRadius: "6px 6px 0 0",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                {/* Left Icon */}
                                <Box sx={{ marginLeft: 1 }}>
                                    {renderGameModeIcons(mode)}
                                </Box>

                                {/* Header Text */}
                                <span
                                    style={{ flexGrow: 1, textAlign: "center" }}
                                >
                                    {mode.replace("-", " ").toUpperCase()}
                                </span>

                                {/* Right Icon */}
                                <Box sx={{ marginRight: 1 }}>
                                    {renderPerspectiveIcon(mode)}
                                </Box>
                            </Typography>

                            {(!stats || stats.roundsPlayed === 0) &&
                            (!stats2 || stats2.roundsPlayed === 0) ? (
                                <Typography
                                    textAlign="center"
                                    sx={{
                                        color: "#757575",
                                        fontStyle: "italic",
                                        padding: "16px 0",
                                    }}
                                >
                                    No data available for this game mode.
                                </Typography>
                            ) : (
                                <Box
                                    sx={{
                                        overflowX: "auto", // Enable horizontal scrolling
                                        width: "100%", // Ensure the container takes full width
                                    }}
                                >
                                    <Box
                                        component="table"
                                        sx={{
                                            minWidth: player2Name
                                                ? "520px"
                                                : "100%",
                                            width: "100%", // Allow the table to expand
                                        }}
                                    >
                                        <thead>
                                            <tr
                                                style={{
                                                    backgroundColor: "#e0e0e0",
                                                    color: "#333",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                <th
                                                    style={{
                                                        textAlign: "left",
                                                        padding: "8px",
                                                    }}
                                                >
                                                    Data Point
                                                </th>

                                                <th
                                                    style={{
                                                        textAlign: "left",
                                                        padding: "8px",
                                                    }}
                                                >
                                                    {playerName}
                                                </th>
                                                {stats2 && (
                                                    <th
                                                        style={{
                                                            textAlign: "left",
                                                            padding: "8px",
                                                        }}
                                                    >
                                                        {player2Name}
                                                    </th>
                                                )}
                                                {stats2 && (
                                                    <th
                                                        style={{
                                                            textAlign: "left",
                                                            padding: "8px",
                                                        }}
                                                    >
                                                        Difference
                                                    </th>
                                                )}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {CATEGORY_MAP[selectedCategory].map(
                                                (statKey) => {
                                                    const base1 =
                                                        statKey === "wins" ||
                                                        statKey === "top10s"
                                                            ? Number(
                                                                  stats?.roundsPlayed ||
                                                                      0
                                                              )
                                                            : statKey ===
                                                              "headshotKills"
                                                            ? Number(
                                                                  stats?.kills ||
                                                                      0
                                                              )
                                                            : undefined;
                                                    const base2 =
                                                        statKey === "wins" ||
                                                        statKey === "top10s"
                                                            ? Number(
                                                                  stats2?.roundsPlayed ||
                                                                      0
                                                              )
                                                            : statKey ===
                                                              "headshotKills"
                                                            ? Number(
                                                                  stats2?.kills ||
                                                                      0
                                                              )
                                                            : undefined;

                                                    return renderTableRow(
                                                        statKey,
                                                        Number(
                                                            stats?.[statKey] ||
                                                                0
                                                        ),
                                                        stats2
                                                            ? Number(
                                                                  stats2?.[
                                                                      statKey
                                                                  ] || 0
                                                              )
                                                            : undefined,
                                                        base1,
                                                        base2
                                                    );
                                                }
                                            )}

                                            {/* Add K/D and K/M for Placement/Kills category */}
                                            {selectedCategory ===
                                                "Placement / Kills" && (
                                                <>
                                                    {renderTableRow(
                                                        "K/D Ratio",
                                                        parseFloat(
                                                            (
                                                                stats?.kills /
                                                                Math.max(
                                                                    1,
                                                                    stats?.roundsPlayed -
                                                                        stats?.wins
                                                                )
                                                            ).toFixed(2)
                                                        ),
                                                        stats2
                                                            ? parseFloat(
                                                                  (
                                                                      stats2?.kills /
                                                                      Math.max(
                                                                          1,
                                                                          stats2?.roundsPlayed -
                                                                              stats2?.wins
                                                                      )
                                                                  ).toFixed(2)
                                                              )
                                                            : undefined
                                                    )}
                                                    {renderTableRow(
                                                        "K/M Ratio",
                                                        parseFloat(
                                                            (
                                                                stats?.kills /
                                                                Math.max(
                                                                    1,
                                                                    stats?.roundsPlayed
                                                                )
                                                            ).toFixed(2)
                                                        ),
                                                        stats2
                                                            ? parseFloat(
                                                                  (
                                                                      stats2?.kills /
                                                                      Math.max(
                                                                          1,
                                                                          stats2?.roundsPlayed
                                                                      )
                                                                  ).toFixed(2)
                                                              )
                                                            : undefined
                                                    )}
                                                </>
                                            )}
                                        </tbody>
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    );
                })}
            </Box>
        </Box>
    );
};

export default LifetimeStats;
