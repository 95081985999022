import steamLogo from "assets/platformLogos/steam.png";
import psnLogo from "assets/platformLogos/psn.png";
import xboxLogo from "assets/platformLogos/xbox.png";

// Function to get platform-specific logo
export const getPlatformLogo = (platform: string): string => {
    switch (platform) {
        case "steam":
            return steamLogo;
        case "psn":
            return psnLogo;
        case "xbox":
            return xboxLogo;
        default:
            return "";
    }
};
