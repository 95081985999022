import React, { useEffect, useState } from "react";
import {
    Box,
    Typography,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableHead,
    Paper,
    Button,
    IconButton,
    useTheme,
    Grid,
    Card,
    CardContent,
    Divider,
    useMediaQuery,
} from "@mui/material";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import MatchCard from "components/MatchesView/MatchCard";
import { Match } from "types/match";
import { enrichMatchData } from "utils/enrichMatchData";

// Google Analytics
import ReactGA from "react-ga4";

/// Heart Icons
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import StarIcon from "@mui/icons-material/Star";
import { useAuth } from "AuthContext";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import { addSeconds, format } from "date-fns";
import { COLORS } from "constants/appWide/colors";
import { useTelemetry } from "hooks/useTelemetry";
import KillTree from "components/trees/KillTree";
import { processLogPlayerKillV2 } from "utils/telemetryProcessing/processLogPlayerKillV2";
import { processLogMatchEnd } from "utils/telemetryProcessing/processLogMatchEnd";
import { WeaponCard } from "components/WeaponCard";
import { getWeaponMetadata } from "utils/weapons/getWeaponMetaData";
import FunnyLoading from "components/FunnyLoading";
import { weatherMap } from "constants/weatherMap";
import { API_ENDPOINTS } from "constants/API_ENDPOINTS";
import { maxWidths } from "constants/appWide/maxWidths";
import PlayerTag from "components/PlayerTag";
import { getPlatformLogo } from "utils/getPlatformLogo";
import BanStatus from "components/BanStatus";
import { useSurvivalMastery } from "hooks/pubgApi/useSurvivalMastery";
import { useClanInfo } from "hooks/pubgApi/useClanInfo";
import { useAccountData } from "hooks/pubgApi/useAccountData";

const MatchDetails: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const {
        platform = "steam",
        playerName = "Unknown Player",
        matchId,
    } = useParams<{
        platform?: string;
        playerName?: string;
        matchId?: string;
    }>();

    const { accountId, clanId, banType, accountLoading, accountError } =
        useAccountData(platform, playerName);

    // Using Banner Hooks
    const { clanInfo, clanLoading, clanError } = useClanInfo(clanId, platform);

    const { survivalMastery, survivalLoading, survivalError } =
        useSurvivalMastery(accountId, platform);

    const [matchRegion, setMatchRegion] = useState<string | null>(null);
    const [weather, setWeather] = useState<string | null>(null);
    const weatherInfo = weather
        ? weatherMap[weather]
        : { label: "Unknown", emoji: "❓" };

    const [matchData, setMatchData] = useState<Match | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    // Save Match
    const [isSaved, setIsSaved] = useState(false);
    const { isAuthenticated } = useAuth();

    const favoriteGameMode = localStorage.getItem("favoriteGameMode") || "";

    const [rosterSortField, setRosterSortField] = useState<
        "rank" | "totalKills" | "totalDamage"
    >("rank");
    const [rosterSortOrder, setRosterSortOrder] = useState<"asc" | "desc">(
        "asc"
    );

    const [participantSortField, setParticipantSortField] = useState<
        "winPlace" | "kills" | "damageDealt"
    >("winPlace");
    const [participantSortOrder, setParticipantSortOrder] = useState<
        "asc" | "desc"
    >("asc");

    // Telemetry Data: LogMatchEnd
    const [playerRankings, setPlayerRankings] = useState<
        {
            name: string;
            accountId: string;
            ranking: number;
            individualRanking: number;
            primaryWeaponFirst?: string;
            primaryWeaponSecond?: string;
        }[]
    >([]);
    const [botList, setBotList] = useState<
        {
            name: string;
            accountId: string;
            ranking: number;
            individualRanking: number;
        }[]
    >([]);

    // Telemetry Data: LogPlayerKillV2
    const [killTree, setKillTree] = useState<{
        [killer: string]: {
            victim: string;
            distance: number;
            weapon: string;
        }[];
    }>({});
    const [remainingPlayers, setRemainingPlayers] = useState<string[]>([]);

    // Get Telemetry
    const {
        telemetryData,
        loading: telemetryLoading,
        error: telemetryError,
    } = useTelemetry(matchId!, platform);

    useEffect(() => {
        if (telemetryData) {
            // LogMatchEnd
            const matchEndEvent = telemetryData.find(
                (event) => event._T === "LogMatchEnd"
            );

            if (matchEndEvent) {
                const { playerRankings, botList } =
                    processLogMatchEnd(matchEndEvent);
                setPlayerRankings(playerRankings);
                setBotList(botList);
            }

            // Extract LogPlayerKillV2 events
            const logPlayerKillEvents = telemetryData.filter(
                (event) => event._T === "LogPlayerKillV2"
            );

            const { killTree, remainingPlayers } =
                processLogPlayerKillV2(logPlayerKillEvents);
            setKillTree(killTree);
            setRemainingPlayers(remainingPlayers);

            // Extract Region
            const logMatchDefinitionEvent = telemetryData.find(
                (event) => event._T === "LogMatchDefinition"
            );

            if (logMatchDefinitionEvent) {
                // Extract the MatchId
                const matchId = logMatchDefinitionEvent.MatchId;

                // Extract the region (third value when splitting by '.')
                const matchRegion = matchId.split(".")[6]; // "as" in this case

                // Set the region
                setMatchRegion(matchRegion.toUpperCase());
            }

            // Extract Weather
            const matchStartEvent = telemetryData.find(
                (event) => event._T === "LogMatchStart"
            );

            if (matchStartEvent && matchStartEvent.weatherId) {
                setWeather(matchStartEvent.weatherId);
            }
        }
    }, [telemetryData]);

    // Filter winning players (ranking === 1)
    const winningPlayers = playerRankings.filter(
        (player) => player.ranking === 1
    );

    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: "/player-stats/matches/",
            title: "Match Details Page",
        });
    }, []);

    // Fetch match details
    useEffect(() => {
        if (!matchId || !platform || !accountId) return;
        const fetchMatchDetails = async () => {
            setLoading(true);
            setError(null);

            try {
                const matchResponse = await fetch(
                    API_ENDPOINTS.MATCH_DETAILS(platform, matchId)
                );

                if (!matchResponse.ok) {
                    throw new Error(
                        `Failed to fetch details for match ID: ${matchId}`
                    );
                }

                const matchData = await matchResponse.json();

                const enrichedData = enrichMatchData(
                    matchData,
                    accountId,
                    playerName
                );

                setMatchData(enrichedData);
            } catch (err) {
                setError(
                    (err as Error).message ||
                        "An error occurred while fetching match details."
                );
            } finally {
                setLoading(false);
            }
        };

        fetchMatchDetails();
    }, [matchId, platform, playerName, accountId]);

    const checkIfMatchSaved = async () => {
        try {
            const token = localStorage.getItem("authToken");
            const response = await fetch(API_ENDPOINTS.SAVED_MATCHES, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error("Failed to fetch saved matches");
            }

            const data = await response.json();
            const matchSaved = data.matches.some(
                (match: { matchId: string; platform: string }) =>
                    match.matchId === matchId && match.platform === platform
            );

            setIsSaved(matchSaved);
        } catch (error) {
            console.error("Error checking saved match:", error);
        }
    };

    const handleToggleSave = async () => {
        try {
            const token = localStorage.getItem("authToken");

            if (isSaved) {
                const response = await fetch(API_ENDPOINTS.REMOVE_MATCH, {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({ matchId, platform }),
                });

                if (!response.ok) {
                    throw new Error("Failed to remove match");
                }

                setIsSaved(false);
            } else {
                const response = await fetch(API_ENDPOINTS.SAVE_MATCH, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        matchId,
                        platform,
                        matchData,
                        playerName,
                    }),
                });

                if (!response.ok) {
                    throw new Error("Failed to save match");
                }

                setIsSaved(true);
            }
        } catch (error) {
            console.error("Error toggling save:", error);
            alert("An error occurred while toggling the saved state.");
        }
    };

    useEffect(() => {
        if (isAuthenticated) {
            checkIfMatchSaved();
        }
    }, [isAuthenticated, matchId, platform]);

    const handleRosterSort = (field: "rank" | "totalKills" | "totalDamage") => {
        if (rosterSortField === field) {
            setRosterSortOrder(rosterSortOrder === "asc" ? "desc" : "asc");
        } else {
            setRosterSortField(field);
            setRosterSortOrder("asc");
        }
    };

    const handleParticipantSort = (
        field: "winPlace" | "kills" | "damageDealt"
    ) => {
        if (participantSortField === field) {
            setParticipantSortOrder(
                participantSortOrder === "asc" ? "desc" : "asc"
            );
        } else {
            setParticipantSortField(field);
            setParticipantSortOrder("asc");
        }
    };

    const sortData = <T,>(
        data: T[],
        field: keyof T,
        order: "asc" | "desc"
    ): T[] => {
        return [...data].sort((a, b) => {
            const aValue = a[field] as number;
            const bValue = b[field] as number;

            return order === "asc" ? aValue - bValue : bValue - aValue;
        });
    };

    if (
        loading ||
        accountLoading ||
        clanLoading ||
        survivalLoading ||
        !survivalMastery
    ) {
        return <FunnyLoading />;
    }

    if (error)
        return (
            <Typography color="error" variant="body1">
                {error}
            </Typography>
        );

    if (!matchData) return null;

    return (
        <Box
            sx={{
                maxWidth: maxWidths.l,
                margin: "0 auto",
                display: "flex",
                flexDirection: "column",
                gap: 3,
            }}
        >
            <Button
                variant="contained"
                sx={{
                    mt: 2,

                    backgroundColor: COLORS.orange,
                    fontWeight: "bold",
                    ":hover": {
                        backgroundColor: COLORS.darkOrange,
                    },
                }}
                onClick={() =>
                    navigate(
                        `/player-stats/${platform}/${encodeURIComponent(
                            playerName
                        )}/matches`
                    )
                }
            >
                Back to Matches
            </Button>
            <Box
                sx={{
                    margin: "0 auto",
                    border: "2px solid #444",
                    borderRadius: "8px",
                    padding: "16px",
                    backgroundColor: "#2e2e2e",
                    marginTop: "16px",
                    marginBottom: "24px",
                    alignContent: "center",
                    width: "100%",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: { xs: "column", sm: "row" },
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: "16px",
                        marginBottom: "16px",
                    }}
                >
                    {/* Left Section: Page Title and Banner */}
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: { xs: "column", sm: "row" },
                            alignItems: "center",
                            gap: 2, // Gap between banner and favorite icon
                        }}
                    >
                        {/* Banner Section */}
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                            }}
                        >
                            <img
                                src={getPlatformLogo(platform)}
                                alt={`${platform} logo`}
                                style={{ width: 26, height: 26 }}
                            />
                            <Typography variant="h6" color="#fff">
                                Match Details for {playerName}
                            </Typography>
                        </Box>

                        {/* Player Tag */}
                        <PlayerTag
                            playerName={playerName}
                            survivalTier={survivalMastery?.tier}
                            survivalLevel={survivalMastery?.level}
                            clanInfo={clanInfo}
                        />
                        <IconButton
                            onClick={handleToggleSave}
                            sx={{
                                color: isSaved ? "red" : "gray",
                                marginBottom: 1,
                            }}
                        >
                            {isSaved ? (
                                <FavoriteIcon />
                            ) : (
                                <FavoriteBorderIcon />
                            )}
                        </IconButton>
                    </Box>

                    <BanStatus banType={banType} />
                </Box>

                <Box
                    sx={{
                        display: "grid",
                        gridTemplateColumns: {
                            xs: "repeat(2, 1fr)", // Always 2 cards per row on mobile
                            sm: "repeat(auto-fit, minmax(200px, 1fr))", // Ensure equal card dimensions on desktop
                        },
                        justifyContent: "center", // Center the grid container
                        gap: 2, // Spacing between cards
                        padding: 2,
                        maxWidth: maxWidths.l, // Limit the width of the grid to prevent stretching
                        margin: "0 auto", // Center the grid on the page
                    }}
                >
                    {/* Match Date Box */}
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: 2,
                            border: (theme) =>
                                `2px solid ${theme.palette.primary.main}`, // Border in theme color
                            borderRadius: 2, // Rounded corners
                            backgroundColor: (theme) =>
                                theme.palette.background.paper, // Background color
                            boxShadow: 3, // Add a subtle shadow

                            textAlign: "center",
                        }}
                    >
                        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                            Match Date
                        </Typography>
                        <Typography variant="body1">
                            {format(
                                new Date(matchData.createdAt),
                                "MMMM d, yyyy"
                            )}
                        </Typography>
                        <Divider
                            sx={{
                                width: "100%",
                                marginY: 1,
                            }}
                        />
                        <Typography variant="body2" gutterBottom>
                            Start Time:{" "}
                            {format(
                                new Date(matchData.createdAt),
                                "hh:mm:ss a"
                            )}
                        </Typography>
                        <Typography variant="body2">
                            End Time:{" "}
                            {format(
                                addSeconds(
                                    new Date(matchData.createdAt),
                                    matchData.duration
                                ),
                                "hh:mm:ss a"
                            )}
                        </Typography>
                    </Box>

                    {/* Players / Bots Box */}
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: 2,
                            border: (theme) =>
                                `2px solid ${theme.palette.primary.main}`, // Border in theme color
                            borderRadius: 2, // Rounded corners
                            backgroundColor: (theme) =>
                                theme.palette.background.paper, // Background color
                            boxShadow: 3, // Add a subtle shadow

                            textAlign: "center",
                        }}
                    >
                        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                            Enemies:{" "}
                            {matchData.participants.length -
                                matchData.playerRoster.length}
                        </Typography>
                        <Divider
                            sx={{
                                width: "100%",
                                marginY: 1,
                            }}
                        />

                        <Typography variant="body1">
                            Players:{" "}
                            <strong>
                                {matchData.participants.length -
                                    matchData.playerRoster.length -
                                    botList.length}
                            </strong>
                        </Typography>
                        <Divider
                            sx={{
                                width: "100%",
                                marginY: 1,
                            }}
                        />
                        <Typography variant="body2" gutterBottom>
                            Bots: <strong>{botList.length}</strong> (
                            {Math.round(
                                (botList.length /
                                    matchData.participants.length) *
                                    100
                            )}
                            %)
                        </Typography>
                    </Box>

                    {/* Weather Box */}
                    {weather !== null && (
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                padding: 2,
                                border: (theme) =>
                                    `2px solid ${theme.palette.primary.main}`, // Border in theme color
                                borderRadius: 2, // Rounded corners
                                backgroundColor: (theme) =>
                                    theme.palette.background.paper, // Background color
                                boxShadow: 3, // Add a subtle shadow

                                textAlign: "center",
                            }}
                        >
                            <Typography
                                variant="h6"
                                sx={{ fontWeight: "bold" }}
                            >
                                Weather
                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{ fontWeight: "bold", margin: "8px 0" }}
                            >
                                {weatherInfo.emoji}
                            </Typography>
                            <Typography variant="body1">
                                {weatherInfo.label}
                            </Typography>
                        </Box>
                    )}

                    {/*  Region Box */}
                    {weather !== null && (
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                padding: 2,
                                border: (theme) =>
                                    `2px solid ${theme.palette.primary.main}`, // Border in theme color
                                borderRadius: 2, // Rounded corners
                                backgroundColor: (theme) =>
                                    theme.palette.background.paper, // Background color
                                boxShadow: 3, // Add a subtle shadow

                                textAlign: "center",
                            }}
                        >
                            <Typography
                                variant="h6"
                                sx={{ fontWeight: "bold" }}
                            >
                                Region
                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{ fontWeight: "bold", margin: "8px 0" }}
                            >
                                {matchRegion}
                            </Typography>
                        </Box>
                    )}
                </Box>
            </Box>

            {/* Add Detailed MatchCard */}
            <MatchCard
                match={matchData}
                accountId={accountId}
                viewType="detailedCard"
                platform={platform}
                playerName={playerName}
                onMatchHistoryPage={false}
                favoriteGameMode={favoriteGameMode}
                showImages={true}
            />

            <Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        mb: 2,
                    }}
                >
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                        {matchData.gameMode.includes("solo") ||
                        matchData.playerRoster.length === 1
                            ? "Winning Player Overview"
                            : "Winning Team Overview"}
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => navigate("/faq")}
                        sx={{
                            textTransform: "none",
                            fontWeight: "bold",
                            backgroundColor: theme.palette.primary.main,
                            ":hover": {
                                backgroundColor: theme.palette.primary.dark,
                            },
                        }}
                    >
                        {isMobile && "FAQ"}
                        {!isMobile && "Player Cards FAQ"}
                    </Button>
                </Box>

                <Grid container spacing={3}>
                    {winningPlayers
                        .map((player) => {
                            // Find the player data from participants
                            const playerStats = matchData.participants.find(
                                (participant) =>
                                    participant.name === player.name
                            );

                            // Calculate MVP score
                            const mvpScore =
                                (playerStats?.kills || 0) * 100 +
                                (playerStats?.damageDealt || 0);

                            return {
                                ...player,
                                kills: playerStats?.kills || 0,
                                damageDealt: playerStats?.damageDealt || 0,
                                mvpScore, // Add MVP score to the player object
                            };
                        })
                        .sort((a, b) => b.mvpScore - a.mvpScore) // Sort by MVP score in descending order
                        .map((player, index) => {
                            // Check if the current player is the MVP
                            const isMVP = index === 0; // The first player after sorting is the MVP

                            return (
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={3}
                                    key={player.accountId}
                                >
                                    <Card
                                        sx={{
                                            background: `linear-gradient(135deg,${theme.palette.secondary.main}, ${theme.palette.custom.accentColor2})`,
                                            color: theme.palette.text.primary,
                                            border: isMVP
                                                ? "8px solid gold"
                                                : `2px solid ${theme.palette.divider}`,
                                            borderRadius: "12px",
                                            boxShadow:
                                                "0px 6px 12px rgba(0, 0, 0, 0.15)",
                                            overflow: "hidden",
                                            position: "relative",
                                            transition:
                                                "transform 0.2s ease-in-out",
                                            ":hover": {
                                                transform: "translateY(-10px)",
                                                boxShadow:
                                                    "0px 10px 20px rgba(0, 0, 0, 0.25)",
                                            },
                                        }}
                                    >
                                        <CardContent>
                                            {isMVP && (
                                                <Typography
                                                    variant="body1"
                                                    sx={{
                                                        position: "absolute",
                                                        top: 0,
                                                        left: 0,
                                                        backgroundColor: "gold",
                                                        color: "black",
                                                        display: "flex", // Ensure text and icon are inline
                                                        alignItems: "center", // Vertically align text and icon
                                                        padding: "0px 8px",
                                                        lineHeight: "1.4",
                                                        borderBottomRightRadius:
                                                            "8px",
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    MVP
                                                    <StarIcon
                                                        sx={{
                                                            fontSize: "16px", // Adjust size as needed
                                                            marginLeft: "4px", // Add space between text and icon
                                                        }}
                                                    />
                                                </Typography>
                                            )}
                                            <Typography
                                                variant="h6"
                                                sx={{
                                                    fontWeight: "bold",
                                                    mb: 2,
                                                    textAlign: "center",
                                                    color: theme.palette.primary
                                                        .contrastText,
                                                }}
                                            >
                                                {player.name}
                                            </Typography>
                                            <Box
                                                sx={{
                                                    textAlign: "center",
                                                    mb: 2,
                                                }}
                                            >
                                                <Typography
                                                    variant="body1"
                                                    sx={{ fontWeight: "bold" }}
                                                >
                                                    Kills: {player.kills}
                                                </Typography>
                                                <Typography
                                                    variant="body1"
                                                    sx={{ fontWeight: "bold" }}
                                                >
                                                    Damage:{" "}
                                                    {Number(
                                                        player.damageDealt.toFixed(
                                                            0
                                                        )
                                                    ).toLocaleString()}
                                                </Typography>
                                            </Box>

                                            {player.primaryWeaponFirst &&
                                                player.primaryWeaponFirst !==
                                                    "None" && (
                                                    <Box
                                                        sx={{
                                                            textAlign: "center",

                                                            mb: 2,
                                                            background:
                                                                "rgba(0, 0, 0, 0.1)",
                                                            padding: "10px",
                                                            borderRadius: "8px",
                                                        }}
                                                    >
                                                        <Typography
                                                            variant="body1"
                                                            sx={{
                                                                fontWeight:
                                                                    "bold",
                                                                mb: 1,
                                                            }}
                                                        >
                                                            Primary Weapon 1
                                                        </Typography>
                                                        <WeaponCard
                                                            {...getWeaponMetadata(
                                                                player.primaryWeaponFirst
                                                            )}
                                                        />
                                                    </Box>
                                                )}
                                            {player.primaryWeaponSecond &&
                                                player.primaryWeaponSecond !==
                                                    "None" && (
                                                    <Box
                                                        sx={{
                                                            textAlign: "center",
                                                            background:
                                                                "rgba(0, 0, 0, 0.1)",
                                                            padding: "10px",
                                                            borderRadius: "8px",
                                                        }}
                                                    >
                                                        <Typography
                                                            variant="body1"
                                                            sx={{
                                                                fontWeight:
                                                                    "bold",
                                                                mb: 1,
                                                            }}
                                                        >
                                                            Primary Weapon 2
                                                        </Typography>
                                                        <WeaponCard
                                                            {...getWeaponMetadata(
                                                                player.primaryWeaponSecond
                                                            )}
                                                        />
                                                    </Box>
                                                )}
                                            {/* MVP Score */}
                                            {player.primaryWeaponFirst ===
                                                "None" &&
                                                player.primaryWeaponSecond ===
                                                    "None" && (
                                                    <Box
                                                        sx={{
                                                            textAlign: "center",
                                                            background:
                                                                "rgba(0, 0, 0, 0.1)",
                                                            padding: "10px",
                                                            borderRadius: "8px",
                                                        }}
                                                    >
                                                        <Typography
                                                            variant="body2"
                                                            sx={{
                                                                fontWeight:
                                                                    "bold",
                                                                textAlign:
                                                                    "center",
                                                            }}
                                                        >
                                                            No Weapon Data
                                                            because the player
                                                            was dead at the time
                                                            of the match end.
                                                        </Typography>
                                                    </Box>
                                                )}
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    fontWeight: "bold",
                                                    textAlign: "center",
                                                    mt: 2,
                                                }}
                                            >
                                                PUBG Meta Score:{" "}
                                                {player.mvpScore.toFixed(0)}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            );
                        })}
                </Grid>
            </Box>

            {/* Rosters Table */}
            <Box>
                <Typography variant="h6" textAlign={"left"}>
                    Teams
                </Typography>
                <TableContainer
                    component={Paper}
                    sx={{
                        maxHeight: 500,
                        overflowY: "auto",
                    }}
                >
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>Team</TableCell>
                                <TableCell>
                                    <Button
                                        onClick={() => handleRosterSort("rank")}
                                    >
                                        Placement
                                    </Button>
                                </TableCell>
                                <TableCell>
                                    <Button
                                        onClick={() =>
                                            handleRosterSort("totalKills")
                                        }
                                    >
                                        Kills
                                    </Button>
                                </TableCell>
                                <TableCell>
                                    <Button
                                        onClick={() =>
                                            handleRosterSort("totalDamage")
                                        }
                                    >
                                        Damage
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortData(
                                matchData.rosters,
                                rosterSortField,
                                rosterSortOrder
                            ).map((roster) => (
                                <TableRow key={roster.id}>
                                    <TableCell>Team {roster.rank}</TableCell>
                                    <TableCell>{roster.rank}</TableCell>
                                    <TableCell>{roster.totalKills}</TableCell>
                                    <TableCell>
                                        {roster.totalDamage?.toFixed(2)}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            {/* Participants Table */}
            <Box>
                <Typography variant="h6" textAlign={"left"}>
                    Players
                </Typography>
                <TableContainer
                    component={Paper}
                    sx={{
                        maxHeight: 500,
                        overflowY: "auto",
                    }}
                >
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>Player</TableCell>
                                <TableCell>
                                    <Button
                                        onClick={() =>
                                            handleParticipantSort("winPlace")
                                        }
                                    >
                                        Placement
                                    </Button>
                                </TableCell>
                                <TableCell>
                                    <Button
                                        onClick={() =>
                                            handleParticipantSort("kills")
                                        }
                                    >
                                        Kills
                                    </Button>
                                </TableCell>
                                <TableCell>
                                    <Button
                                        onClick={() =>
                                            handleParticipantSort("damageDealt")
                                        }
                                    >
                                        Damage
                                    </Button>
                                </TableCell>
                                <TableCell>Survival Time</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortData(
                                matchData.participants,
                                participantSortField,
                                participantSortOrder
                            ).map((participant) => (
                                <TableRow key={participant.id}>
                                    <TableCell>{participant.name}</TableCell>
                                    <TableCell>
                                        {participant.winPlace}
                                    </TableCell>
                                    <TableCell>{participant.kills}</TableCell>
                                    <TableCell>
                                        {participant.damageDealt.toFixed(2)}
                                    </TableCell>
                                    <TableCell>
                                        {Math.floor(
                                            participant.timeSurvived / 60
                                        )}
                                        m {participant.timeSurvived % 60}s
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            {matchData.matchType !== "competitive" &&
                matchData.gameMode !== "tdm" &&
                matchData.gameMode !== "training" &&
                matchData.gameMode !== "trainingRoom" && (
                    <Box
                        sx={{
                            marginTop: 3,
                            padding: 2,
                            border: "1px solid #444",
                            borderRadius: "8px",
                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                            }}
                        >
                            <SmartToyIcon
                                sx={{
                                    fontSize: "1.5rem",
                                    color: theme.palette.secondary.main,
                                }}
                            />
                            <Typography variant="h6" textAlign="left">
                                Bot List{" "}
                                {botList.length > 0 && `(${botList.length})`}
                            </Typography>
                        </Box>
                        {botList.length > 0 ? (
                            <TableContainer
                                component={Paper}
                                sx={{
                                    maxHeight: 500,
                                    overflowY: "auto",
                                    borderRadius: "8px",
                                    border: "1px solid #444",
                                }}
                            >
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow
                                            sx={{
                                                "& th": {
                                                    fontWeight: "bold",
                                                    color: "#fff",
                                                },
                                            }}
                                        >
                                            <TableCell>Bot Name</TableCell>
                                            <TableCell>Ranking</TableCell>
                                            <TableCell>
                                                Individual Ranking
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {botList.map((bot) => (
                                            <TableRow
                                                key={bot.accountId}
                                                sx={{
                                                    "&:nth-of-type(odd)": {
                                                        backgroundColor:
                                                            "rgba(255, 255, 255, 0.05)",
                                                    },
                                                    "&:hover": {
                                                        backgroundColor:
                                                            "rgba(255, 255, 255, 0.1)",
                                                    },
                                                }}
                                            >
                                                <TableCell
                                                    sx={{
                                                        fontWeight: 500,
                                                        color: "#ddd",
                                                    }}
                                                >
                                                    {bot.name}
                                                </TableCell>
                                                <TableCell
                                                    sx={{
                                                        color: "#aaa",
                                                    }}
                                                >
                                                    {bot.ranking}
                                                </TableCell>
                                                <TableCell
                                                    sx={{
                                                        color: "#aaa",
                                                    }}
                                                >
                                                    {bot.individualRanking}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ) : (
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: 200,
                                    border: "1px dashed #666",
                                    borderRadius: "8px",
                                }}
                            >
                                <Typography
                                    variant="h6"
                                    sx={{
                                        color: "#aaa",
                                        marginBottom: 1,
                                    }}
                                >
                                    No Bots Found
                                </Typography>
                                <Typography
                                    variant="body2"
                                    sx={{
                                        color: "#888",
                                    }}
                                >
                                    There were no bots in this match.
                                </Typography>
                            </Box>
                        )}
                    </Box>
                )}

            {matchData.gameMode !== "tdm" &&
                matchData.gameMode !== "training" &&
                matchData.gameMode !== "trainingRoom" && (
                    <Box>
                        {telemetryLoading ? (
                            <FunnyLoading />
                        ) : telemetryError ? (
                            <Typography color="error">
                                {telemetryError}
                            </Typography>
                        ) : (
                            <>
                                {/* Kill Tree Section */}
                                <Box
                                    sx={{
                                        border: "2px solid #333", // Dark border
                                        padding: "20px 20px 10px", // Space around the tree
                                        marginBottom: "20px", // Space between trees
                                        position: "relative", // Needed for the title placement
                                        backgroundColor:
                                            theme.palette.secondary.main,
                                    }}
                                >
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            position: "absolute",
                                            left: "20px",
                                            padding: "0 10px",
                                            fontWeight: "bold",
                                            fontSize: "16px",
                                            color: theme.palette.text.primary,
                                        }}
                                    >
                                        Kill Tree
                                    </Typography>
                                    <KillTree
                                        killTree={killTree}
                                        remainingPlayers={remainingPlayers}
                                        focusPlayer={playerName}
                                    />
                                </Box>
                            </>
                        )}
                    </Box>
                )}
        </Box>
    );
};

export default MatchDetails;
