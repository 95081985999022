import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import erangelImage from "assets/maps/ibr/erangel/erangel.jpeg";
import miramarImage from "assets/maps/ibr/miramar/miramar.jpeg";
import vikendiImage from "assets/maps/ibr/vikendi/vikendi.png";
import sanhokImage from "assets/maps/ibr/sanhok/sanhok.png";
import taegoImage from "assets/maps/ibr/taego/taego.png";
import destonImage from "assets/maps/ibr/deston/deston.jpeg";
import rondoImage from "assets/maps/ibr/rondo/rondo.jpg";
import { COLORS } from "constants/appWide/colors";
import BoxDetailsSection from "../../../components/ibr/BoxDetailsSection";

const maps = [
    { name: "Erangel", path: "/maps/ibr-crates/erangel", image: erangelImage },
    { name: "Miramar", path: "/maps/ibr-crates/miramar", image: miramarImage },
    { name: "Sanhok", path: "/maps/ibr-crates/sanhok", image: sanhokImage },
    { name: "Vikendi", path: "/maps/ibr-crates/vikendi", image: vikendiImage },
    { name: "Taego", path: "/maps/ibr-crates/taego", image: taegoImage },
    { name: "Deston", path: "/maps/ibr-crates/deston", image: destonImage },
    { name: "Rondo", path: "/maps/ibr-crates/rondo", image: rondoImage },
];

const IBRMapSection: React.FC = () => (
    <Box
        sx={{
            width: { xs: "95%", sm: "80%", md: "60%" },
            margin: "0 auto",
            padding: 2,
        }}
    >
        <Grid container spacing={3}>
            {maps.map((map) => (
                <Grid item xs={12} sm={6} md={4} key={map.name}>
                    <Link
                        to={map.path}
                        style={{ textDecoration: "none", color: "inherit" }}
                    >
                        <Box
                            sx={{
                                borderRadius: "8px",
                                overflow: "hidden",
                                boxShadow: 2,
                                "&:hover": { boxShadow: 4 },
                                height: "100%",
                                textAlign: "center",
                                display: "flex",
                                flexDirection: "column",
                                backgroundColor: COLORS.orange,
                                color: "black",
                            }}
                        >
                            <Box
                                component="img"
                                src={map.image}
                                alt={map.name}
                                sx={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                }}
                            />
                            <Typography
                                variant="h6"
                                sx={{
                                    padding: "10px 0",
                                }}
                            >
                                {map.name}
                            </Typography>
                        </Box>
                    </Link>
                </Grid>
            ))}
        </Grid>
        <BoxDetailsSection />
    </Box>
);

export default IBRMapSection;
