import { Typography, Box } from "@mui/material";

import dinner from "assets/matchCardIcons/dinner.png";
import trophy from "assets/matchCardIcons/trophy.png";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import { COLORS } from "./appWide/colors";

interface FAQ {
    id: string;
    question: string;
    answer: string | JSX.Element; // Accept both plain text and JSX
}

interface FAQCategory {
    category: string;
    questions: FAQ[];
}

export const faqData: FAQCategory[] = [
    {
        category: "General",
        questions: [
            {
                id: "faq8",
                question: "How do you get the data for this site?",
                answer: (
                    <Box>
                        <Typography variant="body1" gutterBottom>
                            All the data displayed on this site is sourced from
                            reliable and official sources:
                        </Typography>
                        <ul style={{ paddingLeft: "20px" }}>
                            <li>
                                <Typography variant="body1">
                                    <strong>PUBG API</strong>: Player stats,
                                    match history, and other game-related data
                                    are fetched directly from the official PUBG
                                    API. You can find more details here:{" "}
                                    <a
                                        href="https://documentation.pubg.com/en/introduction.html"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ color: "#007BFF" }}
                                    >
                                        PUBG API Documentation
                                    </a>
                                    .
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1">
                                    <strong>Steam API</strong>: For Steam player
                                    counts, we use the official Steam API. Learn
                                    more:{" "}
                                    <a
                                        href="https://partner.steamgames.com/doc/webapi/ISteamUserStats"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ color: "#007BFF" }}
                                    >
                                        Steam API Documentation
                                    </a>
                                    .
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1">
                                    <strong>PUBG Report Clips</strong>: The
                                    clips displayed on this site are sourced by
                                    scraping data from the{" "}
                                    <a
                                        href="https://pubg.report/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ color: "#007BFF" }}
                                    >
                                        pubg.report
                                    </a>{" "}
                                    official web app.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1">
                                    <strong>Liquipedia Links</strong>: For
                                    player profiles, we provide links to{" "}
                                    <a
                                        href="https://liquipedia.net/pubg/Main_Page"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ color: "#007BFF" }}
                                    >
                                        Liquipedia
                                    </a>
                                    . No additional data is fetched or used from
                                    Liquipedia beyond linking to their player
                                    profile pages.
                                </Typography>
                            </li>
                        </ul>
                    </Box>
                ),
            },
            {
                id: "faq4",
                question:
                    "What makes this different from other PUBG stats websites?",
                answer: "I aim to create the ultimate PUBG companion, designed to be used side by side while playing the game. This site helps you check maps for secret room locations and review stats as you play, in addition to being a PUBG resource with a lot of information about the game.",
            },
            {
                id: "faq1",
                question: "How many people work on this?",
                answer: "It’s just me! I’m a full-stack software engineer with a passion for web apps and PUBG. This project is a personal labor of love, combining my skills with my enthusiasm for the game to create something truly helpful for players.",
            },
            {
                id: "faq2",
                question: "Why did you work on this?",
                answer: "I wanted to address the gaps in features that other leading PUBG-related websites are missing, and also have some fun with it! I’m a huge fan of PUBG and wanted to create a platform that I would personally enjoy using while playing the game.",
            },
            {
                id: "faq3",
                question: "Will there be updates and improvements?",
                answer: "Absolutely! This is an ongoing project, and I constantly aim to improve the site, taking user feedback into account to make it even better.",
            },
            {
                id: "faq5",
                question: "Will you ever introduce ads in the future?",
                answer: "No, I will never introduce ads on this site. I’m passionate about delivering clean, distraction-free web apps, and I’m an advocate for ad blockers. I want you to have the best possible experience while using PUBG Meta.",
            },
            {
                id: "faq6",
                question: "What is the maintenance cost for this site?",
                answer: "The total cost to maintain this site averages $30 per month. This includes server hosting, database storage, and domain name costs to ensure the platform runs smoothly and remains accessible to all users.",
            },

            {
                id: "faq7",
                question: "Why doesn’t this site include PUBG Mobile data?",
                answer: "Unfortunately, there’s no official API for PUBG Mobile, so I can’t retrieve or display its data. PUBG Mobile is a separate version of the game developed by Tencent Games and LightSpeed Studios, whereas PUBG on PC and consoles is developed by PUBG Studios (a subsidiary of Krafton).",
            },
        ],
    },
    {
        category: "Player Search Page",
        questions: [
            {
                id: "search-1",
                question: "How are the preset players selected?",
                answer: "The preset players were chosen based on their popularity and recent prominence in the community.",
            },
        ],
    },
    {
        category: "Player Profile Page",
        questions: [
            {
                id: "profile-3",
                question:
                    "Is there a way to retrieve the actual player banner and clan tag decoration?",
                answer: "Unfortunately, there is no way to retrieve the actual player banner and clan tag decoration. This information is not provided through the PUBG API.",
            },
            // {
            //     id: "profile-1",
            //     question:
            //         "Does the Liquipedia pro player message confirm that the player is a professional?",
            //     answer: "Not necessarily. The Liquipedia pro player message indicates that the player's in-game name matches a Liquipedia profile. However, we cannot guarantee that the profile belongs to the same individual. It is simply a name match with no additional verification.",
            // },
            // {
            //     id: "profile-2",
            //     question:
            //         "If I don’t see the Liquipedia pro player message, does that mean the player isn’t a professional?",
            //     answer: "Not at all. It simply means there isn’t a Liquipedia profile with the given name. The actual pro player may use a different name in the game. For example, a professional player like TGLTN might appear in-game as TGLT_N.",
            // },
        ],
    },
    {
        category: "Recent Matches Page",
        questions: [
            {
                id: "match-1",
                question:
                    "What do the different icons on the match card represent?",
                answer: (
                    <Box>
                        <Typography variant="body1" gutterBottom>
                            The icons on the match card represent the following
                            achievements:
                        </Typography>
                        <ul style={{ paddingLeft: "0", listStyleType: "none" }}>
                            <li
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "16px",
                                }}
                            >
                                <img
                                    src={dinner}
                                    alt="Chicken Dinner Icon"
                                    style={{
                                        width: 40,
                                        height: 40,
                                        marginRight: "16px", // Space between icon and text
                                    }}
                                />
                                <Typography variant="body1">
                                    <strong>Chicken Dinner Icon</strong>:
                                    Signifies a victory in standard game modes
                                    such as Solo, Duo, or Squad.
                                </Typography>
                            </li>
                            <li
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "16px",
                                }}
                            >
                                <img
                                    src={trophy}
                                    alt="Trophy Icon"
                                    style={{
                                        width: 40,
                                        height: 40,
                                        marginRight: "16px", // Space between icon and text
                                    }}
                                />
                                <Typography variant="body1">
                                    <strong>Trophy Icon</strong>: Indicates a
                                    win in arcade modes like TDM (Team
                                    Deathmatch) or IBR (Intense Battle Royale).
                                </Typography>
                            </li>
                            <li
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "16px",
                                }}
                            >
                                <VerifiedUserIcon
                                    sx={{
                                        fontSize: 40, // Icon size
                                        color: COLORS.green, // Green color for survival
                                        marginRight: "16px", // Space between icon and text
                                    }}
                                />
                                <Typography variant="body1">
                                    <strong>Green Checkmark Icon</strong>: Shows
                                    that the player survived until at least the
                                    last 15 seconds of the match, demonstrating
                                    a significant contribution to the win. This
                                    differentiates matches where the player
                                    played a key role from those where the team
                                    won despite the player's early elimination.
                                </Typography>
                            </li>
                        </ul>
                    </Box>
                ),
            },
            {
                id: "heatmap-1",
                question: "What do the different heatmap colors represent?",
                answer: (
                    <Box>
                        <Typography variant="body1" gutterBottom>
                            The colors on the heatmap represent the intensity of
                            activity for a given metric (e.g., kills, damage,
                            survival time) over the past 14 days. The thresholds
                            for each color are based on the normalized range of
                            values for the selected metric:
                        </Typography>
                        <ul style={{ paddingLeft: "0", listStyleType: "none" }}>
                            <li
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "16px",
                                }}
                            >
                                <Box
                                    sx={{
                                        width: 40,
                                        height: 40,
                                        backgroundColor: "background.paper",
                                        border: "1px solid #000",
                                        marginRight: "16px",
                                    }}
                                />
                                <Typography variant="body1">
                                    <strong>Gray</strong>: Represents the lowest
                                    activity, with a normalized value of{" "}
                                    <strong>0</strong>. This indicates no
                                    contribution for that day.
                                </Typography>
                            </li>
                            <li
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "16px",
                                }}
                            >
                                <Box
                                    sx={{
                                        width: 40,
                                        height: 40,
                                        backgroundColor: "#FFA500",
                                        marginRight: "16px",
                                    }}
                                />
                                <Typography variant="body1">
                                    <strong>Orange</strong>: Represents low
                                    activity, with a normalized value between{" "}
                                    <strong>0.01</strong> and{" "}
                                    <strong>0.33</strong>. This indicates some
                                    contribution but below average compared to
                                    other days.
                                </Typography>
                            </li>
                            <li
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "16px",
                                }}
                            >
                                <Box
                                    sx={{
                                        width: 40,
                                        height: 40,
                                        backgroundColor: "#FFFF00",
                                        marginRight: "16px",
                                    }}
                                />
                                <Typography variant="body1">
                                    <strong>Yellow</strong>: Represents medium
                                    activity, with a normalized value between{" "}
                                    <strong>0.34</strong> and{" "}
                                    <strong>0.66</strong>. This shows a day with
                                    moderate contributions.
                                </Typography>
                            </li>
                            <li
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "16px",
                                }}
                            >
                                <Box
                                    sx={{
                                        width: 40,
                                        height: 40,
                                        backgroundColor: "secondary.main",
                                        marginRight: "16px",
                                    }}
                                />
                                <Typography variant="body1">
                                    <strong>Green</strong>: Represents the
                                    highest activity, with a normalized value
                                    between <strong>0.67</strong> and{" "}
                                    <strong>1.0</strong>. This indicates
                                    exceptional contributions for the selected
                                    metric on that day.
                                </Typography>
                            </li>
                        </ul>
                    </Box>
                ),
            },
            {
                id: "match-2",
                question: "What are the different match types?",
                answer: (
                    <Box>
                        <Typography variant="body1" gutterBottom>
                            Here are the different match types available:
                        </Typography>
                        <ul style={{ paddingLeft: "20px" }}>
                            <li>
                                <Typography variant="body1">
                                    <strong>Ranked</strong>: High-stakes matches
                                    with performance-based rankings.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1">
                                    <strong>Public</strong>: Standard matches in
                                    Solo, Duo, or Squad modes.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1">
                                    <strong>Arcade</strong>: Only includes TDM
                                    (Team Deathmatch), IBR (Intense Battle
                                    Royale), and Hot Drop.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1">
                                    <strong>Casual</strong>: Available for Solo
                                    or Squad, 12 players and 88 bots.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1">
                                    <strong>Custom</strong>: Player-created
                                    matches with customizable rules.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1">
                                    <strong>Training</strong>: Matches designed
                                    for skill development with no ranking
                                    implications.
                                </Typography>
                            </li>
                        </ul>
                    </Box>
                ),
            },
            {
                id: "match-3",
                question: "What are the different game modes?",
                answer: (
                    <Box>
                        <Typography variant="body1" gutterBottom>
                            Here are the different game modes:
                        </Typography>
                        <ul style={{ paddingLeft: "20px" }}>
                            <li>
                                <Typography variant="body1">
                                    <strong>Arcade Modes</strong>: TDM (Team
                                    Deathmatch), IBR (Intense Battle Royale),
                                    and Hot Drop.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1">
                                    <strong>Standard Modes</strong>: Solo FPP /
                                    TPP, Duo FPP / TPP, Squad FPP / TPP.
                                </Typography>
                            </li>
                        </ul>
                    </Box>
                ),
            },
            {
                id: "match-4",
                question:
                    "Why do you not show the Casual Matches left today on all players?",
                answer: "That info is only shown for a player that have played a casual match in the last 14 days. I believe that those who play that mode will benefit from the info, and those who do not play casual do not need to see that.",
            },
            {
                id: "match-5",
                question:
                    "What time does the daily limit on the number of casual matches played reset?",
                answer: "The casual matches limit reset at 2 AM UTC every day, similar to the map rotation time. However, the time is shown in your local timezone on the site.",
            },
        ],
    },
    {
        category: "Match Details Page - Kill Tree",
        questions: [
            {
                id: "match-1",
                question: "Can you please explain more about the kill tree?",
                answer: "Yes! At the bottom of the kill tree (called the leaf nodes), there are the players that didn't get any kills in the match and have been killed by a player. The players above them are the ones who killed them. The tree structure shows the relationships between the players and who killed whom in the match.",
            },
            {
                id: "match-1",
                question: "What players are not shown in the kill tree?",
                answer: "The kill tree will not show players got 0 kills in the match, even if they survived until the end. It will also not show players who got 0 kills and died to any other cause than a player (blue zone damage, suicide throwable, fall damage).",
            },
        ],
    },
    {
        category: "Match Details Page - Player Cards",
        questions: [
            {
                id: "player-1",
                question: "How is the player score computed?",
                answer: "The player score is calculated based on their performance in the match. It combines the total kills multiplied by 100 and the total damage dealt. For example, a player with 5 kills and 1000 damage would have a score of 1500.",
            },
            {
                id: "player-2",
                question:
                    "Why are some players missing weapon data in certain modes?",
                answer: "In some modes, such as IBR, we only have equipped weapon data for players who are still alive. For players who are already dead, their equipped weapon data might not be available and will not be displayed on the player card.",
            },
            {
                id: "player-3",
                question:
                    "Bonus Tip: How can I equip weapons more strategically?",
                answer: "It's recommended to equip your close-range weapon in the secondary slot because switching to it is faster than switching to a primary weapon. This can give you a crucial advantage in close-quarters combat situations.",
            },
        ],
    },
    {
        category: "Map Rotations Page",
        questions: [
            {
                id: "rotation-1",
                question: "How do you get the map rotation info?",
                answer: "The map rotation probabilities are manually calculated using the official PUBG patch announcements once they are posted. This ensures accurate and up-to-date rotation details for PC, Console, and Ranked modes.",
            },
        ],
    },
];
