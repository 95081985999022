import React, { useState } from "react";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Paper,
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    useTheme,
    useMediaQuery,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { COLORS } from "constants/appWide/colors";
import { weapons } from "constants/tierListWeapons";
import { ammoIcons } from "constants/ammoIcons";

import changeType from "assets/webButtons/changeType.png";

// Charts
import TopWeaponsByKillsChart from "./dataViz/PlayerProfile/BarCharts/TopWeaponsByKillChart";
import TopWeaponsByXPChart from "./dataViz/PlayerProfile/BarCharts/TopWeaponsByXPChart";
import WeaponXPDistributionChart from "./dataViz/PlayerProfile/PieChart/WeaponXPDistributionChart";
import { KeyboardController } from "hooks/KeyboardController";

interface WeaponStats {
    MostDefeatsInAGame?: number;
    Defeats?: number;
    MostDamagePlayerInAGame?: number;
    DamagePlayer?: number;
    MostHeadShotsInAGame?: number;
    HeadShots?: number;
    LongestDefeat?: number;
    LongRangeDefeats?: number;
    Kills?: number;
    MostKillsInAGame?: number;
    Groggies?: number;
    MostGroggiesInAGame?: number;
    LongestKill?: number;
}

export interface WeaponInfo {
    weaponId: string;
    XPTotal: number;
    LevelCurrent: number;
    TierCurrent: number;
    StatsTotal?: WeaponStats; // Legacy
    OfficialStatsTotal?: WeaponStats; // Normal
    CompetitiveStatsTotal?: WeaponStats; // Ranked
}

export interface WeaponCategories {
    "Assault Rifles": WeaponInfo[];
    "Designated Marksman Rifles": WeaponInfo[];
    Handguns: WeaponInfo[];
    "Light Machine Guns": WeaponInfo[];
    Shotguns: WeaponInfo[];
    "Sniper Rifles": WeaponInfo[];
    "Submachine Guns": WeaponInfo[];
    Throwables: WeaponInfo[];
    Miscellaneous: WeaponInfo[];
}

const WeaponMasterySection: React.FC<{
    weaponMastery: WeaponCategories | null;
}> = ({ weaponMastery }) => {
    const statTypes = ["Normal", "Ranked", "Legacy"] as const;
    const [selectedStatType, setSelectedStatType] =
        useState<(typeof statTypes)[number]>("Normal");

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Detect mobile screens

    interface MasteryImageData {
        masteryImage?: string; // Mastery image URL
        ammoType?: string; // Ammo type (if available)
        isCrateWeapon?: boolean; // Whether the weapon is a crate weapon
    }

    const getWeaponMasteryData = (
        weaponId: string
    ): MasteryImageData | undefined => {
        const formattedName = formatWeaponName(weaponId);
        const weapon = weapons.find((w) => w.title === formattedName);

        if (!weapon) return undefined;

        return {
            masteryImage: weapon.masteryImage, // Return the mastery image
            ammoType: weapon.ammoType, // Return the ammo type if it exists
            isCrateWeapon: weapon.crateWeapon || false, // Return crate weapon status (default to false if undefined)
        };
    };

    // Works because my backend already cleans up the weapon names
    const formatWeaponName = (weaponId: string): string => {
        const match = weaponId.match(/Weapon_(.+?)_C/);
        return match ? match[1].replace(/_/g, " ") : weaponId;
    };

    const renderStatsTable = (
        stats: WeaponStats | undefined,
        isLegacy: boolean
    ) => {
        if (!stats) return <Typography>No stats available</Typography>;

        const rows = isLegacy
            ? [
                  ["Kills", stats.Kills],
                  ["Most Kills in a Game", stats.MostKillsInAGame],
                  ["Defeats", stats.Defeats],
                  ["Most Defeats in a Game", stats.MostDefeatsInAGame],
                  [
                      "Total Damage",
                      stats.DamagePlayer
                          ? Number(stats.DamagePlayer).toLocaleString(
                                undefined,
                                {
                                    maximumFractionDigits: 0,
                                }
                            )
                          : undefined,
                  ],
                  ["Headshots", stats.HeadShots],
                  [
                      "Longest Defeat",
                      stats.LongestDefeat
                          ? `${Number(stats.LongestDefeat).toLocaleString(
                                undefined,
                                { maximumFractionDigits: 0 }
                            )} m`
                          : undefined,
                  ],
                  ["Long Range Defeats", stats.LongRangeDefeats],
                  ["Knockdowns", stats.Groggies],
                  ["Most Knockdowns in a Game", stats.MostGroggiesInAGame],
              ]
            : [
                  ["Kills", stats.Kills],
                  ["Most Kills in a Game", stats.MostKillsInAGame],
                  [
                      "Total Damage",
                      stats.DamagePlayer
                          ? Number(stats.DamagePlayer).toLocaleString(
                                undefined,
                                {
                                    maximumFractionDigits: 0,
                                }
                            )
                          : undefined,
                  ],
                  ["Headshots", stats.HeadShots],
                  [
                      "Longest Kill",
                      stats.LongestKill
                          ? `${Number(stats.LongestKill).toLocaleString(
                                undefined,
                                { maximumFractionDigits: 0 }
                            )} m`
                          : undefined,
                  ],
                  ["Knockdowns", stats.Groggies],
              ];

        return (
            <TableContainer
                sx={{
                    backgroundColor: "#2e2e2e",
                    borderRadius: "8px",
                    border: "1px solid #444",
                    overflowX: "auto", // Ensure responsiveness for very wide tables
                }}
            >
                <Table>
                    <TableBody>
                        {rows.map(
                            ([label, value]) =>
                                value !== undefined && (
                                    <TableRow key={label}>
                                        <TableCell
                                            sx={{
                                                textAlign: "left",
                                                color: "#fff",
                                                padding: "8px 16px", // Add spacing
                                                borderBottom: "1px solid #444",
                                            }}
                                        >
                                            <strong>{label}</strong>
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                textAlign: "left",
                                                color: COLORS.silver,
                                                padding: "8px 16px", // Add spacing
                                                borderBottom: "1px solid #444",
                                            }}
                                        >
                                            {value}
                                        </TableCell>
                                    </TableRow>
                                )
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    const renderWeaponDetails = (weapons: WeaponInfo[]) => {
        const sortedWeapons = [...weapons].sort((a, b) => {
            if (b.TierCurrent !== a.TierCurrent) {
                return b.TierCurrent - a.TierCurrent;
            }
            return b.LevelCurrent - a.LevelCurrent;
        });

        return sortedWeapons.map((weapon, index) => {
            const weaponData = getWeaponMasteryData(weapon.weaponId);
            return (
                <Paper
                    key={index}
                    sx={{
                        padding: 2,
                        marginBottom: 2,
                        backgroundColor: "#3a3a3a",
                        borderRadius: "8px",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: { xs: "column", md: "row" }, // Column on mobile, row on desktop
                            justifyContent: {
                                xs: "flex-start",
                                md: "space-between",
                            },
                            gap: 2,
                            alignItems: { xs: "flex-start", md: "center" }, // Adjust alignment for smaller screens,
                        }}
                    >
                        {/* Left Section: Weapon Details */}
                        {/* Mastery Image */}

                        <Box
                            sx={{
                                flex: 1,
                                display: "flex", // Ensures flex container behavior
                                flexDirection: "column", // Stack items vertically
                                alignItems: "center", // Center items horizontally
                                justifyContent: "center", // Center items vertically
                                textAlign: "center", // Ensures text is centered
                                width: "100%",
                                position: "relative",
                            }}
                        >
                            {weaponData?.masteryImage && (
                                <Box
                                    sx={{
                                        position: "relative", // Allows for positioning child elements
                                        display: "inline-block", // Ensures it wraps the image and icons
                                    }}
                                >
                                    <img
                                        src={weaponData.masteryImage}
                                        alt={`Mastery for ${formatWeaponName(
                                            weapon.weaponId
                                        )}`}
                                        style={{
                                            width: "100%", // Make the width responsive
                                            height: "auto", // Maintain aspect ratio
                                            maxWidth: "640px", // Prevent it from exceeding 640px
                                            borderRadius: "8px", // Optional: Add rounded corners
                                        }}
                                    />
                                    {/* Crate Weapon Icon */}
                                    {weaponData.isCrateWeapon && (
                                        <Box
                                            component="img"
                                            src="https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Icons/CarePackage/CarePackage_Normal.png"
                                            alt="Crate Icon"
                                            sx={{
                                                position: "absolute",
                                                bottom: "10px", // Distance from the bottom
                                                left: "10px", // Distance from the left
                                                width: "40px", // Adjust size as needed
                                                height: "40px", // Adjust size as needed
                                            }}
                                        />
                                    )}
                                    {/* Ammo Type Icon */}
                                    {weaponData.ammoType &&
                                        ammoIcons[weaponData.ammoType] && (
                                            <Box
                                                component="img"
                                                src={
                                                    ammoIcons[
                                                        weaponData.ammoType
                                                    ]
                                                }
                                                alt={`${weaponData.ammoType} Ammo Icon`}
                                                sx={{
                                                    position: "absolute",
                                                    top: "5px", // Distance from the top
                                                    right: "10px", // Distance from the right
                                                    width: "40px", // Adjust size as needed
                                                    height: "40px", // Adjust size as needed
                                                }}
                                            />
                                        )}
                                </Box>
                            )}

                            <Typography>
                                <strong>Weapon:</strong>{" "}
                                {formatWeaponName(weapon.weaponId)}
                            </Typography>
                            {weapon.TierCurrent > 0 && (
                                <img
                                    src={require(`../assets/weaponMastery/tier${weapon.TierCurrent}weapon.png`)}
                                    alt={`Tier ${weapon.TierCurrent}`}
                                    style={{ width: 250, height: 60 }}
                                />
                            )}
                            <Box
                                sx={{
                                    display: "flex",

                                    alignItems: "center", // Center items horizontally
                                    justifyContent: "center", // Ensure centered alignment
                                    gap: 2,
                                }}
                            >
                                <Typography>
                                    <strong>Level:</strong>{" "}
                                    {weapon.LevelCurrent}
                                </Typography>
                                <Typography>
                                    <strong>XP:</strong>{" "}
                                    {Number(weapon.XPTotal).toLocaleString()}
                                </Typography>
                            </Box>
                        </Box>

                        {/* Right Section: Stats Table */}
                        <Box
                            sx={{
                                flex: 1,
                                width: "100%",
                                marginTop: { xs: 2, md: 0 },
                            }}
                        >
                            <Typography variant="h6" gutterBottom>
                                {selectedStatType} Stats:
                            </Typography>
                            {selectedStatType === "Normal"
                                ? renderStatsTable(
                                      weapon.OfficialStatsTotal,
                                      false
                                  )
                                : selectedStatType === "Ranked"
                                ? renderStatsTable(
                                      weapon.CompetitiveStatsTotal,
                                      false
                                  )
                                : renderStatsTable(weapon.StatsTotal, true)}
                        </Box>
                    </Box>
                </Paper>
            );
        });
    };

    return (
        <Box>
            <KeyboardController
                controlType="cycle"
                items={statTypes}
                currentIndex={statTypes.indexOf(selectedStatType)}
                setIndex={(newIndex) =>
                    setSelectedStatType(statTypes[newIndex])
                }
                prevKey="q"
                nextKey="e"
            />
            <Box
                sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                    justifyContent: "space-between",
                    gap: 2,
                    mb: 3,
                }}
            >
                <Box
                    sx={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Typography
                        variant="h6"
                        sx={{
                            textAlign: "left",
                            color: theme.palette.text.primary,
                        }}
                    >
                        Top 10 Weapons by Total Kills
                    </Typography>
                    {/* Scrollable Container for Bar Charts */}
                    <Box
                        sx={{
                            overflowX: "auto", // Enable horizontal scrolling
                            width: "100%", // Occupy full width of the parent container
                        }}
                    >
                        <Box
                            sx={{
                                minWidth: "600px",
                            }}
                        >
                            <TopWeaponsByKillsChart
                                weaponMastery={weaponMastery}
                            />
                        </Box>
                    </Box>
                    <Typography
                        variant="h6"
                        sx={{
                            textAlign: "left",
                            color: theme.palette.text.primary,
                            marginTop: 2,
                        }}
                    >
                        Top 10 Weapons by XP
                    </Typography>
                    <Box
                        sx={{
                            overflowX: "auto", // Enable horizontal scrolling
                            width: "100%", // Occupy full width of the parent container
                        }}
                    >
                        <Box
                            sx={{
                                minWidth: "600px",
                                minHeight: "300px",
                            }}
                        >
                            <TopWeaponsByXPChart
                                weaponMastery={weaponMastery}
                            />
                        </Box>
                    </Box>
                </Box>

                {/* Pie Chart */}
                <Box sx={{ flex: 1, maxWidth: "100%" }}>
                    <WeaponXPDistributionChart weaponMastery={weaponMastery} />
                </Box>
            </Box>

            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between", // Align title on the left and buttons on the right
                    alignItems: "center", // Align items vertically in the center
                    flexDirection: { xs: "column", sm: "row" }, // Stack vertically on mobile, row on desktop
                    mb: 3,
                }}
            >
                {/* Left-aligned text */}
                <Typography
                    variant="h5"
                    sx={{
                        fontWeight: "bold",
                        color: COLORS.orange,
                        alignSelf: "flex-start", // Ensure text aligns to the left
                        mb: { xs: 2, sm: 0 }, // Add margin bottom for mobile when stacked
                        textAlign: "left", // Explicitly left-align text
                        width: "100%", // Prevent shrinking on mobile
                    }}
                >
                    Weapon Mastery Details
                </Typography>

                {/* Buttons */}
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row", // Stack vertically on mobile, row on desktop
                        gap: 2, // Spacing between buttons
                        justifyContent: "flex-end", // Align buttons to the right on desktop
                        width: { xs: "100%", sm: "auto" }, // Take full width on mobile
                    }}
                >
                    {!isMobile && (
                        <img
                            src={changeType}
                            alt="Press Q/E to change type"
                            style={{
                                width: "200px", // Adjust as needed
                                height: "auto", // Maintain aspect ratio
                            }}
                        />
                    )}
                    <Button
                        variant="contained"
                        onClick={() => setSelectedStatType("Normal")}
                        sx={{
                            backgroundColor:
                                selectedStatType === "Normal"
                                    ? COLORS.orange
                                    : "transparent",
                            color:
                                selectedStatType === "Normal"
                                    ? "black"
                                    : "white",
                            border: "1px solid orange",
                            "&:hover": {
                                backgroundColor:
                                    selectedStatType === "Normal"
                                        ? "#cc7700"
                                        : "#4d4d4d",
                            },
                            width: { xs: "100%", sm: "auto" }, // Full width on mobile
                        }}
                    >
                        Normal
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => setSelectedStatType("Ranked")}
                        sx={{
                            backgroundColor:
                                selectedStatType === "Ranked"
                                    ? COLORS.orange
                                    : "transparent",
                            color:
                                selectedStatType === "Ranked"
                                    ? "black"
                                    : "white",
                            border: "1px solid orange",
                            "&:hover": {
                                backgroundColor:
                                    selectedStatType === "Ranked"
                                        ? "#cc7700"
                                        : "#4d4d4d",
                            },
                            width: { xs: "100%", sm: "auto" }, // Full width on mobile
                        }}
                    >
                        Ranked
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => setSelectedStatType("Legacy")}
                        sx={{
                            backgroundColor:
                                selectedStatType === "Legacy"
                                    ? COLORS.orange
                                    : "transparent",
                            color:
                                selectedStatType === "Legacy"
                                    ? "black"
                                    : "white",
                            border: "1px solid orange",
                            "&:hover": {
                                backgroundColor:
                                    selectedStatType === "Legacy"
                                        ? "#cc7700"
                                        : "#4d4d4d",
                            },
                            width: { xs: "100%", sm: "auto" }, // Full width on mobile
                        }}
                    >
                        Legacy
                    </Button>
                </Box>
            </Box>

            {weaponMastery ? (
                Object.entries(weaponMastery).map(([category, weapons]) => (
                    <Accordion key={category} sx={{ marginBottom: 2 }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            sx={{ backgroundColor: "#2e2e2e" }}
                        >
                            <Typography sx={{ textTransform: "capitalize" }}>
                                {category}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ backgroundColor: "#1e1e1e" }}>
                            {renderWeaponDetails(weapons)}
                        </AccordionDetails>
                    </Accordion>
                ))
            ) : (
                <Typography>No weapon mastery data available.</Typography>
            )}
        </Box>
    );
};

export default WeaponMasterySection;
