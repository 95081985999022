import { Weapon } from "../types/weapon";

// Custom weapon images
import js9 from "assets/customWeaponImages/js9.png";
import js9Mastery from "assets/customWeaponImages/js9Mastery.png";
import mortarImage from "assets/customWeaponImages/mortar.png";
import fragMastery from "assets/customWeaponImages/fragMastery.png";
import bzSquare from "assets/customWeaponImages/bzSquare.png";
import bzMastery from "assets/customWeaponImages/bzMastery.png";
import c4Mastery from "assets/customWeaponImages/c4Mastery.png";
import stickyMastery from "assets/customWeaponImages/stickyMastery.png";
import molotovMastery from "assets/customWeaponImages/molotovMastery.png";
import panzerMastery from "assets/customWeaponImages/panzerMastery.png";

const weaponsWithoutId: Omit<
    Omit<Omit<Weapon, "id">, "cardImage">,
    "masteryImage"
>[] = [
    // Crate Weapons
    {
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_FAMASG2_C.png",
        title: "FAMAS",
        crateWeapon: true,
        ammoType: "556",
        weaponType: "AR",
    },
    {
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_Groza_C.png",
        title: "Groza",
        crateWeapon: true,
        ammoType: "762",
        weaponType: "AR",
    },
    {
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_AWM_C.png",
        title: "AWM",
        crateWeapon: true,
        ammoType: ".300",
        weaponType: "SR",
    },
    {
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_L6_C.png",
        title: "Lynx AMR",
        crateWeapon: true,
        ammoType: "50cal",
        weaponType: "SR",
    },

    {
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_Mk14_C.png",
        title: "Mk14",
        crateWeapon: true,
        ammoType: "762",
        weaponType: "DMR",
    },
    {
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_P90_C.png",
        title: "P90",
        crateWeapon: true,
        ammoType: "57",
        weaponType: "SMG",
    },
    {
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_DP12_C.png",
        title: "DBS",
        crateWeapon: true,
        ammoType: "12ga",
        weaponType: "SG",
    },
    // AR Weapons
    {
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_ACE32_C.png",
        title: "ACE32",
        ammoType: "762",
        weaponType: "AR",
    },
    {
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_AK47_C.png",
        title: "AKM",
        ammoType: "762",
        weaponType: "AR",
    },
    {
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_AUG_C.png",
        title: "AUG",
        ammoType: "556",
        weaponType: "AR",
    },
    {
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_BerylM762_C.png",
        title: "Beryl M762",
        ammoType: "762",
        weaponType: "AR",
    },
    {
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_G36C_C.png",
        title: "G36C",
        ammoType: "556",
        weaponType: "AR",
    },
    {
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_HK416_C.png",
        title: "M416",
        ammoType: "556",
        weaponType: "AR",
    },
    {
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_K2_C.png",
        title: "K2",
        ammoType: "556",
        weaponType: "AR",
    },
    {
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_M16A4_C.png",
        title: "M16A4",
        ammoType: "556",
        weaponType: "AR",
    },
    {
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_Mk47Mutant_C.png",
        title: "Mk47 Mutant",
        ammoType: "762",
        weaponType: "AR",
    },
    {
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_QBZ95_C.png",
        title: "QBZ",
        ammoType: "556",
        weaponType: "AR",
    },
    {
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_SCAR-L_C.png",
        title: "SCAR-L",
        ammoType: "556",
        weaponType: "AR",
    },

    // DMR Weapons
    {
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_Dragunov_C.png",
        title: "Dragunov",
        ammoType: "762",
        weaponType: "DMR",
    },
    {
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_Mini14_C.png",
        title: "Mini14",
        ammoType: "556",
        weaponType: "DMR",
    },
    {
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_Mk12_C.png",
        title: "Mk12",
        ammoType: "556",
        weaponType: "DMR",
    },
    {
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_QBU88_C.png",
        title: "QBU",
        ammoType: "556",
        weaponType: "DMR",
    },
    {
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_SKS_C.png",
        title: "SKS",
        ammoType: "762",
        weaponType: "DMR",
    },
    {
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_FNFal_C.png",
        title: "SLR",
        ammoType: "762",
        weaponType: "DMR",
    },
    {
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_VSS_C.png",
        title: "VSS",
        ammoType: "9mm",
        weaponType: "DMR",
    },

    // SR Weapons
    {
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_Kar98k_C.png",
        title: "Kar98k",
        ammoType: "762",
        weaponType: "SR",
    },
    {
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_M24_C.png",
        title: "M24",
        ammoType: "762",
        weaponType: "SR",
    },
    {
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_Win1894_C.png",
        title: "Win94",
        ammoType: "45",
        weaponType: "SR",
    },
    {
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_Mosin_C.png",
        title: "Mosin Nagant",
        ammoType: "762",
        weaponType: "SR",
    },
    // LMGs
    {
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_DP28_C.png",
        title: "DP-28",
        ammoType: "762",
        weaponType: "LMG",
    },
    {
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_MG3_C.png",
        title: "MG3",
        crateWeapon: true,
        ammoType: "762",
        weaponType: "LMG",
    },
    {
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_M249_C.png",
        title: "M249",
        ammoType: "556",
        weaponType: "LMG",
    },
    // SMG Weapons
    {
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_UMP_C.png",
        title: "UMP",
        ammoType: "45",
        weaponType: "SMG",
    },
    {
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_Vector_C.png",
        title: "Vector",
        ammoType: "9mm",
        weaponType: "SMG",
    },
    {
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_UZI_C.png",
        title: "Micro UZI",
        ammoType: "9mm",
        weaponType: "SMG",
    },
    {
        src: js9,
        title: "JS9",
        ammoType: "9mm",
        weaponType: "SMG",
    },
    {
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_MP9_C.png",
        title: "MP9",
        ammoType: "9mm",
        weaponType: "SMG",
    },
    {
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_MP5K_C.png",
        title: "MP5K",
        ammoType: "9mm",
        weaponType: "SMG",
    },
    {
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_BizonPP19_C.png",
        title: "PP-19 Bizon",
        ammoType: "9mm",
        weaponType: "SMG",
    },
    {
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_Thompson_C.png",
        title: "Tommy Gun",
        ammoType: "45",
        weaponType: "SMG",
    },
    // Shotguns
    {
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_Berreta686_C.png",
        title: "S686",
        ammoType: "12ga",
        weaponType: "SG",
    },
    {
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_Saiga12_C.png",
        title: "S12K",
        ammoType: "12ga",
        weaponType: "SG",
    },
    {
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_Winchester_C.png",
        title: "S1897",
        ammoType: "12ga",
        weaponType: "SG",
    },

    {
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_OriginS12_C.png",
        title: "O12",
        ammoType: "12gaSlug",
        weaponType: "SG",
    },
    // Miscellaneous Weapons
    {
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_PanzerFaust100M_C.png",
        title: "Panzerfaust",
        ammoType: "",
        weaponType: "Misc",
    },
    {
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_Crossbow_C.png",
        title: "Crossbow",
        ammoType: "Bolt",
        weaponType: "Misc",
    },
    {
        src: mortarImage,
        title: "Mortar",
        ammoType: "60",
        weaponType: "Misc",
    },
    // Handguns
    {
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Handgun/Item_Weapon_G18_C.png",
        title: "P18C",
        ammoType: "9mm",
        weaponType: "Handgun",
    },
    {
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Handgun/Item_Weapon_M1911_C.png",
        title: "P1911",
        ammoType: "45",
        weaponType: "Handgun",
    },
    {
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Handgun/Item_Weapon_M9_C.png",
        title: "P92",
        ammoType: "9mm",
        weaponType: "Handgun",
    },
    {
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Handgun/Item_Weapon_NagantM1895_C.png",
        title: "R1895",
        ammoType: "762",
        weaponType: "Handgun",
    },
    {
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Handgun/Item_Weapon_vz61Skorpion_C.png",
        title: "Skorpion",
        ammoType: "9mm",
        weaponType: "Handgun",
    },
    {
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Handgun/Item_Weapon_Rhino_C.png",
        title: "R45",
        ammoType: "45",
        weaponType: "Handgun",
    },
    {
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Handgun/Item_Weapon_Sawnoff_C.png",
        title: "Sawed-Off",
        ammoType: "12ga",
        weaponType: "Handgun",
    },
    {
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Handgun/Item_Weapon_DesertEagle_C.png",
        title: "Deagle",
        ammoType: "45",
        weaponType: "Handgun",
    },
    // Throwables
    {
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Equipment/Throwable/Item_Weapon_Grenade_C.png",
        title: "Frag Grenade",
        weaponType: "Throwable",
    },
    {
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Equipment/Throwable/Item_Weapon_Molotov_C.png",
        title: "Molotov Cocktail",
        weaponType: "Throwable",
    },
    {
        src: bzSquare,
        title: "BZ Grenade",
        weaponType: "Throwable",
    },
    {
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Equipment/Throwable/Item_Weapon_C4_C.png",
        title: "C4",
        weaponType: "Throwable",
    },
    {
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Equipment/Throwable/Item_Weapon_StickyGrenade_C.png",
        title: "Sticky Bomb",
        weaponType: "Throwable",
    },
];

// Function to assign IDs, rename 'src' to 'cardImage', and add 'masteryImage'
function assignIds(
    items: Omit<Omit<Omit<Weapon, "id">, "cardImage">, "masteryImage">[]
): Weapon[] {
    const placeholderImage = "https://example.com/placeholder.png"; // Replace with your actual placeholder URL or path

    return items.map((item, index) => {
        let cardImage = item.src || placeholderImage; // Ensure cardImage is always a string
        let masteryImage = placeholderImage; // Default to placeholder image

        // Handle special cases
        if (item.title === "Groza") {
            masteryImage =
                "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_GROZA_C_h.png";
        } else if (item.title === "G36C") {
            masteryImage =
                "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_G36C_C_h.png";
        } else if (item.title === "SLR") {
            masteryImage =
                "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_FNFAL_C_h.png";
        } else if (item.title === "Mortar") {
            masteryImage =
                "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_Mortar_C.png";
        } else if (item.title === "JS9") {
            masteryImage = js9Mastery;
        } else if (item.title === "Frag Grenade") {
            masteryImage = fragMastery;
        } else if (item.title === "BZ Grenade") {
            masteryImage = bzMastery;
        } else if (item.title === "Molotov Cocktail") {
            masteryImage = molotovMastery;
        } else if (item.title === "Sticky Bomb") {
            masteryImage = stickyMastery;
        } else if (item.title === "Panzerfaust") {
            masteryImage = panzerMastery;
        } else if (item.title === "C4") {
            masteryImage = c4Mastery;
        } else if (cardImage.includes("_C.png")) {
            masteryImage = cardImage.replace("_C.png", "_C_h.png");
        }

        return {
            ...item,
            id: index + 1, // Assign sequential IDs starting from 1
            cardImage,
            masteryImage,
        };
    });
}

// Array with assigned IDs
export const weapons: Weapon[] = assignIds(weaponsWithoutId);
