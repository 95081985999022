export const versionHistory = [
    {
        version: "Version 0.6.3 (December 24, 2024)",
        description: `
        - Integrated PUBG Report functionality to enhance player statistics with additional insights.
        - Added a comprehensive Preferences section for logged-in users, allowing many customization options.
        - Implemented dynamic dropdowns for favorite game mode selection, including FPP, TPP, and Arcade categories.
        - Enhanced user experience with Turnstile-based bot protection during initial website visits, ensuring seamless verification.
        - Improved Player Profile page with PUBG Report logo.
        - Fixed minor UI inconsistencies across various pages for better responsiveness and usability.
        `,
    },
    {
        version: "Version 0.6.2 (December 16, 2024)",
        description: `
    - Refined the Payment Options table to clearly distinguish one-time payments with no login required using subtle highlights.
    - Improved table readability by only highlighting relevant cells instead of entire rows for better user focus.
    - Added a subtle message below the table to guide users towards the easiest support options.
    - Introduced dynamic row rendering for payment methods, allowing for cleaner and more organized support type presentation.
    - Updated the FAQ section to address the absence of PUBG Mobile data, clarifying the lack of API support and ownership differences.
    - Ensured code consistency and maintainability by restructuring payment data into reusable constants.
    - Enhanced the Support Us page with clean formatting and highlighted key details, such as the $30 monthly operating cost.`,
    },
    {
        version: "Version 0.6.1 (December 14, 2024)",
        description: `
        - Redesigned the Navbar for both desktop and mobile to improve usability and aesthetics.
        - Implemented consistent button styling and page paddings across the application for a cohesive look and feel.
        - Introduced a custom color palette for centralized and reusable styling.
        - Enhanced the FAQ page by dynamically rendering questions and answers from a constants file.
        - Fixed spacing issues between images and buttons in category cards for better visual alignment.
        - Optimized the Sidebar layout to ensure it opens below the top bar and remains visually distinct.
        - Improved the design for ban status notifications, including a subtle "No Active Ban" style.
        - Added type safety for state variables like \`banType\` using TypeScript union types.
        - Refined the Player Search page to fix UI inconsistencies and improve dropdown functionality.
        `,
    },
    {
        version: "Version 0.6 (December 12, 2024)",
        description: `
        - Enhanced Tier List Details view.
        - Introduced optional authentication middleware for flexibility.
        `,
    },
    {
        version: "Version 0.5.9 (December 8, 2024)",
        description: `
        - Added full match analysis for saved matches.
        - Improved API performance for retrieving tier lists.
        - Fixed edge case issues with player search functionality.
        `,
    },
    {
        version: "Version 0.5.8 (December 7, 2024)",
        description: `
        - Finalized UI for player search and match statistics.
        - Improved validation for tier list creation.
        - Enhanced responsiveness across mobile devices.
        `,
    },
    {
        version: "Version 0.5.7 (December 5, 2024)",
        description: `
        - Added player stats landing page.
        - Implemented persistent player name across tabs.
        `,
    },
    {
        version: "Version 0.5.6 (December 1, 2024)",
        description: `
        - Improved UX for dragging and dropping items in tier lists.
        - Updated backend validations for tier list API.
        `,
    },
    {
        version: "Version 0.5.5 (November 28, 2024)",
        description: `
        - Added Google Analytics integration.
        - Enhanced backend logging for troubleshooting.
        `,
    },
    {
        version: "Version 0.5.4 (November 25, 2024)",
        description: `
        - Added password change functionality to user settings.
        - Introduced an option to delete accounts.
        `,
    },
    {
        version: "Version 0.5 (November 24, 2024)",
        description: `
        - Implemented drag-and-drop functionality for tier list creation.
        - Added public/private tier list browsing.
        - Improved user authentication flow.
        `,
    },
    {
        version: "Version 0.4.9 (November 22, 2024)",
        description: `
        - Introduced email verification for new users.
        - Added the ability to reset forgotten passwords.
        `,
    },
    {
        version: "Version 0.4.8 (November 20, 2024)",
        description: `
        - Introduced API for player search functionality.
        - Optimized backend tier list storage with MongoDB indexes.
        - Refined login and sign-up error handling.
        `,
    },
    {
        version: "Version 0.4.5 (November 18, 2024)",
        description: `
        - Added a feedback submission page for users to report issues or suggest features.
        - Improved error handling for invalid tier list inputs.
        `,
    },
    {
        version: "Version 0.4 (November 15, 2024)",
        description: `
        - Completed backend API for saving tier lists.
        - Added functionality to save and share player statistics.
        `,
    },
    {
        version: "Version 0.3.9 (November 13, 2024)",
        description: `
        - Launched a Patch History tab for tracking all PUBG updates.
        - Introduced a More Resources section for gameplay tips and strategies.
        `,
    },
    {
        version: "Version 0.3.5 (November 10, 2024)",
        description: `
        - Introduced user authentication using JWT.
        - Enhanced frontend forms with error validation for login/signup.
        - Improved responsiveness for mobile users.
        `,
    },
    {
        version: "Version 0.3.3 (November 8, 2024)",
        description: `
        - Created a Maps Landing Page with all 4k maps and secret room locations.
        - Introduced Esports match details page.
        `,
    },
    {
        version: "Version 0.3 (November 5, 2024)",
        description: `
        - Developed public browsing for tier lists.
        - Added user session management across tabs.
        - Introduced anonymous tier list creation.
        `,
    },
    {
        version: "Version 0.2.5 (November 4, 2024)",
        description: `
        - Added Tips and Strategy tab with beginner and advanced gameplay advice.
        - Introduced FAQ page to address common user questions.
        `,
    },
    {
        version: "Version 0.2 (November 3, 2024)",
        description: `
        - Set up initial project architecture with React and Express.
        - Created MongoDB schema for tier lists.
        - Designed initial wireframes for the application.
        `,
    },
    {
        version: "Version 0.1 (November 1, 2024)",
        description: `
        - Launched the project with a basic structure.
        - Created initial API endpoints for user registration.
        - Introduced a simple React frontend for login and signup forms.
        `,
    },
];
