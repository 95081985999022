import { useTheme } from "@mui/material";
import { ChartOptions } from "chart.js";
import React from "react";
import { Bar } from "react-chartjs-2";
import { Match } from "types/match";

interface KillsBarChartProps {
    matches: Match[];
    onClick: (index: number) => void;
}

const KillsBarChart: React.FC<KillsBarChartProps> = ({ matches, onClick }) => {
    const labels = matches.map((_, index) => `Match ${index + 1}`);
    const data = matches.map((match) => match.playerKills || 0);

    const theme = useTheme();

    const chartData = {
        labels,
        datasets: [
            {
                label: "Kills",
                data,
                backgroundColor: theme.palette.secondary.main,
            },
        ],
    };

    const options: ChartOptions<"bar"> = {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
            padding: {
                top: 20, // Add padding at the top
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    stepSize: 5,
                },
            },
        },
        onClick: (_: any, chartElement: any) => {
            if (chartElement.length > 0) {
                const index = chartElement[0].index;
                onClick(index);
            }
        },
        plugins: {
            datalabels: {
                display: (context) => {
                    const value = context.dataset.data[
                        context.dataIndex
                    ] as number; // Safely get the raw value
                    return value !== 0; // Only display if the value is not 0
                },
                anchor: "end",
                align: "end",
                color: theme.palette.text.primary,
                formatter: (value) => value.toLocaleString(), // Format the value
                font: {
                    size: 12,
                    weight: "bold",
                },
            },
            legend: {
                display: false,
            },
        },
    };

    return <Bar data={chartData} options={options} />;
};

export default KillsBarChart;
