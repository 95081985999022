import React, { useEffect, useState } from "react";
import {
    Box,
    Typography,
    Paper,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    SelectChangeEvent,
    Button,
    TextField,
    Modal,
    FormControlLabel,
    Checkbox,
    Divider,
} from "@mui/material";
import { useDrag, useDrop } from "react-dnd";
import { weapons } from "../../constants/tierListWeapons";
import { Weapon } from "../../types/weapon";
import { useParams } from "react-router-dom";
import { saveTierList } from "../../api/saveTierList";
import { useAuth } from "AuthContext";
import { COLORS } from "constants/appWide/colors";
import { maxWidths } from "constants/appWide/maxWidths";
import { WeaponCard } from "components/WeaponCard";
import { useShowWeaponCategories } from "hooks/useShowWeaponCategories";
import { API_ENDPOINTS } from "constants/API_ENDPOINTS";

const getWeaponTypeLabel = (type: string): string => {
    switch (type) {
        case "AR":
            return "Assault Rifles";
        case "DMR":
            return "Designated Marksman Rifles";
        case "Handgun":
            return "Handguns";
        case "LMG":
            return "Light Machine Guns";
        case "SG":
            return "Shotguns";
        case "SR":
            return "Sniper Rifles";
        case "SMG":
            return "Submachine Guns";
        case "Throwable":
            return "Throwables";
        case "Misc":
            return "Miscellaneous";
        default:
            return type;
    }
};

const TierListMaker: React.FC = () => {
    const { id } = useParams(); // Get the ID from the URL
    const [tierList, setTierList] = useState<{ [key: string]: Weapon[] }>({
        S: [],
        A: [],
        B: [],
        C: [],
    });
    const [fetchedName, setFetchedName] = useState<string | null>(null);
    const [tierListName, setTierListName] = useState<string>("");
    const [fetchedDescription, setFetchedDescription] = useState<string>("");
    const [createdBy, setCreatedBy] = useState<string>("");
    const [createdAt, setCreatedAt] = useState<string>("");

    const { isAuthenticated } = useAuth();

    const { showWeaponCategories, toggleShowWeaponCategories } =
        useShowWeaponCategories();

    // Form
    const [description, setDescription] = useState<string>("");
    const [showUsername, setShowUsername] = useState<boolean>(false);

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTierListName(event.target.value);
    };

    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);

    // Fetch the tier list by ID when the component loads
    useEffect(() => {
        const fetchTierList = async () => {
            if (!id) return;

            try {
                const response = await fetch(API_ENDPOINTS.GET_TIER_LIST(id));
                if (response.ok) {
                    const data = await response.json();

                    // Convert backend data (IDs) to frontend data (Weapon[])
                    const tierListFromBackend: { [key: string]: Weapon[] } = {};
                    for (const tier in data.tierList) {
                        tierListFromBackend[tier] = data.tierList[tier]
                            .map((id: number) =>
                                weapons.find((item) => item.id === id)
                            )
                            .filter(
                                (item: Weapon): item is Weapon =>
                                    item !== undefined
                            );
                    }

                    setTierList(tierListFromBackend);
                    setFetchedName(data.name || "");
                    setFetchedDescription(data.description || ""); // Set description
                    setCreatedBy(data.createdBy || ""); // Set createdBy
                    setCreatedAt(data.createdAt || ""); // Set createdAt
                } else {
                    console.error("Failed to fetch tier list");
                }
            } catch (error) {
                console.error("Error fetching tier list:", error);
            }
        };

        fetchTierList();
    }, [id]);

    const [selectedWeaponType, setSelectedWeaponType] = useState<string>("All");

    const handleWeaponTypeChange = (event: SelectChangeEvent<string>) => {
        setSelectedWeaponType(event.target.value);
    };

    const getWeaponsInTiers = (): Set<number> => {
        const idsInTiers = new Set<number>();
        Object.values(tierList).forEach((items) => {
            items.forEach((item) => idsInTiers.add(item.id));
        });
        return idsInTiers;
    };

    const [filteredWeapons, setFilteredWeapons] = useState<Weapon[]>([]);

    const computeFilteredWeapons = () => {
        const idsInTiers = getWeaponsInTiers();
        return weapons.filter((weapon) => {
            const matchesType =
                selectedWeaponType === "All" ||
                weapon.weaponType === selectedWeaponType;
            return matchesType && !idsInTiers.has(weapon.id);
        });
    };

    useEffect(() => {
        setFilteredWeapons(computeFilteredWeapons());
    }, [selectedWeaponType, tierList, weapons]);

    const handleRandomizeWeapons = () => {
        const shuffled = [...filteredWeapons].sort(() => Math.random() - 0.5);
        setFilteredWeapons(shuffled);
    };

    const [isPublic, setIsPublic] = useState(false);

    const handleSaveTierList = async () => {
        // Check if the name is empty
        if (tierListName.trim().length < 3) {
            alert("Tier list name must be at least 3 characters long!");
            return;
        }

        // Check if the tier list has any selections
        const hasSelections = Object.values(tierList).some(
            (items) => items.length > 0
        );
        if (!hasSelections) {
            alert("You must select at least one item in the tier list!");
            return;
        }
        await saveTierList(
            tierList,
            tierListName,
            isPublic,
            description,
            showUsername
        );
        handleCloseModal();
    };

    const moveWeaponToTier = (weapon: Weapon, tier: string | null) => {
        setTierList((prev) => {
            const newTierList = { ...prev };

            // Remove the image from any previous tier
            for (const key in newTierList) {
                newTierList[key] = newTierList[key].filter(
                    (item) => item.id !== weapon.id
                );
            }

            // If tier is null, the item goes back to the bottom list
            if (tier) {
                newTierList[tier] = [...newTierList[tier], weapon];
            }

            return newTierList;
        });
    };

    return (
        <Box sx={{ padding: "20px", textAlign: "center" }}>
            <Typography variant="h5" gutterBottom>
                Tier List Maker
            </Typography>
            {id && (
                <Paper
                    elevation={3}
                    sx={{
                        padding: "20px",
                        margin: "0 auto",
                        maxWidth: maxWidths.m,
                        textAlign: "left",
                    }}
                >
                    <Typography
                        variant="h5"
                        gutterBottom
                        sx={{ fontWeight: "bold", color: "primary.main" }}
                    >
                        {fetchedName}
                    </Typography>

                    <Divider sx={{ marginBottom: 2 }} />

                    {fetchedDescription && (
                        <>
                            <Typography variant="h6" gutterBottom>
                                Description:
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{ marginBottom: 3, whiteSpace: "pre-line" }}
                            >
                                {fetchedDescription}
                            </Typography>
                        </>
                    )}

                    {/* Created Info */}
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexWrap: "wrap",
                            marginTop: 2,
                            padding: "10px",

                            borderRadius: "8px",
                        }}
                    >
                        <Box>
                            <Typography
                                variant="subtitle1"
                                color="textSecondary"
                            >
                                Created By:
                            </Typography>
                            <Typography variant="body1">
                                {createdBy || "Anonymous"}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography
                                variant="subtitle1"
                                color="textSecondary"
                            >
                                Created On:
                            </Typography>
                            <Typography variant="body1">{createdAt}</Typography>
                        </Box>
                    </Box>
                </Paper>
            )}

            <Typography
                marginBottom={3}
                marginTop={1}
                variant="body1"
                gutterBottom
            >
                Drag and drop images into the tiers below or back to the list.
            </Typography>

            {/* Vertical Tier Rows */}
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: 2,
                    width: "100%",
                }}
            >
                {["S", "A", "B", "C"].map((tier) => (
                    <TierRow
                        key={tier}
                        tier={tier}
                        weapons={tierList[tier]}
                        onDrop={(item) => moveWeaponToTier(item, tier)}
                    />
                ))}
            </Box>

            {/* Images to Drag */}
            <Box
                sx={{
                    marginTop: "20px",
                    border: "1px dashed gray",
                    padding: "10px",
                    minHeight: "150px",
                    textAlign: "center",
                }}
                ref={
                    useDrop({
                        accept: "IMAGE",
                        drop: (item: Weapon) => moveWeaponToTier(item, null),
                    })[1]
                } // Use drop ref for the weapons list
            >
                <Typography variant="h6">
                    Drag weapons here to remove them from tiers
                </Typography>
                {/* Button to Share Tier List */}
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOpenModal}
                    sx={{
                        textTransform: "uppercase",
                    }}
                >
                    Share Tier List
                </Button>

                <Box
                    sx={{
                        display: "flex", // Enables flexbox
                        justifyContent: "center", // Centers horizontally
                        alignItems: "center", // Centers vertically
                        marginTop: "20px",
                        gap: "20px",
                    }}
                >
                    <FormControl
                        sx={{
                            maxWidth: "200px",
                            width: "100%", // Optional: Ensures it scales well
                        }}
                    >
                        <InputLabel id="Weapon Type Label">
                            Weapon Type Filter
                        </InputLabel>
                        <Select
                            label="Weapon Type Label"
                            value={selectedWeaponType}
                            onChange={handleWeaponTypeChange}
                        >
                            <MenuItem value="All">All</MenuItem>
                            <MenuItem value="AR">Assault Rifle (AR)</MenuItem>
                            <MenuItem value="DMR">
                                Designated Marksman Rifle (DMR)
                            </MenuItem>
                            <MenuItem value="Handgun">Handgun</MenuItem>
                            <MenuItem value="LMG">
                                Light Machine Gun (LMG)
                            </MenuItem>
                            <MenuItem value="SG">Shotgun (SG)</MenuItem>
                            <MenuItem value="SR">Sniper Rifle (SR)</MenuItem>
                            <MenuItem value="SMG">
                                Submachine Gun (SMG)
                            </MenuItem>
                            <MenuItem value="Throwable">Throwable</MenuItem>
                            <MenuItem value="Misc">Miscellaneous</MenuItem>
                        </Select>
                    </FormControl>

                    {selectedWeaponType === "All" && (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={showWeaponCategories}
                                    onChange={(e) =>
                                        toggleShowWeaponCategories(
                                            e.target.checked
                                        )
                                    }
                                    sx={{
                                        color: "white",
                                        "&.Mui-checked": {
                                            color: COLORS.orange,
                                        },
                                    }}
                                />
                            }
                            label="Group by Category"
                            sx={{
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        />
                    )}
                </Box>

                <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => handleRandomizeWeapons()}
                    sx={{ marginTop: "20px" }}
                >
                    Randomize Order
                </Button>

                <Box display="flex" flexDirection="column" gap={4} mt={3}>
                    {showWeaponCategories ? (
                        [
                            "AR",
                            "DMR",
                            "Handgun",
                            "LMG",
                            "SG",
                            "SR",
                            "SMG",
                            "Misc",
                        ].map((type) => {
                            const weaponsByType = filteredWeapons.filter(
                                (weapon) => weapon.weaponType === type
                            );

                            if (weaponsByType.length === 0) return null; // Skip empty categories

                            return (
                                <Box key={type}>
                                    {/* Header for the type */}
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            textTransform: "capitalize",
                                            color: COLORS.orange,
                                            mb: 2,
                                            textAlign: "center",
                                        }}
                                    >
                                        {getWeaponTypeLabel(type)}
                                    </Typography>

                                    {/* Weapons for the type */}
                                    <Box
                                        display="flex"
                                        gap={2}
                                        flexWrap="wrap"
                                        justifyContent="center" // Center-align weapons in the row
                                    >
                                        {weaponsByType.map((weapon) => (
                                            <DraggableImage
                                                key={weapon.id}
                                                weapon={weapon}
                                            />
                                        ))}
                                    </Box>
                                </Box>
                            );
                        })
                    ) : (
                        <Box
                            display="flex"
                            flexWrap="wrap"
                            gap={2}
                            justifyContent="center"
                        >
                            {filteredWeapons.map((weapon) => (
                                <DraggableImage
                                    key={weapon.id}
                                    weapon={weapon}
                                />
                            ))}
                        </Box>
                    )}
                </Box>
            </Box>

            {/* Modal for Tier List Name */}
            <Modal
                open={isModalOpen}
                onClose={handleCloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        backgroundColor: "#2e2e2e",
                        padding: "20px",
                        borderRadius: "8px",
                        boxShadow: 24,
                        textAlign: "center",
                        width: "400px", // Optional: Set width to avoid dynamic resizing
                    }}
                >
                    <Typography id="modal-title" variant="h6" gutterBottom>
                        Save Your Tier List
                    </Typography>
                    <TextField
                        id="tier-list-name"
                        label="Tier List Name"
                        variant="outlined"
                        fullWidth
                        required
                        value={tierListName}
                        onChange={handleNameChange}
                        sx={{ marginBottom: "20px" }}
                    />
                    <TextField
                        id="tier-list-description"
                        label="Description (optional)"
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={3}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        sx={{
                            marginBottom: "10px",
                            input: { color: "white" },
                            label: { color: "white" },
                            "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                    borderColor: "white",
                                },
                                "&:hover fieldset": {
                                    borderColor: COLORS.orange,
                                },
                                "&.Mui-focused fieldset": {
                                    borderColor: COLORS.orange,
                                },
                            },
                        }}
                    />
                    {isAuthenticated && (
                        <>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isPublic}
                                        onChange={(e) =>
                                            setIsPublic(e.target.checked)
                                        }
                                        sx={{
                                            color: "white",
                                            "&.Mui-checked": {
                                                color: COLORS.orange,
                                            },
                                        }}
                                    />
                                }
                                label="Make this tier list public"
                                sx={{
                                    alignItems: "left",
                                    justifyContent: "left",
                                    textAlign: "left",
                                }}
                            />
                            {isPublic && (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={showUsername}
                                            onChange={(e) =>
                                                setShowUsername(
                                                    e.target.checked
                                                )
                                            }
                                            sx={{
                                                color: "white",
                                                "&.Mui-checked": {
                                                    color: COLORS.orange,
                                                },
                                            }}
                                        />
                                    }
                                    label="Show my username on the public list browser"
                                    sx={{
                                        marginBottom: "20px", // Add space between the checkbox and the button
                                        alignItems: "center",
                                        justifyContent: "center",
                                        color: "white",
                                    }}
                                />
                            )}
                        </>
                    )}
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSaveTierList}
                        sx={{ marginTop: 1, textTransform: "none" }}
                    >
                        Save and Share
                    </Button>
                    {!isAuthenticated && (
                        <>
                            <Typography marginTop={1}>
                                You are not logged in, so you can only share the
                                tier list link with your friends.
                            </Typography>
                            <Typography marginTop={1}>
                                If you want to publish a tier list to the public
                                list browser, you need to log in.
                            </Typography>
                        </>
                    )}
                </Box>
            </Modal>
        </Box>
    );
};

export default TierListMaker;

// Tier Row Component
const TierRow: React.FC<{
    tier: string;
    weapons: Weapon[];
    onDrop: (weapon: Weapon) => void;
}> = ({ tier, weapons, onDrop }) => {
    const [, dropRef] = useDrop({
        accept: "IMAGE",
        drop: (weapon: Weapon) => onDrop(weapon),
    });

    return (
        <Paper
            ref={dropRef}
            sx={{
                width: "100%",
                maxWidth: maxWidths.m,
                padding: "10px",
                minHeight: "120px",
                textAlign: "left",
                display: "flex",
                flexDirection: "column",
                gap: 1,
            }}
        >
            <Typography variant="h6">{tier} Tier</Typography>
            <Box display="flex" gap={1} flexWrap="wrap" mt={1}>
                {weapons.map((weapon) => (
                    <DraggableImage key={weapon.id} weapon={weapon} />
                ))}
            </Box>
        </Paper>
    );
};

// Draggable Image Component
const DraggableImage: React.FC<{ weapon: Weapon }> = ({ weapon }) => {
    const [, dragRef] = useDrag({
        type: "IMAGE",
        item: weapon,
    });

    return (
        <Box
            ref={dragRef}
            sx={{
                textAlign: "center",
                position: "relative",
            }}
        >
            <WeaponCard
                src={weapon.src}
                title={weapon.title}
                crateWeapon={weapon.crateWeapon}
                ammoType={weapon.ammoType}
                weaponType={weapon.weaponType}
                draggable
            />
        </Box>
    );
};
