export const GameModeMap: Record<string, string> = {
    // Arcade Modes
    tdm: "TDM",
    ibr: "IBR",
    landmark: "Hot Drop",

    // Standard Modes
    solo: "Solo TPP",
    "solo-fpp": "Solo FPP",
    duo: "Duo TPP",
    "duo-fpp": "Duo FPP",
    squad: "Squad TPP",
    "squad-fpp": "Squad FPP",

    // Normal Modes
    "normal-solo": "Normal Solo TPP",
    "normal-solo-fpp": "Normal Solo FPP",
    "normal-duo": "Normal Duo TPP",
    "normal-duo-fpp": "Normal Duo FPP",
    "normal-squad": "Normal Squad TPP",
    "normal-squad-fpp": "Normal Squad FPP",

    // War Modes
    "war-solo": "War Solo TPP",
    "war-solo-fpp": "War Solo FPP",
    "war-duo": "War Duo TPP",
    "war-duo-fpp": "War Duo FPP",
    "war-squad": "War Squad TPP",
    "war-squad-fpp": "War Squad FPP",

    // Zombie Modes
    "zombie-solo": "Zombie Solo TPP",
    "zombie-solo-fpp": "Zombie Solo FPP",
    "zombie-duo": "Zombie Duo TPP",
    "zombie-duo-fpp": "Zombie Duo FPP",
    "zombie-squad": "Zombie Squad TPP",
    "zombie-squad-fpp": "Zombie Squad FPP",

    // Conquest Modes
    "conquest-solo": "Conquest Solo TPP",
    "conquest-solo-fpp": "Conquest Solo FPP",
    "conquest-duo": "Conquest Duo TPP",
    "conquest-duo-fpp": "Conquest Duo FPP",
    "conquest-squad": "Conquest Squad TPP",
    "conquest-squad-fpp": "Conquest Squad FPP",

    // Esports Modes
    "esports-solo": "Esports Solo TPP",
    "esports-solo-fpp": "Esports Solo FPP",
    "esports-duo": "Esports Duo TPP",
    "esports-duo-fpp": "Esports Duo FPP",
    "esports-squad": "Esports Squad TPP",
    "esports-squad-fpp": "Esports Squad FPP",

    // Lab Modes
    "lab-tpp": "Lab TPP",
    "lab-fpp": "Lab FPP",
};
