export const handleSearchAction = async (
    action: () => Promise<void>,
    setErrorMessage: (message: string) => void
) => {
    try {
        await action();
        setErrorMessage(""); // Clear error message on success
    } catch (error) {
        if (error instanceof Error) {
            setErrorMessage(error.message); // Set error message
        } else {
            setErrorMessage("An unknown error occurred."); // Fallback message
        }
    }
};
