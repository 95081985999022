// src/pages/TipsStrategies.tsx
import React, { useEffect } from "react";
import {
    Box,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ReactGA from "react-ga4";
import { SECTIONS } from "constants/tipsStrategies";
import { maxWidths } from "constants/appWide/maxWidths";

export const TipsStrategies: React.FC = () => {
    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: "/tips-strategies",
            title: "Tips & Strategies Page",
        });
    }, []);

    return (
        <Box sx={{ padding: "20px", maxWidth: maxWidths.m, margin: "auto" }}>
            <Typography variant="h5" gutterBottom align="center">
                Tips & Strategies
            </Typography>
            {SECTIONS.map((section, index) => (
                <Accordion key={index}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6">{section.title}</Typography>
                    </AccordionSummary>
                    <AccordionDetails
                        sx={{
                            textAlign: "left",
                            marginTop: "-20px",
                        }}
                    >
                        {section.content}
                    </AccordionDetails>
                </Accordion>
            ))}
        </Box>
    );
};
