export const processLogMatchEnd = (logMatchEndEvent: any) => {
    const playerRankings: {
        name: string;
        accountId: string;
        ranking: number;
        individualRanking: number;
        primaryWeaponFirst?: string;
        primaryWeaponSecond?: string;
    }[] = [];

    const botList: {
        name: string;
        accountId: string;
        ranking: number;
        individualRanking: number;
    }[] = [];

    if (logMatchEndEvent.characters) {
        logMatchEndEvent.characters.forEach((characterData: any) => {
            const { character, primaryWeaponFirst, primaryWeaponSecond } =
                characterData;
            const { name, accountId, ranking, individualRanking } = character;

            // Map player details
            const playerDetails: {
                name: string;
                accountId: string;
                ranking: number;
                individualRanking: number;
                primaryWeaponFirst?: string;
                primaryWeaponSecond?: string;
            } = {
                name,
                accountId,
                ranking,
                individualRanking,
            };

            // Add weapons if ranking is 1
            if (ranking === 1) {
                playerDetails.primaryWeaponFirst = primaryWeaponFirst || "None";
                playerDetails.primaryWeaponSecond =
                    primaryWeaponSecond || "None";
            }

            playerRankings.push(playerDetails);

            // Add to bot list if accountId starts with "ai."
            if (accountId.startsWith("ai.")) {
                botList.push({ name, accountId, ranking, individualRanking });
            }
        });
    }

    return { playerRankings, botList };
};
