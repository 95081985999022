// utils.ts
export interface Roster {
    id: string;
    rank: number;
    teamId: number;
    won: boolean;
    participants: string[];
    totalKills?: number;
    totalDamage?: number;
    playerStats?: {
        name: string;
        kills: number;
        assists: number;
        DBNOs: number;
        damageDealt: number;
        timeSurvived: number;
    }[];
}

export interface Participant {
    id: string;
    name: string;
    walkDistance: number;
    winPlace: number;
    timeSurvived: number;
    playerId: string;

    // Damage Related
    damageDealt: number;
    kills: number;
    assists: number;
    DBNOs: number;
}

export const computeRosterStats = (
    rosters: Roster[],
    participants: Participant[],
    isTdm: boolean
): Roster[] => {
    return rosters.map((roster) => {
        const rosterParticipants = roster.participants.map((id) =>
            participants.find((p) => p.id === id)
        );

        const totalKills = rosterParticipants.reduce(
            (sum, p) => sum + (p?.kills || 0),
            0
        );
        const totalDamage = rosterParticipants.reduce(
            (sum, p) => sum + (p?.damageDealt || 0),
            0
        );

        // Sort playerStats with specific logic based on game mode
        const playerStats = rosterParticipants
            .filter((p) => p !== undefined) // Filter out undefined participants
            .map((p) => ({
                name: p?.name || "Unknown",
                kills: p?.kills || 0,
                assists: p?.assists || 0,
                DBNOs: p?.DBNOs || 0,
                damageDealt: p?.damageDealt || 0,
                timeSurvived: p?.timeSurvived || 0,
            }))
            .sort((a, b) => {
                if (isTdm) {
                    // TDM-specific sorting: kills -> damage
                    if (b.kills !== a.kills) return b.kills - a.kills;
                    return b.damageDealt - a.damageDealt;
                } else {
                    // Default sorting: survival -> kills -> damage -> DBNOs -> assists
                    const survivalA =
                        a.timeSurvived /
                        (rosterParticipants[0]?.timeSurvived || 1); // Avoid division by zero
                    const survivalB =
                        b.timeSurvived /
                        (rosterParticipants[0]?.timeSurvived || 1); // Avoid division by zero
                    if (survivalB !== survivalA) return survivalB - survivalA;
                    if (b.kills !== a.kills) return b.kills - a.kills;
                    if (b.damageDealt !== a.damageDealt)
                        return b.damageDealt - a.damageDealt;
                    if (b.DBNOs !== a.DBNOs) return b.DBNOs - a.DBNOs;
                    return b.assists - a.assists;
                }
            });

        return {
            ...roster,
            totalKills,
            totalDamage,
            playerStats,
        };
    });
};
