import React, { useEffect, useRef } from "react";
import {
    ReactFlow,
    MiniMap,
    Controls,
    Background,
    useNodesState,
    useEdgesState,
    Node,
    Edge,
    OnNodesChange,
    OnEdgesChange,
} from "@xyflow/react";
import dagre from "@dagrejs/dagre";
import "@xyflow/react/dist/style.css";
import { useTheme, Button } from "@mui/material";

interface KillTreeProps {
    killTree: {
        [killer: string]: {
            victim: string;
            distance: number;
            weapon: string;
        }[];
    };
    remainingPlayers: string[]; // Players still alive at the end
    focusPlayer: string;
}

type KillNodeData = { label: string };
type KillNode = Node<KillNodeData>;
type KillEdge = Edge<any>;

const KillTree: React.FC<KillTreeProps> = ({
    killTree,
    remainingPlayers,
    focusPlayer,
}) => {
    const [nodes, setNodes, onNodesChange] = useNodesState<KillNode>([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState<KillEdge>([]);

    const theme = useTheme();
    const containerRef = useRef<HTMLDivElement>(null); // Reference to the container

    useEffect(() => {
        const newNodes: KillNode[] = [];
        const newEdges: KillEdge[] = [];
        const playerIds: { [name: string]: string } = {};
        let idCounter = 0;

        const addNode = (name: string, isRoot = false) => {
            if (!playerIds[name]) {
                playerIds[name] = `player-${idCounter++}`;
                const isFocusPlayer = name === focusPlayer;
                newNodes.push({
                    id: playerIds[name],
                    data: { label: name },
                    position: { x: 0, y: 0 },
                    type: "default",
                    draggable: false,
                    style: {
                        background: isFocusPlayer
                            ? theme.palette.primary.main
                            : isRoot
                            ? "linear-gradient(to bottom, #FFD700, #FFA500)"
                            : "linear-gradient(to bottom, #89CFF0, #6F8FAF)",
                        border: "1px solid #555",
                        borderRadius: "8px",
                        padding: "10px",
                        textAlign: "center",
                        color: "#fff",
                        fontWeight: "bold",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                    },
                });
            }
        };

        // Add nodes and edges for kills
        Object.keys(killTree).forEach((killer) => {
            addNode(killer);

            killTree[killer].forEach(({ victim, distance, weapon }) => {
                addNode(victim);

                newEdges.push({
                    id: `edge-${playerIds[killer]}-${playerIds[victim]}`,
                    source: playerIds[killer],
                    target: playerIds[victim],
                    type: "smoothstep",
                    label: `${weapon} ${Math.round(distance / 100)}m`,
                    labelStyle: { fill: "#333", fontSize: 12 },
                    animated: true,
                    style: { stroke: "#00bcd4", strokeWidth: 2 },
                });
            });
        });

        // Add a "Winning Team" root node
        if (remainingPlayers.length > 1) {
            const rootNodeId = `root-winning-team`;
            newNodes.push({
                id: rootNodeId,
                data: { label: "Winning Team" },
                position: { x: 0, y: 0 },
                type: "default",
                draggable: false,
                style: {
                    background: "linear-gradient(to bottom, #32CD32, #228B22)",
                    border: "1px solid #444",
                    borderRadius: "8px",
                    padding: "10px",
                    textAlign: "center",
                    color: "#fff",
                    fontWeight: "bold",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
                },
            });

            // Connect remaining players to the root node
            remainingPlayers.forEach((player) => {
                addNode(player);

                newEdges.push({
                    id: `edge-${rootNodeId}-${playerIds[player]}`,
                    source: rootNodeId,
                    target: playerIds[player],
                    type: "smoothstep",
                    label: "",
                    animated: true,
                    style: { stroke: "#32CD32", strokeWidth: 2 },
                });
            });
        }

        // Apply tree layout using dagre
        const dagreGraph = new dagre.graphlib.Graph();
        dagreGraph.setDefaultEdgeLabel(() => ({}));
        dagreGraph.setGraph({ rankdir: "TB", align: "UL" }); // Top-to-bottom layout

        newNodes.forEach((node) =>
            dagreGraph.setNode(node.id, { width: 150, height: 50 })
        );
        newEdges.forEach((edge) =>
            dagreGraph.setEdge(edge.source, edge.target)
        );

        dagre.layout(dagreGraph);

        // Update node positions based on layout
        newNodes.forEach((node) => {
            const nodeWithPosition = dagreGraph.node(node.id);
            node.position = {
                x: nodeWithPosition.x,
                y: nodeWithPosition.y,
            };
        });

        setNodes(newNodes);
        setEdges(newEdges);
    }, [killTree, remainingPlayers, focusPlayer, setNodes, setEdges]);

    const handleFullScreen = () => {
        if (containerRef.current) {
            if (document.fullscreenElement) {
                document.exitFullscreen();
            } else {
                containerRef.current.requestFullscreen();
            }
        }
    };

    useEffect(() => {
        const handleFullscreenChange = () => {
            if (!document.fullscreenElement) {
                window.scrollTo({
                    top: document.body.scrollHeight,
                });
            }
        };

        document.addEventListener("fullscreenchange", handleFullscreenChange);

        return () => {
            document.removeEventListener(
                "fullscreenchange",
                handleFullscreenChange
            );
        };
    }, []);

    return (
        <div
            ref={containerRef} // Attach the ref to the container
            style={{
                height: "600px",
                width: "100%",
                backgroundColor: theme.palette.background.paper,
                position: "relative",
            }}
        >
            <Button
                variant="contained"
                color="primary"
                onClick={handleFullScreen}
                style={{
                    position: "absolute",
                    top: 10,
                    right: 10,
                    zIndex: 10, // Ensure the button is above the flow
                }}
            >
                Toggle Fullscreen
            </Button>
            <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange as OnNodesChange<KillNode>}
                onEdgesChange={onEdgesChange as OnEdgesChange<KillEdge>}
                fitView
                attributionPosition="bottom-left"
            >
                <MiniMap nodeColor={() => "#555"} />
            </ReactFlow>
        </div>
    );
};

export default KillTree;
