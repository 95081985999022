import React from "react";
import { Link } from "react-router-dom";
import { IconButton } from "@mui/material";
import InsightsIcon from "@mui/icons-material/Insights";
import { useTheme } from "@mui/material/styles";

interface QuickActionButtonProps {
    playerName: string;
    platform: string;
}

const QuickActionButton: React.FC<QuickActionButtonProps> = ({
    playerName,
    platform,
}) => {
    const theme = useTheme();

    return (
        <Link
            to={`/player-stats/${platform}/${encodeURIComponent(playerName)}`}
            style={{ textDecoration: "none" }}
        >
            <IconButton
                sx={{
                    width: 40,
                    height: 40,
                    backgroundColor: theme.palette.custom.accentColor2,
                    color: "white",
                    "&:hover": {
                        backgroundColor: theme.palette.custom.accentColor2,
                        color: "white",
                    },
                }}
            >
                <InsightsIcon fontSize="medium" />
            </IconButton>
        </Link>
    );
};

export default QuickActionButton;
