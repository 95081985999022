import React, { useEffect, useState } from "react";

type ControlType = "cycle" | "action";

interface KeyboardControllerProps<T> {
    controlType: ControlType; // "cycle" for q/e, "action" for f
    items?: readonly T[]; // For "cycle", array of items to navigate
    currentIndex?: number; // For "cycle", the current index
    setIndex?: (newIndex: number) => void; // For "cycle", function to update index
    actionKey?: string; // For "action", the key to trigger the action
    onAction?: () => void; // For "action", the function to execute
    prevKey?: string; // For "cycle", key to move backward
    nextKey?: string; // For "cycle", key to move forward
}

export const KeyboardController = <T>({
    controlType,
    items = [],
    currentIndex,
    setIndex,
    actionKey = "f",
    onAction,
    prevKey = "q",
    nextKey = "e",
}: KeyboardControllerProps<T>) => {
    const [isKeyHandled, setIsKeyHandled] = useState(false);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (isKeyHandled) return;

            if (
                controlType === "cycle" &&
                items &&
                currentIndex !== undefined &&
                setIndex
            ) {
                if (event.key.toLowerCase() === prevKey && currentIndex > 0) {
                    setIsKeyHandled(true);
                    setIndex(currentIndex - 1);
                } else if (
                    event.key.toLowerCase() === nextKey &&
                    currentIndex < items.length - 1
                ) {
                    setIsKeyHandled(true);
                    setIndex(currentIndex + 1);
                }
            } else if (
                controlType === "action" &&
                event.key.toLowerCase() === actionKey &&
                onAction
            ) {
                setIsKeyHandled(true);
                onAction();
            }
        };

        const handleKeyUp = (event: KeyboardEvent) => {
            if (
                (controlType === "cycle" &&
                    (event.key.toLowerCase() === prevKey ||
                        event.key.toLowerCase() === nextKey)) ||
                (controlType === "action" &&
                    event.key.toLowerCase() === actionKey)
            ) {
                setIsKeyHandled(false);
            }
        };

        window.addEventListener("keydown", handleKeyDown);
        window.addEventListener("keyup", handleKeyUp);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
            window.removeEventListener("keyup", handleKeyUp);
        };
    }, [
        controlType,
        items,
        currentIndex,
        setIndex,
        actionKey,
        onAction,
        prevKey,
        nextKey,
        isKeyHandled,
    ]);

    return null; // No UI for this component
};
