import React, { useEffect, useState } from "react";
import {
    Tabs,
    Tab,
    Box,
    Typography,
    Tooltip,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import {
    Routes,
    Route,
    useLocation,
    useNavigate,
    useParams,
} from "react-router-dom";
import PlayerProfile from "./PlayerProfile";
import RecentMatches from "./RecentMatches";
import LifetimeStats from "./lifetimeStats/LifetimeStats";
import SeasonStats from "./SeasonStats";
import MatchDetails from "./MatchDetails";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { COLORS } from "constants/appWide/colors";
import { KeyboardController } from "hooks/KeyboardController";

// A and D Images
import aKey from "assets/webButtons/blankA.jpg";
import dKey from "assets/webButtons/blankD.jpg";

const TAB_ROUTES = [
    "/player-stats/:platform/:playerName/profile",
    "/player-stats/:platform/:playerName/matches",
    "/player-stats/:platform/:playerName/lifetime",
    "/player-stats/:platform/:playerName/season",
];

const TAB_TOOLTIPS = [
    "View player information, including Survival Mastery, Weapon Mastery, and Clan details.",
    "Access match data from the past 14 days.",
    "Explore lifetime stats.",
    "Check detailed stats for the current or past seasons, similar to what's displayed in the PUBG Career > Stats section.",
];

const TAB_LABELS = [
    "Player Profile",
    "Recent Matches",
    "Lifetime Stats",
    "Season Stats",
];

const PlayerStatsLandingPage: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const { platform = "steam", playerName = "Unknown" } = useParams<{
        platform?: string;
        playerName?: string;
    }>();

    const isMatchDetailsPage = /\/matches\/[a-z0-9-]+$/.test(location.pathname);

    const activeTab = TAB_ROUTES.findIndex((route) => {
        const resolvedRoute = route
            .replace(":platform", platform)
            .replace(":playerName", encodeURIComponent(playerName || ""));
        return location.pathname.startsWith(resolvedRoute);
    });

    // Responsive design
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const [currentTabIndex, setCurrentTabIndex] = useState(activeTab);

    useEffect(() => {
        // Sync the currentTabIndex with the activeTab whenever location changes
        if (activeTab !== -1 && activeTab !== currentTabIndex) {
            setCurrentTabIndex(activeTab);
        }
    }, [activeTab, currentTabIndex]);

    useEffect(() => {
        // Construct the expected URL for the default profile page
        const expectedUrl = TAB_ROUTES[0]
            .replace(":platform", platform)
            .replace(
                ":playerName",
                encodeURIComponent(playerName || "Unknown")
            );

        // Check if the current URL is incomplete (landing page) and redirect
        if (
            !location.pathname.includes("/profile") &&
            location.pathname ===
                `/player-stats/${platform}/${encodeURIComponent(
                    playerName || "Unknown"
                )}`
        ) {
            navigate(expectedUrl, { replace: true });
        }
    }, [location.pathname, navigate, platform, playerName]);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        const targetUrl = TAB_ROUTES[newValue]
            .replace(":platform", platform)
            .replace(":playerName", encodeURIComponent(playerName || ""));
        navigate(targetUrl);
    };

    const tabNavigation = (newIndex: number) => {
        if (newIndex >= 0 && newIndex < TAB_ROUTES.length) {
            setCurrentTabIndex(newIndex);
            const targetUrl = TAB_ROUTES[newIndex]
                .replace(":platform", platform)
                .replace(":playerName", encodeURIComponent(playerName || ""));
            navigate(targetUrl);
        }
    };

    return (
        <Box sx={{ width: "100%", padding: 2 }}>
            <KeyboardController
                controlType="cycle"
                items={TAB_ROUTES}
                currentIndex={currentTabIndex}
                setIndex={tabNavigation}
                prevKey="a"
                nextKey="d"
            />

            <Box
                sx={{
                    display: "flex",
                }}
            >
                {/* Left Image (Desktop Only) */}
                {!isMobile && (
                    <img
                        src={aKey}
                        alt="Navigate Left (A)"
                        style={{
                            width: 30,
                            height: 30,
                            marginTop: -4,
                            marginRight: 8,
                        }}
                    />
                )}
                <Tabs
                    value={
                        isMatchDetailsPage
                            ? 1
                            : activeTab !== -1
                            ? activeTab
                            : false
                    }
                    onChange={handleTabChange}
                    variant="fullWidth"
                    aria-label="Player Stats Tabs"
                    sx={{
                        mt: -2,
                        flexGrow: 1,
                        "& .MuiTab-root": {
                            borderBottom: "1px solid #ccc", // Gray bottom border for inactive tabs
                            color: "gray", // Inactive tab text color
                        },
                        "& .Mui-selected": {
                            color: "orange !important", // Orange text color for selected tab
                        },
                        "& .MuiTabs-indicator": {
                            backgroundColor: COLORS.orange, // Orange bottom border for selected tab
                        },
                    }}
                >
                    {TAB_ROUTES.map((route, index) => (
                        <Tooltip
                            key={route}
                            title={
                                <Typography sx={{ fontSize: "0.875rem" }}>
                                    {TAB_TOOLTIPS[index]}
                                </Typography>
                            }
                            arrow
                        >
                            <Tab
                                label={
                                    index === 1 && isMatchDetailsPage ? (
                                        <Box display="inline">
                                            {!isMobile && (
                                                <>
                                                    <Typography
                                                        component="span"
                                                        sx={{
                                                            color: "text.secondary",
                                                            fontSize:
                                                                "0.875rem",
                                                        }}
                                                    >
                                                        Recent Matches
                                                    </Typography>
                                                    <ArrowRightAltIcon
                                                        sx={{
                                                            fontSize: "1rem",
                                                            color: "text.secondary",
                                                            marginBottom:
                                                                "-3px",
                                                        }}
                                                    />
                                                </>
                                            )}

                                            <Typography
                                                component="span"
                                                sx={{
                                                    color: "primary.main",
                                                    fontSize: "0.875rem",
                                                }}
                                            >
                                                Match Details
                                            </Typography>
                                        </Box>
                                    ) : (
                                        TAB_LABELS[index]
                                    )
                                }
                            />
                        </Tooltip>
                    ))}
                </Tabs>
                {/* Right Image (Desktop Only) */}
                {!isMobile && (
                    <img
                        src={dKey}
                        alt="Navigate Right (D)"
                        style={{
                            width: 30,
                            height: 30,
                            marginTop: -4,
                            marginLeft: 8,
                        }}
                    />
                )}
            </Box>
            <Box sx={{ marginTop: 2 }}>
                <Routes>
                    <Route path="profile" element={<PlayerProfile />} />
                    <Route path="matches">
                        <Route index element={<RecentMatches />} />
                        <Route path=":matchId" element={<MatchDetails />} />
                    </Route>
                    <Route path="lifetime">
                        <Route index element={<LifetimeStats />} />
                        <Route
                            path="compare/:player2Name"
                            element={<LifetimeStats />}
                        />
                    </Route>
                    <Route path="season/:seasonId?" element={<SeasonStats />} />
                </Routes>
            </Box>
        </Box>
    );
};

export default PlayerStatsLandingPage;
