import { mapsUrlAndSize } from "constants/mapsUrlAndSize";

export const retiredMap = [
    {
        name: "Haven",
        url: mapsUrlAndSize["Haven"].url,
        size: mapsUrlAndSize["Haven"].size,
        players: 32,
    },
];
