const seasonDates: Record<number, { name: string; startDate: string }> = {
    1: { name: "Season 1", startDate: "October 2018" },
    2: { name: "Season 2", startDate: "December 2018" },
    3: { name: "Season 3", startDate: "March 2019" },
    4: { name: "Season 4", startDate: "July 2019" },
    5: { name: "Season 5", startDate: "October 2019" },
    6: { name: "Season 6", startDate: "January 2020" },
    7: { name: "Season 7", startDate: "May 2020" },
    8: { name: "Season 8", startDate: "July 2020" },
    9: { name: "Season 9", startDate: "October 2020" },
    10: { name: "Season 10", startDate: "December 2020" },
    11: { name: "Season 11", startDate: "March 2021" },
    12: { name: "Season 12", startDate: "June 2021" },
    13: { name: "Season 13", startDate: "August 2021" },
    14: { name: "Season 14", startDate: "October 2021" },
    15: { name: "Season 15", startDate: "November 2021" },
    16: { name: "Season 16", startDate: "February 2022" },
    17: { name: "Season 17", startDate: "April 2022" },
    18: { name: "Season 18", startDate: "June 2022" },
    19: { name: "Season 19", startDate: "August 2022" },
    20: { name: "Season 20", startDate: "October 2022" },
    21: { name: "Season 21", startDate: "December 2022" },
    22: { name: "Season 22", startDate: "February 2023" },
    23: { name: "Season 23", startDate: "April 2023" },
    24: { name: "Season 24", startDate: "June 2023" },
    25: { name: "Season 25", startDate: "August 2023" },
    26: { name: "Season 26", startDate: "October 2023" },
    27: { name: "Season 27", startDate: "December 2023" },
    28: { name: "Season 28", startDate: "February 2024" },
    29: { name: "Season 29", startDate: "April 2024" },
    30: { name: "Season 30", startDate: "June 2024" },
    31: { name: "Season 31", startDate: "August 2024" },
    32: { name: "Season 32", startDate: "October 2024" },
    33: { name: "Season 33", startDate: "December 2024" },
    34: { name: "Season 33", startDate: "February 2025" },
};

const generateSeasonStartDate = (): Record<
    string,
    { name: string; startDate: string }
> => {
    const metadata: Record<string, { name: string; startDate: string }> = {};

    for (const [number, { name, startDate }] of Object.entries(seasonDates)) {
        const seasonNumber = parseInt(number);
        metadata[`pc-2018-${String(seasonNumber).padStart(2, "0")}`] = {
            name,
            startDate,
        };
        if (seasonNumber >= 3) {
            metadata[`console-${String(seasonNumber).padStart(2, "0")}`] = {
                name,
                startDate,
            };
        }
    }

    return metadata;
};

const seasonMetadata = generateSeasonStartDate();

export const formatSeasonName = (seasonId: string): string => {
    // Extract the correct key format from `seasonId`
    const steamMatch = seasonId.match(/pc-\d{4}-(\d{2})/); // Match PC seasons
    const consoleMatch = seasonId.match(/console-(\d{2})/); // Match Console seasons

    let key = "";

    if (steamMatch) {
        key = `pc-2018-${steamMatch[1]}`;
    } else if (consoleMatch) {
        key = `console-${consoleMatch[1]}`;
    }

    console.log("Looking for key:", key); // Debugging purpose

    const season = seasonMetadata[key];

    if (season) {
        return `${season.name} - Started: ${season.startDate}`;
    }

    return `Unknown Season - ID: ${seasonId}`; // Fallback for unmatched keys
};

export const getSeasonStatus = (
    season: any,
    currentSeasonId: string
): string => {
    if (season.attributes.isCurrentSeason) {
        return "(Current)";
    } else if (
        parseInt(season.id.match(/\d{2}$/)?.[0] || "0", 10) >
        parseInt(currentSeasonId.match(/\d{2}$/)?.[0] || "0", 10)
    ) {
        return "(Upcoming)";
    }
    return ""; // Return an empty string for past seasons
};
