import { useTheme } from "@mui/material";
import React from "react";
import { Bar } from "react-chartjs-2";
import { Match } from "types/match";

interface DamageBarChartProps {
    matches: Match[];
    onClick: (index: number) => void;
}

const DamageBarChart: React.FC<DamageBarChartProps> = ({
    matches,
    onClick,
}) => {
    const labels = matches.map((_, index) => `Match ${index + 1}`);
    const data = matches.map((match) => match.playerDamage || 0);

    const theme = useTheme();

    const chartData = {
        labels,
        datasets: [
            {
                label: "Damage",
                data,
                backgroundColor: theme.palette.custom.accentColor2,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    stepSize: 500,
                },
            },
        },
        onClick: (_: any, chartElement: any) => {
            if (chartElement.length > 0) {
                const index = chartElement[0].index;
                onClick(index);
            }
        },
        plugins: {
            datalabels: {
                display: false, // Disable data labels for this chart
            },
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: (context: any) => {
                        const value = context.raw as number; // Raw value of the bar
                        return `Damage: ${Math.round(value).toLocaleString()}`; // Round and format
                    },
                },
            },
        },
    };

    return <Bar data={chartData} options={options} />;
};

export default DamageBarChart;
