import React, { useEffect } from "react";
import {
    Box,
    Button,
    Typography,
    Card,
    CardContent,
    Grid,
} from "@mui/material";
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";
import { COLORS } from "constants/appWide/colors";

export const TierListsLanding: React.FC = () => {
    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: "/tier-lists",
            title: "Tier Lists Page",
        });
    }, []);

    return (
        <Box sx={{ p: 2, textAlign: "center" }}>
            <Typography variant="h5" sx={{ mb: 4 }}>
                Welcome to Tier Lists
            </Typography>
            <Typography variant="h6" sx={{ mb: 6 }}>
                Create and explore tier lists to dominate your favorite games!
                Select an option below to get started.
            </Typography>
            <Grid container spacing={4} justifyContent="center">
                {/* Tier List Maker Card */}
                <Grid item xs={12} sm={6} md={4}>
                    <Card sx={{ textAlign: "center", height: "100%" }}>
                        <CardContent>
                            <Typography variant="h5" sx={{ mb: 2 }}>
                                Tier List Maker
                            </Typography>
                            <Typography variant="body1" sx={{ mb: 4 }}>
                                Build your own tier lists with our interactive
                                drag-and-drop tool. Rank characters, items, and
                                more!
                            </Typography>
                            <Button
                                variant="contained"
                                sx={{
                                    textTransform: "uppercase",
                                }}
                                component={Link}
                                to="/tier-lists/maker"
                            >
                                Create Tier List
                            </Button>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Browse Tier Lists Card */}
                <Grid item xs={12} sm={6} md={4}>
                    <Card sx={{ textAlign: "center", height: "100%" }}>
                        <CardContent>
                            <Typography variant="h5" sx={{ mb: 2 }}>
                                Browse Tier Lists
                            </Typography>
                            <Typography variant="body1" sx={{ mb: 4 }}>
                                Explore tier lists created by the community.
                                Find strategies and ideas to improve your
                                gameplay.
                            </Typography>
                            <Button
                                variant="contained"
                                sx={{
                                    textTransform: "uppercase",
                                }}
                                component={Link}
                                to="/tier-lists/public"
                            >
                                Browse Tier Lists
                            </Button>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
};
