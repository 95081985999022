import React, { useEffect } from "react";
import { Box, Divider, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { maxWidths } from "constants/appWide/maxWidths";

const PrivacyPolicy: React.FC = () => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                padding: { xs: "16px", md: "40px" }, // Responsive padding
                color: theme.palette.text.primary, // Use the theme's text color
                maxWidth: maxWidths.l, // Limit the maximum width of the content
                margin: "0 auto", // Center the content horizontally
                borderRadius: "8px", // Add rounded corners for better aesthetics
                textAlign: "left", // Align text to the left
            }}
        >
            <Typography variant="h4" gutterBottom sx={{ textAlign: "left" }}>
                Privacy Policy
            </Typography>

            <Typography
                variant="body1"
                paragraph
                sx={{ textAlign: "left", marginBottom: 2 }}
            >
                Your privacy matters to me at PUBG Meta. This page explains what
                kind of information I collect, why I collect it, and how it’s
                used and protected while you’re enjoying the site.
            </Typography>

            <Divider />

            <Typography
                variant="h5"
                gutterBottom
                sx={{ textAlign: "left", marginTop: 2 }}
            >
                What Information I Collect
            </Typography>
            <Box
                component="ul"
                sx={{ paddingLeft: 2, margin: 0, marginBottom: 2 }}
            >
                <Box component="li" sx={{ marginBottom: 2 }}>
                    <Typography variant="body1" paragraph>
                        <strong>Google Analytics:</strong>
                        <br />I use Google Analytics to see how visitors like
                        you use the site. This includes:
                    </Typography>
                    <Box
                        component="ul"
                        sx={{ paddingLeft: 3, margin: 0, marginBottom: 2 }}
                    >
                        <Box component="li">
                            <Typography variant="body2">
                                Which pages are most popular
                            </Typography>
                        </Box>
                        <Box component="li">
                            <Typography variant="body2">
                                How long visitors spend on different parts of
                                the site
                            </Typography>
                        </Box>
                        <Box component="li">
                            <Typography variant="body2">
                                What types of devices or browsers are being used
                            </Typography>
                        </Box>
                    </Box>
                    <Typography variant="body1" paragraph>
                        This helps me figure out what works well and where I can
                        make improvements. All data collected is anonymous,
                        meaning I can’t see specific details about you
                        personally—just overall trends.
                    </Typography>
                </Box>
                <Box component="li">
                    <Typography variant="body1" paragraph>
                        <strong>Cookies:</strong>
                        <br />
                        Cookies are small text files stored on your device when
                        you visit a website. On PUBG Meta, cookies are used to:
                    </Typography>
                    <Box component="ul" sx={{ paddingLeft: 3, margin: 0 }}>
                        <Box component="li">
                            <Typography variant="body2">
                                Remember your preferences, like dark mode
                                settings or selected language
                            </Typography>
                        </Box>
                        <Box component="li">
                            <Typography variant="body2">
                                Track general usage trends (anonymously) for
                                improving the site
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>

            <Divider />
            <Typography
                variant="h5"
                gutterBottom
                sx={{ textAlign: "left", marginTop: 2 }}
            >
                How I Use Your Information
            </Typography>
            <Box
                component="ul"
                sx={{ paddingLeft: 2, margin: 0, marginBottom: 2 }}
            >
                <Box component="li" sx={{ marginBottom: 2 }}>
                    <Typography variant="body1">
                        Improving the website – By seeing what works and what
                        doesn’t, I can make changes to give you a smoother
                        experience.
                    </Typography>
                </Box>
                <Box component="li" sx={{ marginBottom: 2 }}>
                    <Typography variant="body1">
                        Analyzing trends – Trends help me understand how people
                        use the site, like which features are most helpful.
                    </Typography>
                </Box>
                <Box component="li">
                    <Typography variant="body1">
                        Debugging and security – If something breaks or looks
                        suspicious, the data helps me figure out what’s going on
                        and fix it.
                    </Typography>
                </Box>
            </Box>
            <Divider />

            <Typography
                variant="h5"
                gutterBottom
                sx={{ textAlign: "left", marginTop: 2 }}
            >
                Your Privacy Choices
            </Typography>
            <Typography variant="body1" paragraph sx={{ textAlign: "left" }}>
                I believe in giving you control over your data. Here’s what you
                can do:
            </Typography>
            <Typography variant="body1" paragraph sx={{ textAlign: "left" }}>
                1. Opt out of Google Analytics If you don’t want your visits
                included in my analytics, you can use the
                <a
                    href="https://tools.google.com/dlpage/gaoptout"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                        color: theme.palette.primary.main,
                        textDecoration: "none",
                    }}
                >
                    Google Analytics Opt-out Browser Add-on
                </a>
                .
            </Typography>
            <Typography variant="body1" paragraph sx={{ textAlign: "left" }}>
                2. Disable cookies. Most browsers let you block or delete
                cookies in their settings. Just keep in mind that some features
                on PUBG Meta, like saving your preferences, might not work as
                expected if cookies are disabled.
            </Typography>

            <Divider />

            <Typography
                variant="h5"
                gutterBottom
                sx={{ textAlign: "left", marginTop: 2 }}
            >
                Data Security
            </Typography>
            <Typography variant="body1" paragraph sx={{ textAlign: "left" }}>
                I do my best to keep your data safe with up-to-date security
                measures. However, the internet is not 100% secure, and I
                encourage you to be cautious about sharing sensitive information
                online.
            </Typography>

            <Typography variant="h5" gutterBottom sx={{ textAlign: "left" }}>
                Contact Us
            </Typography>
            <Typography variant="body1" paragraph sx={{ textAlign: "left" }}>
                If you have any questions, concerns, or feedback about this
                Privacy Policy, feel free to reach out through the
                <Link
                    to="/contact"
                    style={{
                        textDecoration: "none",
                        color: theme.palette.primary.main,
                        marginLeft: "4px",
                    }}
                >
                    Contact
                </Link>{" "}
                option in the top-left menu. I’m always happy to help!
            </Typography>
        </Box>
    );
};

export default PrivacyPolicy;
