import React, { useState } from "react";
import { TextField, Button, Box, Typography } from "@mui/material";
import axiosInstance from "api/axios";
import { COLORS } from "constants/appWide/colors";

export const ForgotPassword: React.FC = () => {
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setMessage("");
        setError("");

        try {
            const response = await axiosInstance.post(
                "/api/auth/forgot-password",
                {
                    email,
                }
            );
            if (response.status === 200) {
                setMessage(
                    "If there is an account associated with this email address, you will receive a password reset link shortly. The link expires in 1 hour. If you do not see the email, please check your spam folder and ensure you have spelled the email address correctly. If you continue to have issues, you can contact us directly using the left menu and choosing the 'Contact' option."
                );
            }
        } catch (err: any) {
            setError(err.response?.data?.message || "Something went wrong.");
        }
    };

    return (
        <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
                p: 3,
                maxWidth: 600, // Set max width
                margin: "0 auto", // Center the content
            }}
        >
            <Typography variant="h5" gutterBottom>
                Forgot Password
            </Typography>
            <TextField
                label="Email Address"
                variant="outlined"
                fullWidth
                margin="normal"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            {message && (
                <Typography
                    sx={{ pl: 1, textAlign: "left" }}
                    color="success.main"
                >
                    {message}
                </Typography>
            )}
            {error && <Typography color="error">{error}</Typography>}
            <Button
                type="submit"
                variant="contained"
                sx={{
                    mt: 3,
                    backgroundColor: COLORS.orange,
                    fontWeight: "bold",
                    ":hover": {
                        backgroundColor: COLORS.darkOrange,
                    },
                }}
            >
                Send Reset Link
            </Button>
        </Box>
    );
};
