import React, { useState, useEffect } from "react";
import {
    Box,
    Typography,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
} from "@mui/material";
import { Match } from "types/match";
import { getDateSuffix } from "utils/getDateSuffix";

interface DailyStats {
    date: string;
    value: number;
}

interface DailyStatsGridProps {
    filteredMatches: Match[];
}

const DailyStatsGrid: React.FC<DailyStatsGridProps> = ({ filteredMatches }) => {
    const [selectedMetric, setSelectedMetric] = useState<string>("kills");
    const [dailyStats, setDailyStats] = useState<DailyStats[]>([]);

    useEffect(() => {
        const calculateDailyStats = () => {
            const stats: DailyStats[] = [];
            const today = new Date();

            for (let i = 13; i >= 0; i--) {
                const date = new Date(today);
                date.setDate(today.getDate() - i);
                const dateString = date.toISOString().split("T")[0];

                const matchesForDay = filteredMatches.filter((match) => {
                    const matchDate = match.createdAt.split("T")[0];
                    return matchDate === dateString;
                });

                let value = 0;
                switch (selectedMetric) {
                    case "kills":
                        value = matchesForDay.reduce(
                            (sum, match) => sum + match.playerKills,
                            0
                        );
                        break;
                    case "matchesPlayed":
                        value = matchesForDay.length;
                        break;
                    // case "kd":
                    //     const totalKills = matchesForDay.reduce(
                    //         (sum, match) => sum + match.playerKills,
                    //         0
                    //     );
                    //     const totalDeaths = matchesForDay.length;
                    //     value =
                    //         totalDeaths > 0
                    //             ? parseFloat(
                    //                   (totalKills / totalDeaths).toFixed(2)
                    //               )
                    //             : 0;
                    //     break;
                    case "damage":
                        value = Math.round(
                            matchesForDay.reduce(
                                (sum, match) => sum + match.playerDamage,
                                0
                            )
                        );
                        break;

                    case "survivalTime":
                        value = matchesForDay.reduce(
                            (sum, match) =>
                                sum + Math.floor(match.playerSurvivalTime / 60),
                            0
                        );
                        break;
                    default:
                        break;
                }

                stats.push({ date: dateString, value });
            }

            setDailyStats(stats);
        };

        calculateDailyStats();
    }, [filteredMatches, selectedMetric]);

    const minStat = Math.min(...dailyStats.map((stat) => stat.value), 0);
    const maxStat = Math.max(...dailyStats.map((stat) => stat.value), 1);

    const getBackgroundColor = (value: number) => {
        const normalized = (value - minStat) / (maxStat - minStat);
        if (normalized === 0) return "background.paper";
        if (normalized <= 0.33) return "#FFA500";
        if (normalized <= 0.66) return "#FFFF00";
        return "secondary.main";
    };

    const dayNames = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
    const todayIndex = new Date().getDay();
    const orderedDayNames = [
        ...dayNames.slice(todayIndex),
        ...dayNames.slice(0, todayIndex),
    ];

    return (
        <Box
            sx={{
                backgroundColor: "#2e2e2e",
                border: "1px solid #444",
                borderRadius: "8px",
                padding: 2,
                mb: 4,
            }}
        >
            {/* Title and Radio Buttons */}
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 2,
                }}
            >
                <Typography variant="h6">14-Day Heatmap for</Typography>

                <FormControl component="fieldset">
                    <RadioGroup
                        row
                        value={selectedMetric}
                        onChange={(e) => setSelectedMetric(e.target.value)}
                    >
                        <FormControlLabel
                            value="kills"
                            control={<Radio />}
                            label="Kills"
                        />
                        <FormControlLabel
                            value="matchesPlayed"
                            control={<Radio />}
                            label="Matches Played"
                        />
                        {/* <FormControlLabel
                            value="kd"
                            control={<Radio />}
                            label="K/D"
                        /> */}
                        <FormControlLabel
                            value="damage"
                            control={<Radio />}
                            label="Damage"
                        />
                        <FormControlLabel
                            value="survivalTime"
                            control={<Radio />}
                            label="Survival Time"
                        />
                    </RadioGroup>
                </FormControl>
            </Box>

            {/* Inner Box for Heatmap */}
            <Box
                sx={{
                    backgroundColor: "#2e2e2e",
                    border: "1px solid #666",
                    borderRadius: "8px",
                    padding: 2,
                    overflowX: "auto", // Make it scrollable on mobile
                }}
            >
                {/* Heatmap */}
                <Box
                    display="grid"
                    gridTemplateColumns="repeat(7, 1fr)"
                    gap={1}
                >
                    {/* Top Row: Day and Date */}
                    {dailyStats.slice(0, 7).map((stat, index) => {
                        const date = new Date(stat.date);
                        const day = date.getDate();
                        return (
                            <Typography
                                key={`top-${index}`}
                                align="center"
                                sx={{ fontSize: "0.9rem", color: "#aaa" }}
                            >
                                {orderedDayNames[index]} {day}
                                {getDateSuffix(day)}
                            </Typography>
                        );
                    })}
                    {dailyStats.map((stat, index) => (
                        <Box
                            key={index}
                            bgcolor={getBackgroundColor(stat.value)}
                            color="black"
                            p={2}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            sx={{
                                aspectRatio: "1 / 0.6",
                                borderRadius: "4px",
                                whiteSpace: "nowrap",
                            }}
                        >
                            {selectedMetric === "damage"
                                ? stat.value.toLocaleString()
                                : selectedMetric === "survivalTime"
                                ? (() => {
                                      const hours = Math.floor(stat.value / 60);
                                      const minutes = stat.value % 60;
                                      return `${
                                          hours > 0 ? `${hours}h ` : ""
                                      }${minutes}m`;
                                  })()
                                : stat.value}
                        </Box>
                    ))}
                    {/* Bottom Row: Date Continuation */}
                    {dailyStats.slice(7).map((stat, index) => {
                        const date = new Date(stat.date);
                        const day = date.getDate();
                        return (
                            <Typography
                                key={`bottom-${index}`}
                                align="center"
                                sx={{ fontSize: "0.9rem", color: "#aaa" }}
                            >
                                {day}
                                {getDateSuffix(day)}
                            </Typography>
                        );
                    })}
                </Box>
            </Box>
        </Box>
    );
};

export default DailyStatsGrid;
